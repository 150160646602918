<template>
  <v-form ref="form" id="frmPersonal">
    <div>
      <v-row>
        <v-col md="4">
          <v-select
            v-model="form.title"
            :items="visibleTitlesList"
            name="title"
            outlined
            label="Title"
            style="width: 50%"
            required
            :error-messages="titleErrors"
            @input="$v.form.title.$touch(), selectGender()"
            @blur="$v.form.title.$touch(), selectGender()"
            @change="currentState(), selectGender()"
          >
          </v-select>
          <!--v-select
            v-model="form.gender"
            :items="genders"
            name="gender"
            outlined
            item-text="txt"
            item-value="id"
            label="Gender"
            style="width: 50%"
            required
            :error-messages="genderErrors"
            @input="$v.form.gender.$touch()"
            @blur="$v.form.gender.$touch()"
            @change="currentState()"
          >
          </v-select-->
          <v-text-field
            v-model="form.dob"
            name="dob"
            label="Date of Birth"
            outlined
            required
            :error-messages="dobErrors"
            @input="$v.form.dob.$touch()"
            @blur="$v.form.dob.$touch()"
            type="date"
            @change="currentState()"
          ></v-text-field>

          <v-text-field
            v-model="form.fname"
            name="fname"
            outlined
            :counter="50"
            label="First Name"
            required
            :error-messages="fnameErrors"
            @input="$v.form.fname.$touch()"
            @blur="$v.form.fname.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.mname"
            name="mname"
            outlined
            :counter="50"
            label="Middle Name(s)"
            :error-messages="mnameErrors"
            @input="$v.form.mname.$touch()"
            @blur="$v.form.mname.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.lname"
            name="lname"
            outlined
            :counter="50"
            label="Surname"
            required
            :error-messages="lnameErrors"
            @input="$v.form.lname.$touch()"
            @blur="$v.form.lname.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-divider class="my-5"></v-divider>
          <!-- Permenent Address -->
          <span>Permanent Home Address</span>
          <v-text-field
            v-model="form.pafline"
            name="pafline"
            outlined
            :counter="50"
            label="Address Line 1"
            required
            :error-messages="paflineErrors"
            @input="$v.form.pafline.$touch()"
            @blur="$v.form.pafline.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.pasline"
            name="pasline"
            outlined
            :counter="50"
            label="Address Line 2"
            :error-messages="paslineErrors"
            @input="$v.form.pasline.$touch()"
            @blur="$v.form.pasline.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.pacity"
            name="pacity"
            outlined
            :counter="50"
            label="City"
            required
            :error-messages="pacityErrors"
            @input="$v.form.pacity.$touch()"
            @blur="$v.form.pacity.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.pacounty"
            name="pacounty"
            outlined
            :counter="50"
            label="County"
            :error-messages="pacountyErrors"
            @input="$v.form.pacounty.$touch()"
            @blur="$v.form.pacounty.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.papostcode"
            name="papostcode"
            outlined
            :counter="9"
            label="Postcode"
            style="width: 50%"
            required
            :error-messages="papostcodeErrors"
            @input="$v.form.papostcode.$touch()"
            @blur="$v.form.papostcode.$touch()"
            @change="currentState()"
          ></v-text-field>
          <span
            >Is your Permanent Address your current address of residence where
            you physically live?</span
          >
          <v-radio-group v-model="form.paissame" row @change="currentState()">
            <v-radio
              label="Yes"
              value="1"
              name="paissame"
              @click="addressSame"
            ></v-radio>
            <v-radio
              label="No"
              value="0"
              name="paissame"
              @click="addressNotSame"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4" v-if="form.paissame == '0'">
          <span>Current Address (where you physically live)</span>
          <v-text-field
            v-model="form.cufline"
            name="cufline"
            outlined
            :counter="50"
            label="Address Line 1"
            required
            :error-messages="cuflineErrors"
            @input="$v.form.cufline.$touch()"
            @blur="$v.form.cufline.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.cusline"
            name="cusline"
            outlined
            :counter="50"
            label="Address Line 2"
            :error-messages="cuslineErrors"
            @input="$v.form.cusline.$touch()"
            @blur="$v.form.cusline.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.cucity"
            name="cucity"
            outlined
            :counter="50"
            label="City"
            required
            :error-messages="cucityErrors"
            @input="$v.form.cucity.$touch()"
            @blur="$v.form.cucity.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.cucounty"
            name="cucounty"
            outlined
            :counter="50"
            label="County"
            :error-messages="cucountyErrors"
            @input="$v.form.cucounty.$touch()"
            @blur="$v.form.cucounty.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.cupostcode"
            name="cupostcode"
            outlined
            :counter="9"
            label="Postcode"
            style="width: 50%"
            required
            :error-messages="cupostcodeErrors"
            @input="$v.form.cupostcode.$touch()"
            @blur="$v.form.cupostcode.$touch()"
            @change="currentState()"
          ></v-text-field></v-col
      ></v-row>
      <v-row>
        <v-col md="4">
          <v-text-field
            v-model="form.dlno"
            name="dlno"
            outlined
            :counter="16"
            label="Driving Licence Number"
            required
            :error-messages="dlnoErrors"
            @input="$v.form.dlno.$touch()"
            @blur="$v.form.dlno.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.adilno"
            name="adilno"
            outlined
            :counter="6"
            label="ADI/PDI Licence Number"
            required
            :error-messages="adilnoErrors"
            @input="$v.form.adilno.$touch()"
            @blur="$v.form.adilno.$touch()"
            @change="currentState()"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { M_DD, GET_TITLES } from "@/store/mutation-list";
import { mapGetters } from "vuex";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "Personal",
  data() {
    return {
      genders: [
        { id: "female", txt: "Female" },
        { id: "male", txt: "Male" },
      ],
      form: {
        title: "",
        gender: "",
        dob: "",
        fname: "",
        mname: "",
        lname: "",

        permaddr: "",
        pafline: "",
        pasline: "",
        pacity: "",
        pacounty: "",
        papostcode: "",

        paissame: "",

        curaddr: "",
        cufline: "",
        cusline: "",
        cucity: "",
        cucounty: "",
        cupostcode: "",

        dlno: "",
        adilno: "",
      },
    };
  },
  validations: {
    form: {
      title: { required },
      gender: { required },
      dob: { required },
      fname: { required, maxLength: maxLength(50) },
      mname: { maxLength: maxLength(50) },
      lname: { required, maxLength: maxLength(50) },

      //permaddr: { required, maxLength: maxLength(50) },
      pafline: { required, maxLength: maxLength(50) },
      pasline: { maxLength: maxLength(50) },
      pacity: { required, maxLength: maxLength(50) },
      pacounty: { maxLength: maxLength(50) },
      papostcode: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(9),
      },

      paissame: { required },

      //curaddr: { required, maxLength: maxLength(50) },
      cufline: { required, maxLength: maxLength(50) },
      cusline: { maxLength: maxLength(50) },
      cucity: { required, maxLength: maxLength(50) },
      cucounty: { maxLength: maxLength(50) },
      cupostcode: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(8),
      },

      dlno: { required, maxLength: maxLength(16), minLength: minLength(16) },
      adilno: { required, maxLength: maxLength(6), minLength: minLength(6) },
    },
  },
  methods: {
    currentState() {
      this.$emit("peronal-update", {
        state: this.$v.form.$invalid,
        data: this.form,
        fd: new FormData(this.$refs.form.$el),
      });
    },
    addressSame() {
      //current address is permenent address
      this.form.cufline = this.form.pafline;
      this.form.cusline = this.form.pasline;
      this.form.cucity = this.form.pacity;
      this.form.cucounty = this.form.pacounty;
      this.form.cupostcode = this.form.papostcode;
    },
    addressNotSame() {
      //current address is NO permenent address
      this.form.cufline = "";
      this.form.cusline = "";
      this.form.cucity = "";
      this.form.cucounty = "";
      this.form.cupostcode = "";
    },
    //this function select the correct gender based on selected title
    selectGender() {
      if (this.form.title && this.titlesList) {
        //there are some data.
        //lets filter through and find out the correct ones

        let gen = this.titlesList.find((rec) => rec.id === this.form.title).gen;

        if (gen == "m") {
          this.form.gender = "male";
        } else if (gen == "f") {
          this.form.gender = "female";
        } else {
          this.form.gender = "";
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      titlesList: `${M_DD}${GET_TITLES}`,
    }),
    //this section is to generate titles with  fav and duplicates and separate
    visibleTitlesList() {
      if (this.titlesList) {
        //lets convert this to vuerify array
        /*
        {
            text: string | number | object,
            value: string | number | object,
            disabled: boolean,
            divider: boolean,
            header: string
          }
        */
        let converted = [];
        let fav = [];
        this.titlesList.forEach((title) => {
          //console.log(title.txt);
          //need to remove master and miss

          converted.push({
            text: title.txt,
            value: title.id,
            disabled: false,
            divider: false,
          });
          //get favorite to a list
          if (title.favourite == 1) {
            fav.push({
              text: title.txt,
              value: title.id,
              disabled: false,
              divider: false,
            });
          }
        });
        //separator line
        fav.push({
          text: "---",
          value: null,
          disabled: true,
          divider: true,
        });
        let retArr = [...fav, ...converted];

        //had to filter it twice to get the title out.

        return retArr
          .filter((elem) => elem.value !== "Miss")
          .filter((elem) => elem.value !== "Master");
      }
      return [];
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.form.title.$dirty) return errors;
      !this.$v.form.title.required && errors.push("Title is required.");
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.form.gender.$dirty) return errors;
      !this.$v.form.gender.required && errors.push("Gender is required.");
      return errors;
    },
    dobErrors() {
      const errors = [];
      if (!this.$v.form.dob.$dirty) return errors;
      !this.$v.form.dob.required && errors.push("Date of birth  is required.");

      return errors;
    },
    fnameErrors() {
      const errors = [];
      if (!this.$v.form.fname.$dirty) return errors;
      !this.$v.form.fname.maxLength &&
        errors.push("Name must be at most 50 characters long");
      !this.$v.form.fname.required && errors.push("Name is required.");

      return errors;
    },
    mnameErrors() {
      const errors = [];
      if (!this.$v.form.mname.$dirty) return errors;
      !this.$v.form.mname.maxLength &&
        errors.push("Name must be at most 50 characters long");
      return errors;
    },
    lnameErrors() {
      const errors = [];
      if (!this.$v.form.lname.$dirty) return errors;
      !this.$v.form.lname.maxLength &&
        errors.push("Name must be at most 50 characters long");
      !this.$v.form.lname.required && errors.push("Name is required.");
      return errors;
    },
    permaddrErrors() {
      return [];
    },
    paflineErrors() {
      const errors = [];
      if (!this.$v.form.pafline.$dirty) return errors;
      !this.$v.form.pafline.maxLength &&
        errors.push("Address Line 1 is too long. Max 50 characters allowed.");
      !this.$v.form.pafline.required &&
        errors.push("Address Line 1 is required.");

      return errors;
    },
    paslineErrors() {
      const errors = [];
      if (!this.$v.form.pasline.$dirty) return errors;
      !this.$v.form.pasline.maxLength &&
        errors.push("Address Line 2 is too long. Max 60 characters allowed.");

      return errors;
    },
    pacityErrors() {
      const errors = [];
      if (!this.$v.form.pacity.$dirty) return errors;
      !this.$v.form.pacity.maxLength &&
        errors.push("City name is too long. Max 60 characters allowed.");
      !this.$v.form.pacity.required && errors.push("City  is required.");

      return errors;
    },
    pacountyErrors() {
      const errors = [];
      if (!this.$v.form.pacounty.$dirty) return errors;
      !this.$v.form.pacounty.maxLength &&
        errors.push("County name is too long. Max 60 characters allowed.");

      return errors;
    },
    papostcodeErrors() {
      const errors = [];
      if (!this.$v.form.papostcode.$dirty) return errors;
      !this.$v.form.papostcode.minLength &&
        errors.push("Postcode is too short. Min 6 characters required.");
      !this.$v.form.papostcode.maxLength &&
        errors.push("Postcode is too long. Max 9 characters allowed.");
      !this.$v.form.papostcode.required &&
        errors.push("Postcode  is required.");

      return errors;
    },
    paissameErrors() {
      return [];
    },
    curaddrErrors() {
      return [];
    },
    cuflineErrors() {
      const errors = [];
      if (!this.$v.form.cufline.$dirty) return errors;
      !this.$v.form.cufline.maxLength &&
        errors.push("Address Line 1 is too long. Max 50 characters allowed.");
      !this.$v.form.cufline.required &&
        errors.push("Address Line 1 is required.");

      return errors;
    },
    cuslineErrors() {
      const errors = [];
      if (!this.$v.form.cusline.$dirty) return errors;
      !this.$v.form.cusline.maxLength &&
        errors.push("Address Line 2 is too long. Max 60 characters allowed.");

      return errors;
    },
    cucityErrors() {
      const errors = [];
      if (!this.$v.form.cucity.$dirty) return errors;
      !this.$v.form.cucity.maxLength &&
        errors.push("City name is too long. Max 60 characters allowed.");
      !this.$v.form.cucity.required && errors.push("City  is required.");

      return errors;
    },
    cucountyErrors() {
      const errors = [];
      if (!this.$v.form.cucounty.$dirty) return errors;
      !this.$v.form.cucounty.maxLength &&
        errors.push("County name is too long. Max 60 characters allowed.");

      return errors;
    },
    cupostcodeErrors() {
      const errors = [];
      if (!this.$v.form.cupostcode.$dirty) return errors;
      !this.$v.form.cupostcode.minLength &&
        errors.push("Postcode is too short. Min 6 characters required.");
      !this.$v.form.cupostcode.maxLength &&
        errors.push("Postcode is too long. Max 9 characters allowed.");
      !this.$v.form.cupostcode.required &&
        errors.push("Postcode  is required.");

      return errors;
    },
    dlnoErrors() {
      const errors = [];
      if (!this.$v.form.dlno.$dirty) return errors;
      !this.$v.form.dlno.maxLength && errors.push("Invalid Licence Number");
      !this.$v.form.dlno.required && errors.push("Licence Number is required.");

      return errors;
    },
    adilnoErrors() {
      const errors = [];
      if (!this.$v.form.adilno.$dirty) return errors;
      !this.$v.form.adilno.maxLength &&
        errors.push("Invalid ADI/PDI Licence Number");
      !this.$v.form.adilno.required &&
        errors.push("ADI/ADI Licence Number is required.");

      return errors;
    },
  },
};
</script>

<style></style>
