<template>
  <v-col cols="12">
    <v-divider />
    <div class="mt-5">
      <h3 class="text-center">Summary Chart</h3>
      <GChart
        type="ColumnChart"
        :settings="{ packages: ['corechart'] }"
        :data="getChartData"
        :options="chartOptions"
        :title="chartOptions.title"
      />
    </div>
  </v-col>
</template>
<script>
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "VehicleDataColumnChart",
  components: { GChart },
  props: [
    "gearRatios",
    "duelCtrlRatios",
    "fuelRatios",
    "makeRatios",
    "shapeRatios",
    "yearRatios",
  ],
  data() {
    return {
      /*chartData: [
          [
            "Ratio",
            "Male",
            "Female",
            "17 - 25",
            "26 - 35 ",
            "36 - 45 ",
            "46 - 55",
            "56 - 65",
            "65 & over",
            "Automatic",
            "Manual",
            "E8",
            "E7",
          ],
          ["Gender Ratio", 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          ["Age Ratios", 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
          ["Gear Ratios", 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0],
          ["Geographical Areas", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1],
        ],*/
      chartOptions: {
        chart: {
          title: "Vehicle Report Ratios",
          subtitle:
            "Gear Types, Dual Controls, Fuel Types, Makes, Vehicle Type and Registration Year:",
        },
        legend: { topic: "top", position: "right", maxLines: 3 },
        isStacked: "percent",
        height: 600,
        vAxis: { minValue: 1 },
        is3D: true,
      },
    };
  },
  methods: {
    getTableKeys(arr) {
      let keys = [];
      if (arr) {
        arr.forEach(function (rec) {
          var key = JSON.stringify(rec.key).replaceAll('"', "");
          keys.push(key);
        });
      }
      return keys;
    },
  },
  computed: {
    populateChartTitles() {
      let retArray = [];

      //add gear ratios titles
      let arryGearRatio = ["Automatic", "Manual"];

      //add duel controls titles
      let arryDuelCtrlRatio = this.getTableKeys(this.duelCtrlRatios);

      //add fuel types titles
      let arrayFuelTypeRatio = this.getTableKeys(this.fuelRatios);

      //add makes ratios
      let arrayMakesRatio = this.getTableKeys(this.makeRatios);

      //add shape type ratios
      let arrayVehiTypeRatio = this.getTableKeys(this.shapeRatios);

      //add reg year ratios
      let arrayRegYearRatio = this.getTableKeys(this.yearRatios);

      retArray = [
        //lets add title
        ...arryGearRatio,
        ...arryDuelCtrlRatio,
        ...arrayFuelTypeRatio,
        ...arrayMakesRatio,
        ...arrayVehiTypeRatio,
        ...arrayRegYearRatio,
      ];

      //console.log(retArray);
      return retArray;
    },
    populateChartData() {
      //step 1 : need to know full with of tabel i.e No of postcodes at the end of the table. This section fils requred section rest will be zerro

      //size/ with of data tabel

      return [
        this.getGearRatioColumn,
        this.getDualCtrlColumn,
        this.getFuelRatioColumn,
        this.getMakesRatioColumn,
        this.getTypesRatioColumn,
        this.getRegyearRatioColumn,
      ];
    },
    getGearRatioColumn() {
      let a = this.gearRatios[0].count, //this is auto
        m = this.gearRatios[1].count; //this is manual
      let row = [];
      this.populateChartTitles.forEach((_title, i) => {
        if (i == 0) {
          //ignote becase we added title
        } else if (i == 1) {
          //`automatic`
          row.push(a);
        } else if (i == 2) {
          //`Manual`
          row.push(m);
        } else {
          //other data. Lets push empty
          row.push(0);
        }
      });
      row.unshift("Vehicle Gear Types");
      //console.log(row);
      return row;
    },
    getDualCtrlColumn() {
      let row = []; // returning row
      let c = 0; // this is the static counter
      //mesure the size of  previus tables
      let previousTitlesSize = this.gearRatios.length;
      //mesure the size of current table

      for (let i = 0; i < this.getFullWidth; i++) {
        if (i == 0) {
          //ignote becase we added title
        } else if (i <= previousTitlesSize) {
          // add 0 to previous table with and value to current table width
          row.push(0);
        } else if (
          i > previousTitlesSize &&
          i <= previousTitlesSize + this.duelCtrlRatios.length
        ) {
          row.push(this.duelCtrlRatios[c].count);
          c++; //increase c for next title
          // the right elemt space
        } else {
          // i>previousTitlesSize + this.duelCtrlRatios.length
          //loop gone pass the title area
          row.push(0);
          //add 0 to rest of the table widths
        }
      }
      row.unshift("Vehicle Duel Controls");
      return row;
    },
    getFuelRatioColumn() {
      let row = []; // returning row
      let c = 0; // this is the static counter
      //mesure the size of  previus tables
      let previousTitlesSize =
        this.gearRatios.length + this.duelCtrlRatios.length;
      //mesure the size of current table

      for (let i = 0; i < this.getFullWidth; i++) {
        if (i == 0) {
          //ignote becase we added title
        } else if (i <= previousTitlesSize) {
          // add 0 to previous table with and value to current table width
          row.push(0);
        } else if (
          i > previousTitlesSize &&
          i <= previousTitlesSize + this.fuelRatios.length
        ) {
          row.push(this.fuelRatios[c].count);
          c++; //increase c for next title
          // the right elemt space
        } else {
          // i>previousTitlesSize + this.duelCtrlRatios.length
          //loop gone pass the title area
          row.push(0);
          //add 0 to rest of the table widths
        }
      }
      row.unshift("Vehicle Fuel Types");
      return row;
    },
    getMakesRatioColumn() {
      let row = []; // returning row
      let c = 0; // this is the static counter
      //mesure the size of  previus tables
      let previousTitlesSize =
        this.gearRatios.length +
        this.duelCtrlRatios.length +
        this.fuelRatios.length;
      //mesure the size of current table

      for (let i = 0; i < this.getFullWidth; i++) {
        if (i == 0) {
          //ignote becase we added title
        } else if (i <= previousTitlesSize) {
          // add 0 to previous table with and value to current table width
          row.push(0);
        } else if (
          i > previousTitlesSize &&
          i <= previousTitlesSize + this.makeRatios.length
        ) {
          row.push(this.makeRatios[c].count);
          c++; //increase c for next title
          // the right elemt space
        } else {
          // i>previousTitlesSize + this.duelCtrlRatios.length
          //loop gone pass the title area
          row.push(0);
          //add 0 to rest of the table widths
        }
      }
      row.unshift("Vehicle Makes");
      return row;
    },
    getTypesRatioColumn() {
      let row = []; // returning row
      let c = 0; // this is the static counter
      //mesure the size of  previus tables
      let previousTitlesSize =
        this.gearRatios.length +
        this.duelCtrlRatios.length +
        this.fuelRatios.length +
        this.makeRatios.length;
      //mesure the size of current table

      for (let i = 0; i < this.getFullWidth; i++) {
        if (i == 0) {
          //ignote becase we added title
        } else if (i <= previousTitlesSize) {
          // add 0 to previous table with and value to current table width
          row.push(0);
        } else if (
          i > previousTitlesSize &&
          i <= previousTitlesSize + this.shapeRatios.length
        ) {
          row.push(this.shapeRatios[c].count);
          c++; //increase c for next title
          // the right elemt space
        } else {
          // i>previousTitlesSize + this.duelCtrlRatios.length
          //loop gone pass the title area
          row.push(0);
          //add 0 to rest of the table widths
        }
      }
      row.unshift("Vehicle Types");
      return row;
    },
    getRegyearRatioColumn() {
      let row = []; // returning row
      let c = 0; // this is the static counter
      //mesure the size of  previus tables
      let previousTitlesSize =
        this.gearRatios.length +
        this.duelCtrlRatios.length +
        this.fuelRatios.length +
        this.makeRatios.length +
        this.shapeRatios.length;
      //mesure the size of current table

      for (let i = 0; i < this.getFullWidth; i++) {
        if (i == 0) {
          //ignote becase we added title
        } else if (i <= previousTitlesSize) {
          // add 0 to previous table with and value to current table width
          row.push(0);
        } else if (
          i > previousTitlesSize &&
          i <= previousTitlesSize + this.yearRatios.length
        ) {
          row.push(this.yearRatios[c].count);
          c++; //increase c for next title
          // the right elemt space
        } else {
          // i>previousTitlesSize + this.duelCtrlRatios.length
          //loop gone pass the title area
          row.push(0);
          //add 0 to rest of the table widths
        }
      }
      row.unshift("Registration Years");
      return row;
    },

    getTableval(arr) {
      let keysAndValues = {};
      if (arr) {
        this.arr.forEach(function (rec) {
          var val = JSON.stringify(rec.count).replaceAll('""', "");
          keysAndValues.push(val);
        });
      }
      return keysAndValues;
    },
    //this will return google charData
    getChartData() {
      let titles = this.populateChartTitles;
      titles.unshift("Ratio");
      let ar = this.populateChartData;
      ar.unshift(titles);
      //console.log(ar);
      return ar;
    },
    //this will return number of titles in the table (expect first one)
    getFullWidth() {
      if (this.populateChartTitles) {
        return this.populateChartTitles.length;
      }
      return 0;
    },
  },
};
</script>
