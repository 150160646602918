<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col
      ><v-sheet color="white" elevation="1" class="pa-5">
        <h2>General Terms and Conditions Upload/Download</h2>
        <v-divider></v-divider>
        <DocDownloader :data-array="passData" upload-type="general" />
        <!-- //<DocDownloader dataArray="hello" /> -->
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import DocDownloader from "@/components/Admin/DocDownloader.vue";
import { mapActions, mapGetters } from "vuex";
import {
  M_OA,
  LOAD_TRAINIG_PACK,
  GET_TRAINIG_PACK,
} from "@/store/mutation-list";
//import { Footer } from "@/components/Footer.vue";
export default {
  name: "UploadGeneralTC",
  components: { DocDownloader },

  data: () => ({
    items: [
      {
        text: "Admin Dashboard",
        disabled: false,
        href: "/dashboard/adminhome",
      },
      {
        text: "Upload/Download General T&C",
        disabled: true,
        href: "/dashboard/uploadgeneraltc",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      passData: `${M_OA}${GET_TRAINIG_PACK}`,
    }),
  },
  methods: {
    ...mapActions({
      loadFileHistory: `${M_OA}${LOAD_TRAINIG_PACK}`,
    }),
  },
  created() {
    this.loadFileHistory();
  },
};
</script>

<style></style>
