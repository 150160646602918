<template>
  <div>
    <v-card class="point" @click="dialog = true" v-if="basicObj">
      <v-card-title
        class="text-h6 grey lighten-2"
        style="justify-content: center"
      >
        Update Franchise Plan
      </v-card-title>
      <v-img
        :src="require(`@/assets/img/updateinfo/docs.webp`)"
        max-height="300"
        contain
        class="grey lighten-3 pa-5"
      ></v-img>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="750px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title>
            <span class="text-h5">Update Franchise Plan</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text style="height: 60vh">
          <v-card class="my-5">
            <v-toolbar color="blue lighten-5">
              <v-card-title
                ><span class="text-h6">Current Plan</span></v-card-title
              ></v-toolbar
            >
            <v-card-text>
              <table
                class="special-table text-left my-5"
                v-if="basicObj.franchiseplan"
              >
                <tr>
                  <th style="width: 35%">ADI ID</th>
                  <th style="width: 65%">{{ "A" + basicObj.adiid }}</th>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>
                    {{
                      `${this.$route.params.info.title} ${this.$route.params.info.fname} ${this.$route.params.info.lname}`
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Franchise Plan</th>
                  <th>
                    Plan ID: {{ basicObj.franchiseplan.plan_data.plan_id }}
                  </th>
                </tr>
                <tr>
                  <td class="tar">Joining Fee</td>
                  <td>£{{ basicObj.franchiseplan.plan_data.initial_fee }}</td>
                </tr>
                <tr>
                  <td class="tar">Weekly Fee</td>
                  <td>£{{ basicObj.franchiseplan.plan_data.weekly_fee }}</td>
                </tr>
                <tr>
                  <td class="tar">Working Hours</td>
                  <td>{{ basicObj.franchiseplan.plan_data.work_type }}</td>
                </tr>
                <tr>
                  <td class="tar">Average Weekly Hours</td>
                  <td>
                    {{ basicObj.franchiseplan.plan_data.weekly_hours }} Hours
                  </td>
                </tr>
                <tr>
                  <td class="tar">Payment Regularity</td>
                  <td>{{ basicObj.franchiseplan.regularity | payReg }}</td>
                </tr>
                <tr>
                  <td class="tar">Added On</td>
                  <td>
                    {{ basicObj.franchiseplan.created_on | dateReOrderRMtime }}
                  </td>
                </tr>
              </table></v-card-text
            >
          </v-card>
          <v-divider class="mt-5"></v-divider>
          <v-card class="my-5">
            <v-toolbar color="blue lighten-5">
              <v-card-title
                ><span class="text-h6">New Plan</span></v-card-title
              ></v-toolbar
            >
            <v-card-text>
              <v-form>
                <v-container>
                  <v-row transition="scroll-y-transition">
                    <v-col md="12">
                      <v-select
                        v-model="form.fplan"
                        :items="franchiseplans"
                        outlined
                        label="Franchise Plan"
                        required
                        :error-messages="fplanErrors"
                        @input="$v.form.fplan.$touch()"
                        @blur="$v.form.fplan.$touch()"
                        @change="
                          currentState();
                          updateTable($event);
                        "
                        ><template v-slot:item="{ item }">
                          <span
                            v-html="getPopulatedLines(item)"
                            class="white light"
                          ></span>
                        </template>
                        <template v-slot:selection="{ item }">
                          <span
                            v-html="getPopulatedLines(item)"
                            class="white light"
                          ></span>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <input
                        type="hidden"
                        v-model="form.francplan"
                        name="fplan"
                      />
                      <v-text-field
                        v-model="franchiseinfo.joiningfee"
                        outlined
                        label="Joining Fee"
                        prepend-inner-icon="mdi-currency-gbp"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="franchiseinfo.weeklyfee"
                        outlined
                        label="Weekly Fee"
                        prepend-inner-icon="mdi-currency-gbp"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="franchiseinfo.monthlyfee"
                        outlined
                        label="Monthly Fee"
                        prepend-inner-icon="mdi-currency-gbp"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="franchiseinfo.quarterlyfee"
                        outlined
                        label="Quarterly Fee"
                        prepend-inner-icon="mdi-currency-gbp"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="franchiseinfo.biannualfee"
                        outlined
                        label="Biannual Fee"
                        prepend-inner-icon="mdi-currency-gbp"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="franchiseinfo.annualfee"
                        outlined
                        label="Annual  Fee"
                        prepend-inner-icon="mdi-currency-gbp"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="franchiseinfo.workinghours"
                        outlined
                        label="Working Hours"
                        prepend-inner-icon="mdi-clock-outline"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="franchiseinfo.weeklyhours"
                        outlined
                        label="Average Weekly Hours"
                        prepend-inner-icon="mdi-timetable"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="franchiseinfo.promotioncode"
                        outlined
                        label="Promotion Code"
                        prepend-inner-icon="mdi-sale"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-select
                        v-model="form.feeregularity"
                        name="feeregularity"
                        :items="payRegs"
                        outlined
                        item-text="txt"
                        item-value="id"
                        label="Payment Regularity"
                        required
                        :error-messages="feeregularityErrors"
                        @input="$v.form.feeregularity.$touch()"
                        @blur="
                          $v.form.feeregularity.$touch();
                          currentState();
                        "
                        @change="currentState()"
                      >
                      </v-select> </v-col
                    ><v-col cols="12" md="12">
                      <!--v-text-field
                        v-model="form.startdate"
                        name="startdate"
                        label="Starting Date"
                        outlined
                        required
                        :error-messages="startdateErrors"
                        @input="$v.form.startdate.$touch()"
                        @blur="$v.form.startdate.$touch()"
                        type="date"
                        min="minDate"
                        @change="currentState()"
                      ></v-text-field-->
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="selectMode"
            color="warning darken-1"
            text
            @click="saveChanges()"
            :disabled="$v.form.$invalid"
          >
            Save Plan
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  GET_FRANCHISE_PLANS_ACTIVE,
  M_COMMON,
  M_ADI,
  SAVE_FRANCHISE_CHANGE,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "UpdateFranchiseAgreement",
  props: ["basicObj"],
  data() {
    return {
      selectMode: true,
      dialog: false,
      franchiseplans: [],
      franchiseinfo: {
        joiningfee: 0.0,
        weeklyfee: 0.0,
        monthlyfee: 0.0,
        quarterlyfee: 0.0,
        biannualfee: 0.0,
        annualfee: 0.0,
        workinghours: "-",
        weeklyhours: "0 Hour",
        promotioncode: "",
      },
      payRegs: [
        { id: "1", txt: "Weekly" },
        { id: "4.33", txt: "Monthly" },
        { id: "13", txt: "Quarterly" },
        { id: "26", txt: "Biannualy" },
        { id: "52", txt: "Annualy" },
      ],
      form: {
        fplan: "",
        feeregularity: "",
        francplan: "", // selected plan id
        startdate: "",
        currentPlan: this.basicObj.franchiseplan.rec_id,
      },
    };
  },
  validations: {
    form: {
      fplan: { required },
      feeregularity: { required },
      francplan: { required },
      //startdate: { required },
    },
  },
  methods: {
    ...mapActions({
      saveAdvanceChanges: `${M_ADI}${SAVE_FRANCHISE_CHANGE}`,
    }),
    async currentState() {
      //attach files need to be sent as well
      await this.$nextTick();
      this.$emit("financials-update", {
        state: this.$v.form.$invalid,
        data: this.form,
        fd: null,
      });
    },
    //this method will get franchise plans
    getFranchisePlans() {
      this.franchiseplans = this.fPlans.filter((el) => {
        return el.plan_id != this.basicObj.franchiseplan.plan_data.plan_id;
      });
    },
    getPopulatedLines(item) {
      let temp = `<strong> ${item.plan_id} </strong>- Joining £${item.initial_fee} | ${item.work_type} | ${item.weekly_hours} Hours | £${item.weekly_fee} per week `;
      if (item.promo_code) {
        temp += `| <strong style="color: red">${item.promo_code}</strong>`;
      }
      return temp;
    },
    updateTable(item) {
      let WF = Number(item.weekly_fee);
      this.franchiseinfo = {
        joiningfee: Number(item.initial_fee),
        weeklyfee: WF,
        monthlyfee: Math.round(WF * 4.34 * 100) / 100,
        quarterlyfee: (WF * 13).toLocaleString(),
        biannualfee: (WF * 26).toLocaleString(),
        annualfee: (WF * 52).toLocaleString(),
        workinghours: item.work_type,
        weeklyhours: item.weekly_hours + " Hours",
        promotioncode: item.promo_code,
      };
      this.form.francplan = item.plan_id;
      //console.log(item);
    },
    saveChanges() {
      this.$v.$touch();
      if (this.$v.$invalid == false) {
        this.saveAdvanceChanges({
          id: this.basicObj.adiid,
          form: this.form,
        }).then((rep) => {
          if (rep == "SUCCESS") {
            this.$fire({
              title: "Instructor Updated",
              text: "Instructor basic information updated successfully.",
              type: "success",
              //timer: 3000,
              allowOutsideClick: false,
            }).then((r) => {
              this.dialog = false;
              console.log(r.value);
            });
          } else {
            this.$fire({
              title: "Error",
              text: "Error updating Instructor basic information. Please try again.",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      fPlans: `${M_COMMON}${GET_FRANCHISE_PLANS_ACTIVE}`,
    }),
    fplanErrors() {
      const errors = [];
      if (!this.$v.form.fplan.$dirty) return errors;
      !this.$v.form.fplan.required &&
        errors.push("Franchise Plan is required.");
      return errors;
    },
    feeregularityErrors() {
      const errors = [];
      if (!this.$v.form.feeregularity.$dirty) return errors;
      !this.$v.form.feeregularity.required &&
        errors.push("Payment Regularity is required.");
      return errors;
    },
    startdateErrors() {
      const errors = [];
      if (!this.$v.form.startdate.$dirty) return errors;
      !this.$v.form.startdate.required &&
        errors.push("Starting day of agreement is required.");

      return errors;
    },
    minDate() {
      let yourDate = new Date();
      //const offset = yourDate.toISOString();
      //yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
      return yourDate.toISOString().split("T")[0];
    },
  },
  created() {
    this.getFranchisePlans();
  },
};
</script>

<style scoped>
.special-table {
  width: 100%;
}
th {
  padding: 10px;
}
td {
  padding: 8px;
}
.tar {
  text-align: right;
}
tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
tr:nth-of-type(even) {
  background-color: #fffffe;
}
</style>
