<template>
  <v-dialog
    v-model="dialog"
    width="800px"
    :retain-focus="false"
    @click:outside="$emit('close')"
    eager
  >
    <v-card>
      <v-toolbar color="primary" dark>{{ title }}</v-toolbar>

      <v-card-text class="mt-5">
        <div id="screen" style="height: 70vh">
          <embed :src="url" width="100%" height="100%" type="application/pdf" />
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="emit('close')"> Close </v-btn>
        <v-btn color="blue darken-1" text v-on:click="tst()"> TST </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PDFViewer",
  props: ["title", "dialog", "url"],
  methods: {
    /*getURL() {
      return "https://cdn.filestackcontent.com/5qOCEpKzQldoRsVatUPS";
    },*/
  },
  //THERE IS AN ISSUE. CLOSE BUTTON DOES NOT WORK ALOS THE SCROLL
  //CLICK OUTSIDE TO CLOSE FOR NOW
};
</script>
