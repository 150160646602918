<template>
  <login-comp />
</template>

<script>
import LoginComp from "../components/LoginComp.vue";

export default {
  name: "Home",

  components: {
    LoginComp,
  },
};
</script>
