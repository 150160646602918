<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5"
        ><h2>Learners' Payments Data</h2>
        <p class="sub-title">
          Search Learner by their ID, Name, E-mail or Mobile Numbers
        </p>
        <v-text-field
          v-model="searchKeyWord"
          label="Search here"
          placeholder="Enter keayword to search ... "
          clearable
          append-icon="mdi-account-search"
          @click:append="searched()"
          v-on:keyup.enter="searched()"
        ></v-text-field>
        <LearnerTable
          v-if="showTable"
          title=""
          sub-title=""
          :dataarray="resultArry"
          :is-loading="isLoading"
          :is-search="false"
          @choosen="selected"
        />
      </v-sheet>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="800"
        v-model="dialog"
        persistent
        ><v-card>
          <v-toolbar color="primary" dark>Learner Information</v-toolbar>
          <!-- <v-card-title class="text-h5">
            Use Google's location service?
          </v-card-title> -->

          <v-card-text>
            <v-simple-table
              id="summarytbl"
              class="text-left my-5"
              v-if="selectedLearner"
            >
              <tr>
                <th>ID</th>
                <td>{{ "L" + selectedLearner.student_id }}</td>
                <th>Name</th>
                <td>
                  {{ selectedLearner.title }} {{ selectedLearner.fname }}
                  {{ selectedLearner.lname }}
                </td>
                <th>Mobile</th>
                <td>{{ selectedLearner.mobile | mobileno }}</td>
              </tr>
              <tr>
                <th>Pick Up Postcode</th>
                <td>{{ selectedLearner.pickup_pc }}</td>
                <th>E-mail</th>
                <td>{{ selectedLearner.email }}</td>
                <th>Joined</th>
                <td>{{ selectedLearner.created }}</td>
              </tr>
            </v-simple-table>
            <!-- All credits -->

            <v-simple-table class="text-left" style="width: 100%">
              <thead>
                <tr class="trhead">
                  <th colspan="2">Total Paid Credits</th>
                  <th colspan="6">{{ loadedData.total_cred }}</th>
                  <th colspan="1" class="text-right">
                    <v-btn
                      @click="loadAllCreds"
                      class="primary"
                      small
                      :loading="allPaidCredLoading"
                      :disabled="allPaidCredLoading"
                      >More <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>

              <v-expand-transition>
                <tbody v-show="isAllPadiCredLoaded && payData.length > 0">
                  <tr class="trbody">
                    <th colspan="2">Payment ID</th>
                    <th colspan="3">Plan</th>
                    <th colspan="2">Amount</th>
                    <th colspan="1">Hours</th>
                    <th colspan="1">Paid On</th>
                  </tr>
                  <tr v-for="(item, index) in payData" :key="index">
                    <td colspan="2">{{ item.pay_id }}</td>
                    <td colspan="3">{{ item.plan_desc }}</td>
                    <td colspan="2">{{ "£" + item.plan_price }}</td>
                    <td colspan="1">{{ item.plan_hours | noDec }}</td>
                    <td colspan="1">{{ item.paid_on | dateReOrderRMtime }}</td>
                  </tr>
                </tbody>

                <tbody v-if="isAllPadiCredLoaded && payData.length == 0">
                  <tr class="noinfo">
                    <th colspan="9">No Information.</th>
                  </tr>
                </tbody>
              </v-expand-transition>
              <!-- Used Credits -->
              <thead>
                <tr class="trhead">
                  <th colspan="2">Used Credits</th>
                  <th colspan="6">{{ loadedData.used_cred }}</th>
                  <th colspan="1" class="text-right">
                    <v-btn
                      @click="loadUsedCreds"
                      class="primary"
                      small
                      :loading="usedCredLoading"
                      :disabled="usedCredLoading"
                      >More <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>

              <tbody v-show="isUsedCredLoaded && usedcredData.length > 0">
                <tr class="trbody">
                  <th colspan="2">Booking ID</th>
                  <th colspan="2">Lesson Date Time</th>
                  <th colspan="1">Lesson Status</th>
                  <th colspan="2">Instructor ID</th>
                  <th colspan="1">Booked on</th>
                  <th colspan="1">Booked By</th>
                </tr>
                <!-- data -->
                <tr v-for="(bks, index) in usedcredData" :key="index">
                  <td colspan="2">{{ "B" + bks.booking_id }}</td>
                  <td colspan="2">{{ bks.booking_starts | dateReOrder }}</td>
                  <td colspan="1">{{ bks.booking_status | bookingStatus }}</td>

                  <td colspan="2">{{ "A" + bks.booking_adi }}</td>
                  <td colspan="1">{{ bks.booking_added | dateReOrder }}</td>
                  <td style="text-transform: capitalize" colspan="1">
                    {{ bks.booking_by }}
                  </td>
                </tr>
              </tbody>
              <tbody v-if="isUsedCredLoaded && usedcredData.length == 0">
                <tr class="noinfo">
                  <th colspan="9">No Information.</th>
                </tr>
              </tbody>
              <!-- Forfeited Credits -->
              <thead>
                <tr class="trhead">
                  <th colspan="2">Forfeited Credits</th>
                  <th colspan="6">{{ loadedData.wasted_cred }}</th>
                  <th colspan="1" class="text-right">
                    <v-btn
                      @click="loadFortCreds"
                      class="primary"
                      small
                      :loading="fortCredLoading"
                      :disabled="fortCredLoading"
                      >More <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>

              <tbody v-show="isFortCredLoaded && forfeitData.length > 0">
                <tr class="trbody">
                  <th colspan="2">Payment ID</th>
                  <th colspan="3">Reason</th>
                  <th colspan="2">Credit ID</th>
                  <th colspan="1">Forfeited on</th>
                  <th colspan="1">Forfeited By</th>
                </tr>
                <!-- data -->
                <tr v-for="(bks, index) in forfeitData" :key="index">
                  <td colspan="2">{{ "P" + bks.pay_id }}</td>
                  <td colspan="3">{{ bks.cred_status | creditStatus }}</td>

                  <td colspan="2">{{ "C" + bks.cred_id }}</td>
                  <td colspan="1">{{ bks.cred_foreited_on | dateReOrder }}</td>
                  <td style="text-transform: capitalize" colspan="1">System</td>
                </tr>
              </tbody>
              <tbody v-if="isFortCredLoaded && forfeitData.length == 0">
                <tr class="noinfo">
                  <th colspan="9">No Information.</th>
                </tr>
              </tbody>
              <!-- Un used credits -->
              <thead>
                <tr class="trhead">
                  <th colspan="2">Unused Credits</th>
                  <th colspan="6">{{ loadedData.unused_cred }}</th>
                  <th colspan="1" class="text-right">
                    <v-btn
                      @click="loadUnUsedCreds"
                      class="primary"
                      small
                      :loading="unsudedCredLoading"
                      :disabled="unsudedCredLoading"
                      >More <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>

              <tbody v-show="isUnsudedCredLoaded && unusedData.length > 0">
                <tr class="trbody">
                  <th colspan="2">Credit ID</th>
                  <th colspan="3">Expire Date</th>
                  <th colspan="2">Credit Amount</th>
                  <th colspan="1">Payment ID</th>
                  <th colspan="1">Paid On</th>
                </tr>
                <tr v-for="(ucred, index) in unusedData" :key="index">
                  <td colspan="2">{{ ucred.cred_id }}</td>
                  <td colspan="3">{{ ucred.cred_exp | dateReOrder }}</td>
                  <td colspan="2">{{ ucred.cred_amount }}</td>
                  <td colspan="1">{{ ucred.payment_id }}</td>
                  <td colspan="1">{{ ucred.payment_added | dateReOrder }}</td>
                </tr>
              </tbody>
              <tbody v-if="isUnsudedCredLoaded && unusedData.length == 0">
                <tr class="noinfo">
                  <th colspan="9">No Information.</th>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary darken-1" text @click="closeDialog">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import LearnerTable from "@/components/LearnerComps/LearnerTable";
import {
  SEARCH_LEARNER,
  M_OU,
  LOAD_LEARNER_CREDIT_SUMMARY,
  LOAD_LEARNER_PAID_CREDIT_INFO,
  LOAD_LEARNER_USED_CREDIT_INFO,
  LOAD_LEARNER_FORFEIT_CREDIT_INFO,
  LOAD_LEARNER_UNUSED_CREDIT_INFO,
} from "@/store/mutation-list";
import { mapActions } from "vuex";
export default {
  name: "LearnerPaymentData",
  components: { LearnerTable },
  data: function () {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Learners' Payment Data",
          disabled: true,
          href: "/dashboard/learnerpaymentdata",
        },
      ],
      searchKeyWord: "",

      showTable: false,
      resultArry: [],
      isLoading: true,
      dialog: false,

      selectedLearner: {},

      //initial data
      loadedData: [],

      allPaidCredLoading: false,
      usedCredLoading: false,
      fortCredLoading: false,
      unsudedCredLoading: false,

      //this will be true when the load record request sent
      isAllPadiCredLoaded: false,
      isUsedCredLoaded: false,
      isFortCredLoaded: false,
      isUnsudedCredLoaded: false,

      //dataArrays
      payData: [],
      usedcredData: [],
      forfeitData: [],
      unusedData: [],
    };
  },
  methods: {
    ...mapActions({
      getSearchData: `${M_OU}${SEARCH_LEARNER}`,
      getCreditSummary: `${M_OU}${LOAD_LEARNER_CREDIT_SUMMARY}`,
      getPayHistory: `${M_OU}${LOAD_LEARNER_PAID_CREDIT_INFO}`,
      getUsedHistory: `${M_OU}${LOAD_LEARNER_USED_CREDIT_INFO}`,
      getForfeditedHistory: `${M_OU}${LOAD_LEARNER_FORFEIT_CREDIT_INFO}`,
      getUnusedHistory: `${M_OU}${LOAD_LEARNER_UNUSED_CREDIT_INFO}`,
    }),
    searched() {
      //searching - searchlearner
      if (this.searchKeyWord) {
        this.showTable = true;
        this.getSearchData({ keyword: this.searchKeyWord })
          .then((res) => {
            if (res) {
              this.resultArry = res;
            } else {
              this.resultArry = [];
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    selected(Learner) {
      //console.log(Learner);
      this.selectedLearner = Learner.record;
      this.getCreditSummary({ id: Learner.record.student_id })
        .then((res) => {
          if (res) {
            this.loadedData = res;
          } else {
            this.loadedData = {};
          }
        })
        .finally(() => {
          this.dialog = true;
        });
    },
    loadAllCreds() {
      if (this.selectedLearner) {
        //setting the spinner
        this.allPaidCredLoading = true;
        this.getPayHistory({ id: this.selectedLearner.student_id })
          .then((res) => {
            if (res) {
              this.payData = res;
            } else {
              this.payData = [];
            }
          })
          .finally(() => {
            //setting is loaded
            this.isAllPadiCredLoaded = true;
            //hide spinner
            this.allPaidCredLoading = false;
          });
      }
    },
    loadUsedCreds() {
      if (this.selectedLearner) {
        //setting the spinner
        this.usedCredLoading = true;
        this.getUsedHistory({ id: this.selectedLearner.student_id })
          .then((res) => {
            if (res) {
              this.usedcredData = res;
            } else {
              this.usedcredData = [];
            }
          })
          .finally(() => {
            //setting is loaded
            this.isUsedCredLoaded = true;
            //hide spinner
            this.usedCredLoading = false;
          });
      }
    },
    loadFortCreds() {
      if (this.selectedLearner) {
        //setting the spinner
        this.fortCredLoading = true;
        this.getForfeditedHistory({ id: this.selectedLearner.student_id })
          .then((res) => {
            if (res) {
              this.forfeitData = res;
            } else {
              this.forfeitData = [];
            }
          })
          .finally(() => {
            //setting is loaded
            this.isFortCredLoaded = true;
            //hide spinner
            this.fortCredLoading = false;
          });
      }
    },
    loadUnUsedCreds() {
      if (this.selectedLearner) {
        //setting the spinner
        this.unsudedCredLoading = true;
        this.getUnusedHistory({ id: this.selectedLearner.student_id })
          .then((res) => {
            if (res) {
              this.unusedData = res;
            } else {
              this.unusedData = [];
            }
          })
          .finally(() => {
            //setting is loaded
            this.isUnsudedCredLoaded = true;
            //hide spinner
            this.unsudedCredLoading = false;
          });
      }
    },
    closeDialog() {
      this.dialog = false;
      //resetting values
      this.selectedLearner = {};

      //initial data
      this.loadedData = {};

      this.allPaidCredLoading = false;
      this.usedCredLoading = false;
      this.fortCredLoading = false;
      this.unsudedCredLoading = false;

      //this will be true when the load record request sent
      this.isAllPadiCredLoaded = false;
      this.isUsedCredLoaded = false;
      this.isFortCredLoaded = false;
      this.isUnsudedCredLoaded = false;

      //dataArrays
      this.payData = [];
      this.usedcredData = [];
      this.forfeitData = [];
      this.unusedData = [];
    },
  },
};
</script>

<style lang="scss">
#summarytbl {
  td {
    padding: 5px;
  }
}
.trhead {
  background-color: #d1d3d6;
  th:nth-child(1) {
    column-span: 2;
  }
  th:nth-child(2) {
    column-span: 6;
  }
  th:nth-child(3) {
    column-span: 1;
  }
}

.trbody {
  background-color: #e9ebef;
}
.noinfo {
  background-color: #f00;
  color: white;
}
</style>
