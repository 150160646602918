<template>
  <v-col md="4">
    <h3 class="mb-5">Geographical Areas</h3>
    <v-card class="mx-auto" :elevation="important" :outlined="isMain"
      ><v-simple-table v-if="tableData">
        <template v-slot:default>
          <thead>
            <tr class="text-center">
              <th class="text-center">Outer Postcode</th>
              <th class="text-center">Frequency</th>
              <th class="text-center">Precentage</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ratio, label, i) in getOPCRatios"
              :key="i"
              class="text-center"
            >
              <td>{{ label }}</td>
              <td>{{ ratio }}</td>
              <td>{{ ratio | precentage(tableData.length) }}</td>
            </tr>
            <tr class="tots">
              <th class="text-center">Total</th>
              <th class="text-center">{{ tableData.length }}</th>
              <th class="text-center">100%</th>
            </tr>
            <tr v-if="getOPCRatios.length == 0">
              <th colspan="2">No records to display</th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p v-else class="warning">No Data Provided</p></v-card
    ></v-col
  >
</template>

<script>
export default {
  name: "GeographicalAreasTable",
  props: ["tableData", "isMain"],
  computed: {
    getOPCRatios() {
      let counter = {};
      this.tableData.forEach(function (rec) {
        var key = JSON.stringify(rec.outer_pc).replaceAll('"', "");
        counter[key] = (counter[key] || 0) + 1;
      });
      return counter;
    },
    important() {
      if (this.isMain) {
        return 24;
      } else {
        return 2;
      }
    },
  },
};
</script>

<style></style>
