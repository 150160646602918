<template>
  <v-col :md="colSize" class="mx-auto mb-5">
    <h4 class="mb-5">{{ title }}</h4>
    <v-card class="mx-auto"
      ><v-simple-table v-if="tableData">
        <template v-slot:default>
          <thead>
            <tr class="text-center">
              <th class="text-center">{{ keyHedding }}</th>
              <th class="text-center">{{ valueHedding }}</th>
              <th class="text-center" v-if="isPrecentage">Precentage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rec, i) in tableData" :key="i" class="text-center">
              <td>{{ rec.key }}</td>
              <td>{{ rec.count }}</td>
              <td v-if="isPrecentage">{{ rec.count | precentage(total) }}</td>
            </tr>
            <tr v-if="tableData.length == 0">
              <th class="text-center" colspan="5">No Data to display</th>
            </tr>
            <tr v-if="total" class="tots">
              <th class="text-center">Total</th>
              <th class="text-center">{{ total }}</th>
              <th class="text-center" v-if="isPrecentage">100%</th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p v-else class="warning">No Data Provided</p></v-card
    ><v-divider />
  </v-col>
</template>

<script>
export default {
  name: "GeneralDataTable",
  props: [
    "tableData",
    "title",
    "colSize",
    "keyHedding",
    "valueHedding",
    "total",
    "isPrecentage",
  ],
};
</script>

<style></style>
