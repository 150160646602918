<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col
      ><v-sheet color="white" elevation="1" class="pa-5">
        <h2>View/Edit Office Staff</h2>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="allstaff"
          sort-by="id"
          class="elevation-1"
        >
          <template v-slot:[`item.mobile`]="{ item }">
            {{ item.mobile | mobileno }}
          </template>
          <template v-slot:[`item.accState`]="{ item }">
            <v-chip :color="getChipColour(item.accState)" dark>
              {{ getChipText(item.accState) }}
            </v-chip>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>All Office Staff</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <!-- <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Item
                  </v-btn>
                </template> -->
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            prepend-icon="mdi-card-account-details"
                            v-model="form.staff_id"
                            label="Staff ID"
                            readonly
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col md="12">
                          <v-select
                            v-model="form.title"
                            :items="titlesList"
                            item-text="txt"
                            item-value="id"
                            label="Title"
                            required
                            :error-messages="titleErrors"
                            @input="$v.form.title.$touch()"
                            @blur="$v.form.title.$touch()"
                          >
                          </v-select>

                          <v-text-field
                            v-model="form.fname"
                            :counter="20"
                            label="First Name"
                            required
                            :error-messages="fnameErrors"
                            @input="$v.form.fname.$touch()"
                            @blur="$v.form.fname.$touch()"
                          ></v-text-field>
                          <v-text-field
                            v-model="form.mname"
                            :counter="20"
                            label="Middle Name(s)"
                            :error-messages="mnameErrors"
                            @input="$v.form.mname.$touch()"
                            @blur="$v.form.mname.$touch()"
                          ></v-text-field>
                          <v-text-field
                            v-model="form.lname"
                            :counter="20"
                            label="Surname"
                            required
                            :error-messages="lnameErrors"
                            @input="$v.form.lname.$touch()"
                            @blur="$v.form.lname.$touch()"
                          ></v-text-field>
                          <v-text-field
                            v-model="form.mobile"
                            :counter="11"
                            label="Mobile Number"
                            required
                            :error-messages="mobileErrors"
                            @input="$v.form.mobile.$touch()"
                            @blur="$v.form.mobile.$touch()"
                          ></v-text-field>

                          <v-text-field
                            v-model="form.email"
                            label="E-mail"
                            :counter="60"
                            required
                            :error-messages="emailErrors"
                            @input="$v.form.email.$touch()"
                            @blur="$v.form.email.$touch()"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogView" max-width="500px">
                <!-- <template v-slot:activator="{ on, attrs }">
                  
                </template> -->
                <v-card>
                  <v-card-title>
                    <span class="text-h5">View Staff Information</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            prepend-icon="mdi-card-account-details"
                            v-model="form.staff_id"
                            label="Staff ID"
                            disabled
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col md="12">
                          <v-select
                            v-model="form.title"
                            :items="titlesList"
                            item-text="txt"
                            item-value="id"
                            label="Title"
                            readonly
                          >
                          </v-select>

                          <v-text-field
                            v-model="form.fname"
                            label="First Name"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            v-model="form.mname"
                            label="Middle Name(s)"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            v-model="form.lname"
                            label="Surname"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            v-model="form.mobile"
                            label="Mobile Number"
                            readonly
                          ></v-text-field>

                          <v-text-field
                            v-model="form.email"
                            label="E-mail"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogBlock" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to block this staff?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeBlock"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="blockStaffConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogUnBlock" max-width="600px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to Un-block this staff?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeUnBlock"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="unBlockStaffConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this staff? This action is
                    irreversible</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="item.accState == 1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="green"
                    dense
                    class="mr-2"
                    @click="editItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
              |
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="amber"
                    dense
                    @click="blockItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-account-cancel
                  </v-icon>
                </template>
                <span>Block</span>
              </v-tooltip>
              |
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="red"
                    dense
                    @click="deleteItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
            <div v-if="item.accState == 0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="green"
                    dense
                    class="mr-2"
                    @click="editItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
              |

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="blue"
                    dense
                    @click="unBlockItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-account-check
                  </v-icon>
                </template>
                <span>Un-Block</span>
              </v-tooltip>
              |
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="red"
                    dense
                    @click="deleteItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
            <div v-if="item.accState > 1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="amber"
                    large
                    @click="viewonlyItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-account-eye
                  </v-icon>
                </template>
                <span>View</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" @click="loadInitData"> Load </v-btn>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import {
  GET_TITLES,
  REGISTER_STAFF,
  BLOCK_STAFF,
  UNBLOCK_STAFF,
  DELETE_STAFF,
  GET_STAFF_TABLE,
  GET_STAFF_INFO,
  LOAD_OFFICE_STAFF,
  UPDATE_STAFF,
  M_OA,
  M_DD,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";

import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "ManageStaff",

  data: () => ({
    items: [
      {
        text: "Admin Dashboard",
        disabled: false,
        href: "/dashboard/adminhome",
      },
      {
        text: "View/Edit Office Staff",
        disabled: true,
        href: "/dashboard/addstaff",
      },
    ],
    genders: [
      { id: "f", txt: "Female" },
      { id: "m", txt: "Male" },
    ],
    form: {
      staff_id: "",
      title: "",
      gender: "",
      fname: "",
      mname: "",
      lname: "",
      mobile: "",
      email: "",
      heckedpriv: ["11", "12", "13", "14", "15", "16", "17"],
    },

    //table related
    dialog: false,
    dialogView: false,
    dialogBlock: false,
    dialogUnBlock: false,
    dialogDelete: false,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      { text: "Name", value: "name" },
      { text: "Mobile", value: "mobile" },
      { text: "E-mail", value: "email" },
      { text: "Account Status", value: "accState" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    allstaff: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      title: "",
      fName: "",
      mName: "",
      lName: 0,
      mobile: 0,
      email: 0,
    },
    defaultItem: {
      id: "",
      name: 0,
      mobile: 0,
      email: 0,
      status: 0,
    },
  }),
  validations: {
    form: {
      title: {
        required,
      },
      fname: {
        required,
        maxLength: maxLength(20),
      },
      mname: {
        maxLength: maxLength(20),
      },
      lname: {
        required,
        maxLength: maxLength(20),
      },
      mobile: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },
      email: {
        required,
        email,
        maxLength: maxLength(60),
      },
    },
  },
  computed: {
    ...mapGetters({
      titlesList: `${M_DD}${GET_TITLES}`,
      staffList: `${M_OA}${GET_STAFF_TABLE}`,
      getStaffInfo: `${M_OA}${GET_STAFF_INFO}`,
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Staff Info";
    },
    //this section is for errors
    titleErrors() {
      const errors = [];
      if (!this.$v.form.title.$dirty) return errors;
      !this.$v.form.title.required && errors.push("Title is required.");
      return errors;
    },
    fnameErrors() {
      const errors = [];
      if (!this.$v.form.fname.$dirty) return errors;
      !this.$v.form.fname.maxLength &&
        errors.push("Name must be at most 20 characters long");
      !this.$v.form.fname.required && errors.push("Name is required.");

      return errors;
    },
    mnameErrors() {
      const errors = [];
      if (!this.$v.form.mname.$dirty) return errors;
      !this.$v.form.mname.maxLength &&
        errors.push("Name must be at most 20 characters long");
      return errors;
    },
    lnameErrors() {
      const errors = [];
      if (!this.$v.form.lname.$dirty) return errors;
      !this.$v.form.lname.maxLength &&
        errors.push("Name must be at most 20 characters long");
      !this.$v.form.lname.required && errors.push("Name is required.");
      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (!this.$v.form.mobile.$dirty) return errors;
      !this.$v.form.mobile.maxLength &&
        errors.push("Mobile number can not be more than 11 digits");
      !this.$v.form.mobile.minLength &&
        errors.push("Mobile number can not be less than 9 digits");
      !this.$v.form.mobile.numeric && errors.push("Only numbers are allowed.");
      !this.$v.form.mobile.required &&
        errors.push("Mobile number is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.maxLength &&
        errors.push("E-mail address is too long. Max 60 characters allowed.");
      !this.$v.form.email.email && errors.push("Invalid e-mail address");
      !this.$v.form.email.required && errors.push("E-mail is required.");

      return errors;
    },
  },
  /*watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },*/

  created() {
    this.initialize();
  },
  methods: {
    ...mapActions({
      saveNewStaff: REGISTER_STAFF,
      loadInitData: `${M_OA}${LOAD_OFFICE_STAFF}`,
      saveUpdate: `${M_OA}${UPDATE_STAFF}`,
      blockStaff: `${M_OA}${BLOCK_STAFF}`,
      unBlockStaff: `${M_OA}${UNBLOCK_STAFF}`,
      deleteStaff: `${M_OA}${DELETE_STAFF}`,
      // staffList: `${M_OA}${GET_STAFF_TABLE}`,
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveNewStaff({
          // the data that need to sent to store/server
          id: 1,
          txt: "this is a test",
        }).then((res) => {
          //response from store. All complete just need to show to popup message
          //console.log(res);
          if (res == "SUCCESS") {
            this.$fire({
              title: "Success",
              text: "New Staff has been added successfully",
              type: "success",
              timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          } else {
            this.$fire({
              title: "Error",
              text: "text",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
    },
    clear() {
      this.$v.$reset();

      this.form = {
        staff_id: null,
        title: "",
        fname: "",
        mname: "",
        lname: "",
        mobile: "",
        email: "",
        heckedpriv: [],
      };
    },
    initialize() {
      this.loadInitData();
      this.allstaff = this.staffList;
    },
    getChipColour(accState) {
      accState = parseInt(accState);
      let retVal = "";
      switch (accState) {
        case 0:
          retVal = "orange";
          break;
        case 1:
          retVal = "green";
          break;
        case 2:
          retVal = "blue-grey";
          break;
        case 3:
          retVal = "red";
          break;

        default:
          retVal = "red";
          break;
      }
      return retVal;
    },
    getChipText(accState) {
      accState = parseInt(accState);
      let retVal = "";
      switch (accState) {
        case 0:
          retVal = "Blocked";
          break;
        case 1:
          retVal = "Active";
          break;
        case 2:
          retVal = "Readonly";
          break;
        case 3:
          retVal = "Deleted";
          break;

        default:
          retVal = "Error";
          break;
      }
      return retVal;
    },

    editItem(item) {
      this.form = {};
      this.editedIndex = this.allstaff.indexOf(item);
      let editID = this.allstaff[this.editedIndex].id;
      this.form = this.getStaffInfo(editID);

      this.dialog = true;
    },
    viewonlyItem(item) {
      this.form = {};
      this.viewIndex = this.allstaff.indexOf(item);
      let viewID = this.allstaff[this.viewIndex].id;
      this.form = this.getStaffInfo(viewID);
      this.dialogView = true;
    },

    deleteItem(item) {
      this.editedIndex = this.allstaff.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.form = Object.assign({}, item);
      this.dialogDelete = true;
    },
    blockItem(item) {
      this.editedIndex = this.allstaff.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogBlock = true;
    },
    unBlockItem(item) {
      this.editedIndex = this.allstaff.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogUnBlock = true;
    },

    blockStaffConfirm() {
      this.closeBlock();
      this.blockStaff({ staff_id: this.editedItem.id }).then((res) => {
        //response from store. All complete just need to show to popup message
        //console.log(res);
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Staff blocked successfully.",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        } else {
          this.$fire({
            title: "Error!",
            text: "Error with updating staff record. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
    unBlockStaffConfirm() {
      this.closeUnBlock();

      this.unBlockStaff({
        staff_id: this.editedItem.id,
      }).then((res) => {
        //response from store. All complete just need to show to popup message
        //console.log(res);
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Staff unblocked successfully.",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        } else {
          this.$fire({
            title: "Error!",
            text: "Error with updating staff record. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
    deleteItemConfirm() {
      //this.allstaff.splice(this.editedIndex, 1);
      this.closeDelete();

      this.deleteStaff({
        staff_id: this.editedItem.id,
      }).then((res) => {
        //response from store. All complete just need to show to popup message
        //console.log(res);
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Staff deleted successfully.",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        } else {
          this.$fire({
            title: "Error!",
            text: "Error with updating staff record. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },

    close() {
      this.dialog = false;
      this.dialogView = false;
      this.clear();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeBlock() {
      this.dialogBlock = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeUnBlock() {
      this.dialogUnBlock = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      //just need to pass the changes to store and upadte the table

      //lets do the axios and do the changes here
      //lets pass the data as an object

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveUpdate({
          staff_id: this.form.staff_id,
          mobile: this.form.mobile,
          email: this.form.email,
          title: this.form.title,
          fname: this.form.fname,
          mname: this.form.mname,
          lname: this.form.lname,
          privlist: ["11", "12", "13", "14", "15", "16", "17"],
        }).then((res) => {
          //response from store. All complete just need to show to popup message
          //console.log(res);
          if (res == "SUCCESS") {
            this.$fire({
              title: "Success",
              text: "Staff record updated successfully.",
              type: "success",
              timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          } else {
            this.$fire({
              title: "Error!",
              text: "Error with updating staff record. Please try again.",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
      this.close();
    },
  },
};
</script>

<style></style>
