<template>
  <v-row justify="center" class="text-center">
    <v-col
      v-for="(item, index) in panels"
      :key="index"
      cols="12"
      sm="6"
      md="4"
      lg="4"
      xl="4"
    >
      <v-card class="pa-1" outlined tile elevation="2">
        <v-card-title
          class="blue accent-1 text-center"
          style="flex-direction: column"
        >
          <span class="text-h6">{{ item.title }}</span>
        </v-card-title>
        <div class="pa-5">
          <v-img :src="getImgUrl(item.img)" height="250px" contain></v-img>
        </div>

        <v-list v-for="(func, i) in item.targets" :key="i">
          <v-divider></v-divider>
          <v-list-item link :to="func.link" :disabled="!can(func.privilege)">
            <v-list-item-content>
              <v-list-item-title>{{ func.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AdminDashPanels",
  data: () => ({
    panels: [
      {
        title: "Staff",
        img: "staff.webp",
        targets: [
          {
            name: "Add Staff",
            link: "/dashboard/addstaff",
            privilege: "admin",
          },
          {
            name: "View/Edit Staff",
            link: "/dashboard/managestaff",
            privilege: "admin",
          },
          {
            name: "Reset Password",
            link: "/dashboard/resetstaffpass",
            privilege: "admin",
          },
          { name: "", link: "", privilege: "admin" },
        ],
      },
      {
        title: "Driving Instructor",
        img: "instructor3.webp",
        targets: [
          {
            name: "Approve Instructor",
            link: "/dashboard/approveinstructors",
            privilege: "admin",
          },
          {
            name: "Block Instructors",
            link: "/dashboard/instructorstatemanagement",
            privilege: "admin",
          },
          {
            name: "Add Franchise Plan",
            link: "/dashboard/addfranchiseplan",
            privilege: "admin",
          },
          {
            name: "View/Manage Franchise Plans",
            link: "/dashboard/managefranchiseplans",
            privilege: "admin",
          },
          // { name: "", link: "", privilege: "admin" },
        ],
      },
      {
        title: "Driving Lessons",
        img: "driving-lessons.webp",
        targets: [
          {
            name: "Add Lesson Plan",
            link: "/dashboard/addlessonplan",
            privilege: "admin",
          },

          {
            name: "View/Manage Lesson Plans",
            link: "/dashboard/managelessonplans",
            privilege: "admin",
          },
          { name: "", link: "", privilege: "admin" },
          { name: "", link: "", privilege: "admin" },
        ],
      },
      {
        title: "Feedback Questions",
        img: "review.webp",
        targets: [
          {
            name: "Add Learner Question Group",
            link: "/dashboard/addlearnerquestiongroup",
            privilege: "admin",
          },
          {
            name: "Manage Learner Feedback Questions",
            link: "/dashboard/managelearnerfeedbackquestions",
            privilege: "admin",
          },
          {
            name: "Add Instructor Question Group",
            link: "/dashboard/addinstructorquestiongroup",
            privilege: "admin",
          },
          {
            name: "Manage Instructor Feedback Questions",
            link: "/dashboard/manageinstructorfeedbackquestions",
            privilege: "admin",
          },
        ],
      },
      {
        title: "Quick Info",
        img: "info.webp",
        targets: [
          {
            name: "Manage Quick Info",
            link: "/dashboard/managequickinfo",
            privilege: "admin",
          },
          { name: "", link: "", privilege: "admin" },
          { name: "", link: "", privilege: "admin" },
          { name: "", link: "", privilege: "admin" },
        ],
      },
      {
        title: "T & C Documents",
        img: "contact.webp",
        targets: [
          {
            name: "Upload/Download Learner T&C",
            link: "/dashboard/uploadleanertc",
            privilege: "admin",
          },
          {
            name: "Upload/Download ADI T&C",
            link: "/dashboard/uploadaditc",
            privilege: "admin",
          },
          {
            name: "Upload/Download PDI Training Pack",
            link: "/dashboard/uploadtrainigpack",
            privilege: "admin",
          },
          {
            name: "Upload/Download General T&C",
            link: "/dashboard/uploadgeneraltc",
            privilege: "admin",
          },
        ],
      },
    ],
  }),
  methods: {
    getImgUrl(pic) {
      return require("../../assets/img/dash/" + pic);
    },
  },
};
</script>

<style></style>
