<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5"
        ><h2>Instructor Franchise Payments Data</h2>
        <p class="sub-title">
          Search Instructors by their ID, Name, E-mail or Mobile Numbers
        </p>
        <v-text-field
          label="Search"
          placeholder="Enter keayword to search ... "
          clearable
          append-icon="mdi-account-search"
          v-model="keyword"
        ></v-text-field>
        <InstructorTable
          v-if="showTable"
          title=""
          sub-title=""
          :dataarray="resultArry"
          :is-loading="isLoading"
          :is-search="false"
          @choosen="selected"
        />
      </v-sheet>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="900"
        v-model="dialog"
        persistent
        ><v-card>
          <v-toolbar color="primary" dark>Instructor Information</v-toolbar>
          <!-- <v-card-title class="text-h5">
            Use Google's location service?
          </v-card-title> -->

          <v-card-text>
            <v-simple-table
              id="summarytbl"
              class="text-left my-5"
              v-if="selectedADI"
            >
              <tr>
                <th>Instructor ID</th>
                <td>{{ "A" + selectedADI.adi_id }}</td>
                <th>Name</th>
                <td>
                  {{ selectedADI.title }} {{ selectedADI.fname }}
                  {{ selectedADI.lname }}
                </td>
                <th>Mobile</th>
                <td>{{ selectedADI.mobile | mobileno }}</td>
              </tr>
              <tr>
                <th>Account State</th>
                <td>{{ selectedADI.accState | adiStatus }}</td>
                <th>E-mail</th>
                <td>{{ selectedADI.email }}</td>
                <th>Joined</th>
                <td>{{ selectedADI.created }}</td>
              </tr>
            </v-simple-table>
            <v-divider></v-divider>
            <h4 class="mt-5">Current Franchise Package</h4>
            <v-simple-table
              id="summarytbl2"
              class="text-left my-5"
              v-if="selectedFranchisePlan"
            >
              <tr>
                <th>Plan ID</th>
                <td>{{ selectedFranchisePlan.plan_id }}</td>
                <th>Initial Fee</th>
                <td>£{{ selectedFranchisePlan.initial_fee }}</td>
                <th>Payment Regularity</th>
                <td>{{ loadedData.regularity | payReg }}</td>
              </tr>
              <tr>
                <th>Weekly Fee</th>
                <td>£{{ selectedFranchisePlan.weekly_fee }}</td>
                <th>Weekly Hours</th>
                <td>{{ selectedFranchisePlan.weekly_hours }}</td>
                <th>Work Type</th>
                <td>{{ selectedFranchisePlan.work_type }}</td>
              </tr>
              <tr>
                <th>Started On</th>
                <td>
                  {{ selectedFranchisePlan.created_on | dateReOrderRMtime }}
                </td>
                <th>Promotion Code</th>
                <td>{{ selectedFranchisePlan.promo_code }}</td>
                <th>Recorded By</th>
                <td>{{ loadedData.recored_by }}</td>
              </tr>
            </v-simple-table>

            <v-divider></v-divider>

            <div v-if="selectMode" class="mb-5">
              <h4 class="my-3">Select Reporting Range</h4>
              <div class="row">
                <div class="col-md-4">
                  <v-text-field
                    outlined
                    class="mt-2"
                    label="Start Date"
                    type="date"
                    v-model="startD"
                  ></v-text-field>
                </div>
                <div class="col-md-4">
                  <v-text-field
                    outlined
                    class="mt-2"
                    label="End Date"
                    type="date"
                    v-model="endD"
                  ></v-text-field>
                </div>
                <div class="col-md-4">
                  <v-btn
                    class="primary float-start mt-4"
                    large
                    @click="generateReport()"
                    :loading="isGenerating"
                    :disabled="startD == null || endD == null"
                  >
                    View Report</v-btn
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <h4>Selected Range</h4>
              <p style="font-size: 1.2em">
                <b>From: </b>
                <em>{{ startD | dateReOrderRMtime }}</em>
                <b> To: </b>
                <em>{{ endD | dateReOrderRMtime }} </em>
                <v-btn color="primary" text @click="selectMode = true"
                  >Change</v-btn
                >
              </p>
            </div>

            <v-simple-table
              class="text-left"
              style="width: 100%"
              v-if="dialogTbl && loadedData"
            >
              <thead>
                <tr class="trhead">
                  <th colspan="2">Transaction ID</th>
                  <th colspan="2">Transaction Reference</th>
                  <th colspan="2">Transaction Amount</th>
                  <th colspan="2">Transaction Date</th>
                  <th colspan="2">Recorded On</th>
                </tr>
              </thead>

              <v-expand-transition>
                <tbody v-show="isPayRecsLoaded">
                  <!-- Data -->
                  <tr v-for="(item, index) in PayRecsData" :key="index">
                    <td colspan="2">{{ item.transaction_id }}</td>
                    <td colspan="2">{{ item.reference }}</td>
                    <td colspan="2" style="text-align: right">
                      £{{ item.amount }}
                    </td>
                    <td colspan="2">
                      {{ item.date_of_transaction | dateReOrderRMtime }}
                    </td>
                    <td colspan="2">{{ item.added | dateReOrder }}</td>
                  </tr>
                </tbody>

                <tbody v-if="isPayRecsLoaded && PayRecsData.length == 0">
                  <tr class="noinfo">
                    <th colspan="10">No Information.</th>
                  </tr>
                </tbody>
              </v-expand-transition>
            </v-simple-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary darken-1" text @click="closeDialog">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import InstructorTable from "@/components/Instructor/InstructorTable";
import {
  M_OU,
  GET_ACTIVE_INSTRUCTORS,
  LOAD_ADI_FRANCHISE_DATA,
  LOAD_ADI_FRANCHISE_PAYMENTS_DATA,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InstructorFranchisePayData",
  components: { InstructorTable },
  data: function () {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "View Instructor Franchise Payment Report",
          disabled: true,
          href: "/dashboard/instructorfranchisepayData",
        },
      ],

      keyword: "",
      showTable: true,
      //resultArry: [],
      isLoading: false,
      dialog: false,

      selectedADI: {},
      selectedFranchisePlan: {},

      //select mode
      selectMode: true,
      isGenerating: false,
      startD: null,
      endD: null,

      //table inside dialog
      dialogTbl: false,

      //initial data
      loadedData: [],

      //this will be true when the load record request sent
      isPayRecsLoaded: false,

      //dataArrays
      PayRecsData: [],
    };
  },
  methods: {
    ...mapActions({
      loadFranchiseData: `${M_OU}${LOAD_ADI_FRANCHISE_DATA}`,
      loadFranchisePaymentsData: `${M_OU}${LOAD_ADI_FRANCHISE_PAYMENTS_DATA}`,
    }),
    selected(instructor) {
      this.selectedADI = instructor.record;
      this.loadFranchiseData({ id: instructor.record.adi_id })
        .then((res) => {
          if (res) {
            this.loadedData = res;
          } else {
            this.loadedData = [];
          }
        })
        .finally(() => {
          //more data needed
          this.selectedFranchisePlan = this.loadedData.plan_data;
          this.dialog = true;
        });
    },
    makeitradable(val) {
      if (val < 10) {
        //code
        return "0" + val;
      } else {
        return val;
      }
    },
    generateReport() {
      //if input date validations are true
      //then show loading and start axios to get summary from controller
      this.isGenerating = true;
      this.loadFranchisePaymentsData({
        id: this.selectedADI.adi_id,
        start: this.startD,
        end: this.endD,
      })
        .then((res) => {
          if (res) {
            this.PayRecsData = res;
          } else {
            this.PayRecsData = [];
          }
        })
        .finally(() => {
          this.isPayRecsLoaded = true;
          this.isGenerating = false;
          this.selectMode = false;
          this.dialogTbl = true;
        });
    },
    closeDialog() {
      this.dialog = false;
      this.dialogTbl = false;
      //reset range selection
      this.selectMode = true;

      this.isGenerating = false;
      this.startD = null;
      this.endD = null;
      //resetting values
      this.selectedADI = {};
      this.selectedFranchisePlan = {};

      //initial data
      this.loadedData = [];

      this.isPayRecsLoaded = false;
      this.PayRecsData = [];
    },
  },
  computed: {
    ...mapGetters({
      getADIs: `${M_OU}${GET_ACTIVE_INSTRUCTORS}`,
    }),
    resultArry() {
      if (this.keyword && this.getADIs) {
        return this.getADIs.filter((adirec) => {
          return (
            adirec.adi_id.toString().includes(this.keyword.toLowerCase()) ||
            adirec.fname.toLowerCase().includes(this.keyword.toLowerCase()) ||
            adirec.mname.toLowerCase().includes(this.keyword.toLowerCase()) ||
            adirec.lname.toLowerCase().includes(this.keyword.toLowerCase()) ||
            adirec.mobile.toLowerCase().includes(this.keyword.toLowerCase()) ||
            adirec.email.toLowerCase().includes(this.keyword.toLowerCase()) ||
            adirec.currAddr.county
              .toLowerCase()
              .includes(this.keyword.toLowerCase()) ||
            adirec.driving_licence_no
              .toLowerCase()
              .includes(this.keyword.toLowerCase()) ||
            adirec.instructor_licence_no
              .toLowerCase()
              .includes(this.keyword.toLowerCase())
          );
        });
      } else {
        return this.getADIs;
      }
    },
  },
};
</script>

<style lang="scss">
#summarytbl {
  td {
    padding: 5px;
  }
}
#summarytbl2 {
  td {
    padding: 5px;
  }
}
</style>
