<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5"
        ><h2>Instructor Performance Data</h2>
        <p class="sub-title">
          Search Instructors by their ID, Name, E-mail or Mobile No.
        </p>
        <v-text-field
          label="Search"
          placeholder="Enter keayword to search ... "
          clearable
          append-icon="mdi-account-search"
          v-model="keyword"
        ></v-text-field>
        <InstructorTable
          v-if="showTable"
          title=""
          sub-title=""
          :dataarray="resultArry"
          :is-loading="isLoading"
          :is-search="false"
          @choosen="selected"
        />
      </v-sheet>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="900"
        v-model="dialog"
        persistent
        ><v-card>
          <v-toolbar color="primary" dark>Instructor Information</v-toolbar>
          <!-- <v-card-title class="text-h5">
            Use Google's location service?
          </v-card-title> -->

          <v-card-text>
            <v-simple-table
              id="summarytbl"
              class="text-left my-5"
              v-if="selectedADI"
            >
              <tr>
                <th>Instructor ID</th>
                <td>{{ "A" + selectedADI.adi_id }}</td>
                <th>Name</th>
                <td>
                  {{ selectedADI.title }} {{ selectedADI.fname }}
                  {{ selectedADI.lname }}
                </td>
                <th>Mobile</th>
                <td>{{ selectedADI.mobile | mobileno }}</td>
              </tr>
              <tr>
                <th>Account State</th>
                <td>{{ selectedADI.accState | adiStatus }}</td>
                <th>E-mail</th>
                <td>{{ selectedADI.email }}</td>
                <th>Joined</th>
                <td>{{ selectedADI.created }}</td>
              </tr>
            </v-simple-table>

            <div v-if="selectMode" class="mb-5">
              <h4>Select Reporting Month</h4>
              <v-text-field
                outlined
                class="mt-2"
                label="Reporting Month"
                type="month"
                v-model="reportingMonth"
              ></v-text-field>
              <v-btn
                class="primary"
                @click="generateReport()"
                :loading="isGenerating"
                :disabled="reportingMonth == null"
              >
                View Report</v-btn
              >
            </div>
            <div v-else>
              <h4>Selected Range</h4>
              <p style="font-size: 1.2em">
                <b>From: </b>
                <em>{{ startD | dateReOrderRMtime }}</em>
                <b> To: </b>
                <em>{{ endD | dateReOrderRMtime }} </em>
                <v-btn color="primary" text @click="selectMode = true"
                  >Change</v-btn
                >
              </p>
            </div>

            <v-simple-table
              class="text-left"
              style="width: 100%"
              v-if="dialogTbl && loadedData"
            >
              <!-- New Learners -->
              <thead>
                <tr class="trhead">
                  <th colspan="2">Number of New Learners</th>
                  <th colspan="5">{{ loadedData.new_assings }}</th>
                  <th colspan="1" class="text-right">
                    <v-btn
                      @click="loadNewLearnerData"
                      class="primary"
                      small
                      :loading="newLearnsLoading"
                      :disabled="!isMoreNLInfo"
                      >More <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>

              <v-expand-transition>
                <tbody v-show="isNewLearnsLoaded">
                  <tr class="trbody">
                    <th colspan="2">Learner ID</th>
                    <th colspan="4">Learner Name</th>
                    <th colspan="2">Date of Assign</th>
                  </tr>
                  <!-- Data -->
                  <tr v-for="(item, index) in newLearnarData" :key="index">
                    <td colspan="2">L{{ item.stu_id }}</td>
                    <td colspan="4">
                      {{ item.title }} {{ item.fname }} {{ item.lname }}
                    </td>
                    <td colspan="2">
                      {{ item.assign_on | dateReOrderRMtime }}
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="isNewLearnsLoaded && newLearnarData.length == 0">
                  <tr class="noinfo">
                    <th colspan="8">No Information.</th>
                  </tr>
                </tbody>
              </v-expand-transition>
              <!-- Booked Lessons -->
              <thead>
                <tr class="trhead">
                  <th colspan="2">Number of Booked Lessons:</th>
                  <th colspan="5">{{ loadedData.lesson_bookings }}</th>
                  <th colspan="1" class="text-right">
                    <v-btn
                      @click="loadBookedLessonsData"
                      class="primary"
                      small
                      :loading="bookedLessonsLoading"
                      :disabled="!isMoreBookedLInfo"
                      >More <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>

              <v-expand-transition>
                <tbody v-show="isBookedLessonsLoaded">
                  <tr class="trbody">
                    <th colspan="1">Booking ID</th>
                    <th colspan="1">Learner ID</th>
                    <th colspan="2">Learner Name</th>
                    <th colspan="2">Lesson Date and Time</th>
                    <th colspan="1">Booking Status</th>
                    <th colspan="1">Booked By</th>
                  </tr>
                  <tr v-for="(item, index) in bookedLessData" :key="index">
                    <td colspan="1">B{{ item.b_id }}</td>
                    <td colspan="1">L{{ item.stu_id }}</td>
                    <td colspan="2">
                      {{ item.stu_title }} {{ item.stu_fname }}
                      {{ item.stu_lname }}
                    </td>
                    <td colspan="2">{{ item.b_starts | dateReOrder }}</td>
                    <td colspan="1">{{ item.b_status | bookingStatus }}</td>
                    <td colspan="1" style="text-transform: capitalize">
                      {{ item.b_by }}
                    </td>
                  </tr>
                </tbody>

                <tbody
                  v-if="isBookedLessonsLoaded && bookedLessData.length == 0"
                >
                  <tr class="noinfo">
                    <th colspan="8">No Information.</th>
                  </tr>
                </tbody>
              </v-expand-transition>
              <!-- Given Lessons -->
              <thead>
                <tr class="trhead">
                  <th colspan="1"></th>
                  <th colspan="1">Provided</th>
                  <th colspan="5">
                    {{
                      loadedData.lesson_bookings -
                      loadedData.rescheduled_bookings
                    }}
                  </th>
                  <th colspan="1" class="text-right">
                    <v-btn
                      @click="loadGivenLessonsData"
                      class="primary"
                      small
                      :loading="givenLessonsLoading"
                      :disabled="!isMoreGivenLInfo"
                      >More <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>

              <v-expand-transition>
                <tbody v-show="isGivenLessonsLoaded">
                  <tr class="trbody">
                    <th colspan="1">Booking ID</th>
                    <th colspan="1">Learner ID</th>
                    <th colspan="2">Learner Name</th>
                    <th colspan="2">Lesson Date and Time</th>
                    <th colspan="1">Booking Status</th>
                    <th colspan="1">Booked By</th>
                  </tr>
                  <tr v-for="(item, index) in givenLessData" :key="index">
                    <td colspan="1">B{{ item.b_id }}</td>
                    <td colspan="1">L{{ item.stu_id }}</td>
                    <td colspan="2">
                      {{ item.stu_title }} {{ item.stu_fname }}
                      {{ item.stu_lname }}
                    </td>
                    <td colspan="2">{{ item.b_starts | dateReOrder }}</td>
                    <td colspan="1">{{ item.b_status | bookingStatus }}</td>
                    <td colspan="1" style="text-transform: capitalize">
                      {{ item.b_by }}
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="isGivenLessonsLoaded && givenLessData.length == 0">
                  <tr class="noinfo">
                    <th colspan="8">No Information.</th>
                  </tr>
                </tbody>
              </v-expand-transition>
              <!-- Resheduled Lessons -->
              <thead>
                <tr class="trhead">
                  <th colspan="1"></th>
                  <th colspan="1">Rescheduled</th>
                  <th colspan="5">{{ loadedData.rescheduled_bookings }}</th>
                  <th colspan="1" class="text-right">
                    <v-btn
                      @click="loadRescheduledData"
                      class="primary"
                      small
                      :loading="rescheduledLessonsLoading"
                      :disabled="!isMoreReshedLInfo"
                      >More <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>

              <v-expand-transition>
                <tbody v-show="isRescheduledLessonsLoaded">
                  <tr class="trbody">
                    <th colspan="1">Booking ID</th>
                    <th colspan="1">Learner ID</th>
                    <th colspan="2">Learner Name</th>
                    <th colspan="2">Lesson Date and Time</th>
                    <th colspan="2">Rescheduled On</th>
                  </tr>
                  <tr v-for="(item, index) in reschedLessData" :key="index">
                    <td colspan="1">B{{ item.b_id }}</td>
                    <td colspan="1">L{{ item.stu_id }}</td>
                    <td colspan="2">
                      {{ item.stu_title }} {{ item.stu_fname }}
                      {{ item.stu_lname }}
                    </td>
                    <td colspan="2">{{ item.b_starts | dateReOrder }}</td>
                    <td colspan="2">{{ item.b_edited | dateReOrder }}</td>
                  </tr>
                </tbody>

                <tbody
                  v-if="
                    isRescheduledLessonsLoaded && reschedLessData.length == 0
                  "
                >
                  <tr class="noinfo">
                    <th colspan="8">No Information.</th>
                  </tr>
                </tbody>
              </v-expand-transition>
              <!-- Canceled Lessons -->
              <thead>
                <tr class="trhead">
                  <th colspan="1"></th>
                  <th colspan="1">Cancelled</th>
                  <th colspan="5">{{ loadedData.canceled_bookings }}</th>
                  <th colspan="1" class="text-right">
                    <v-btn
                      @click="loadCanceledData"
                      class="primary"
                      small
                      :loading="canceledLessonsLoading"
                      :disabled="!isMoreCanceledLInfo"
                      >More <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>

              <v-expand-transition>
                <tbody v-show="isCanceledLessonsLoaded">
                  <tr class="trbody">
                    <th colspan="1">Booking ID</th>
                    <th colspan="1">Learner ID</th>
                    <th colspan="2">Learner Name</th>
                    <th colspan="2">Lesson Date and Time</th>
                    <th colspan="2">Canceled On</th>
                  </tr>
                  <tr v-for="(item, index) in canceledLessData" :key="index">
                    <td colspan="1">B{{ item.b_id }}</td>
                    <td colspan="1">L{{ item.stu_id }}</td>
                    <td colspan="2">
                      {{ item.stu_title }} {{ item.stu_fname }}
                      {{ item.stu_lname }}
                    </td>
                    <td colspan="2">{{ item.b_starts | dateReOrder }}</td>
                    <td colspan="2">{{ item.b_edited | dateReOrder }}</td>
                  </tr>
                </tbody>

                <tbody
                  v-if="isCanceledLessonsLoaded && canceledLessData.length == 0"
                >
                  <tr class="noinfo">
                    <th colspan="8">No Information.</th>
                  </tr>
                </tbody>
              </v-expand-transition>
              <!-- Test Outcomes head -->
              <thead>
                <tr class="trhead">
                  <th colspan="2">Test Outcomes:</th>
                  <th colspan="5">
                    {{
                      loadedData.test_outcomes.passed +
                      loadedData.test_outcomes.failed
                    }}
                  </th>
                  <th colspan="1" class="text-right"></th>
                </tr>
              </thead>
              <!-- Passed -->
              <thead>
                <tr class="trhead">
                  <th colspan="1"></th>
                  <th colspan="1">Number of Learners Passed</th>
                  <th colspan="5">{{ loadedData.test_outcomes.passed }}</th>
                  <th colspan="1" class="text-right">
                    <v-btn
                      @click="loadPassedData"
                      class="primary"
                      small
                      :loading="passedLoading"
                      :disabled="!isMorePassesInfo"
                      >More <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>

              <v-expand-transition>
                <tbody v-show="isPassedLoaded">
                  <tr class="trbody">
                    <th colspan="1">Learner ID</th>
                    <th colspan="3">Learner Name</th>
                    <th colspan="2">Passed Date and Time</th>
                    <th colspan="2">Recorded On</th>
                  </tr>
                  <tr v-for="(item, index) in passLearnerData" :key="index">
                    <td colspan="1">L{{ item.stu_id }}</td>
                    <td colspan="3">
                      {{ item.stu_title }} {{ item.stu_fname }}
                      {{ item.stu_lname }}
                    </td>
                    <td colspan="2">{{ item.testdate | dateReOrder }}</td>
                    <td colspan="2">{{ item.created_on | dateReOrder }}</td>
                  </tr>
                </tbody>

                <tbody v-if="isPassedLoaded && passLearnerData.length == 0">
                  <tr class="noinfo">
                    <th colspan="8">No Information.</th>
                  </tr>
                </tbody>
              </v-expand-transition>
              <!-- Failed -->
              <thead>
                <tr class="trhead">
                  <th colspan="1"></th>
                  <th colspan="1">Number of Learners Failed</th>
                  <th colspan="5">{{ loadedData.test_outcomes.failed }}</th>
                  <th colspan="1" class="text-right">
                    <v-btn
                      @click="loadFailedData"
                      class="primary"
                      small
                      :loading="failedLoading"
                      :disabled="!isMoreFailedInfo"
                      >More <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>

              <v-expand-transition>
                <tbody v-show="isFailedLoaded">
                  <tr class="trbody">
                    <th colspan="1">Learner ID</th>
                    <th colspan="3">Learner Name</th>
                    <th colspan="2">Failed Date and Time</th>
                    <th colspan="2">Recorded On</th>
                  </tr>
                  <tr v-for="(item, index) in failedLearnerData" :key="index">
                    <td colspan="1">L{{ item.stu_id }}</td>
                    <td colspan="3">
                      {{ item.stu_title }} {{ item.stu_fname }}
                      {{ item.stu_lname }}
                    </td>
                    <td colspan="2">{{ item.testdate | dateReOrder }}</td>
                    <td colspan="2">{{ item.created_on | dateReOrder }}</td>
                  </tr>
                </tbody>

                <tbody v-if="isFailedLoaded && failedLearnerData.length == 0">
                  <tr class="noinfo">
                    <th colspan="8">No Information.</th>
                  </tr>
                </tbody>
              </v-expand-transition>
            </v-simple-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary darken-1" text @click="closeDialog">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import InstructorTable from "@/components/Instructor/InstructorTable";
import {
  M_OU,
  GET_ACTIVE_INSTRUCTORS,
  LOAD_INSTRUCTOR_LESSONS_SUMMARY,
  LOAD_ADI_NEW_LEARNER_DATA,
  LOAD_ADI_BOOKED_LESSONS_DATA,
  LOAD_ADI_RESCHEDULED_LESSONS_DATA,
  LOAD_ADI_CANCELED_LESSONS_DATA,
  LOAD_ADI_TEST_OUTCOMES_DATA,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InstructorPerformanceReport",
  components: { InstructorTable },
  data: function () {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Instructor Performance Report",
          disabled: true,
          href: "/dashboard/instructorperformancereport",
        },
      ],
      keyword: "",

      showTable: true,
      isLoading: false,
      dialog: false,

      selectedADI: {},

      //select mode
      selectMode: true,
      reportingMonth: null,
      isGenerating: false,
      startD: null,
      endD: null,

      //table inside dialog
      dialogTbl: false,

      //initial data
      loadedData: [],

      newLearnsLoading: false,
      bookedLessonsLoading: false,
      givenLessonsLoading: false,
      rescheduledLessonsLoading: false,
      canceledLessonsLoading: false,

      passedLoading: false,
      failedLoading: false,

      //this will be true when the load record request sent

      isNewLearnsLoaded: false,
      isBookedLessonsLoaded: false,
      isGivenLessonsLoaded: false,
      isRescheduledLessonsLoaded: false,
      isCanceledLessonsLoaded: false,

      isPassedLoaded: false,
      isFailedLoaded: false,

      //dataArrays
      newLearnarData: [],
      bookedLessData: [],
      givenLessData: [],
      reschedLessData: [],
      canceledLessData: [],
      passLearnerData: [],
      failedLearnerData: [],
    };
  },
  methods: {
    ...mapActions({
      getLessonsSummary: `${M_OU}${LOAD_INSTRUCTOR_LESSONS_SUMMARY}`,
      getNewLearnerData: `${M_OU}${LOAD_ADI_NEW_LEARNER_DATA}`,
      getBookedLessonsData: `${M_OU}${LOAD_ADI_BOOKED_LESSONS_DATA}`,
      getRescheduledData: `${M_OU}${LOAD_ADI_RESCHEDULED_LESSONS_DATA}`,
      getCanceledLessonData: `${M_OU}${LOAD_ADI_CANCELED_LESSONS_DATA}`,
      getTestOutcomesData: `${M_OU}${LOAD_ADI_TEST_OUTCOMES_DATA}`,
    }),
    selected(instructor) {
      this.selectedADI = instructor.record;
      this.dialog = true;
    },
    makeitradable(val) {
      if (val < 10) {
        //code
        return "0" + val;
      } else {
        return val;
      }
    },
    generateReport() {
      //if input date validations are true
      //then show loading and start axios to get summary from controller
      this.isGenerating = true;

      let dt = new Date(this.reportingMonth);
      let month = dt.getMonth(),
        year = dt.getFullYear();
      let fDay = new Date(year, month, 1);
      let lDay = new Date(year, month + 1, 0);

      //this will be new starting and ending
      let sd =
        fDay.getFullYear() +
        "-" +
        this.makeitradable(fDay.getMonth() + 1) +
        "-" +
        this.makeitradable(fDay.getDate());
      this.startD = sd;
      let ed =
        lDay.getFullYear() +
        "-" +
        this.makeitradable(lDay.getMonth() + 1) +
        "-" +
        this.makeitradable(lDay.getDate());
      this.endD = ed;

      //promise call
      this.getLessonsSummary({
        id: this.selectedADI.adi_id,
        start: this.startD,
        end: this.endD,
      })
        .then((res) => {
          if (res) {
            this.loadedData = res;
          } else {
            this.loadedData = {};
          }
        })
        .finally(() => {
          this.isGenerating = false;
          this.selectMode = false;
          this.dialogTbl = true;
        });
    },
    loadNewLearnerData() {
      //setting the spinner
      this.newLearnsLoading = true;
      this.getNewLearnerData({
        id: this.selectedADI.adi_id,
        start: this.startD,
        end: this.endD,
      })
        .then((res) => {
          if (res) {
            this.newLearnarData = res;
          } else {
            this.newLearnarData = [];
          }
        })
        .finally(() => {
          //setting is loaded
          this.isNewLearnsLoaded = true;
          //hide spinner
          this.newLearnsLoading = false;
        });
    },
    loadBookedLessonsData() {
      //setting the spinner
      this.bookedLessonsLoading = true;
      this.getBookedLessonsData({
        id: this.selectedADI.adi_id,
        start: this.startD,
        end: this.endD,
      })
        .then((res) => {
          if (res) {
            this.bookedLessData = res.filter((bk) => bk.b_status != 3);
          } else {
            this.bookedLessData = [];
          }
        })
        .finally(() => {
          //setting is loaded
          this.isBookedLessonsLoaded = true;
          //hide spinner
          this.bookedLessonsLoading = false;
        });
    },
    loadGivenLessonsData() {
      //setting the spinner
      this.givenLessonsLoading = true;
      //doing the loading now
      this.getBookedLessonsData({
        id: this.selectedADI.adi_id,
        start: this.startD,
        end: this.endD,
      })
        .then((res) => {
          if (res) {
            this.givenLessData = res;
          } else {
            this.givenLessData = [];
          }
        })
        .finally(() => {
          //setting is loaded
          this.isGivenLessonsLoaded = true;
          //hide spinner
          this.givenLessonsLoading = false;
        });
    },
    loadRescheduledData() {
      //setting the spinner
      this.rescheduledLessonsLoading = true;
      this.getRescheduledData({
        id: this.selectedADI.adi_id,
        start: this.startD,
        end: this.endD,
      })
        .then((res) => {
          if (res) {
            this.reschedLessData = res;
          } else {
            this.reschedLessData = [];
          }
        })
        .finally(() => {
          //setting is loaded
          this.isRescheduledLessonsLoaded = true;
          //hide spinner
          this.rescheduledLessonsLoading = false;
        });
    },
    loadCanceledData() {
      //setting the spinner
      this.canceledLessonsLoading = true;
      this.getCanceledLessonData({
        id: this.selectedADI.adi_id,
        start: this.startD,
        end: this.endD,
      })
        .then((res) => {
          if (res) {
            this.canceledLessData = res;
          } else {
            this.canceledLessData = [];
          }
        })
        .finally(() => {
          //setting is loaded
          this.isCanceledLessonsLoaded = true;
          //hide spinner
          this.canceledLessonsLoading = false;
        });
    },
    loadPassedData() {
      //setting the spinner
      this.passedLoading = true;
      this.getTestOutcomesData({
        type: "1",
        id: this.selectedADI.adi_id,
        start: this.startD,
        end: this.endD,
      })
        .then((res) => {
          if (res) {
            this.passLearnerData = res;
          } else {
            this.passLearnerData = [];
          }
        })
        .finally(() => {
          //setting is loaded
          this.isPassedLoaded = true;
          //hide spinner
          this.passedLoading = false;
        });
    },
    loadFailedData() {
      //setting the spinner
      this.failedLoading = true;
      //doing the loading now
      this.getTestOutcomesData({
        type: "0",
        id: this.selectedADI.adi_id,
        start: this.startD,
        end: this.endD,
      })
        .then((res) => {
          if (res) {
            this.failedLearnerData = res;
          } else {
            this.failedLearnerData = [];
          }
        })
        .finally(() => {
          //setting is loaded
          this.isFailedLoaded = true;
          //hide spinner
          this.failedLoading = false;
        });
    },
    closeDialog() {
      this.dialog = false;
      this.dialogTbl = false;
      //reset range selection
      this.selectMode = true;
      this.reportingMonth = null;
      this.isGenerating = false;
      this.startD = null;
      this.endD = null;
      //resetting values
      this.selectedADI = {};

      //initial data
      this.loadedData = [];

      this.newLearnsLoading = false;
      this.bookedLessonsLoading = false;
      this.givenLessonsLoading = false;
      this.rescheduledLessonsLoading = false;
      this.canceledLessonsLoading = false;

      this.passedLoading = false;
      this.failedLoading = false;

      this.isNewLearnsLoaded = false;
      this.isBookedLessonsLoaded = false;
      this.isGivenLessonsLoaded = false;
      this.isRescheduledLessonsLoaded = false;
      this.isCanceledLessonsLoaded = false;

      this.isPassedLoaded = false;
      this.isFailedLoaded = false;

      this.newLearnarData = [];
      this.bookedLessData = [];
      this.givenLessData = [];
      this.reschedLessData = [];
      this.canceledLessData = [];
      this.passLearnerData = [];
      this.failedLearnerData = [];
    },
  },
  computed: {
    ...mapGetters({
      getADIs: `${M_OU}${GET_ACTIVE_INSTRUCTORS}`,
    }),
    resultArry() {
      if (this.keyword && this.getADIs) {
        return this.getADIs.filter((adirec) => {
          return (
            adirec.adi_id.toString().includes(this.keyword.toLowerCase()) ||
            adirec.fname.toLowerCase().includes(this.keyword.toLowerCase()) ||
            adirec.mname.toLowerCase().includes(this.keyword.toLowerCase()) ||
            adirec.lname.toLowerCase().includes(this.keyword.toLowerCase()) ||
            adirec.mobile.toLowerCase().includes(this.keyword.toLowerCase()) ||
            adirec.email.toLowerCase().includes(this.keyword.toLowerCase()) ||
            adirec.currAddr.county
              .toLowerCase()
              .includes(this.keyword.toLowerCase()) ||
            adirec.driving_licence_no
              .toLowerCase()
              .includes(this.keyword.toLowerCase()) ||
            adirec.instructor_licence_no
              .toLowerCase()
              .includes(this.keyword.toLowerCase())
          );
        });
      } else {
        return this.getADIs;
      }
    },
    isMoreNLInfo() {
      if (this.newLearnsLoading) {
        return true;
      } else {
        if (parseInt(this.loadedData.new_assings) > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    isMoreBookedLInfo() {
      if (this.bookedLessonsLoading) {
        return true;
      } else {
        if (parseInt(this.loadedData.lesson_bookings) > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    isMoreGivenLInfo() {
      if (this.givenLessonsLoading) {
        return true;
      } else {
        if (parseInt(this.loadedData.lesson_bookings) > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    isMoreReshedLInfo() {
      if (this.rescheduledLessonsLoading) {
        return true;
      } else {
        if (parseInt(this.loadedData.rescheduled_bookings) > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    isMoreCanceledLInfo() {
      if (this.canceledLessonsLoading) {
        return true;
      } else {
        if (parseInt(this.loadedData.canceled_bookings) > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    isMorePassesInfo() {
      if (this.passedLoading) {
        return true;
      } else {
        if (typeof this.loadedData.test_outcomes == "undefined") return false;
        if (parseInt(this.loadedData.test_outcomes.passed) > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    isMoreFailedInfo() {
      if (this.failedLoading) {
        return true;
      } else {
        if (typeof this.loadedData.test_outcomes == "undefined") return false;
        if (parseInt(this.loadedData.test_outcomes.failed) > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
#summarytbl {
  td {
    padding: 5px;
  }
}
.trhead {
  background-color: #d1d3d6;
  th:nth-child(1) {
    column-span: 2;
  }
  th:nth-child(2) {
    column-span: 6;
  }
  th:nth-child(3) {
    column-span: 1;
  }
}

.trbody {
  background-color: #e9ebef;
}
.noinfo {
  background-color: #f00;
  color: white;
}
</style>
