<template>
  <div>
    <v-card class="point" @click="dialog = true" v-if="basicObj">
      <v-card-title
        class="text-h6 grey lighten-2"
        style="justify-content: center"
      >
        Upload Vehicle Documents
      </v-card-title>
      <v-img
        :src="require(`@/assets/img/updateinfo/uploadcardoc.webp`)"
        max-height="300"
        contain
        class="grey lighten-3 pa-5"
      ></v-img>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title>
            <span class="text-h5">Update Instructor Vehicle Documents</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text style="height: 60vh">
          <v-container>
            <v-row
              ><v-alert type="error" v-if="activeCars.length < 1">
                No Active Cars to display</v-alert
              >
              <v-col md="6" v-for="(car, i) in activeCars" :key="i"
                ><v-card elevation="2" outlined shaped
                  ><v-img
                    height="250"
                    :src="require(`@/assets/img/updateinfo/carupdate.webp`)"
                  ></v-img
                  ><v-card-title>{{ car.make }} {{ car.model }} </v-card-title>

                  <v-card-text>
                    <div class="my-4 text-subtitle-1">
                      <v-icon> mdi-counter</v-icon> {{ car.c_reg }}
                    </div>
                    <div class="my-4 text-subtitle-1">
                      <v-icon> mdi-palette</v-icon> {{ car.colour }}
                    </div>
                    <div class="my-4 text-subtitle-1">
                      <v-icon> mdi-car-sports</v-icon
                      >{{ getCarTypeName(car.c_type_id) }}
                    </div>
                    <div class="my-4 text-subtitle-1">
                      <v-icon> mdi-gas-station</v-icon
                      ><span style="text-transform: uppercase">{{
                        car.c_fuel
                      }}</span>
                    </div>
                  </v-card-text>

                  <v-divider class="mx-4"></v-divider>

                  <v-card-actions>
                    <v-btn color="red lighten-2" text @click="carSelected(i)">
                      Select
                    </v-btn>
                  </v-card-actions></v-card
                ></v-col
              ></v-row
            ><v-row v-if="selectMode" transition="scroll-y-transition">
              <v-col
                ><div>
                  <v-form ref="form">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-text-field
                        v-if="selectedCar"
                        name="leagalowner"
                        outlined
                        label="Selected Vehicle"
                        required
                        :value="`${selectedCar.make} ${selectedCar.model} (${selectedCar.c_reg})`"
                        readonly
                      ></v-text-field>
                      <v-select
                        v-model="form.dualcontrol"
                        :items="dualCont"
                        name="dualcontols"
                        outlined
                        item-text="txt"
                        item-value="id"
                        label="Does the vehicle have dual controls?"
                      ></v-select>
                      <v-select
                        v-model="form.carownertype"
                        :items="vehicleOwnerTypes"
                        name="carownertype"
                        outlined
                        item-text="desc"
                        item-value="type_id"
                        label="The vehicle you teach in is:"
                      ></v-select>
                      <v-text-field
                        v-model="form.caroneleagalowner"
                        name="leagalowner"
                        outlined
                        :counter="50"
                        label="Name of the Legal Registered Owner &amp; Keeper of the Vehicle"
                        required
                        :error-messages="caroneleagalownerErrors"
                        @input="$v.form.caroneleagalowner.$touch()"
                        @blur="$v.form.caroneleagalowner.$touch()"
                      ></v-text-field>
                      <v-file-input
                        v-model="form.caronelogbook"
                        id="fllogbook1"
                        label="Log Book (V5C)"
                        :rules="rules"
                        outlined
                        clearable
                        show-size
                        :error-messages="caronelogbookErrors"
                        accept="application/pdf"
                        placeholder="Select PDF File"
                        @change="onLogbook($event)"
                        @input="$v.form.caronelogbook.$touch()"
                        @blur="$v.form.caronelogbook.$touch()"
                      ></v-file-input>
                      <v-file-input
                        v-model="form.caronemot"
                        id="flmot1"
                        label="MOT Documents"
                        :rules="rules"
                        outlined
                        clearable
                        show-size
                        :error-messages="caronemotErrors"
                        accept="application/pdf"
                        placeholder="Select PDF File"
                        @change="onMot($event)"
                        @input="$v.form.caronemot.$touch()"
                        @blur="$v.form.caronemot.$touch()"
                      ></v-file-input>
                      <v-text-field
                        v-model="form.caronemotexp"
                        name="motexp"
                        label="MOT Expiry Date"
                        outlined
                        required
                        :error-messages="caronemotexpErrors"
                        @input="$v.form.caronemotexp.$touch()"
                        @blur="$v.form.caronemotexp.$touch()"
                        type="date"
                      ></v-text-field>
                      <v-file-input
                        v-model="form.caroneinsurance"
                        id="flinsurance1"
                        label="Insurance Documents"
                        :rules="rules"
                        outlined
                        clearable
                        show-size
                        :error-messages="caroneinsuranceErrors"
                        accept="application/pdf"
                        placeholder="Select PDF File"
                        @change="onInsurance($event)"
                        @input="$v.form.caroneinsurance.$touch()"
                        @blur="$v.form.caroneinsurance.$touch()"
                      ></v-file-input>

                      <v-text-field
                        v-model="form.caroneinsuranceexp"
                        name="insuranceexp"
                        label="Insurance Expiry Date"
                        outlined
                        required
                        :error-messages="caroneinsuranceexpErrors"
                        @input="$v.form.caroneinsuranceexp.$touch()"
                        @blur="$v.form.caroneinsuranceexp.$touch()"
                        type="date"
                      ></v-text-field>
                    </v-card-text>
                  </v-form></div
              ></v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="selectMode"
            color="warning darken-1"
            text
            @click="saveChanges()"
            :disabled="$v.form.$invalid || isAllEmpty"
          >
            Update Documents
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  GET_FUEL_TYPES,
  GET_VEHICLE_TYPES,
  M_DD,
  M_ADI,
  SAVE_ADI_CAR_DOCS,
  GET_VEHICLE_OWNER_TYPES,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
import { maxLength, requiredIf } from "vuelidate/lib/validators";
export default {
  name: "UploadCarDocs",
  props: ["basicObj"],
  data() {
    return {
      dialog: false,
      gears: [
        { id: "automatic", txt: "Automatic" },
        { id: "manual", txt: "Manual" },
      ],
      dualCont: [
        { id: 2, txt: "No" },
        { id: 1, txt: "Yes" },
      ],
      rules: [
        (value) =>
          !value ||
          value.size < 4000000 ||
          "PDF Document file size should be less than 4 MB!",
      ],
      selectMode: false,
      selectedCar: {},
      //activeCars: [],
      form: {
        adiid: null, //readonly
        carid: null,

        caroneleagalowner: "",
        caronelogbook: null,
        caronemot: null,
        caronemotexp: "",
        caroneinsurance: null,
        caroneinsuranceexp: "",
        //new questions
        dualcontrol: null,
        carownertype: null,
      },
      fls: {
        fllogbook1: "",
        flmot1: "",
        flinsurance1: "",
      },
    };
  },
  validations: {
    form: {
      caroneleagalowner: {
        required: requiredIf(function (form) {
          return form.caronelogbook;
        }),
        maxLength: maxLength(50),
      },
      caronelogbook: {
        required: requiredIf(function (form) {
          return form.caroneleagalowner;
        }),
      },
      caronemot: {
        required: requiredIf(function (form) {
          return form.caronemotexp;
        }),
      },
      caronemotexp: {
        required: requiredIf(function (form) {
          return form.caronemot;
        }),
      },
      caroneinsurance: {
        required: requiredIf(function (form) {
          return form.caroneinsuranceexp;
        }),
      },
      caroneinsuranceexp: {
        required: requiredIf(function (form) {
          return form.caroneinsurance;
        }),
      },
    },
  },

  computed: {
    ...mapGetters({
      fuelTypes: `${M_DD}${GET_FUEL_TYPES}`,
      vehicleTypes: `${M_DD}${GET_VEHICLE_TYPES}`,
      vehicleOwnerTypes: `${M_DD}${GET_VEHICLE_OWNER_TYPES}`,
    }),
    //error messages
    caroneleagalownerErrors() {
      const errors = [];
      if (!this.$v.form.caroneleagalowner.$dirty) return errors;
      !this.$v.form.caroneleagalowner.required &&
        errors.push("Leagl Owner information required.");
      !this.$v.form.caroneleagalowner.maxLength &&
        errors.push("Leagl Owner Name is too long. Max 50 characters allowed.");
      return errors;
    },
    caronelogbookErrors() {
      const errors = [];
      if (!this.$v.form.caronelogbook.$dirty) return errors;
      !this.$v.form.caronelogbook.required &&
        errors.push("Vehicle V5C is required.");
      return errors;
    },
    caronemotErrors() {
      const errors = [];
      if (!this.$v.form.caronemot.$dirty) return errors;
      !this.$v.form.caronemot.required &&
        errors.push("Vehicle MOT is required.");
      return errors;
    },
    caronemotexpErrors() {
      const errors = [];
      if (!this.$v.form.caronemotexp.$dirty) return errors;
      !this.$v.form.caronemotexp.required &&
        errors.push("Vehicle MOT Expiry Date is required.");
      return errors;
    },
    caroneinsuranceErrors() {
      const errors = [];
      if (!this.$v.form.caroneinsurance.$dirty) return errors;
      !this.$v.form.caroneinsurance.required &&
        errors.push("Vehicle Insurance Document required.");
      return errors;
    },
    caroneinsuranceexpErrors() {
      const errors = [];
      if (!this.$v.form.caroneinsuranceexp.$dirty) return errors;
      !this.$v.form.caroneinsuranceexp.required &&
        errors.push("Vehicle Insurance Expiry Date is required.");
      return errors;
    },
    isAllEmpty() {
      if (
        !this.form.caroneleagalowner &&
        !this.form.caronelogbook &&
        !this.form.caronemot &&
        !this.form.caronemotexp &&
        !this.form.caroneinsurance &&
        !this.form.caroneinsuranceexp
      ) {
        return true;
      } else {
        return false;
      }
    },
    activeCars() {
      return this.basicObj.cars.filter((car) => car.rec_status === 1);
    },
  },
  methods: {
    ...mapActions({
      updateDocs: `${M_ADI}${SAVE_ADI_CAR_DOCS}`,
    }),
    carSelected(index) {
      this.selectMode = false;
      this.clearForm();
      this.selectMode = true;
      this.selectedCar = this.activeCars[index];

      this.form.caroneleagalowner = this.activeCars[index].leagal_owner;
      this.form.dualcontrol = this.activeCars[index].dual_ctrl;
      this.form.carownertype = this.activeCars[index].owner_type;
    },
    saveChanges() {
      //need to do some processing in here.
      //passing object
      let passObj = {};
      passObj.id = this.form.adiid;
      passObj.carid = this.selectedCar.c_id;
      let frm = {}; //this will hold primitive data
      let upfls = {}; // this will hold file uploads

      if (this.form.caroneleagalowner && this.form.caronelogbook) {
        frm.leagalowner = this.form.caroneleagalowner;
        upfls.fllogbook1 = this.fls.fllogbook1;
      }
      if (this.form.caronemot && this.form.caronemotexp) {
        frm.motexp = this.form.caronemotexp;
        upfls.flmot1 = this.fls.flmot1;
      }
      if (this.form.caroneinsurance && this.form.caroneinsuranceexp) {
        frm.insuranceexp = this.form.caroneinsuranceexp;
        upfls.flinsurance1 = this.fls.flinsurance1;
      }
      //add dual ctrl and car owner
      frm.dualcontrol = this.form.dualcontrol;
      frm.carownertype = this.form.carownertype;
      passObj.form = frm; //assign form
      passObj.upfiles = upfls; // assign files

      //lets upload
      this.updateDocs(passObj).then((rep) => {
        if (rep == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Vehicle documents uploaded successfully. Local data will updated on next refresh.",
            type: "success",
            //timer: 3000,
            allowOutsideClick: false,
          }).then((r) => {
            this.dialog = false;
            console.log(r.value);
            //clearing form
            this.clearForm();
            this.selectMode = false;
            this.$refs.form.resetValidation();
            this.$v.form.$reset();
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error uploading vehicle document. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
    onLogbook(e) {
      this.fls.fllogbook1 = e;
    },
    onMot(e) {
      this.fls.flmot1 = e;
    },
    onInsurance(e) {
      this.fls.flinsurance1 = e;
    },
    clearForm() {
      this.form = {
        adiid: this.form.adiid, //readonly
        carid: this.form.carid,

        caroneleagalowner: "",
        caronelogbook: null,
        caronemot: null,
        caronemotexp: "",
        caroneinsurance: null,
        caroneinsuranceexp: "",
        dualcontrol: null,
        carownertype: null,
      };
    },
    //this method will return car type
    getCarTypeName(key) {
      if (key) {
        let res = this.vehicleTypes.filter((data) => {
          return data.id == key;
        });
        return res[0]["txt"];
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.form.adiid = this.basicObj.adiid;
    /*this.basicObj.cars.forEach((car) => {
      if (car.rec_status == 1) {
        this.activeCars.push(car);
      }
    });*/
  },
};
</script>

<style></style>
