<template>
  <div>
    <v-form ref="form">
      <v-row>
        <v-col md="4">
          <v-text-field
            v-model.number="form.crtbref"
            name="crtbref"
            style="width: 50%"
            :counter="12"
            outlined
            label="DBS Reference Check*"
            reqired
            type="number"
            :error-messages="crtbrefErrors"
            @input="$v.form.crtbref.$touch()"
            @blur="$v.form.crtbref.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.fbname"
            name="fbname"
            :counter="30"
            outlined
            label="Name of Bank*"
            reqired
            :error-messages="fbnameErrors"
            @input="$v.form.fbname.$touch()"
            @blur="$v.form.fbname.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.iscode"
            name="iscode"
            style="width: 50%"
            :counter="8"
            outlined
            label="Sort Code*"
            reqired
            :error-messages="scodeErrors"
            @input="$v.form.scode.$touch()"
            @blur="$v.form.scode.$touch()"
            @change="
              currentState();
              scodeUpdate();
            "
            v-mask="`##-##-##`"
          ></v-text-field>
          <v-text-field
            v-model="form.accno"
            name="accno"
            style="width: 50%"
            :counter="8"
            outlined
            label="Account Number*"
            reqired
            :error-messages="accnoErrors"
            @input="$v.form.accno.$touch()"
            @blur="$v.form.accno.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.naob"
            name="naob"
            :counter="50"
            outlined
            label="Your Name as it appears on the Bank Account*"
            reqired
            :error-messages="naobErrors"
            @input="$v.form.naob.$touch()"
            @blur="$v.form.naob.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.nino"
            name="nino"
            style="width: 50%"
            outlined
            :counter="9"
            label="National Insurance Number"
            reqired
            :error-messages="ninoErrors"
            @input="$v.form.nino.$touch()"
            @blur="$v.form.nino.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-file-input
            v-model="form.addressProf1"
            id="poadoc1"
            label="Proof of Address 1"
            :rules="rules"
            outlined
            clearable
            show-size
            :error-messages="addressProf1Errors"
            accept="application/pdf"
            placeholder="Select PDF File"
            @input="$v.form.addressProf1.$touch()"
            @blur="$v.form.addressProf1.$touch()"
            @change="currentState()"
          ></v-file-input>
          <v-file-input
            v-model="form.addressProf2"
            id="poadoc2"
            label="Proof of Address 2"
            :rules="rules"
            outlined
            clearable
            show-size
            :error-messages="addressProf2Errors"
            accept="application/pdf"
            placeholder="Select PDF File"
            @input="$v.form.addressProf2.$touch()"
            @blur="$v.form.addressProf2.$touch()"
            @change="currentState()"
          ></v-file-input> </v-col
      ></v-row>
    </v-form>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import {
  required,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";

export default {
  name: "Compliance",
  directives: { mask },
  data() {
    return {
      //rules for address update
      rules: [
        (value) =>
          !value ||
          value.size < 4000000 ||
          "Address proof file size should be less than 4 MB!",
      ],
      form: {
        crtbref: "",
        fbname: "",
        scode: "",
        iscode: "", //visible one with mask

        accno: "",
        naob: "",
        nino: "",

        addressProf1: null,
        addressProf2: null,
      },
    };
  },
  validations: {
    form: {
      crtbref: {
        required,
        numeric,
        minLength: minLength(12),
        maxLength: maxLength(12),
      },
      fbname: { required, maxLength: maxLength(30) },
      scode: {
        required,
        numeric,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
      accno: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(8),
      },
      naob: { required, maxLength: maxLength(50) },
      nino: { required, minLength: minLength(9), maxLength: maxLength(9) },
      addressProf1: { required },
      addressProf2: { required },
    },
  },
  methods: {
    currentState() {
      if (this.form.addressProf1 && this.form.addressProf2) {
        //lets append those to form
        let proofAdd1 = document.getElementById("poadoc1").files[0];
        let proofAdd2 = document.getElementById("poadoc2").files[0];

        let formdata = new FormData(this.$refs.form.$el);
        formdata.set("scode", this.form.scode); //unmasked sortcode
        formdata.set("poadoc1", proofAdd1, "proof1.pdf");
        formdata.set("poadoc2", proofAdd2, "proof2.pdf");

        this.$emit("compliance-update", {
          state: this.$v.form.$invalid,
          data: this.form,
          fd: formdata,
        });
      } else {
        this.$emit("compliance-update", {
          state: this.$v.form.$invalid,
          data: this.form,
          fd: new FormData(this.$refs.form.$el),
        });
      }
    },
    //this function will unmask sortcode on change
    scodeUpdate() {
      this.form.scode = this.form.iscode.replace(/-/g, "");
    },
  },
  computed: {
    crtbrefErrors() {
      const errors = [];
      if (!this.$v.form.crtbref.$dirty) return errors;
      !this.$v.form.crtbref.numeric && errors.push("Numbers only");
      !this.$v.form.crtbref.maxLength &&
        errors.push("DBS Reference must be at most 16 characters long");
      !this.$v.form.crtbref.minLength &&
        errors.push("DBS Reference must be at most 16 characters long");
      !this.$v.form.crtbref.required &&
        errors.push("DBS Reference is required.");

      return errors;
    },
    fbnameErrors() {
      const errors = [];
      if (!this.$v.form.fbname.$dirty) return errors;
      !this.$v.form.fbname.maxLength &&
        errors.push("Full Bank Name must be at most 30 characters long");
      !this.$v.form.fbname.required &&
        errors.push("Full Bank Name is required.");

      return errors;
    },
    scodeErrors() {
      const errors = [];
      if (!this.$v.form.scode.$dirty) return errors;
      !this.$v.form.scode.numeric && errors.push("Numbers only");
      !this.$v.form.scode.maxLength &&
        errors.push("Sort Code must be at most 8 characters long");
      !this.$v.form.scode.minLength && errors.push("Invalid Sort Code");
      !this.$v.form.scode.required && errors.push("Sort Code is required.");

      return errors;
    },
    accnoErrors() {
      const errors = [];
      if (!this.$v.form.accno.$dirty) return errors;
      !this.$v.form.accno.numeric && errors.push("Numbers only");
      !this.$v.form.accno.maxLength &&
        errors.push("Account Number must be at most 8 characters long");
      !this.$v.form.accno.minLength && errors.push("Invalid Account Number");
      !this.$v.form.accno.required &&
        errors.push("Account Number is required.");

      return errors;
    },
    naobErrors() {
      const errors = [];
      if (!this.$v.form.naob.$dirty) return errors;
      !this.$v.form.naob.maxLength &&
        errors.push("Name must be at most 50 characters long");
      !this.$v.form.naob.required && errors.push("Name is required.");

      return errors;
    },
    ninoErrors() {
      const errors = [];
      if (!this.$v.form.nino.$dirty) return errors;
      !this.$v.form.nino.maxLength &&
        errors.push(
          "National Insurance Number must be at most 9 characters long"
        );
      !this.$v.form.nino.minLength &&
        errors.push("Invalid National Insurance Number");
      !this.$v.form.nino.required &&
        errors.push("National Insurance Number is required.");

      return errors;
    },
    addressProf1Errors() {
      const errors = [];
      if (!this.$v.form.addressProf1.$dirty) return errors;
      !this.$v.form.addressProf1.required &&
        errors.push("Address Proof 1 is required.");

      return errors;
    },
    addressProf2Errors() {
      const errors = [];
      if (!this.$v.form.addressProf2.$dirty) return errors;
      !this.$v.form.addressProf2.required &&
        errors.push("Address Proof 2 is required.");

      return errors;
    },
  },
};
</script>

<style></style>
