<template>
  <div class="row">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5"
        ><h2>All Instructors in the System</h2>
        <p class="sub-title">
          Search Instructors by their ID, Name, E-mail or Mobile Numbers
        </p>
        <InstructorTable
          title=""
          sub-title=""
          :dataarray="resultArry"
          :is-loading="!isLoading"
          :is-search="true"
          @choosen="selected"
        />
        <v-dialog
          transition="dialog-bottom-transition"
          max-width="900"
          v-model="dialog"
          persistent
          ><v-card>
            <v-toolbar color="primary" dark>Instructor Information</v-toolbar>
            <!-- <v-card-title class="text-h5">
            Use Google's location service?
          </v-card-title> -->

            <v-card-text>
              <table class="special-table text-left my-5" v-if="adiObj">
                <tr>
                  <th style="width: 50%">ADI ID</th>
                  <th style="width: 50%">{{ "A" + adiObj.adi_id }}</th>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>
                    {{
                      adiObj.title +
                      " " +
                      adiObj.fname +
                      " " +
                      adiObj.mname +
                      " " +
                      adiObj.lname
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Gender</th>
                  <td>{{ adiObj.gender | capitalize }}</td>
                </tr>
                <tr>
                  <th>Permanent Home Address</th>
                  <td>
                    <span v-for="(recs, i) in adiObj.permAddr" :key="i"
                      >{{ recs }}<br
                    /></span>
                  </td>
                </tr>
                <tr>
                  <th>Current Address</th>
                  <td>
                    <span v-for="(recs, i) in adiObj.currAddr" :key="i"
                      >{{ recs }}<br
                    /></span>
                  </td>
                </tr>
                <tr>
                  <th>Driving Licence No</th>
                  <td>{{ adiObj.driving_licence_no }}</td>
                </tr>
                <tr>
                  <th>ADI/PDI Licence No</th>
                  <td>{{ adiObj.instructor_licence_no }}</td>
                </tr>
                <tr>
                  <th>E-mail</th>
                  <td>{{ adiObj.email }}</td>
                </tr>
                <tr>
                  <th>Main Mobile Number</th>
                  <td>{{ adiObj.mobile | mobileno }}</td>
                </tr>
                <tr>
                  <th>Mobile Number 2</th>
                  <td>{{ adiObj.mobilePhone2 | mobileno }}</td>
                </tr>
                <tr>
                  <th>Home Phone Number 1</th>
                  <td>{{ adiObj.homePhone1 | landlineno }}</td>
                </tr>
                <tr>
                  <th>Home Phone Number 2</th>
                  <td>{{ adiObj.homePhone2 | landlineno }}</td>
                </tr>
                <tr>
                  <th>Work Phone Number 1</th>
                  <td>{{ adiObj.workPhone1 | landlineno }}</td>
                </tr>
                <tr>
                  <th>Work Phone Number 2</th>
                  <td>{{ adiObj.workPhone2 | landlineno }}</td>
                </tr>
                <tr>
                  <th>DBS Reference</th>
                  <td>{{ adiObj.crtbRef }}</td>
                </tr>
                <tr>
                  <th>Full Bank Name</th>
                  <td v-if="adiObj.bankInfo">{{ adiObj.bankInfo.bankname }}</td>
                </tr>
                <tr>
                  <th>Name of Account</th>
                  <td v-if="adiObj.bankInfo">
                    {{ adiObj.bankInfo.nameonacc }}
                  </td>
                </tr>
                <tr>
                  <th>Account Number</th>
                  <td v-if="adiObj.bankInfo">{{ adiObj.bankInfo.accno }}</td>
                </tr>
                <tr>
                  <th>Sort Code</th>
                  <td v-if="adiObj.bankInfo">
                    {{ adiObj.bankInfo.sortcode | decoratesortcode }}
                  </td>
                </tr>
                <tr>
                  <th>National Insurance Number</th>
                  <td>{{ adiObj.nino }}</td>
                </tr>
                <tr>
                  <th>Teaching Gender</th>
                  <td>{{ adiObj.teaching_gens | capitalize }}</td>
                </tr>
                <tr>
                  <th>Teaching Gear Type</th>
                  <td>{{ adiObj.teaching_gears | capitalize }}</td>
                </tr>
                <tr>
                  <th>Covering Postcodes</th>
                  <td>
                    <span
                      v-for="(area, index) in adiObj.covering_pcs"
                      style="font-weight: 500"
                      :key="index"
                    >
                      <span
                        v-if="Object.keys(adiObj.covering_pcs).length == 1"
                        >{{ area.pc }}</span
                      >
                      <span
                        v-else-if="
                          index == Object.keys(adiObj.covering_pcs).length - 1
                        "
                        >{{ area.pc }}</span
                      >
                      <span v-else>{{ area.pc }}, </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Teaching Languages</th>
                  <td>
                    <span
                      v-for="(lang, index) in adiObj.teaching_langs"
                      style="font-weight: 500"
                      :key="index"
                    >
                      <span
                        v-if="Object.keys(adiObj.teaching_langs).length == 1"
                        >{{ lang.lang_name }}</span
                      >
                      <span
                        v-else-if="
                          index == Object.keys(adiObj.teaching_langs).length - 1
                        "
                        >{{ lang.lang_name }}</span
                      >
                      <span v-else>{{ lang.lang_name }}, </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Teaching Vehicle Types</th>
                  <td>
                    <span
                      v-for="(car, index) in adiObj.teaching_car_types"
                      style="font-weight: 500"
                      :key="index"
                    >
                      <span
                        v-if="
                          Object.keys(adiObj.teaching_car_types).length == 1
                        "
                        >{{ car.type_name }}</span
                      >
                      <span
                        v-else-if="
                          index ==
                          Object.keys(adiObj.teaching_car_types).length - 1
                        "
                        >{{ car.type_name }}</span
                      >
                      <span v-else>{{ car.type_name }}, </span>
                    </span>
                  </td>
                </tr>

                <template v-for="(veh, i) in adiObj.car">
                  <template v-if="veh.rec_status < 4">
                    <tbody :key="i">
                      <tr>
                        <th>Vehicle</th>
                        <th>{{ veh.c_id }}</th>
                      </tr>
                      <tr>
                        <td class="tar">Make</td>
                        <td>{{ veh.make }}</td>
                      </tr>
                      <tr>
                        <td class="tar">Model</td>
                        <td>{{ veh.model }}</td>
                      </tr>
                      <tr>
                        <td class="tar">Colour</td>
                        <td>{{ veh.colour }}</td>
                      </tr>
                      <tr>
                        <td class="tar">Registration No</td>
                        <td>{{ veh.c_reg }}</td>
                      </tr>
                      <tr>
                        <td class="tar">Year of Registration</td>
                        <td>{{ veh.regyear }}</td>
                      </tr>
                      <tr>
                        <td class="tar">Vehicle Type</td>
                        <td>{{ getCarTypeName(veh.c_type_id) }}</td>
                      </tr>
                      <tr>
                        <td class="tar">Vehicle Gear Type</td>
                        <td>{{ veh.c_gear | capitalize }}</td>
                      </tr>
                      <tr>
                        <td class="tar">Fuel Type</td>
                        <td>{{ veh.c_fuel | capitalize }}</td>
                      </tr>
                      <tr>
                        <td class="tar">Name of The Legal Keeper</td>
                        <td>{{ veh.leagal_owner }}</td>
                      </tr>
                      <tr>
                        <td class="tar">MOT Expiry Date</td>
                        <td>{{ veh.mot_exp | dateReOrder }}</td>
                      </tr>
                      <tr>
                        <td class="tar">Insurance Expiry Date</td>
                        <td>{{ veh.insurance_exp | dateReOrder }}</td>
                      </tr>
                      <tr>
                        <td class="tar">Car Status</td>
                        <td>{{ veh.rec_status | carStateToTxt }}</td>
                      </tr>
                    </tbody>
                  </template>
                </template>
                <template v-if="adiObj.adi_franchise">
                  <tr>
                    <th>Franchise Plan</th>
                    <th>
                      Plan ID: {{ adiObj.adi_franchise.plan_data.plan_id }}
                    </th>
                  </tr>
                  <tr>
                    <td class="tar">Joining Fee</td>
                    <td>£{{ adiObj.adi_franchise.plan_data.initial_fee }}</td>
                  </tr>
                  <tr>
                    <td class="tar">Weekly Fee</td>
                    <td>£{{ adiObj.adi_franchise.plan_data.weekly_fee }}</td>
                  </tr>
                  <tr>
                    <td class="tar">Working Hours</td>
                    <td>{{ adiObj.adi_franchise.plan_data.work_type }}</td>
                  </tr>
                  <tr>
                    <td class="tar">Average Weekly Hours</td>
                    <td>
                      {{ adiObj.adi_franchise.plan_data.weekly_hours }} Hours
                    </td>
                  </tr>
                  <tr>
                    <td class="tar">Payment Regularity</td>
                    <td>{{ adiObj.adi_franchise.regularity | payReg }}</td>
                  </tr>
                </template>
              </table>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary darken-1" text @click="closeDialog">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import InstructorTable from "@/components/Instructor/InstructorTable";
import {
  GET_ADVANCE_INSTRUCTOR_SUMMARY,
  GET_VEHICLE_TYPES,
  IS_ADVANCE_INSTRUCTOR_SUMMARY_LOADED,
  M_DD,
  M_OU,
} from "@/store/mutation-list";
import { mapGetters } from "vuex";
export default {
  name: "SearchInstructor",
  components: { InstructorTable },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Advance Instructor Seach",
          disabled: true,
          href: "/dashboard/searchinstructor",
        },
      ],
      // resultArry: [],
      // isLoading: true,
      adiObj: [],
      dialog: false,
    };
  },
  //store,
  methods: {
    selected(data) {
      this.adiObj = data.record;
      this.dialog = true;
    },
    closeDialog() {
      //reset data
      this.adiObj = [];
      this.dialog = false;
    },
    //data fillers
    getCarTypeName(key) {
      //console.log("THE KEY>>>>>>>>>>>>" + key);
      if (key && this.vehicList) {
        let res = this.vehicList.filter((data) => {
          return data.id == key;
        });

        return res[0]["txt"];
      } else {
        return "";
      }
    },
  },
  computed: {
    ...mapGetters({
      vehicList: `${M_DD}${GET_VEHICLE_TYPES}`,
      isLoading: `${M_OU}${IS_ADVANCE_INSTRUCTOR_SUMMARY_LOADED}`,
      resultArry: `${M_OU}${GET_ADVANCE_INSTRUCTOR_SUMMARY}`,
    }),
  },
  filters: {
    accToText: function (acst) {
      if (!acst) return "";

      if (acst == 0) return "Blocked";
      if (acst == 1) return "Active";
      if (acst == 2) return "Read Only";
      if (acst == 3) return "Just Joined";
    },
    carStateToTxt: function (val) {
      if (!val) return "";
      if (val == 1) return "Active";
      if (val == 2) return "Inactive";
      if (val == 3) return "Removed";
      if (val == 4) return "Renewed";
    },
  },
};
</script>

<style scoped>
.special-table {
  width: 100%;
}
th {
  padding: 10px;
}
td {
  padding: 8px;
}
.tar {
  text-align: right;
}
tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
tr:nth-of-type(even) {
  background-color: #fffffe;
}
</style>
