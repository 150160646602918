<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col
      ><v-sheet color="white" elevation="1" class="pa-5">
        <h2>Manage Lesson Plans</h2>
        <v-divider></v-divider>

        <v-data-table
          :headers="headers"
          :items="allPlans"
          sort-by="plan_id"
          class="elevation-1"
        >
          <template v-slot:[`item.plan_price`]="{ item }">
            {{ "£" + item.plan_price }}
          </template>
          <template v-slot:[`item.gear_type`]="{ item }">
            <span style="text-transform: capitalize">{{ item.gear_type }}</span>
          </template>
          <template v-slot:[`item.created_on`]="{ item }">
            {{ item.created_on | dateReOrderRMtime }}
          </template>
          <template v-slot:[`item.updated_on`]="{ item }">
            {{ item.updated_on | dateReOrderRMtime }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="getChipColour(item.status)" label outlined dark>
              {{ getChipText(item.status) }}
            </v-chip>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>All Lesson Packages</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialogRemove" max-width="650px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this lesson
                    plan?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deletePlanConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              dark
              color="red"
              small
              v-if="item.status === 1"
              @click="deletePlan(item)"
            >
              <v-icon left small> mdi-trash-can-outline </v-icon>Remove</v-btn
            >
            <v-btn light color="red" small v-else disabled> No Actions</v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize"> Reset </v-btn>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_LESSON_PLANS,
  M_COMMON,
  LOAD_LESSONS_PLANS,
  REMOVE_LESSON_PLAN,
  M_OA,
} from "@/store/mutation-list";

export default {
  name: "ManageLessonPlans",

  data: () => ({
    items: [
      {
        text: "Admin Dashboard",
        disabled: false,
        href: "/dashboard/adminhome",
      },
      {
        text: "View/Manage Lesson Plans",
        disabled: true,
        href: "/dashboard/managelessonplans",
      },
    ],
    dialogRemove: false,
    dialogDelete: false,
    headers: [
      { text: "Plan ID", value: "plan_id" },
      { text: "Description", value: "text_description" },
      { text: "Plan Price", value: "plan_price" },
      { text: "No. of Hours", value: "hours" },
      { text: "Gear Type", value: "gear_type" },
      { text: "Promo Code", value: "promo_code" },
      { text: "Added On", value: "created_on" },
      { text: "Updated On", value: "updated_on" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    localPlans: [],
    removingPlanID: 0,
    editedIndex: -1,
  }),
  computed: {
    ...mapGetters({
      allPlans: `${M_COMMON}${GET_LESSON_PLANS}`,
    }),
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      loadPlans: `${M_COMMON}${LOAD_LESSONS_PLANS}`,
      removePlan: `${M_OA}${REMOVE_LESSON_PLAN}`,
    }),
    initialize() {
      // this.localPlans = this.allPlans;//for some reason there is a array in array
      //lets load all the plans
      this.loadPlans();
    },
    getChipColour(accState) {
      accState = parseInt(accState);
      let retVal = "";
      switch (accState) {
        case 0:
          retVal = "red";
          break;
        case 1:
          retVal = "green";
          break;
        default:
          retVal = "red";
          break;
      }
      return retVal;
    },
    getChipText(accState) {
      accState = parseInt(accState);
      let retVal = "";
      switch (accState) {
        case 0:
          retVal = "Removed";
          break;
        case 1:
          retVal = "Active";
          break;

        default:
          retVal = "Error";
          break;
      }
      return retVal;
    },
    deletePlan(item) {
      //assign removing plan id
      this.removingPlanID = item.plan_id;
      //show confirm dialog
      this.dialogRemove = true;
    },
    deletePlanConfirm() {
      //need to forward this to  store and get a confirmation
      this.closeDelete();
      this.removePlan({
        // the data that need to sent to store/server
        plan_id: this.removingPlanID,
      }).then((res) => {
        //response from store. All complete just need to show to popup message
        //console.log(res);
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Lesson Package Removed successfully",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
          this.loadPlans();
        } else {
          this.$fire({
            title: "Error",
            text: "Error removing lesson package. Please try again",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
    closeDelete() {
      this.dialogRemove = false;
      /*this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });*/
    },
  },
};
</script>

<style></style>
