<template>
  <div>
    <v-card class="point" @click="dialog = true" v-if="basicObj">
      <v-card-title
        class="text-h6 grey lighten-2"
        style="justify-content: center"
      >
        Edit Teaching Languages
      </v-card-title>
      <v-img
        :src="require(`@/assets/img/updateinfo/changelangs.webp`)"
        max-height="300"
        contain
        class="grey lighten-3 pa-5"
      ></v-img>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title>
            <span class="text-h5">Update Teaching Languages</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text style="height: 70vh">
          <v-container>
            <v-row>
              <v-col>
                <v-select
                  v-model="form.aditlang"
                  name="aditlang[]"
                  :items="adilangs"
                  chips
                  label="Teaching Languages"
                  item-text="txt"
                  item-value="id"
                  multiple
                  outlined
                  :error-messages="aditlangErrors"
                  @input="$v.form.aditlang.$touch()"
                  @blur="$v.form.aditlang.$touch()"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning darken-1"
            text
            @click="saveChanges()"
            :disabled="$v.form.$invalid"
          >
            Save Update
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  M_DD,
  GET_LANGUAGES,
  M_ADI,
  SAVE_ADI_COVERING_LANGS,
} from "@/store/mutation-list";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UpdateLangs",
  props: ["basicObj"],
  data() {
    return {
      dialog: false,
      form: {
        adiid: null, //readonly
        aditlang: [],
      },
    };
  },
  validations: {
    form: {
      aditlang: { required },
    },
  },
  computed: {
    ...mapGetters({
      adilangs: `${M_DD}${GET_LANGUAGES}`,
    }),
    aditlangErrors() {
      const errors = [];
      if (!this.$v.form.aditlang.$dirty) return errors;
      !this.$v.form.aditlang.required &&
        errors.push("Teaching Languages required.");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      saveLangs: `${M_ADI}${SAVE_ADI_COVERING_LANGS}`,
    }),
    saveChanges() {
      this.saveLangs({
        id: this.form.adiid,
        lns: this.form.aditlang,
      }).then((rep) => {
        if (rep == "SUCCESS") {
          this.$fire({
            title: "Teaching Languages Updated",
            text: "Teaching languages updated successfully.",
            type: "success",
            //timer: 3000,
            allowOutsideClick: false,
          }).then((r) => {
            this.dialog = false;
            console.log(r.value);
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error updating teaching languages. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
  },
  created() {
    this.form.adiid = this.basicObj.adiid;
    this.basicObj.aditlang.forEach((lang) => {
      this.form.aditlang.push(lang.lang_id);
    });
  },
};
</script>

<style></style>
