var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-forward")])]},proxy:true}])})],1),_c('v-col',[_c('v-sheet',{staticClass:"pa-5",attrs:{"color":"white","elevation":"1"}},[_c('h2',[_vm._v("Resolved Complaints")]),_c('v-divider',{staticClass:"mt-3 mb-3"}),_c('v-text-field',{staticClass:"mb-5",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.resolvedCompList,"search":_vm.search,"loading":_vm.tblLoading},on:{"click:row":_vm.actionDialog},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('ComplaintInfoDialog',{attrs:{"title":"Resolved Complaint Information","dialog":_vm.dialog,"summary-table":_vm.tableData,"party-list":_vm.complaintParties,"comp-updates-arr":_vm.updatesArray,"type":"view"},on:{"closedialog":function($event){return _vm.closeDialog()}}})]},proxy:true},{key:"item.complain_type_id",fn:function(ref){
var item = ref.item;
return [(item.complain_type_id < 5)?_c('span',[_vm._v("From")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getComplatTypeName(item.complain_type_id))+" ")]}},{key:"item.ref",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(" C"+_vm._s(item.ref))])]}},{key:"item.complainant",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatComplanant")(item.complainant_type,item.complainant_id, item.complainant_obj))+" ")]}},{key:"item.complainee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatComplanant")(item.complainee_type,item.complainee_id, item.complainee_obj))+" ")]}},{key:"item.incident_date_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("noDec")(item.created_on))+" ")]}},{key:"item.staff",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.staff_obj.title + " " + item.staff_obj.fname + " " + item.staff_obj.lname + " - " + item.staff_id)+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }