export const AppSetup = {
  name: "Brandiie",
  version: "8.2",
  startDate: "2019-03-25",
  startMonth: "2019-03",
  startWeek: "2019-W13",
  startYear: "2019",
};

export const nameTitles = {
  nTitle: [
    { id: "Master", txt: "Master" },
    { id: "Miss", txt: "Miss" },
    { id: "Mr", txt: "Mr" },
    { id: "Mrs", txt: "Mrs" },
    { id: "Ms", txt: "Ms" },
    { id: "Baron", txt: "Baron" },
    { id: "Dr", txt: "Dr" },
    { id: "Duke", txt: "Duke" },
    { id: "Lady", txt: "Lady" },
    { id: "Lord", txt: "Lord" },
    { id: "Madame", txt: "Madame" },
    { id: "Prof.", txt: "Prof." },
    { id: "Sir", txt: "Sir" },
  ],
};

export const VehicleLst = {
  typeslst: [
    { id: 10, txt: "Any" },
    { id: 11, txt: "Hatchback" },
    { id: 16, txt: "Camper Van" },
    { id: 12, txt: "Convertible" },
    { id: 17, txt: "Electric Car" },
    { id: 15, txt: "MPV" },
    { id: 13, txt: "Saloon" },
    { id: 18, txt: "Sports Car" },
    { id: 14, txt: "SUV 4x4" },
  ],
};

export const FuelLst = {
  fl: [
    { id: "biofuel", txt: "Bio-Fuel " },
    { id: "diesel", txt: "Diesel" },
    { id: "dieselhybrid", txt: "Diesel Hybrid" },
    { id: "electric", txt: "Electric" },
    { id: "lpg", txt: "LPG Hybrid" },
    { id: "petrol", txt: "Petrol" },
    { id: "petrolhybrid", txt: "Petrol Hybrid" },
  ],
};
export const langLst = {
  tLangs: [
    { id: 11, txt: "Albanian" },
    { id: 12, txt: "Arabic" },
    { id: 13, txt: "Aymara" },
    { id: 14, txt: "Bengali" },
    { id: 15, txt: "Berber" },
    { id: 16, txt: "Bhojpuri " },
    { id: 17, txt: "Mandarin Chinese (incl. Standard Chinese)" },
    { id: 18, txt: "Yue Chinese (incl. Cantonese)" },
    { id: 19, txt: "Wu Chinese (incl. Shanghainese)" },
    { id: 20, txt: "Dari" },
    { id: 21, txt: "Dutch" },
    { id: 22, txt: "Egyptian Arabic" },
    { id: 10, txt: "English" },
    { id: 23, txt: "Filipino" },
    { id: 24, txt: "French" },
    { id: 25, txt: "German" },
    { id: 26, txt: "Greek" },
    { id: 27, txt: "Guarani" },
    { id: 28, txt: "Gujarati" },
    { id: 29, txt: "Hausa" },
    { id: 30, txt: "Hindi" },
    { id: 31, txt: "Indonesian" },
    { id: 32, txt: "Italian" },
    { id: 33, txt: "Japanese" },
    { id: 34, txt: "Javanese" },
    { id: 35, txt: "Kannada" },
    { id: 36, txt: "Kinyarwanda" },
    { id: 37, txt: "Kirundi" },
    { id: 38, txt: "Korean" },
    { id: 39, txt: "Malay (all Malay based)" },
    { id: 40, txt: "Marathi" },
    { id: 41, txt: "Persian" },
    { id: 42, txt: "Portuguese" },
    { id: 43, txt: "Punjabi " },
    { id: 44, txt: "Western Punjabi (Lahnda)" },
    { id: 45, txt: "Quechua" },
    { id: 46, txt: "Romanian" },
    { id: 47, txt: "Russian" },
    { id: 48, txt: "Serbo-Croatian" },
    { id: 49, txt: "Spanish" },
    { id: 50, txt: "Southern Min (incl. Hokkien)" },
    { id: 51, txt: "Sotho" },
    { id: 52, txt: "Swahili" },
    { id: 53, txt: "Swati" },
    { id: 54, txt: "Swedish" },
    { id: 55, txt: "Tajik" },
    { id: 56, txt: "Tamil" },
    { id: 57, txt: "Telugu" },
    { id: 58, txt: "Thai" },
    { id: 59, txt: "Tswana" },
    { id: 60, txt: "Turkish" },
    { id: 61, txt: "Urdu" },
    { id: 62, txt: "Vedda" },
    { id: 63, txt: "Vietnamese" },
    { id: 64, txt: "Welsh" },
  ],
};
export const serverURLs = {
  adminDocManagerPath: "//drive.brandiie.com/ctrl/tcdwadmin.php",
  //userDocManagerPath: "//ds.test/ctrl/docmanagerV2.php",
  userDocManagerPath: "//drive.brandiie.com/ctrl/docmanoa.php",
  setupPath: "//drive.brandiie.com/ctrl/setup.php",
  helpGuide: "//drive.brandiie.com/ctrl/help.pdf",
};
/*export const complainLst = {
  complaintParties: [
    {
      id: "1",
      txt: "Learner about a Learner",
      description: "",
    },
    {
      id: "2",
      txt: "Learner about an Instructor",
      description: "",
    },
    {
      id: "3",
      txt: "Learner about Driving School",
      description: "",
    },
    {
      id: "4",
      txt: "Learner about Third Party",
      description: "",
    },
    {
      id: "5",
      txt: "Instructor about a Learner",
      description: "",
    },
    {
      id: "6",
      txt: "Instructor about another Instructor",
      description: "",
    },
    {
      id: "7",
      txt: "Instructor about Driving School",
      description: "",
    },
    {
      id: "8",
      txt: "Instructor about Third Party",
      description: "",
    },
    {
      id: "9",
      txt: "Third Party about a Learner ",
      description: "",
    },
    {
      id: "10",
      txt: "Third Party about an Instructor",
      description: "",
    },
    {
      id: "11",
      txt: "Third Party about Driving School",
      description: "",
    },
    {
      id: "12",
      txt: "Regulatory Complaint",
      description: "",
    },
    {
      id: "13",
      txt: "Sales",
      description: "",
    },
    {
      id: "14",
      txt: "Marketing",
      description: "",
    },
    {
      id: "15",
      txt: "Administration",
      description: "",
    },
    {
      id: "16",
      txt: "Office Staff",
      description: "",
    },
    {
      id: "17",
      txt: "Competitors",
      description: "",
    },
    {
      id: "18",
      txt: "Any Other Complaints",
      description: "",
    },
  ],
};*/
/*export const complaintStatusLst = {
  complaintStatusTypes: [
    {
      id: 1,
      status_name: "In Progress",
      status_desc:
        "Case is still active. provide further action to both parties to seek assistance from Outside Entities and the appropriate Regulators",
    },
    {
      id: 2,
      status_name: "Complain Withdrawn",
      status_desc: "Complainant withdraw the complain",
    },
    {
      id: 3,
      status_name: "Resolved",
      status_desc: "Complaint resolved successfully",
    },
    {
      id: 4,
      status_name: "Terminated in Favour of Complainee",
      status_desc:
        "Complainee not providing feedback within the specified time frame therefore the complaint has either been (settled in the favour of complainant/settled with no resolution/terminated unresolved)",
    },
    {
      id: 5,
      status_name: "Terminated in Favour of Complainant",
      status_desc:
        "settled in the favour of complainee / settled with no resolution / terminated unresolved",
    },
    {
      id: 6,
      status_name: "Auto Termination (Times out as no response for 1 year)",
      status_desc:
        "	If no update from both sides in adequate time. No Communication ",
    },
  ],
};*/
