<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pt-2">
          Completing action. Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
            rounded
            height="6"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GET_DIALOG, M_COMMON } from "@/store/mutation-list";
import { mapGetters } from "vuex";
export default {
  name: "LoadingBlockSpinner",
  data() {
    return {
      //dialog: false,
    };
  },
  computed: {
    ...mapGetters({
      dialog: `${M_COMMON}${GET_DIALOG}`,
    }),
  },
};
</script>
