<template>
  <v-col md="10" class="mx-auto mb-5">
    <h3 class="mb-5">Time</h3>
    <v-card class="mx-auto" :elevation="important" :outlined="isMain"
      ><v-simple-table v-if="tableData" s>
        <template v-slot:default>
          <thead>
            <tr class="text-center">
              <th class="text-center">Learner ID</th>
              <th class="text-center" colspan="2">Days Elapsed</th>
              <th class="text-center">Age Group</th>
              <th class="text-center">Gear Type</th>
              <th class="text-center">Gender</th>
              <th class="text-center">Geograhical Area</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rec, i) in tableData" :key="i" class="text-center">
              <td>L{{ rec.stu_id }}</td>
              <td colspan="2">{{ rec.date_elapsed }}</td>
              <td>{{ rec.age_ratio | gendarRatioName }}</td>
              <td style="text-transform: capitalize">{{ rec.lesson_type }}</td>
              <td>{{ rec.gender | reportinggender }}</td>
              <td>{{ rec.outer_pc }}</td>
            </tr>
            <tr v-if="tableData.length == 0">
              <th class="text-center" colspan="5">No Data to display</th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p v-else class="warning">No Data Provided</p></v-card
    ><v-divider />
  </v-col>
</template>

<script>
export default {
  name: "TimeElapsedTable",
  props: ["tableData", "isMain"],
  computed: {
    important() {
      if (this.isMain) {
        return 24;
      } else {
        return 2;
      }
    },
  },
};
</script>

<style></style>
