<template>
  <div>
    <!-- Franchise Fee by Postcode -->
    <h2 class="my-5">{{ reportName }}</h2>
    <div v-if="reportNo == 1">
      <v-divider class="my-5"></v-divider>
      <v-row>
        <TotalsTable
          :tableData="tableData"
          :tots="getColumnTotals(tableData)"
          tableTitle="Total Franchise Fees by Postcode"
          tblSize="12"
          :tableHeadins="tableHeadinsFrn0"
          :tableValueName="datafieldsFen0"
          :isFiveCols="true"
        />
      </v-row>
      <v-row>
        <FinancialPostcodeEarningsChart :dataTable="tableData" />
      </v-row>
    </div>
    <!-- Total ADIs vs Franchise fees -->
    <div v-if="reportNo == 2">
      <v-divider class="my-5"></v-divider>
      <v-row>
        <GeneralDataTable
          title="Total Number of Instructors & Franchise Fees"
          :tableData="getKeyVal"
          :colSize="12"
          keyHedding="Category"
          valueHedding="Total"
        />
      </v-row>

      <v-row>
        <v-divider />
        <FinancialTotalFeesChart
          :tableData="getPieChartTotals"
          :nameVals="keyValNamesForPiChartTotatls"
          :titles="['Fee', 'Total']"
        />
      </v-row>
    </div>
    <!-- Part time vs fulltime franchise fees report -->
    <div v-if="reportNo == 3">
      <v-divider class="my-5"></v-divider>
      <v-row>
        <TotalsTable
          :tableData="tableData"
          tableTitle="Total Franchise Fees by Work Type"
          tblSize="12"
          :tableHeadins="tableHeadins4"
          :tableValueName="datafields4"
          :isFiveCols="true"
          :tots="getColumnTotals(tableData)"
        />
      </v-row>
      <v-row>
        <v-divider />
        <FinancialTotalFeesChart
          :tableData="getPieChartTotalsTime"
          :nameVals="keyValNamesForPiChartTime"
          :titles="['Work Type', 'Total']"
        />
      </v-row>
    </div>
    <!-- ADI franchise fees Male vs Female instructors -->
    <div v-if="reportNo == 4">
      <v-divider class="my-5"></v-divider>
      <v-row>
        <TotalsTable
          :tableData="tableData"
          tableTitle="Total Franchise Fees by Instructor's Gender"
          tblSize="12"
          :tableHeadins="tableHeadins5"
          :tableValueName="datafields5"
          :isFiveCols="true"
        />
      </v-row>
      <v-row>
        <v-divider />
        <FinancialTotalFeesChart
          :tableData="getPieChartTotalsGenders"
          :nameVals="keyValNamesForPiChartGender"
          :titles="['Work Type', 'Total']"
        />
      </v-row>
    </div>
    <!-- ADI Postcode coverage and complaints-->
    <div v-if="reportNo == 5">
      <v-divider class="my-5"></v-divider>
      <v-row>
        <GeneralDataTable
          title="Instructor Postcode Coverage"
          :tableData="tableData.adi_per_pc"
          :colSize="6"
          keyHedding="Outer Postcode"
          valueHedding="Number of ADIs to Cover Postcode"
          :total="getTotal(tableData.adi_per_pc)"
          :isPrecentage="true"
        />
        <GeneralDataTable
          title="Instructor Complaints Levels per Postcode"
          :tableData="tableData.comp_per_pc"
          :colSize="6"
          keyHedding="Postcode"
          valueHedding="Number of ADIs"
          :total="getTotal(tableData.comp_per_pc)"
          :isPrecentage="true"
        />
      </v-row>
      <v-row>
        <v-divider />
        <InstructorPCandComplaintChart
          :pcData="tableData.adi_per_pc"
          :compData="tableData.comp_per_pc"
        />
      </v-row>
    </div>
    <!-- Income generated by number of bookings-->
    <div v-if="reportNo == 6">
      <v-divider class="my-5"></v-divider>
      <v-row>
        <v-col :md="6">
          <h4 class="mb-5">Income Generated By Number Of Bookings</h4>
          <v-card class="mx-auto"
            ><v-simple-table v-if="tableData">
              <thead>
                <tr class="text-center">
                  <th class="text-center">Number Of Lessons</th>
                  <th class="text-center">Average Lesson Price</th>
                  <th class="text-center">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td>{{ tableData.bookings_totals.lessons }}</td>
                  <td>
                    {{ tableData.bookings_totals.average | currencyStyle }}
                  </td>
                  <td>{{ tableData.bookings_totals.money | currencyStyle }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <p v-else class="warning">No Data Provided</p></v-card
          ></v-col
        >
        <MiniTotalsTable
          v-if="tableData.gender_totals"
          :tots="getColumnTotals(tableData.gender_totals)"
          :tableData="tableData.gender_totals"
          tableTitle="Total Money Generated By Gender Types"
          tblSize="6"
          :tableHeadins="tableHeadins1"
          :tableValueName="datafields1"
          :isFiveCols="false"
        />
        <MiniTotalsTable
          :tableData="tableData.postcode_totals"
          :tots="getColumnTotals(tableData.postcode_totals)"
          tableTitle="Total Money Generated By Outer Postcode"
          tblSize="6"
          :tableHeadins="tableHeadins2"
          :tableValueName="datafields2"
          :isFiveCols="false"
        />
        <MiniTotalsTableFiveCol
          :tableData="tableData.postcode_gender_totals"
          :tots="getColumnTotals(tableData.postcode_gender_totals)"
          tableTitle="Total Money Generated By Outer Postcode And Gender"
          tblSize="6"
          :tableHeadins="tableHeadins3"
          :tableValueName="datafields3"
          :isFiveCols="true"
        />
      </v-row>
      <v-row>
        <v-divider />
        <FinacialIncomeGeneratedChart :tableData="tableData" />
      </v-row>
    </div>
  </div>
</template>

<script>
import GeneralDataTable from "@/components/Reports/Tables/GeneralDataTable.vue";
import TotalsTable from "@/components/Reports/Tables/Financials/TotalsTable.vue";
import MiniTotalsTable from "@/components/Reports/Tables/Financials/MiniTotalsTable.vue";
import MiniTotalsTableFiveCol from "@/components/Reports/Tables/Financials/MiniTotalsTableFiveCol.vue";
import FinancialPostcodeEarningsChart from "@/components/Reports/Charts/FinancialPostcodeEarningsChart.vue";
import FinancialTotalFeesChart from "@/components/Reports/Charts/FinancialTotalFeesChart.vue";
import FinacialIncomeGeneratedChart from "@/components/Reports/Charts/FinacialIncomeGeneratedChart.vue";

//import VehicleDataColumnChart from "@/components/Reports/Charts/VehicleDataColumnChart.vue";
//import InstructorTeachingChart from "@/components/Reports/Charts/InstructorTeachingChart.vue";
// import InstructorInfoChart from "@/components/Reports/Charts/InstructorInfoChart.vue";
//import InstructorRetentionChart from "@/components/Reports/Charts/InstructorRetentionChart.vue";
import InstructorPCandComplaintChart from "@/components/Reports/Charts/InstructorPCandComplaintChart.vue";

export default {
  name: "FinancialReportView",
  props: ["tableData", "reportNo", "reportName"],
  data: function () {
    return {
      tableHeadinsFrn0: [
        "Postcode",
        "Joining Fee",
        "Joining Fee Percentage",
        "Weekly Fee",
        "Weekly Fee Percentage",
        "Number of Weeks",
        "Number of Weeks Percentage",
        "Total Franchise Fees",
        "Total Franchise Fees Percentage",
      ],
      datafieldsFen0: [
        //data name is number, is currency
        ["postcode", false, false],
        ["initial_fees", true, true],
        ["weekly_fees", true, true],
        ["weeks", true, false],
        ["total", true, true],
      ],
      tableHeadins1: [
        "Gender",
        "Number Of Lessons",
        "Lessons Percentage",
        "Average Lesson Price",
        "Average Lesson Price Percentage",
        "Total",
        "Total Percentage",
      ],
      datafields1: [
        ["gender", false, false],
        ["lessons", true, false],
        ["average", true, true],
        ["money", true, true],
      ],
      tableHeadins2: [
        "Postcode",
        "Number Of Lessons",
        "Lessons Percentage",
        "Average Lesson Price",
        "Average Lesson Price Percentage",
        "Total",
        "Total Percentage",
      ],
      datafields2: [
        ["postcode", false, false],
        ["lessons", true, false],
        ["average", true, true],
        ["money", true, true],
      ],
      tableHeadins3: [
        "Postcode",
        "Gender",
        "Number Of Lessons",
        "Lessons Percentage",
        "Average Lesson Price",
        "Average Lesson Price Percentage",
        "Total",
        "Total Percentage",
      ],
      datafields3: [
        ["postcode", false, false],
        ["gender", false, false],
        ["lessons", true, false],
        ["average", true, true],
        ["money", true, true],
      ],

      tableHeadins4: [
        "Work Type",
        "Joining Fees",
        "Joining Fee Percentage",
        "Weekly Fees",
        "Weekly Fee Percentage",
        "Number Of Weeks",
        "Number of WeeksPercentage",
        "Total",
        "Total Fees Percentage",
      ],
      datafields4: [
        ["worktype", false, false],
        ["initial_fees", true, true],
        ["weekly_fees", true, true],
        ["weeks", true, false],
        ["total", true, true],
      ],
      tableHeadins5: [
        "Gender",
        "Joining Fees",
        "Weekly Fees",
        "Number Of Weeks",
        "Total",
      ],
      datafields5: [
        ["gender", false, false],
        ["initial_fees", true, true],
        ["weekly_fees", true, true],
        ["weeks", true, false],
        ["total", true, true],
      ],
      keyValNamesForPiChartTotatls: [
        ["initial_fees", "Joining Fees (£)"],
        ["total", "Total Franchise Fees  (£)"],
      ],
      keyValNamesForPiChartTime: [
        ["Full-Time", "Full-Time Fees (£)"],
        ["Part-Time", "Part-Time Fees (£)"],
      ],
      keyValNamesForPiChartGender: [
        ["male", "Male Instructor Fees (£)"],
        ["female", "Female Instructor Fees (£)"],
      ],
    };
  },
  components: {
    GeneralDataTable,
    FinancialPostcodeEarningsChart,
    FinancialTotalFeesChart,
    FinacialIncomeGeneratedChart,

    InstructorPCandComplaintChart,
    TotalsTable,
    MiniTotalsTable,
    MiniTotalsTableFiveCol,
  },
  methods: {
    getTotal(arr, key) {
      if (arr) {
        let t = 0;
        arr.forEach((rec) => {
          t += parseFloat(rec[key]);
        });
        return t;
      }
      return 0;
    },
    convertToKeyCount(objArr) {
      let ret = [];
      if (objArr) {
        objArr.forEach((rec) => {
          ret.push({ key: rec.postcode, count: rec.langs });
        });
      }
      return ret;
    },
    getColumnTotals(arr) {
      if (!arr || !arr.length) return {}; // Return empty object if its empty
      //get all the object keys from first array object
      let objKeys = Object.keys(arr[0]);
      //create the empty object for final data
      let retObj = {};
      //loop through all the elements and add them to holding object
      objKeys.forEach((k) => {
        retObj[k] = 0;
      });

      //lets loops thugh main array
      arr.forEach((element) => {
        Object.keys(retObj).forEach((key) => {
          retObj[key] += parseFloat(element[key]);
        });
      });
      //console.log(retObj);
      return retObj;
    },
  },
  computed: {
    getKeyVal() {
      if (this.tableData) {
        let ret = [
          {
            key: "Total Instructors",
            count: this.tableData.length,
          },
          {
            key: "Initial Fees",
            count: this.$options.filters.currencyStyle(
              this.getTotal(this.tableData, "initial_fees")
            ),
          },
          {
            key: "Franchise Fees",
            count: this.$options.filters.currencyStyle(
              this.getTotal(this.tableData, "total")
            ),
          },
        ];
        return ret;
      } else {
        return {};
      }
    },
    getPieChartTotals() {
      if (this.tableData) {
        let initFee = 0;
        this.tableData.forEach((rec) => {
          initFee += parseFloat(rec["initial_fees"]);
        });
        let totFee = 0;
        this.tableData.forEach((rec) => {
          totFee += parseFloat(rec["total"]);
        });

        let ret = [{ initial_fees: initFee, total: totFee }];
        return ret;
      }
      return {};
    },
    getPieChartTotalsTime() {
      if (this.tableData.length > 1) {
        let ret = [];
        this.tableData.forEach((rec) => {
          // eslint-disable-next-line no-unused-vars
          if (rec["worktype"] == "Full-time") {
            ret.push({ "Full-Time": parseFloat(rec["total"]) });
          } else {
            ret.push({ "Part-Time": parseFloat(rec["total"]) });
          }
        });

        return [{ ...ret[0], ...ret[1] }];
      }
      return {};
    },
    getPieChartTotalsGenders() {
      if (this.tableData.length > 1) {
        let ret = [];
        this.tableData.forEach((rec) => {
          // eslint-disable-next-line no-unused-vars
          if (rec["gender"] == "female") {
            ret.push({ female: parseFloat(rec["total"]) });
          } else {
            ret.push({ male: parseFloat(rec["total"]) });
          }
        });

        return [{ ...ret[0], ...ret[1] }];
      }
      return {};
    },
  },
};
</script>

<style></style>
