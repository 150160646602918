var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-forward")])]},proxy:true}])})],1),_c('v-col',[_c('v-sheet',{staticClass:"pa-5",attrs:{"color":"white","elevation":"1"}},[_c('h2',[_vm._v("Resets Office Staff Password")]),_c('v-divider'),_c('v-alert',{attrs:{"border":"top","colored-border":"","type":"info","elevation":"2"}},[_vm._v(" Password will reset to "),_c('strong',[_vm._v("'NewRoad2020'")])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.allstaff,"sort-by":"calories"},scopedSlots:_vm._u([{key:"item.mobile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mobileno")(item.mobile))+" ")]}},{key:"item.accState",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getChipColour(item.accState),"dark":""}},[_vm._v(" "+_vm._s(_vm.getChipText(item.accState))+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("All Office Staff")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogReset),callback:function ($$v) {_vm.dialogReset=$$v},expression:"dialogReset"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to reset this staff password? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.resetStaffPassConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.accState == 1)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"amber","dark":""},on:{"click":function($event){return _vm.resetStaffPassword(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-lock-reset ")]),_vm._v(" Reset Password ")],1)]}}],null,true)},[_c('span',[_vm._v("Reset Password")])])],1):_vm._e(),(item.accState == 0)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"disabled":"","color":"amber","light":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" Account Blocked ")],1)]}}],null,true)},[_c('span',[_vm._v("Blocked Account")])])],1):_vm._e(),(item.accState > 1)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"disabled":"","color":"amber","light":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" Account Deleted ")],1)]}}],null,true)},[_c('span',[_vm._v("Deleted Account")])])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }