<template>
  <div>
    <v-card class="point" @click="dialog = true" v-if="basicObj">
      <v-card-title
        class="text-h6 grey lighten-2"
        style="justify-content: center"
      >
        Remove a Vehicle
      </v-card-title>
      <v-img
        :src="require(`@/assets/img/updateinfo/rmvcar.webp`)"
        max-height="300"
        contain
        class="grey lighten-3 pa-5"
      ></v-img>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title>
            <span class="text-h5">Remove Instructors' Vehicle</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text style="height: 60vh">
          <v-container>
            <v-row
              ><v-alert type="error" v-if="activeCars.length < 1">
                No Active Cars to display</v-alert
              ><v-col md="6" v-for="(car, i) in activeCars" :key="i"
                ><v-card elevation="2" outlined shaped
                  ><v-img
                    height="250"
                    :src="require(`@/assets/img/updateinfo/cartowe.webp`)"
                  ></v-img
                  ><v-card-title>{{ car.make }} {{ car.model }} </v-card-title>

                  <v-card-text>
                    <div class="my-4 text-subtitle-1">
                      <v-icon> mdi-counter</v-icon> {{ car.c_reg }}
                    </div>
                    <div class="my-4 text-subtitle-1">
                      <v-icon> mdi-palette</v-icon> {{ car.colour }}
                    </div>
                    <div class="my-4 text-subtitle-1">
                      <v-icon> mdi-car-sports</v-icon
                      >{{ getCarTypeName(car.c_type_id) }}
                    </div>
                    <div class="my-4 text-subtitle-1">
                      <v-icon> mdi-gas-station</v-icon
                      ><span style="text-transform: uppercase">{{
                        car.c_fuel
                      }}</span>
                    </div>
                  </v-card-text>

                  <v-divider class="mx-4"></v-divider>

                  <v-card-actions>
                    <v-btn
                      color="red lighten-2"
                      text
                      @click="saveChanges(car.c_id)"
                    >
                      Remove
                    </v-btn>
                  </v-card-actions></v-card
                ></v-col
              ></v-row
            >
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  GET_FUEL_TYPES,
  GET_VEHICLE_TYPES,
  M_DD,
  M_ADI,
  SAVE_ADI_REMOVE_CAR,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "RemoveCar",
  props: ["basicObj"],
  data() {
    return {
      dialog: false,
      gears: [
        { id: "automatic", txt: "Automatic" },
        { id: "manual", txt: "Manual" },
      ],
      activeCars: [],
      form: {
        adiid: null, //readonly
        carid: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      fuelTypes: `${M_DD}${GET_FUEL_TYPES}`,
      vehicleTypes: `${M_DD}${GET_VEHICLE_TYPES}`,
    }),
  },
  methods: {
    ...mapActions({
      actionDel: `${M_ADI}${SAVE_ADI_REMOVE_CAR}`,
    }),
    saveChanges(carID) {
      this.actionDel({ id: this.form.adiid, carid: carID }).then((rep) => {
        if (rep == "SUCCESS") {
          this.$fire({
            title: "Vehicle Removed",
            text: "Instructor vehicle removed successfully.",
            type: "success",
            //timer: 3000,
            allowOutsideClick: false,
          }).then((r) => {
            this.removeLocalObject(carID);
            //this is to emit car when delete
            this.dialog = false;
            console.log(r.value);
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error removing  instructor vehicle. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
    removeLocalObject(cid) {
      this.$emit("deletecar", { carid: cid });
      var removeIndex = this.activeCars.map((item) => item.c_id).indexOf(cid);
      ~removeIndex && this.activeCars.splice(removeIndex, 1);
    },
    //this method will return car type
    getCarTypeName(key) {
      if (key) {
        let res = this.vehicleTypes.filter((data) => {
          return data.id == key;
        });
        return res[0]["txt"];
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.form.adiid = this.basicObj.adiid;
    this.basicObj.cars.forEach((car) => {
      if (car.rec_status == 1) {
        this.activeCars.push(car);
      }
    });
  },
};
</script>

<style></style>
