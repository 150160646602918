var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"href":item.href,"disabled":item.disabled}},[_c('span',{domProps:{"innerHTML":_vm._s(item.text)}})])]}},{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-forward")])]},proxy:true}])})],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-sheet',{staticClass:"pa-5",attrs:{"color":"white","elevation":"1"}},[(_vm.dataError)?_c('v-alert',{attrs:{"outlined":"","type":"warning","prominent":"","border":"left"}},[_vm._v(" No data to display. Please try again or select different report from "),_c('a',{attrs:{"href":"/dashboard/managementreports"}},[_c('strong',[_vm._v("Financial & Management Reports")])]),_vm._v(" section. ")]):_vm._e(),(_vm.criticalError)?_c('v-alert',{attrs:{"outlined":"","type":"error"}},[_vm._v(" Report was not requested with "),_c('a',{attrs:{"href":"/dashboard/managementreports"}},[_c('strong',[_vm._v("Financial & Management Reports")])]),_vm._v(". Please visit "),_c('a',{attrs:{"href":"/dashboard/managementreports"}},[_c('strong',[_vm._v("Financial & Management Reports")])]),_vm._v(" and select report again. ")]):_vm._e(),_c('v-expand-transition',[(_vm.showTable && _vm.tableData)?_c('FrequencyTable',{attrs:{"tableData":_vm.tableData,"TableType":this.$route.params.obj.repName}}):_vm._e()],1),(
          !(
            _vm.showRatingsProgress ||
            _vm.ratingsData ||
            _vm.showComplaintsTable ||
            _vm.showADIVehicleTable ||
            _vm.showFinancialTables
          )
        )?_c('v-divider',{staticClass:"mt-5"}):_vm._e(),_c('v-expand-transition',[(_vm.showTable && _vm.tableData)?_c('ColumnChart',{attrs:{"tableData":_vm.tableData,"ageRatios":true,"genderRatios":true,"geoAreas":true,"gearRatios":true,"TableType":this.$route.params.obj.repName}}):_vm._e()],1),_c('v-expand-transition',[_c('div',[(_vm.showRatingsProgress && _vm.ratingsData)?_c('ProgressBars',{attrs:{"ratingsData":_vm.ratingsData,"reportType":this.$route.params.obj.repName}}):_vm._e()],1)]),_c('v-expand-transition',[_c('div',[(_vm.showComplaintsTable && _vm.complaintsData)?_c('ComplaintsReportView',{attrs:{"tableData":_vm.complaintsData,"subreport":_vm.selectedReport,"statusData":_vm.compalintStatus,"geoData":_vm.outpcData,"allData":_vm.allCompData}}):_vm._e()],1)]),_c('v-expand-transition',[_c('div',[(_vm.showADIVehicleTable && _vm.adiVehicleData)?_c('InstructorReportView',{attrs:{"tableData":_vm.adiVehicleData,"reportNo":_vm.selectedReport}}):_vm._e()],1)]),_c('v-expand-transition',[_c('div',[(_vm.showFinancialTables)?_c('FinancialReportView',{attrs:{"tableData":_vm.financialTableData,"reportNo":_vm.selectedReport,"reportName":this.$route.params.obj.repName.text}}):_vm._e()],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }