<template>
  <v-row>
    <v-col md="6">
      <h4 class="pt-5">Upload New Document</h4>
      <v-form>
        <v-file-input
          accept="application/pdf"
          label="Select Document"
          hint="Latest upload will be active version"
          id="upfl"
          @change="onFile($event)"
        ></v-file-input>
        <v-text-field
          counter="10"
          v-model="inVersion"
          label="Document Version"
          placeholder="Please enter document version number"
        ></v-text-field>
        <v-btn color="success" dense :disabled="!isFormValid" @click="uploadDoc"
          ><v-icon left>mdi-cloud-upload </v-icon> Upload
        </v-btn>
      </v-form>
    </v-col>
    <v-col md="6">
      <h4 class="pt-5">Download Documents</h4>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Version</th>
              <th class="text-left">Uploaded Date</th>
              <th class="text-left">Download</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in dataArray" :key="key">
              <td>{{ item.version }}</td>
              <td>{{ item.added | dateReOrder }}</td>
              <td>
                <v-btn
                  tile
                  dark
                  color="purple"
                  @click="downloadDoc(item.fileid)"
                  ><v-icon left>mdi-cloud-download</v-icon> Download</v-btn
                >
              </td>
            </tr>
            <tr v-if="!anyData">
              <td
                colspan="3"
                style="text-center;font-weight: 600;"
                class="red lighten-4"
              >
                No records to display
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  GET_USER_DOCMANAGER_URL,
  M_DD,
  M_OA,
  SET_TANDC_DOC,
  LOAD_LEARNER_TC,
  LOAD_ADI_TC,
  LOAD_PDI_TC,
  LOAD_TRAINIG_PACK,
  DOWNLOAD_TC_DOC,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DocDownloader",
  props: {
    dataArray: Array,
    uploadType: String,
    //upload can be learner, adi, training, general
  },
  data() {
    return {
      inVersion: null,
      fileChanged: false,

      doc: null,
    };
  },
  computed: {
    ...mapGetters({
      docManURL: `${M_DD}${GET_USER_DOCMANAGER_URL}`,
    }),
    anyData() {
      if (!this.dataArray) return false;
      if (this.dataArray.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    isFormValid() {
      if (this.inVersion && this.fileChanged) {
        let upfile = document.getElementById("upfl");
        if (upfile) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      saveUpload: `${M_OA}${SET_TANDC_DOC}`,
      //reload after success
      loadLTC: `${M_OA}${LOAD_LEARNER_TC}`,
      loadATC: `${M_OA}${LOAD_ADI_TC}`,
      loadPTC: `${M_OA}${LOAD_PDI_TC}`,
      loadGTC: `${M_OA}${LOAD_TRAINIG_PACK}`,
      downloadPDF: `${M_OA}${DOWNLOAD_TC_DOC}`,
    }),
    downloadDoc(docID) {
      //this function will download Files
      //let tempHREF = this.docManURL + "?tac=1&req=" + docID;
      let docType = 0;
      if (this.uploadType == "learner") {
        docType = 1;
      } else if (this.uploadType == "adi") {
        docType = 2;
      } else if (this.uploadType == "training") {
        docType = 3;
      } else if (this.uploadType == "general") {
        docType = 4;
      }
      let filename = docID;
      //document.location.href = tempHREF;
      this.downloadPDF({
        tac: docType,
        req: filename,
      }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "doc.pdf");
        document.body.appendChild(link);
        link.click();
      });
    },
    onFile(e) {
      this.doc = e;
      if (this.doc) {
        this.fileChanged = true;
      } else {
        this.fileChanged = false;
      }
    },
    fileSelected() {
      let upfile = document.getElementById("upfl");
      if (upfile) {
        this.fileChanged = true;
      } else {
        this.fileChanged = false;
      }
    },
    uploadDoc() {
      let upfile = document.getElementById("upfl").files[0];
      //need to do validations to make sure there is a file
      let endpoinName = "";
      if (this.uploadType == "learner") {
        endpoinName = "learnertcupload";
      } else if (this.uploadType == "adi") {
        endpoinName = "instructortcupload";
      } else if (this.uploadType == "training") {
        endpoinName = "traineeinstructortcupload";
      } else if (this.uploadType == "general") {
        endpoinName = "trainingpacktcupload";
      } else {
        console.log("Error");
        return;
      }

      this.saveUpload({
        form: { [endpoinName]: this.inVersion },
        upfile: upfile,
      }).then((rep) => {
        if (rep == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Documents uploaded successfully.",
            type: "success",
            //timer: 3000,
            allowOutsideClick: false,
          }).then((r) => {
            this.clearFrm();
            console.log(r.value);
            this.reloadData();
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error uploading document. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
    reloadData() {
      if (this.uploadType == "learner") {
        this.loadLTC();
      } else if (this.uploadType == "adi") {
        this.loadATC();
      } else if (this.uploadType == "training") {
        this.loadPTC();
      } else if (this.uploadType == "general") {
        this.loadGTC();
      } else {
        console.log("Error");
        return;
      }
    },
    clearFrm() {
      document.getElementById("upfl").files[0].value = "";
      this.doc = null;
      this.inVersion = null;
      this.fileChanged = false;
    },
  },
};
</script>

<style></style>
