<template>
  <v-dialog v-model="dialog" max-width="860px" scrollable persistent>
    <v-card>
      <v-card-title class="text-h5 blue lighten-2">
        <span>{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-simple-table v-if="SummaryTable">
            <tbody>
              <tr>
                <th>Complaint Reference</th>
                <th colspan="3">{{ ref }}</th>
              </tr>

              <tr>
                <th>Complaint From</th>
                <td>{{ complainantTypeTxt }}</td>
                <td>{{ complainantInfo }}</td>
                <td>
                  <span v-if="complainantContN"
                    >Contact No.: {{ complainantContN | mobileno }}</span
                  >
                </td>
              </tr>
              <tr>
                <th>Complaint About</th>
                <td>{{ complaineeTypeTxt }}</td>
                <td>{{ complaineeInfo }}</td>
                <td>
                  <span v-if="complaineeContN"
                    >Contact No.: {{ complaineeContN | mobileno }}</span
                  >
                </td>
              </tr>
              <tr>
                <th>Description</th>
                <td
                  colspan="3"
                  style="word-wrap: break-word; white-space: normal"
                >
                  {{ compDesc }}
                </td>
              </tr>
              <tr>
                <th>Incident Date &amp; Time</th>
                <td colspan="3">
                  <span v-if="incidentDate == '0000-00-00'"> Not Provided</span>
                  <span v-else>
                    {{ incidentDate | dateReOrder }}
                    {{ incidentTime | noDec }}</span
                  >
                </td>
              </tr>
              <tr>
                <th>Tried to Resolve Directly</th>
                <td colspan="3">{{ selResolve | intToYN }}</td>
              </tr>
              <tr v-if="selResolveOBJ">
                <th>Self Resolve Info</th>
                <th>Date and Time</th>
                <th>Complainee Offered a Solution</th>
                <th>Solution Description</th>
              </tr>
              <tr v-if="selResolveOBJ">
                <th></th>
                <td>
                  {{ selResolveOBJ.srDate | dateReOrder }} {{ srTime | noDec }}
                </td>
                <td>{{ selResolveOBJ.srResoOffer | intToYN }}</td>
                <td style="word-wrap: break-word; white-space: normal">
                  {{ selResolveOBJ.srDesc }}
                </td>
              </tr>
              <tr>
                <th>Reported On</th>
                <td>{{ reportedDateTime | dateReOrder | noDec }}</td>
                <th>Record Staff - ID</th>
                <td>{{ recBy }}</td>
              </tr>

              <tr
                v-for="(ups, index) in compUpdatesArr"
                :key="index"
                class="blue lighten-5"
              >
                <th>Progress Update</th>
                <td
                  colspan="3"
                  class="pa-5"
                  style="word-wrap: break-word; white-space: normal; width: 78%"
                >
                  <strong
                    ><em>Updated on {{ ups.created_on | dateReOrder }}</em>
                    by
                    <em>{{
                      ups.title +
                      " " +
                      ups.fname +
                      " " +
                      ups.lname +
                      " - " +
                      ups.rec_by
                    }}</em></strong
                  >
                  <br />
                  {{ ups.update_desc }}
                </td>
              </tr>
              <tr v-if="type == 'view'" class="green lighten-5">
                <th>Resolution</th>
                <th colspan="3">
                  <strong
                    ><em
                      >{{ SummaryTable.complain_status_text }} on
                      {{ SummaryTable.updated_on | dateReOrder | noDec }}</em
                    ></strong
                  >
                </th>
              </tr>
            </tbody>
          </v-simple-table>
          <v-divider class="mb-5"></v-divider>
          <v-form v-if="type == 'update'"
            ><v-row justify="center">
              <v-col md="3"></v-col>
              <v-col md="9"
                ><v-textarea
                  v-model="form.progresstxt"
                  outlined
                  name="input-7-4"
                  label="Progress Update"
                  placeholder="Please Describe Update (Max. 1600 characters)"
                  counter="1600"
                  clearable
                  rows="4"
                  auto-grow
                  value=""
                  :error-messages="progressTxtError"
                  @input="$v.form.progresstxt.$touch()"
                  @blur="$v.form.progresstxt.$touch()"
                ></v-textarea>
                <v-select
                  v-model="form.status"
                  :items="UpdateStatusList"
                  label="Complaint Status"
                  outlined
                  item-value="id"
                  item-text="status_name"
                  :error-messages="compStatusError"
                  @input="$v.form.status.$touch()"
                  @blur="$v.form.status.$touch()"
                ></v-select>
                <v-btn class="primary float-right" @click="saveUpdate"
                  >Save Update</v-btn
                >
              </v-col></v-row
            ></v-form
          >

          <!-- <v-divider></v-divider> -->
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog()">Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_COMPLAINT_STATUS_LIST,
  M_DD,
  M_OU,
  SET_COMPLAINT_UPDATE,
} from "@/store/mutation-list";
import {
  required,
  minLength,
  maxLength,
  minValue,
} from "vuelidate/lib/validators";
export default {
  name: "ComplaintInfoDialog",
  props: [
    "title",
    "dialog",
    "type",
    "compUpdatesArr",
    "SummaryTable",
    "PartyList",
  ],
  data() {
    return {
      compInfoArray: [],
      //ref: "",
      complainantType: "",
      //complainantTypeTxt: "",

      complaineeType: "",

      srDate: "",
      srTime: "",
      srResoOffer: "",
      srDesc: "",

      //reportedDateTime: "",
      //recBy: "",

      statusList: this.PartyList,
      form: {
        progresstxt: "",
        status: 0,
      },
    };
  },
  validations: {
    form: {
      progresstxt: {
        required,
        minLength: minLength(25),
        maxLength: maxLength(1600),
      },
      status: {
        required,
        minValue: minValue(1),
      },
    },
  },
  computed: {
    ...mapGetters({
      UpdateStatusList: `${M_DD}${GET_COMPLAINT_STATUS_LIST}`,
    }),
    ref() {
      return "C" + this.SummaryTable.ref;
    },
    complainantTypeTxt() {
      if (this.SummaryTable.complainant_obj != null) {
        if (this.SummaryTable.complainant_type == 1) {
          return (
            "Learner: L" +
            this.SummaryTable.complainant_obj.student_id.toString()
          );
        } else if (this.SummaryTable.complainant_type == 2) {
          return (
            "Instructor: A" +
            this.SummaryTable.complainant_obj.adi_id.toString()
          );
        } else {
          return null;
        }
      } else {
        if (this.SummaryTable.complainant_type == 3) {
          return "Driving School ";
        } else if (this.SummaryTable.complainant_type == 4) {
          return "Third Party ";
        } else if (this.SummaryTable.complainant_type == 5) {
          return "Other ";
        }
        return null;
      }
    },
    complainantInfo() {
      if (this.SummaryTable.complainant_obj != null) {
        if (this.SummaryTable.complainant_type == 1) {
          return (
            this.SummaryTable.complainant_obj.title +
            " " +
            this.SummaryTable.complainant_obj.fname +
            "  " +
            this.SummaryTable.complainant_obj.lname
          );
        } else if (this.SummaryTable.complainant_type == 2) {
          return (
            this.SummaryTable.complainant_obj.title +
            " " +
            this.SummaryTable.complainant_obj.fname +
            " " +
            this.SummaryTable.complainant_obj.lname
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    complaineeTypeTxt() {
      if (this.SummaryTable.complainee_obj != null) {
        if (this.SummaryTable.complainee_type == 1) {
          return (
            "Learner: L" +
            this.SummaryTable.complainee_obj.student_id.toString()
          );
        } else if (this.SummaryTable.complainee_type == 2) {
          return (
            "Instructor: A" + this.SummaryTable.complainee_obj.adi_id.toString()
          );
        } else {
          return null;
        }
      } else {
        if (this.SummaryTable.complainee_type == 3) {
          return "Driving School ";
        } else if (this.SummaryTable.complainee_type == 4) {
          return "Third Party ";
        } else if (this.SummaryTable.complainee_type == 5) {
          return "Other ";
        }
        return null;
      }
    },
    complaineeInfo() {
      if (this.SummaryTable.complainee_obj != null) {
        if (this.SummaryTable.complainee_type == 1) {
          return (
            this.SummaryTable.complainee_obj.title +
            " " +
            this.SummaryTable.complainee_obj.fname +
            "  " +
            this.SummaryTable.complainee_obj.lname
          );
        } else if (this.SummaryTable.complainee_type == 2) {
          return (
            this.SummaryTable.complainee_obj.title +
            " " +
            this.SummaryTable.complainee_obj.fname +
            " " +
            this.SummaryTable.complainee_obj.lname
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    complainantContN() {
      if (this.SummaryTable.complainant_obj != null) {
        if (
          this.SummaryTable.complainant_type == 1 ||
          this.SummaryTable.complainant_type == 2
        ) {
          return this.SummaryTable.complainant_obj.mobile;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    complaineeContN() {
      if (this.SummaryTable.complainee_obj != null) {
        if (
          this.SummaryTable.complainee_type == 1 ||
          this.SummaryTable.complainee_type == 2
        ) {
          return this.SummaryTable.complainee_obj.mobile;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    compDesc() {
      if (this.SummaryTable.description != null) {
        return this.SummaryTable.description;
      } else {
        return null;
      }
    },
    incidentDate() {
      if (this.SummaryTable.incident_date != null) {
        return this.SummaryTable.incident_date;
      } else {
        return null;
      }
    },
    incidentTime() {
      if (this.SummaryTable.incident_time != null) {
        return this.SummaryTable.incident_time;
      } else {
        return null;
      }
    },
    selResolve() {
      if (this.SummaryTable.self_resolve != null) {
        return this.SummaryTable.self_resolve;
      } else {
        return null;
      }
    },
    selResolveOBJ() {
      if (this.SummaryTable.self_resolve_obj != null) {
        return {
          srDate: this.SummaryTable.self_resolve_obj.self_res_date,
          srTime: this.SummaryTable.self_resolve_obj.self_res_time,
          srResoOffer: this.SummaryTable.self_resolve_obj.resol_offer,
          srDesc: this.SummaryTable.self_resolve_obj.reply_desc,
        };
      } else {
        return null;
      }
    },
    reportedDateTime() {
      if (this.SummaryTable.created_on != null) {
        return this.SummaryTable.created_on;
      } else {
        return null;
      }
    },
    recBy() {
      if (this.SummaryTable.staff_id != null) {
        return (
          this.SummaryTable.staff_obj.title +
          " " +
          this.SummaryTable.staff_obj.fname +
          " " +
          this.SummaryTable.staff_obj.lname +
          " - " +
          this.SummaryTable.staff_id
        );
      } else {
        return null;
      }
    },
    //errors
    progressTxtError() {
      const errors = [];
      if (!this.$v.form.progresstxt.$dirty) return errors;
      !this.$v.form.progresstxt.required &&
        errors.push("Please enter update description.");
      !this.$v.form.progresstxt.minLength &&
        errors.push("Update description is too short. Min 25 characters");
      !this.$v.form.progresstxt.maxLength &&
        errors.push("Update description is too Long. Max 1600 characters");
      return errors;
    },
    compStatusError() {
      const errors = [];
      if (!this.$v.form.status.$dirty) return errors;
      !this.$v.form.status.required &&
        errors.push("Complaint status is required.");
      !this.$v.form.status.minValue &&
        errors.push("Complaint status is required.");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      saveSatusUpdate: `${M_OU}${SET_COMPLAINT_UPDATE}`,
    }),
    saveUpdate() {
      //set form to touch
      this.$v.$touch();
      if (this.$v.$invalid == false) {
        this.saveSatusUpdate({
          id: this.SummaryTable.ref,
          progress: this.form.progresstxt,
          state: this.form.status,
        }).then((rep) => {
          if (rep == "SUCCESS") {
            this.$fire({
              title: "Success",
              text: "Complaint progress updated.",
              type: "success",
              allowOutsideClick: false,
            }).then((r) => {
              console.log(r.value);
              //clear all and reloadclear()
              this.closeDialog();
            });
          } else {
            this.$fire({
              title: "Error",
              text: "Error updating complaint progress",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              //this.$emit("dialogClosed");
              console.log(r.value);
            });
          }
        });
      }
    },
    closeDialog() {
      this.form = {
        progresstxt: "",
        status: 0,
      };
      this.$v.$reset();
      this.$emit("closedialog");
    },
  },
  filters: {
    intToYN(val) {
      return val == 1 ? "Yes" : "No";
    },
  },
};
</script>

<style></style>
