<template>
  <div class="feedbackfaces">
    <img
      v-for="index in 5"
      :key="index"
      :src="getSRC(index)"
      :id="index"
      :data-id="index"
      @click="
        $emit('clicked-face', index);
        setActive($event);
      "
    />
  </div>
</template>

<script>
export default {
  name: "FaceSelector",
  prop: ["faceval"],
  methods: {
    selectFace: function (selecedfaceval) {
      //console.log(selecedfaceval);
      //make all faces non selected
      //make this face selected
      this.select1 = false;
      this.select2 = false;
      this.select3 = false;
      this.select4 = false;
      this.select5 = false;

      switch (parseInt(selecedfaceval)) {
        case 1:
          this.select1 = true;
          break;
        case 2:
          this.select2 = true;
          break;
        case 3:
          this.select3 = true;
          break;
        case 4:
          this.select4 = true;
          break;
        case 5:
          this.select5 = true;
          break;
        default:
          this.select1 = false;
          this.select2 = false;
          this.select3 = false;
          this.select4 = false;
          this.select5 = false;
        //nothing
      }
    },
    getSRC: function (i) {
      //console.log(i);
      //return "/assets/img/emoji/" + i + ".png";
      return require("@/assets/img/emoji/" + i + ".png");
    },
    getSClass: function (i) {
      return "{ active: select" + i + " }";
    },
    setActive: function (ev) {
      var result = [];
      var node = ev.target.parentNode.firstChild;

      while (node) {
        if (node !== this && node.nodeType === Node.ELEMENT_NODE) {
          node.classList.remove("active");
          result.push(node);
          node = node.nextElementSibling || node.nextSibling;
        }
      }
      ev.target.classList.toggle("active");
    },
  },
};
</script>

<style>
@-webkit-keyframes swing {
  15% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
  }
  65% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
  80% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes swing {
  15% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
  }
  65% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
  80% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.feedbackfaces {
  display: block;
}
.feedbackfaces img {
  width: 4.5rem;
  display: inline-block;
  cursor: pointer;
  /* transition: transform .4s; */ /* Animation */
  margin-left: 5px;
  margin-top: 5px;
}
.feedbackfaces img:hover {
  /*    transform: scale(1.2);*/
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.feedbackfaces img.active {
  /*       transform: scale(0.8);*/
  cursor: default;
  transform: scale(0.8);
  filter: drop-shadow(1px 10px 5px #4dcdc4);
}
</style>
