<template>
  <div>
    <v-card class="point" @click="dialog = true" v-if="basicObj">
      <v-card-title
        class="text-h6 grey lighten-2"
        style="justify-content: center"
      >
        Update Advance Information
      </v-card-title>
      <v-img
        :src="require(`@/assets/img/updateinfo/editdavanced.webp`)"
        max-height="300"
        contain
        class="grey lighten-3 pa-5"
      ></v-img>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title>
            <span class="text-h5">Update Instructor Advance Information</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text style="height: 70vh">
          <v-container>
            <v-row>
              <v-col>
                <v-select
                  v-model="form.gender"
                  :items="genders"
                  name="gender"
                  outlined
                  item-text="txt"
                  item-value="id"
                  label="Gender"
                  style="width: 50%"
                  required
                  :error-messages="genderErrors"
                  @input="$v.form.gender.$touch()"
                  @blur="$v.form.gender.$touch()"
                >
                </v-select>
                <v-text-field
                  v-model="form.dob"
                  name="dob"
                  label="Date of Birth"
                  outlined
                  required
                  :error-messages="dobErrors"
                  @input="$v.form.dob.$touch()"
                  @blur="$v.form.dob.$touch()"
                  type="date"
                ></v-text-field>
                <v-text-field
                  v-model="form.hp1"
                  name="hp1"
                  outlined
                  :counter="11"
                  label="Home Phone Number 1"
                  :error-messages="hp1Errors"
                  @input="$v.form.hp1.$touch()"
                  @blur="$v.form.hp1.$touch()"
                  type="tel"
                ></v-text-field>
                <v-text-field
                  v-model="form.hp2"
                  name="hp2"
                  outlined
                  :counter="11"
                  label="Home Phone Number 2"
                  :error-messages="hp2Errors"
                  @input="$v.form.hp1.$touch()"
                  @blur="$v.form.hp1.$touch()"
                  type="tel"
                ></v-text-field>

                <v-text-field
                  v-model="form.mp2"
                  name="mp2"
                  outlined
                  :counter="11"
                  label="Mobile Number 2"
                  :error-messages="mp2Errors"
                  @input="$v.form.mp2.$touch()"
                  @blur="$v.form.mp2.$touch()"
                  type="tel"
                ></v-text-field>
                <v-text-field
                  v-model="form.wp1"
                  name="wp1"
                  outlined
                  :counter="11"
                  label="Work Phone Number 1"
                  :error-messages="wp1Errors"
                  @input="$v.form.wp1.$touch()"
                  @blur="$v.form.wp1.$touch()"
                  type="tel"
                ></v-text-field>
                <v-text-field
                  v-model="form.wp2"
                  name="wp2"
                  outlined
                  :counter="11"
                  label="Work Phone Number 2"
                  :error-messages="wp2Errors"
                  @input="$v.form.wp2.$touch()"
                  @blur="$v.form.wp2.$touch()"
                  type="tel"
                ></v-text-field>
                <v-text-field
                  v-model.number="form.crtbref"
                  name="crtbref"
                  style="width: 40%"
                  :counter="12"
                  outlined
                  label="DBS Reference Check*"
                  reqired
                  type="number"
                  :error-messages="crtbrefErrors"
                  @input="$v.form.crtbref.$touch()"
                  @blur="$v.form.crtbref.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.fbname"
                  name="fbname"
                  :counter="30"
                  outlined
                  label="Full Bank Name*"
                  reqired
                  :error-messages="fbnameErrors"
                  @input="$v.form.fbname.$touch()"
                  @blur="$v.form.fbname.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.iscode"
                  name="iscode"
                  style="width: 40%"
                  :counter="8"
                  outlined
                  label="Sort Code*"
                  reqired
                  :error-messages="scodeErrors"
                  @input="$v.form.scode.$touch()"
                  @blur="$v.form.scode.$touch()"
                  @change="scodeUpdate()"
                  v-mask="`##-##-##`"
                ></v-text-field>
                <v-text-field
                  v-model="form.accno"
                  name="accno"
                  style="width: 40%"
                  :counter="8"
                  outlined
                  label="Account Number*"
                  reqired
                  :error-messages="accnoErrors"
                  @input="$v.form.accno.$touch()"
                  @blur="$v.form.accno.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.naob"
                  name="naob"
                  :counter="50"
                  outlined
                  label="Your Name as it appears on the Bank Account*"
                  reqired
                  :error-messages="naobErrors"
                  @input="$v.form.naob.$touch()"
                  @blur="$v.form.naob.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.nino"
                  name="nino"
                  style="width: 40%"
                  outlined
                  :counter="9"
                  label="National Insurance Number"
                  reqired
                  :error-messages="ninoErrors"
                  @input="$v.form.nino.$touch()"
                  @blur="$v.form.nino.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning darken-1"
            text
            @click="saveChanges()"
            :disabled="$v.form.$invalid"
          >
            Update Instructor
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
import { M_ADI, SAVE_ADI_ADVANCE_INFO } from "@/store/mutation-list";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";

export default {
  name: "UpdateAdvanceInfo",
  directives: { mask },
  props: ["basicObj"],
  data() {
    return {
      dialog: false,
      genders: [
        { id: "female", txt: "Female" },
        { id: "male", txt: "Male" },
      ],

      form: {
        adiid: null, //readonly
        compid: null, // compliance ID required
        gender: "",
        dob: "",

        hp1: "",
        hp2: "",
        mp2: "",

        wp1: "",
        wp2: "",

        crtbref: "",
        fbname: "",
        scode: "",
        iscode: "", //visible one with mask

        accno: "",
        naob: "",
        nino: "",
      },
    };
  },
  validations: {
    form: {
      gender: { required },
      dob: { required },
      hp1: {
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },
      hp2: {
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },
      mp2: {
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },

      wp1: {
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },
      wp2: {
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },
      crtbref: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(12),
      },
      fbname: { required, maxLength: maxLength(30) },
      scode: {
        required,
        numeric,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
      accno: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(8),
      },
      naob: { required, maxLength: maxLength(50) },
      nino: { required, minLength: minLength(9), maxLength: maxLength(9) },
    },
  },
  computed: {
    genderErrors() {
      const errors = [];
      if (!this.$v.form.gender.$dirty) return errors;
      !this.$v.form.gender.required && errors.push("Gender is required.");
      return errors;
    },
    dobErrors() {
      const errors = [];
      if (!this.$v.form.dob.$dirty) return errors;
      !this.$v.form.dob.required && errors.push("Date of birth  is required.");

      return errors;
    },
    hp1Errors() {
      const errors = [];
      if (!this.$v.form.hp1.$dirty) return errors;
      !this.$v.form.hp1.maxLength &&
        errors.push("Home Phone number can not be more than 11 digits");
      !this.$v.form.hp1.minLength &&
        errors.push("Home Phone number can not be less than 9 digits");
      !this.$v.form.hp1.numeric && errors.push("Only numbers are allowed.");

      return errors;
    },
    hp2Errors() {
      const errors = [];
      if (!this.$v.form.hp2.$dirty) return errors;
      !this.$v.form.hp2.maxLength &&
        errors.push("Home Phone number can not be more than 11 digits");
      !this.$v.form.hp2.minLength &&
        errors.push("Home Phone number can not be less than 9 digits");
      !this.$v.form.hp2.numeric && errors.push("Only numbers are allowed.");

      return errors;
    },

    mp2Errors() {
      const errors = [];
      if (!this.$v.form.mp2.$dirty) return errors;
      !this.$v.form.mp2.maxLength &&
        errors.push("Mobile Phone number can not be more than 11 digits");
      !this.$v.form.hp2.minLength &&
        errors.push("Mobile Phone number can not be less than 9 digits");
      !this.$v.form.mp2.numeric && errors.push("Only numbers are allowed.");

      return errors;
    },
    wp1Errors() {
      const errors = [];
      if (!this.$v.form.wp1.$dirty) return errors;
      !this.$v.form.wp1.maxLength &&
        errors.push("Home Phone number can not be more than 11 digits");
      !this.$v.form.wp1.minLength &&
        errors.push("Home Phone number can not be less than 9 digits");
      !this.$v.form.wp1.numeric && errors.push("Only numbers are allowed.");

      return errors;
    },
    wp2Errors() {
      const errors = [];
      if (!this.$v.form.wp2.$dirty) return errors;
      !this.$v.form.wp2.maxLength &&
        errors.push("Home Phone number can not be more than 11 digits");
      !this.$v.form.wp2.minLength &&
        errors.push("Home Phone number can not be less than 9 digits");
      !this.$v.form.wp2.numeric && errors.push("Only numbers are allowed.");

      return errors;
    },
    crtbrefErrors() {
      const errors = [];
      if (!this.$v.form.crtbref.$dirty) return errors;
      !this.$v.form.crtbref.numeric && errors.push("Numbers only");
      !this.$v.form.crtbref.maxLength &&
        errors.push("DBS Reference must be at most 16 characters long");
      !this.$v.form.crtbref.minLength &&
        errors.push("DBS Reference must be at most 16 characters long");
      !this.$v.form.crtbref.required &&
        errors.push("DBS Reference is required.");

      return errors;
    },
    fbnameErrors() {
      const errors = [];
      if (!this.$v.form.fbname.$dirty) return errors;
      !this.$v.form.fbname.maxLength &&
        errors.push("Full Bank Name must be at most 30 characters long");
      !this.$v.form.fbname.required &&
        errors.push("Full Bank Name is required.");

      return errors;
    },
    scodeErrors() {
      const errors = [];
      if (!this.$v.form.scode.$dirty) return errors;
      !this.$v.form.scode.numeric && errors.push("Numbers only");
      !this.$v.form.scode.maxLength &&
        errors.push("Sort Code must be at most 8 characters long");
      !this.$v.form.scode.minLength && errors.push("Invalid Sort Code");
      !this.$v.form.scode.required && errors.push("Sort Code is required.");

      return errors;
    },
    accnoErrors() {
      const errors = [];
      if (!this.$v.form.accno.$dirty) return errors;
      !this.$v.form.accno.numeric && errors.push("Numbers only");
      !this.$v.form.accno.maxLength &&
        errors.push("Account Number must be at most 8 characters long");
      !this.$v.form.accno.minLength && errors.push("Invalid Account Number");
      !this.$v.form.accno.required &&
        errors.push("Account Number is required.");

      return errors;
    },
    naobErrors() {
      const errors = [];
      if (!this.$v.form.naob.$dirty) return errors;
      !this.$v.form.naob.maxLength &&
        errors.push("Name must be at most 50 characters long");
      !this.$v.form.naob.required && errors.push("Name is required.");

      return errors;
    },
    ninoErrors() {
      const errors = [];
      if (!this.$v.form.nino.$dirty) return errors;
      !this.$v.form.nino.maxLength &&
        errors.push(
          "National Insurance Number must be at most 9 characters long"
        );
      !this.$v.form.nino.minLength &&
        errors.push("Invalid National Insurance Number");
      !this.$v.form.nino.required &&
        errors.push("National Insurance Number is required.");

      return errors;
    },
  },
  methods: {
    ...mapActions({
      saveAdvanceChanges: `${M_ADI}${SAVE_ADI_ADVANCE_INFO}`,
    }),
    saveChanges() {
      this.$v.$touch();
      if (this.$v.$invalid == false) {
        this.saveAdvanceChanges({
          id: this.form.adiid,
          form: this.form,
        }).then((rep) => {
          if (rep == "SUCCESS") {
            this.$fire({
              title: "Instructor Updated",
              text: "Instructor basic information updated successfully.",
              type: "success",
              //timer: 3000,
              allowOutsideClick: false,
            }).then((r) => {
              this.dialog = false;
              console.log(r.value);
            });
          } else {
            this.$fire({
              title: "Error",
              text: "Error updating Instructor basic information. Please try again.",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
      //need to unmask sortcode

      /*

      let fd = new FormData(updateadiadvance);
            fd.append("updateadvanceadiinfo",this.adiainfo.adiid);
            fd.append("compid",this.adiainfo.complianceid);
            let self = this;

            axios  
                .post(serverpathou,fd)
                .then(function (response){
                    let reply = response.data;

                    if(typeof reply.result.code != 'undefined'){
                        if(reply.result.code == 1){
                            window.location.reload();
                        }else{
                            self.isErrorAlert = true;
                            self.errorMSG = reply.result.text;

                        }
                    }
                    
                })
                .catch(function (error){
                    self.isErrorAlert = true;
                    self.errorMSG = "Error submitting data. Please try again later";
                })

      */
    },
    scodeUpdate() {
      this.form.scode = this.form.iscode.replace(/-/g, "");
    },
  },
  mounted() {
    this.form = this.basicObj;
  },
};
</script>

<style></style>
