<template>
  <v-col cols="12">
    <v-divider />
    <div class="mt-5">
      <h3 class="text-center">Summary Chart</h3>
      <GChart
        type="PieChart"
        :settings="{ packages: ['corechart'] }"
        :data="genGChart"
        :options="chartOptions"
        :title="chartOptions.chart.title"
      />
    </div>
  </v-col>
</template>
<script>
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "InstructorRetentionChart",
  components: { GChart },
  props: ["tableData"],
  data() {
    return {
      chartOptions: {
        chart: {
          title: "Instructor Retention Levels",
          subtitle: "Instructors Time With Driving School",
        },
        legend: { topic: "top", position: "right", maxLines: 3 },
        // isStacked: "percent",
        height: 600,
        // curveType: "function",
        // vAxis: { minValue: 1 },
        //is3D: true,
        pieHole: 0.4,
        pieSliceText: "label",
        slices: {
          1: { offset: 0.2 },
          2: { offset: 0.3 },
          4: { offset: 0.4 },
          5: { offset: 0.5 },
        },
      },
    };
  },
  methods: {
    getTableKeys(arr) {
      let keys = [];
      if (arr) {
        arr.forEach(function (rec) {
          var key = JSON.stringify(rec.key).replaceAll('"', "");
          keys.push(key);
        });
      }
      return keys;
    },
  },
  computed: {
    genGChart() {
      //this will generate the whole line chart
      let retar = [];

      if (this.tableData) {
        retar.push(["Instructor Status", "Number of Days"]);
        this.tableData.forEach((rec) => {
          //lets add that to array
          retar.push([rec.type, rec.total]);
        });
      }
      return retar;
    },
  },
};
</script>
