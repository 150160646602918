<template>
  <div>
    <v-form ref="form">
      <v-row>
        <v-col md="4">
          <h3 class="mb-5">Vehicle 1</h3>
          <v-text-field
            v-model="form.caronemake"
            name="make"
            outlined
            :counter="30"
            label="Make"
            required
            :error-messages="caronemakeErrors"
            @input="$v.form.caronemake.$touch()"
            @blur="$v.form.caronemake.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.caronemodel"
            name="model"
            outlined
            :counter="30"
            label="Model"
            required
            :error-messages="caronemodelErrors"
            @input="$v.form.caronemodel.$touch()"
            @blur="$v.form.caronemodel.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.caronecarcolour"
            name="carcolour"
            outlined
            :counter="16"
            label="Vehicle Colour"
            required
            :error-messages="caronecarcolourErrors"
            @input="$v.form.caronecarcolour.$touch()"
            @blur="$v.form.caronecarcolour.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.caroneregno"
            name="regno"
            outlined
            :counter="8"
            label="Registration Number"
            required
            :error-messages="caroneregnoErrors"
            @input="$v.form.caroneregno.$touch()"
            @blur="$v.form.caroneregno.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.caroneregyear"
            name="regyear"
            label="Year of Registration"
            outlined
            required
            :error-messages="caroneregyearErrors"
            @input="$v.form.caroneregyear.$touch()"
            @blur="$v.form.caroneregyear.$touch()"
            type="month"
            @change="currentState()"
          ></v-text-field>
          <v-select
            v-model="form.caronecartype"
            name="cartype"
            :items="clensedVT"
            outlined
            item-text="txt"
            item-value="id"
            label="Vehicle Type"
            style="width: 50%"
            required
            :error-messages="caronecartypeErrors"
            @input="$v.form.caronecartype.$touch()"
            @blur="$v.form.caronecartype.$touch()"
            @change="currentState()"
          >
          </v-select
          ><v-select
            v-model="form.caronecargeartype"
            name="cargeartype"
            :items="gears"
            outlined
            item-text="txt"
            item-value="id"
            label="Vehicle Gear Type"
            style="width: 50%"
            required
            :error-messages="caronecargeartypeErrors"
            @input="$v.form.caronecargeartype.$touch()"
            @blur="$v.form.caronecargeartype.$touch()"
            @change="currentState()"
          >
          </v-select
          ><v-select
            v-model="form.caronefueltype"
            name="fueltype"
            :items="fuelTypes"
            outlined
            item-text="txt"
            item-value="id"
            label="Fuel Type"
            style="width: 50%"
            required
            :error-messages="caronefueltypeErrors"
            @input="$v.form.caronefueltype.$touch()"
            @blur="$v.form.caronefueltype.$touch()"
            @change="currentState()"
          >
          </v-select>
          <v-text-field
            v-model="form.caroneleagalowner"
            name="leagalowner"
            outlined
            :counter="80"
            label="Name of the Legal Registered Owner &amp; Keeper of the Vehicle"
            required
            :error-messages="caroneleagalownerErrors"
            @input="$v.form.caroneleagalowner.$touch()"
            @blur="$v.form.caroneleagalowner.$touch()"
            @change="currentState()"
          ></v-text-field>
          <v-select
            v-model="form.caronedualcontrol"
            :items="dualCont"
            name="dualcontrol"
            outlined
            item-text="txt"
            item-value="id"
            label="Does the vehicle have dual controls?"
            @change="currentState()"
          ></v-select>
          <v-select
            v-model="form.caroneownertype"
            :items="vehicleOwnerTypes"
            name="carownertype"
            outlined
            item-text="desc"
            item-value="type_id"
            label="The vehicle you teach in is:"
            :hint="caroneownerMsg"
            @change="currentState()"
          ></v-select>

          <v-file-input
            v-model="form.caronelogbook"
            id="fllogbook1"
            label="Log Book (V5C)"
            :rules="rules"
            outlined
            clearable
            show-size
            :error-messages="caronelogbookErrors"
            accept="application/pdf"
            placeholder="Select PDF File"
            @input="$v.form.caronelogbook.$touch()"
            @blur="$v.form.caronelogbook.$touch()"
            @change="currentState()"
          ></v-file-input>
          <!-- new field road tax -->
          <v-text-field
            v-model="form.caronetaxexp"
            name="taxexp"
            label="Vehicle Excise Duty Expiry Date"
            outlined
            required
            :error-messages="caronetaxexpErrors"
            @input="$v.form.caronetaxexp.$touch()"
            @blur="$v.form.caronetaxexp.$touch()"
            type="date"
            @change="currentState()"
          ></v-text-field>
          <v-file-input
            v-model="form.caronemot"
            id="flmot1"
            label="MOT Documents"
            :rules="rules"
            outlined
            clearable
            show-size
            :error-messages="caronemotErrors"
            accept="application/pdf"
            placeholder="Select PDF File"
            @input="$v.form.caronemot.$touch()"
            @blur="$v.form.caronemot.$touch()"
            @change="currentState()"
          ></v-file-input>
          <v-text-field
            v-model="form.caronemotexp"
            name="motexp"
            label="MOT Expiry Date"
            outlined
            required
            :error-messages="caronemotexpErrors"
            @input="$v.form.caronemotexp.$touch()"
            @blur="$v.form.caronemotexp.$touch()"
            type="date"
            @change="currentState()"
          ></v-text-field>
          <v-file-input
            v-model="form.caroneinsurance"
            id="flinsurance1"
            label="Insurance Documents"
            :rules="rules"
            outlined
            clearable
            show-size
            :error-messages="caroneinsuranceErrors"
            accept="application/pdf"
            placeholder="Select PDF File"
            @input="$v.form.caroneinsurance.$touch()"
            @blur="$v.form.caroneinsurance.$touch()"
            @change="currentState()"
          ></v-file-input>

          <v-text-field
            v-model="form.caroneinsuranceexp"
            name="insuranceexp"
            label="Insurance Expiry Date"
            outlined
            required
            :error-messages="caroneinsuranceexpErrors"
            @input="$v.form.caroneinsuranceexp.$touch()"
            @blur="$v.form.caroneinsuranceexp.$touch()"
            type="date"
            @change="currentState()"
          ></v-text-field>
          <v-btn
            class="primary"
            v-if="!form.hadSeconCar"
            @click="
              form.hadSeconCar = true;
              currentState();
            "
            ><v-icon>mdi-plus</v-icon><v-icon>mdi-car-sports</v-icon> Add
            another vehicle</v-btn
          >
          <v-btn
            class="primary"
            v-if="form.hadSeconCar"
            @click="
              form.hadSeconCar = false;
              currentState();
            "
            ><v-icon>mdi-minus</v-icon><v-icon>mdi-car-sports</v-icon> Remove
            additional vehicle</v-btn
          >
        </v-col>
        <!-- END of car one -->
        <v-divider vertical v-if="form.hadSeconCar"></v-divider>
        <v-expand-x-transition>
          <v-col md="4" v-if="form.hadSeconCar">
            <h3 class="mb-5">Vehicle 2</h3>

            <v-text-field
              v-model="form.cartwomake"
              name="make2"
              outlined
              :counter="30"
              label="Make"
              required
              :error-messages="cartwomakeErrors"
              @input="$v.form.cartwomake.$touch()"
              @blur="$v.form.cartwomake.$touch()"
              @change="currentState()"
            ></v-text-field>
            <v-text-field
              v-model="form.cartwomodel"
              name="model2"
              outlined
              :counter="30"
              label="Model"
              required
              :error-messages="cartwomodelErrors"
              @input="$v.form.cartwomodel.$touch()"
              @blur="$v.form.cartwomodel.$touch()"
              @change="currentState()"
            ></v-text-field>
            <v-text-field
              v-model="form.cartwocarcolour"
              name="carcolour2"
              outlined
              :counter="16"
              label="Vehicle Colour"
              required
              :error-messages="cartwocarcolourErrors"
              @input="$v.form.cartwocarcolour.$touch()"
              @blur="$v.form.cartwocarcolour.$touch()"
              @change="currentState()"
            ></v-text-field>
            <v-text-field
              v-model="form.cartworegno"
              name="regno2"
              outlined
              :counter="8"
              label="Registration Number"
              required
              :error-messages="cartworegnoErrors"
              @input="$v.form.cartworegno.$touch()"
              @blur="$v.form.cartworegno.$touch()"
              @change="currentState()"
            ></v-text-field>
            <v-text-field
              v-model="form.cartworegyear"
              name="regyear2"
              label="Year of Registration"
              outlined
              required
              :error-messages="cartworegyearErrors"
              @input="$v.form.cartworegyear.$touch()"
              @blur="$v.form.cartworegyear.$touch()"
              type="month"
              @change="currentState()"
            ></v-text-field>
            <v-select
              v-model="form.cartwocartype"
              name="cartype2"
              :items="clensedVT"
              outlined
              item-text="txt"
              item-value="id"
              label="Vehicle Type"
              style="width: 50%"
              required
              :error-messages="cartwocartypeErrors"
              @input="$v.form.cartwocartype.$touch()"
              @blur="$v.form.cartwocartype.$touch()"
              @change="currentState()"
            >
            </v-select
            ><v-select
              v-model="form.cartwocargeartype"
              name="cargeartype2"
              :items="gears"
              outlined
              item-text="txt"
              item-value="id"
              label="Vehicle Gear Type"
              style="width: 50%"
              required
              :error-messages="cartwocargeartypeErrors"
              @input="$v.form.cartwocargeartype.$touch()"
              @blur="$v.form.cartwocargeartype.$touch()"
              @change="currentState()"
            >
            </v-select
            ><v-select
              v-model="form.cartwofueltype"
              name="fueltype2"
              :items="fuelTypes"
              outlined
              item-text="txt"
              item-value="id"
              label="Fuel Type"
              style="width: 50%"
              required
              :error-messages="cartwofueltypeErrors"
              @input="$v.form.cartwofueltype.$touch()"
              @blur="$v.form.cartwofueltype.$touch()"
              @change="currentState()"
            >
            </v-select>
            <v-text-field
              v-model="form.cartwoleagalowner"
              name="leagalowner2"
              outlined
              :counter="80"
              label="Name of the Legal Registered Owner &amp; Keeper of the Vehicle"
              required
              :error-messages="cartwoleagalownerErrors"
              @input="$v.form.cartwoleagalowner.$touch()"
              @blur="$v.form.cartwoleagalowner.$touch()"
              @change="currentState()"
            ></v-text-field>

            <v-select
              v-model="form.cartwodualcontrol"
              :items="dualCont"
              name="dualcontrol2"
              outlined
              item-text="txt"
              item-value="id"
              label="Does the vehicle have dual controls?"
              @change="currentState()"
            ></v-select>
            <v-select
              v-model="form.cartwoownertype"
              :items="vehicleOwnerTypes"
              name="carownertype2"
              outlined
              item-text="desc"
              item-value="type_id"
              label="The vehicle you teach in is:"
              :hint="cartwoownerMsg"
              @change="currentState()"
            ></v-select>

            <v-file-input
              v-model="form.cartwologbook"
              id="fllogbook2"
              label="Log Book (V5C)"
              :rules="rules"
              outlined
              clearable
              show-size
              :error-messages="cartwologbookErrors"
              accept="application/pdf"
              placeholder="Select PDF File"
              @input="$v.form.cartwologbook.$touch()"
              @blur="$v.form.cartwologbook.$touch()"
              @change="currentState()"
            ></v-file-input>
            <!-- new field road tax -->
            <v-text-field
              v-model="form.cartwotaxexp"
              name="taxexp2"
              label="Vehicle Excise Duty Expiry Date"
              outlined
              required
              :error-messages="cartwotaxexpErrors"
              @input="$v.form.cartwotaxexp.$touch()"
              @blur="$v.form.cartwotaxexp.$touch()"
              type="date"
              @change="currentState()"
            ></v-text-field>
            <v-file-input
              v-model="form.cartwomot"
              id="flmot2"
              label="MOT Documents"
              :rules="rules"
              outlined
              clearable
              show-size
              :error-messages="cartwomotErrors"
              accept="application/pdf"
              placeholder="Select PDF File"
              @input="$v.form.cartwomot.$touch()"
              @blur="$v.form.cartwomot.$touch()"
              @change="currentState()"
            ></v-file-input>
            <v-text-field
              v-model="form.cartwomotexp"
              name="motexp2"
              label="MOT Expiry Date"
              outlined
              required
              :error-messages="cartwomotexpErrors"
              @input="$v.form.cartwomotexp.$touch()"
              @blur="$v.form.cartwomotexp.$touch()"
              type="date"
              @change="currentState()"
            ></v-text-field>
            <v-file-input
              v-model="form.cartwoinsurance"
              id="flinsurance2"
              label="Insurance Documents"
              :rules="rules"
              outlined
              clearable
              show-size
              :error-messages="cartwoinsuranceErrors"
              accept="application/pdf"
              placeholder="Select PDF File"
              @input="$v.form.cartwoinsurance.$touch()"
              @blur="$v.form.cartwoinsurance.$touch()"
              @change="currentState()"
            ></v-file-input>

            <v-text-field
              v-model="form.cartwoinsuranceexp"
              name="insuranceexp2"
              label="Insurance Expiry Date"
              outlined
              required
              :error-messages="cartwoinsuranceexpErrors"
              @input="$v.form.cartwoinsuranceexp.$touch()"
              @blur="$v.form.cartwoinsuranceexp.$touch()"
              type="date"
              @change="currentState()"
            ></v-text-field>
          </v-col>
        </v-expand-x-transition>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import {
  GET_FUEL_TYPES,
  GET_VEHICLE_TYPES,
  GET_VEHICLE_OWNER_TYPES,
  M_DD,
} from "@/store/mutation-list";
import { mapGetters } from "vuex";
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
export default {
  name: "Vehicles",
  data() {
    return {
      //rules for Docuemnts update
      rules: [
        (value) =>
          !value ||
          value.size < 4000000 ||
          "PDF Document file size should be less than 4 MB!",
      ],
      gears: [
        { id: "automatic", txt: "Automatic" },
        { id: "manual", txt: "Manual" },
      ],
      dualCont: [
        { id: 2, txt: "No" },
        { id: 1, txt: "Yes" },
      ],
      form: {
        caronemake: "",
        caronemodel: "",
        caronecarcolour: "",
        caroneregno: "",
        caroneregyear: "",
        caronecartype: "",
        caronecargeartype: "",
        caronefueltype: "",
        caroneleagalowner: "",

        //new two questions
        caronedualcontrol: "",
        caroneownertype: "",

        caronelogbook: null,
        caronetaxexp: "",
        caronemot: null,
        caronemotexp: "",
        caroneinsurance: null,
        caroneinsuranceexp: "",

        hadSeconCar: false,

        cartwomake: "",
        cartwomodel: "",
        cartwocarcolour: "",
        cartworegno: "",
        cartworegyear: "",
        cartwocartype: "",
        cartwocargeartype: "",
        cartwofueltype: "",
        cartwoleagalowner: "",

        //new two questions
        cartwodualcontrol: "",
        cartwoownertype: "",

        cartwologbook: null,
        cartwotaxexp: "",
        cartwomot: null,
        cartwoinsurance: null,

        cartwomotexp: "",
        cartwoinsuranceexp: "",
      },
    };
  },
  validations: {
    form: {
      caronemake: { required, maxLength: maxLength(30) },
      caronemodel: { required, maxLength: maxLength(30) },
      caronecarcolour: { required, maxLength: maxLength(16) },
      caroneregno: { required, maxLength: maxLength(8) },
      caroneregyear: { required },
      caronecartype: { required },
      caronecargeartype: { required },
      caronefueltype: { required },
      caroneleagalowner: { required, maxLength: maxLength(80) },

      caronelogbook: { required },
      caronetaxexp: { required },
      caronemot: { required },
      caronemotexp: { required },
      caroneinsurance: { required },

      caroneinsuranceexp: { required },

      //   car two
      cartwomake: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
        maxLength: maxLength(30),
      },
      cartwomodel: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
        maxLength: maxLength(30),
      },
      cartwocarcolour: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
        maxLength: maxLength(16),
      },
      cartworegno: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
        maxLength: maxLength(8),
      },
      cartworegyear: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
      },
      cartwocartype: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
      },
      cartwocargeartype: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
      },
      cartwofueltype: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
      },
      cartwoleagalowner: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
        maxLength: maxLength(80),
      },

      cartwologbook: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
      },
      cartwotaxexp: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
      },
      cartwomot: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
      },
      cartwoinsurance: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
      },

      cartwomotexp: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
      },
      cartwoinsuranceexp: {
        required: requiredIf(function (form) {
          return form.hadSeconCar;
        }),
      },
    },
  },
  methods: {
    currentState() {
      //attach files need to be sent as well
      if (
        this.form.caronelogbook &&
        this.form.caronemot &&
        this.form.caroneinsurance
      ) {
        //lets append those to form

        let logbookPDF = document.getElementById("fllogbook1").files[0];
        let MOTPFD = document.getElementById("flmot1").files[0];
        let InsurancePDF = document.getElementById("flinsurance1").files[0];

        let formdata = new FormData(this.$refs.form.$el);
        formdata.set("fllogbook1", logbookPDF, "v5c.pdf");
        formdata.set("flmot1", MOTPFD, "mot.pdf");
        formdata.set("flinsurance1", InsurancePDF, "insurance.pdf");

        //add if there any car 2 docs
        if (
          this.form.cartwologbook &&
          this.form.cartwomot &&
          this.form.cartwoinsurance
        ) {
          //there is a second car
          formdata.set("secondcar", "1");

          let logbookPDF2 = document.getElementById("fllogbook2").files[0];
          let MOTPFD2 = document.getElementById("flmot2").files[0];
          let InsurancePDF2 = document.getElementById("flinsurance2").files[0];

          formdata.set("fllogbook2", logbookPDF2, "v5c2.pdf");
          formdata.set("flmot2", MOTPFD2, "mot2.pdf");
          formdata.set("flinsurance2", InsurancePDF2, "insurance2.pdf");
        }

        this.$emit("vehicles-update", {
          state: this.$v.form.$invalid,
          data: this.form,
          fd: formdata,
        });
      } else {
        this.$emit("vehicles-update", {
          state: this.$v.form.$invalid,
          data: this.form,
          fd: new FormData(this.$refs.form.$el),
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      fuelTypes: `${M_DD}${GET_FUEL_TYPES}`,
      vehicleTypes: `${M_DD}${GET_VEHICLE_TYPES}`,
      vehicleOwnerTypes: `${M_DD}${GET_VEHICLE_OWNER_TYPES}`,
    }),
    //method to remove firstelement
    clensedVT() {
      let temp = this.vehicleTypes.slice(0);
      //temp.shift();
      return temp.splice(1, temp.length);
    },
    // car one Validations
    caronemakeErrors() {
      const errors = [];
      if (!this.$v.form.caronemake.$dirty) return errors;
      !this.$v.form.caronemake.required &&
        errors.push("Vehicle Make is required.");
      !this.$v.form.caronemake.maxLength &&
        errors.push("Vehicle Make is too long. Max 30 characters allowed.");
      return errors;
    },
    caronemodelErrors() {
      const errors = [];
      if (!this.$v.form.caronemodel.$dirty) return errors;
      !this.$v.form.caronemodel.required &&
        errors.push("Vehicle Model is required.");
      !this.$v.form.caronemodel.maxLength &&
        errors.push(
          "Vehicle Model name is too long. Max 30 characters allowed."
        );
      return errors;
    },
    caronecarcolourErrors() {
      const errors = [];
      if (!this.$v.form.caronecarcolour.$dirty) return errors;
      !this.$v.form.caronecarcolour.required &&
        errors.push("Vehicle Colour is required.");
      !this.$v.form.caronecarcolour.maxLength &&
        errors.push("Vehicle Colour is too long. Max 16 characters allowed.");
      return errors;
    },
    caroneregnoErrors() {
      const errors = [];
      if (!this.$v.form.caroneregno.$dirty) return errors;
      !this.$v.form.caroneregno.required &&
        errors.push("Vehicle Registration Number is required.");
      !this.$v.form.caroneregno.maxLength &&
        errors.push(
          "Vehicle Registration Number is too long. Max 8 characters allowed."
        );
      return errors;
    },
    caroneregyearErrors() {
      const errors = [];
      if (!this.$v.form.caroneregyear.$dirty) return errors;
      !this.$v.form.caroneregyear.required &&
        errors.push("Vehicle Registration Year is required.");
      return errors;
    },
    caronecartypeErrors() {
      const errors = [];
      if (!this.$v.form.caronecartype.$dirty) return errors;
      !this.$v.form.caronecartype.required &&
        errors.push("Vehicle Type is required.");
      return errors;
    },
    caronecargeartypeErrors() {
      const errors = [];
      if (!this.$v.form.caronecargeartype.$dirty) return errors;
      !this.$v.form.caronecargeartype.required &&
        errors.push("Vehicle Gear Type is required.");
      return errors;
    },
    caronefueltypeErrors() {
      const errors = [];
      if (!this.$v.form.caronefueltype.$dirty) return errors;
      !this.$v.form.caronefueltype.required &&
        errors.push("Vehicle Fuel Type is required.");
      return errors;
    },
    caroneleagalownerErrors() {
      const errors = [];
      if (!this.$v.form.caroneleagalowner.$dirty) return errors;
      !this.$v.form.caroneleagalowner.required &&
        errors.push(
          "Full Name as it appears on the V5C Registration Document."
        );
      !this.$v.form.caroneleagalowner.maxLength &&
        errors.push(
          "Leagal Owner Name is too long. Max 80 characters allowed."
        );
      return errors;
    },
    caronelogbookErrors() {
      const errors = [];
      if (!this.$v.form.caronelogbook.$dirty) return errors;
      !this.$v.form.caronelogbook.required &&
        errors.push("Vehicle's V5C is required.");
      return errors;
    },
    caronetaxexpErrors() {
      const errors = [];
      if (!this.$v.form.caronetaxexp.$dirty) return errors;
      !this.$v.form.caronetaxexp.required &&
        errors.push("Vehicle's VED Expiry Date is required.");
      return errors;
    },
    caronemotErrors() {
      const errors = [];
      if (!this.$v.form.caronemot.$dirty) return errors;
      !this.$v.form.caronemot.required &&
        errors.push("Vehicle's MOT is required.");
      return errors;
    },
    caronemotexpErrors() {
      const errors = [];
      if (!this.$v.form.caronemotexp.$dirty) return errors;
      !this.$v.form.caronemotexp.required &&
        errors.push("Vehicle's MOT Expiry Date is required.");
      return errors;
    },
    caroneinsuranceErrors() {
      const errors = [];
      if (!this.$v.form.caroneinsurance.$dirty) return errors;
      !this.$v.form.caroneinsurance.required &&
        errors.push(
          "Both Vehicle & Driver's Insurance Documents are required."
        );
      return errors;
    },
    caroneinsuranceexpErrors() {
      const errors = [];
      if (!this.$v.form.caroneinsuranceexp.$dirty) return errors;
      !this.$v.form.caroneinsuranceexp.required &&
        errors.push("Vehicle Insurance Expiry Date is required.");
      return errors;
    },

    caroneownerMsg() {
      if (!this.vehicleOwnerTypes || !this.form.caroneownertype) {
        return "";
      } else {
        let v = this.vehicleOwnerTypes.find(
          (row) => row.type_id === this.form.caroneownertype
        );
        //console.log(v);
        return v.message;
      }
    },
    cartwoownerMsg() {
      if (!this.vehicleOwnerTypes || !this.form.cartwoownertype) {
        return "";
      } else {
        let v = this.vehicleOwnerTypes.find(
          (row) => row.type_id === this.form.cartwoownertype
        );
        //console.log(v);
        return v.message;
      }
    },

    //car two validations
    cartwomakeErrors() {
      const errors = [];
      if (!this.$v.form.cartwomake.$dirty) return errors;
      !this.$v.form.cartwomake.required &&
        errors.push("Vehicle Make is required.");
      !this.$v.form.cartwomake.maxLength &&
        errors.push("Vehicle Make is too long. Max 30 characters allowed.");
      return errors;
    },
    cartwomodelErrors() {
      const errors = [];
      if (!this.$v.form.cartwomodel.$dirty) return errors;
      !this.$v.form.cartwomodel.required &&
        errors.push("Vehicle Model is required.");
      !this.$v.form.cartwomodel.maxLength &&
        errors.push(
          "Vehicle Model name is too long. Max 30 characters allowed."
        );
      return errors;
    },
    cartwocarcolourErrors() {
      const errors = [];
      if (!this.$v.form.cartwocarcolour.$dirty) return errors;
      !this.$v.form.cartwocarcolour.required &&
        errors.push("Vehicle Colour is required.");
      !this.$v.form.cartwocarcolour.maxLength &&
        errors.push("Vehicle Colour is too long. Max 16 characters allowed.");
      return errors;
    },
    cartworegnoErrors() {
      const errors = [];
      if (!this.$v.form.cartworegno.$dirty) return errors;
      !this.$v.form.cartworegno.required &&
        errors.push("Vehicle Registration Number is required.");
      !this.$v.form.cartworegno.maxLength &&
        errors.push(
          "Vehicle Registration Number is too long. Max 8 characters allowed."
        );
      return errors;
    },
    cartworegyearErrors() {
      const errors = [];
      if (!this.$v.form.cartworegyear.$dirty) return errors;
      !this.$v.form.cartworegyear.required &&
        errors.push("Vehicle Registration Year is required.");
      return errors;
    },
    cartwocartypeErrors() {
      const errors = [];
      if (!this.$v.form.cartwocartype.$dirty) return errors;
      !this.$v.form.cartwocartype.required &&
        errors.push("Vehicle Type is required.");
      return errors;
    },
    cartwocargeartypeErrors() {
      const errors = [];
      if (!this.$v.form.cartwocargeartype.$dirty) return errors;
      !this.$v.form.cartwocargeartype.required &&
        errors.push("Vehicle Gear Type is required.");
      return errors;
    },
    cartwofueltypeErrors() {
      const errors = [];
      if (!this.$v.form.cartwofueltype.$dirty) return errors;
      !this.$v.form.cartwofueltype.required &&
        errors.push("Vehicle Fuel Type is required.");
      return errors;
    },
    cartwoleagalownerErrors() {
      const errors = [];
      if (!this.$v.form.cartwoleagalowner.$dirty) return errors;
      !this.$v.form.cartwoleagalowner.required &&
        errors.push(
          "Full Name as it appears on the V5C Registration Document."
        );
      !this.$v.form.cartwoleagalowner.maxLength &&
        errors.push(
          "Leagal Owner Name is too long. Max 80 characters allowed."
        );
      return errors;
    },
    cartwologbookErrors() {
      const errors = [];
      if (!this.$v.form.cartwologbook.$dirty) return errors;
      !this.$v.form.cartwologbook.required &&
        errors.push("Vehicle's V5C is required.");
      return errors;
    },
    cartwotaxexpErrors() {
      const errors = [];
      if (!this.$v.form.cartwotaxexp.$dirty) return errors;
      !this.$v.form.cartwotaxexp.required &&
        errors.push("Vehicle's VED Expiry Date is required.");
      return errors;
    },
    cartwomotErrors() {
      const errors = [];
      if (!this.$v.form.cartwomot.$dirty) return errors;
      !this.$v.form.cartwomot.required &&
        errors.push("Vehicle's MOT is required.");
      return errors;
    },
    cartwomotexpErrors() {
      const errors = [];
      if (!this.$v.form.cartwomotexp.$dirty) return errors;
      !this.$v.form.cartwomotexp.required &&
        errors.push("Vehicle's MOT Expiry Date is required.");
      return errors;
    },
    cartwoinsuranceErrors() {
      const errors = [];
      if (!this.$v.form.cartwoinsurance.$dirty) return errors;
      !this.$v.form.cartwoinsurance.required &&
        errors.push(
          "Both Vehicle & Driver's Insurance Documents are required."
        );
      return errors;
    },
    cartwoinsuranceexpErrors() {
      const errors = [];
      if (!this.$v.form.cartwoinsuranceexp.$dirty) return errors;
      !this.$v.form.cartwoinsuranceexp.required &&
        errors.push("Vehicle Insurance Expiry Date is required.");
      return errors;
    },
  },
};
</script>

<style></style>
