<template>
  <div>
    <v-card class="point" @click="dialog = true" v-if="basicObj">
      <v-card-title
        class="text-h6 grey lighten-2"
        style="justify-content: center"
      >
        Add a Vehicle
      </v-card-title>
      <v-img
        :src="require(`@/assets/img/updateinfo/addcar.webp`)"
        max-height="300"
        contain
        class="grey lighten-3 pa-5"
      ></v-img>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title>
            <span class="text-h5">Add New Instructor Vehicle</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text style="height: 70vh">
          <v-container>
            <v-row
              ><v-col>
                <v-text-field
                  v-model="form.caronemake"
                  name="make"
                  outlined
                  :counter="30"
                  label="Make"
                  required
                  :error-messages="caronemakeErrors"
                  @input="$v.form.caronemake.$touch()"
                  @blur="$v.form.caronemake.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.caronemodel"
                  name="model"
                  outlined
                  :counter="30"
                  label="Model"
                  required
                  :error-messages="caronemodelErrors"
                  @input="$v.form.caronemodel.$touch()"
                  @blur="$v.form.caronemodel.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.caronecarcolour"
                  name="carcolour"
                  outlined
                  :counter="16"
                  label="Vehicle Colour"
                  required
                  :error-messages="caronecarcolourErrors"
                  @input="$v.form.caronecarcolour.$touch()"
                  @blur="$v.form.caronecarcolour.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.caroneregno"
                  name="regno"
                  outlined
                  :counter="8"
                  label="Registration Number"
                  required
                  :error-messages="caroneregnoErrors"
                  @input="$v.form.caroneregno.$touch()"
                  @blur="$v.form.caroneregno.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.caroneregyear"
                  name="regyear"
                  label="Year of Registration"
                  outlined
                  required
                  :error-messages="caroneregyearErrors"
                  @input="$v.form.caroneregyear.$touch()"
                  @blur="$v.form.caroneregyear.$touch()"
                  type="month"
                ></v-text-field>
                <v-select
                  v-model="form.caronecartype"
                  name="cartype"
                  :items="clensedVT"
                  outlined
                  item-text="txt"
                  item-value="id"
                  label="Vehicle Type"
                  style="width: 50%"
                  required
                  :error-messages="caronecartypeErrors"
                  @input="$v.form.caronecartype.$touch()"
                  @blur="$v.form.caronecartype.$touch()"
                >
                </v-select
                ><v-select
                  v-model="form.caronecargeartype"
                  name="cargeartype"
                  :items="gears"
                  outlined
                  item-text="txt"
                  item-value="id"
                  label="Vehicle Gear Type"
                  style="width: 50%"
                  required
                  :error-messages="caronecargeartypeErrors"
                  @input="$v.form.caronecargeartype.$touch()"
                  @blur="$v.form.caronecargeartype.$touch()"
                >
                </v-select
                ><v-select
                  v-model="form.caronefueltype"
                  name="fueltype"
                  :items="fuelTypes"
                  outlined
                  item-text="txt"
                  item-value="id"
                  label="Fuel Type"
                  style="width: 50%"
                  required
                  :error-messages="caronefueltypeErrors"
                  @input="$v.form.caronefueltype.$touch()"
                  @blur="$v.form.caronefueltype.$touch()"
                >
                </v-select>
                <v-text-field
                  v-model="form.caroneleagalowner"
                  name="leagalowner"
                  outlined
                  :counter="80"
                  label="Name of the Legal Registered Owner &amp; Keeper of the Vehicle"
                  required
                  :error-messages="caroneleagalownerErrors"
                  @input="$v.form.caroneleagalowner.$touch()"
                  @blur="$v.form.caroneleagalowner.$touch()"
                ></v-text-field>
                <v-select
                  v-model="form.caronedualcontrol"
                  :items="dualCont"
                  name="dualcontols"
                  outlined
                  item-text="txt"
                  item-value="id"
                  label="Does the vehicle have dual controls?"
                ></v-select>
                <v-select
                  v-model="form.caroneownertype"
                  :items="vehicleOwnerTypes"
                  name="carownertype"
                  outlined
                  item-text="desc"
                  item-value="type_id"
                  label="The vehicle you teach in is:"
                  :hint="caroneownerMsg"
                ></v-select>
                <v-file-input
                  v-model="form.caronelogbook"
                  id="fllogbook1"
                  label="Log Book (V5C)"
                  :rules="rules"
                  outlined
                  clearable
                  show-size
                  :error-messages="caronelogbookErrors"
                  accept="application/pdf"
                  placeholder="Select PDF File"
                  @change="onLogbook($event)"
                  @input="$v.form.caronelogbook.$touch()"
                  @blur="$v.form.caronelogbook.$touch()"
                ></v-file-input>
                <v-text-field
                  v-model="form.caronetaxexp"
                  name="taxexp"
                  label="Vehicle Excise Duty Expiry Date"
                  outlined
                  required
                  :error-messages="caronetaxexpErrors"
                  @input="$v.form.caronetaxexp.$touch()"
                  @blur="$v.form.caronetaxexp.$touch()"
                  type="date"
                ></v-text-field>
                <v-file-input
                  v-model="form.caronemot"
                  id="flmot1"
                  label="MOT Documents"
                  :rules="rules"
                  outlined
                  clearable
                  show-size
                  :error-messages="caronemotErrors"
                  accept="application/pdf"
                  placeholder="Select PDF File"
                  @change="onMot($event)"
                  @input="$v.form.caronemot.$touch()"
                  @blur="$v.form.caronemot.$touch()"
                ></v-file-input>
                <v-text-field
                  v-model="form.caronemotexp"
                  name="motexp"
                  label="MOT Expiry Date"
                  outlined
                  required
                  :error-messages="caronemotexpErrors"
                  @input="$v.form.caronemotexp.$touch()"
                  @blur="$v.form.caronemotexp.$touch()"
                  type="date"
                ></v-text-field>
                <v-file-input
                  v-model="form.caroneinsurance"
                  id="flinsurance1"
                  label="Insurance Documents"
                  :rules="rules"
                  outlined
                  clearable
                  show-size
                  :error-messages="caroneinsuranceErrors"
                  accept="application/pdf"
                  placeholder="Select PDF File"
                  @change="onInsurance($event)"
                  @input="$v.form.caroneinsurance.$touch()"
                  @blur="$v.form.caroneinsurance.$touch()"
                ></v-file-input>

                <v-text-field
                  v-model="form.caroneinsuranceexp"
                  name="insuranceexp"
                  label="Insurance Expiry Date"
                  outlined
                  required
                  :error-messages="caroneinsuranceexpErrors"
                  @input="$v.form.caroneinsuranceexp.$touch()"
                  @blur="$v.form.caroneinsuranceexp.$touch()"
                  type="date"
                ></v-text-field></v-col
            ></v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success darken-1"
            text
            @click="saveChanges()"
            :disabled="$v.form.$invalid"
          >
            Add Car
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  GET_FUEL_TYPES,
  GET_VEHICLE_TYPES,
  GET_VEHICLE_OWNER_TYPES,
  M_DD,
  M_ADI,
  SAVE_ADI_NEW_CAR,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "AddCar",
  props: ["basicObj"],
  data() {
    return {
      dialog: false,
      rules: [
        (value) =>
          !value ||
          value.size < 4000000 ||
          "PDF Document file size should be less than 4 MB!",
      ],
      gears: [
        { id: "automatic", txt: "Automatic" },
        { id: "manual", txt: "Manual" },
      ],
      dualCont: [
        { id: 2, txt: "No" },
        { id: 1, txt: "Yes" },
      ],

      form: {
        adiid: null, //readonly
        caronemake: "",
        caronemodel: "",
        caronecarcolour: "",
        caroneregno: "",
        caroneregyear: "",
        caronecartype: "",
        caronecargeartype: "",
        caronefueltype: "",
        caroneleagalowner: "",

        //dual ctrl and owner questions
        caronedualcontrol: "",
        caroneownertype: "",

        caronelogbook: null,
        caronetaxexp: "",
        caronemot: null,
        caronemotexp: "",
        caroneinsurance: null,
        caroneinsuranceexp: "",
      },
      fls: {
        fllogbook1: "",
        flmot1: "",
        flinsurance1: "",
      },
    };
  },
  validations: {
    form: {
      caronemake: { required, maxLength: maxLength(30) },
      caronemodel: { required, maxLength: maxLength(30) },
      caronecarcolour: { required, maxLength: maxLength(16) },
      caroneregno: { required, maxLength: maxLength(8) },
      caroneregyear: { required },
      caronecartype: { required },
      caronecargeartype: { required },
      caronefueltype: { required },
      caroneleagalowner: { required, maxLength: maxLength(80) },

      caronelogbook: { required },
      caronetaxexp: { required },
      caronemot: { required },
      caronemotexp: { required },
      caroneinsurance: { required },

      caroneinsuranceexp: { required },
    },
  },
  computed: {
    ...mapGetters({
      fuelTypes: `${M_DD}${GET_FUEL_TYPES}`,
      vehicleTypes: `${M_DD}${GET_VEHICLE_TYPES}`,
      vehicleOwnerTypes: `${M_DD}${GET_VEHICLE_OWNER_TYPES}`,
    }),
    //method to remove firstelement
    clensedVT() {
      let temp = this.vehicleTypes.slice(0);
      //temp.shift();
      return temp.splice(1, temp.length);
    },
    // car one Validations
    caronemakeErrors() {
      const errors = [];
      if (!this.$v.form.caronemake.$dirty) return errors;
      !this.$v.form.caronemake.required &&
        errors.push("Vehicle Make is required.");
      !this.$v.form.caronemake.maxLength &&
        errors.push("Vehicle Make is too long. Max 30 characters allowed.");
      return errors;
    },
    caronemodelErrors() {
      const errors = [];
      if (!this.$v.form.caronemodel.$dirty) return errors;
      !this.$v.form.caronemodel.required &&
        errors.push("Vehicle Model is required.");
      !this.$v.form.caronemodel.maxLength &&
        errors.push(
          "Vehicle Model name is too long. Max 30 characters allowed."
        );
      return errors;
    },
    caronecarcolourErrors() {
      const errors = [];
      if (!this.$v.form.caronecarcolour.$dirty) return errors;
      !this.$v.form.caronecarcolour.required &&
        errors.push("Vehicle Colour is required.");
      !this.$v.form.caronecarcolour.maxLength &&
        errors.push("Vehicle Colour is too long. Max 16 characters allowed.");
      return errors;
    },
    caroneregnoErrors() {
      const errors = [];
      if (!this.$v.form.caroneregno.$dirty) return errors;
      !this.$v.form.caroneregno.required &&
        errors.push("Vehicle Registration Number is required.");
      !this.$v.form.caroneregno.maxLength &&
        errors.push(
          "Vehicle Registration Number is too long. Max 8 characters allowed."
        );
      return errors;
    },
    caroneregyearErrors() {
      const errors = [];
      if (!this.$v.form.caroneregyear.$dirty) return errors;
      !this.$v.form.caroneregyear.required &&
        errors.push("Vehicle Registration Year is required.");
      return errors;
    },
    caronecartypeErrors() {
      const errors = [];
      if (!this.$v.form.caronecartype.$dirty) return errors;
      !this.$v.form.caronecartype.required &&
        errors.push("Vehicle Type is required.");
      return errors;
    },
    caronecargeartypeErrors() {
      const errors = [];
      if (!this.$v.form.caronecargeartype.$dirty) return errors;
      !this.$v.form.caronecargeartype.required &&
        errors.push("Vehicle Gear Type is required.");
      return errors;
    },
    caronefueltypeErrors() {
      const errors = [];
      if (!this.$v.form.caronefueltype.$dirty) return errors;
      !this.$v.form.caronefueltype.required &&
        errors.push("Vehicle Fuel Type is required.");
      return errors;
    },
    caroneleagalownerErrors() {
      const errors = [];
      if (!this.$v.form.caroneleagalowner.$dirty) return errors;
      !this.$v.form.caroneleagalowner.required &&
        errors.push(
          "Full Name as it appears on the V5C Registration Document."
        );
      !this.$v.form.caroneleagalowner.maxLength &&
        errors.push(
          "Leagal Owner Name is too long. Max 80 characters allowed."
        );
      return errors;
    },
    caronelogbookErrors() {
      const errors = [];
      if (!this.$v.form.caronelogbook.$dirty) return errors;
      !this.$v.form.caronelogbook.required &&
        errors.push("Vehicle V5C is required.");
      return errors;
    },
    caronetaxexpErrors() {
      const errors = [];
      if (!this.$v.form.caronetaxexp.$dirty) return errors;
      !this.$v.form.caronetaxexp.required &&
        errors.push("Vehicle VED Expiry Date is required.");
      return errors;
    },
    caronemotErrors() {
      const errors = [];
      if (!this.$v.form.caronemot.$dirty) return errors;
      !this.$v.form.caronemot.required &&
        errors.push("Vehicle MOT is required.");
      return errors;
    },
    caronemotexpErrors() {
      const errors = [];
      if (!this.$v.form.caronemotexp.$dirty) return errors;
      !this.$v.form.caronemotexp.required &&
        errors.push("Vehicle MOT Expiry Date is required.");
      return errors;
    },
    caroneinsuranceErrors() {
      const errors = [];
      if (!this.$v.form.caroneinsurance.$dirty) return errors;
      !this.$v.form.caroneinsurance.required &&
        errors.push("Vehicle Insurance Document required.");
      return errors;
    },
    caroneinsuranceexpErrors() {
      const errors = [];
      if (!this.$v.form.caroneinsuranceexp.$dirty) return errors;
      !this.$v.form.caroneinsuranceexp.required &&
        errors.push("Vehicle Insurance Expiry Date is required.");
      return errors;
    },
    caroneownerMsg() {
      if (!this.vehicleOwnerTypes || !this.form.caroneownertype) {
        return "";
      } else {
        let v = this.vehicleOwnerTypes.find(
          (row) => row.type_id === this.form.caroneownertype
        );
        //console.log(v);
        return v.message;
      }
    },
  },
  methods: {
    ...mapActions({
      saveNewCar: `${M_ADI}${SAVE_ADI_NEW_CAR}`,
    }),
    saveChanges() {
      //reset on success
      //this.dialog = false;
      let sendObj = {
        id: this.form.adiid,
        form: {
          make: this.form.caronemake,
          model: this.form.caronemodel,
          carcolour: this.form.caronecarcolour,
          regno: this.form.caroneregno,
          regyear: this.form.caroneregyear,
          cartype: this.form.caronecartype,
          cargeartype: this.form.caronecargeartype,
          fueltype: this.form.caronefueltype,
          leagalowner: this.form.caroneleagalowner,
          dualcontrol: this.form.caronedualcontrol,
          carownertype: this.form.caroneownertype,

          taxexp: this.form.caronetaxexp,
          motexp: this.form.caronemotexp,
          insuranceexp: this.form.caroneinsuranceexp,
        },
        upfiles: {
          fllogbook1: this.fls.fllogbook1,
          flmot1: this.fls.flmot1,
          flinsurance1: this.fls.flinsurance1,
        },
      };
      this.saveNewCar(sendObj).then((rep) => {
        if (rep == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "New vehicle added successfully. Local data will updated on next refresh.",
            type: "success",
            //timer: 3000,
            allowOutsideClick: false,
          }).then((r) => {
            this.dialog = false;
            console.log(r.value);
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error add new vehicle. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
    onLogbook(e) {
      this.fls.fllogbook1 = e;
    },
    onMot(e) {
      this.fls.flmot1 = e;
    },
    onInsurance(e) {
      this.fls.flinsurance1 = e;
    },
  },
  mounted() {
    this.form.adiid = this.basicObj;
  },
};
</script>

<style></style>
