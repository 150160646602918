<template>
  <div>
    <h2>{{ title }}</h2>
    <div class="subtitle-1">{{ SubTitle }}</div>
    <v-card>
      <v-card-title>
        <v-text-field
          v-if="isSearch"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        @click:row="selected"
        :headers="headers"
        :items="dataarray"
        :search="search"
        :loading="isLoading"
      >
        <template v-slot:[`item.student_id`]="{ item }">
          <strong>{{ "L" + item.student_id }}</strong>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          {{ `${item.title} ${item.fname} ${item.lname}` }}
        </template>
        <template v-slot:[`item.mobile`]="{ item }">
          {{ item.mobile | mobileno }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "LearnerTable",
  props: ["title", "SubTitle", "dataarray", "isLoading", "isSearch"],
  data() {
    return {
      search: "",
      headers: [
        { text: "Learner ID", value: "student_id" },
        { text: "Name", value: "name" },
        { text: "Mobile", value: "mobile" },
        { text: "E-mail", value: "email" },
        { text: "Pickup Postcode", value: "pickup_pc" },
        { text: "Date Joined", value: "created" },
      ],
    };
  },
  methods: {
    selected(item) {
      this.$emit("choosen", { record: item });
    },
  },
};
</script>

<style></style>
