<template>
  <v-icon v-if="vals == 0" color="primary" large> $expand </v-icon>
  <v-icon v-else-if="vals == 1" color="success" large> mdi-check </v-icon>
  <v-icon v-else color="error" large> mdi-alert-circle </v-icon>
</template>

<script>
export default {
  name: "IconProvider",
  props: ["vals"],
};
</script>

<style></style>
