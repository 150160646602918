<template>
  <v-app dark
    ><v-main class="bg">
      <v-container fill-height>
        <v-card class="mx-auto my-auto" width="800"
          ><div class="text-center display-1 ma-5">
            <v-img
              class="my-10"
              src="../assets/img/__becodrivelogo.svg"
              contain
              height="100"
            ></v-img>
            Licence Activation & Administrator Setup
          </div>
          <v-divider></v-divider>
          <v-card-title justify="center" class="text-center"></v-card-title>
          <v-card-text
            ><v-form :disabled="isFormLocked" ref="form">
              <v-row justify="center">
                <v-col md="12">
                  <v-select
                    v-model="form.title"
                    :items="titlesList"
                    item-text="txt"
                    item-value="id"
                    label="Title"
                    required
                    :error-messages="titleErrors"
                    @input="$v.form.title.$touch()"
                    @blur="$v.form.title.$touch()"
                  >
                  </v-select>
                  <v-text-field
                    v-model="form.fname"
                    :counter="40"
                    label="First Name"
                    required
                    :error-messages="fnameErrors"
                    @input="$v.form.fname.$touch()"
                    @blur="$v.form.fname.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="form.mname"
                    :counter="120"
                    label="Middle Name(s)"
                    :error-messages="mnameErrors"
                    @input="$v.form.mname.$touch()"
                    @blur="$v.form.mname.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="form.lname"
                    :counter="40"
                    label="Surname"
                    required
                    :error-messages="lnameErrors"
                    @input="$v.form.lname.$touch()"
                    @blur="$v.form.lname.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="form.mobile"
                    :counter="11"
                    label="Mobile Number"
                    required
                    :error-messages="mobileErrors"
                    @input="$v.form.mobile.$touch()"
                    @blur="$v.form.mobile.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="form.inemail"
                    label="E-mail"
                    :counter="60"
                    required
                    :error-messages="emailErrors"
                    @input="$v.form.inemail.$touch()"
                    @blur="$v.form.inemail.$touch()"
                  ></v-text-field>
                  <!-- <v-header>Home Address</v-header> -->
                  <v-text-field
                    v-model="form.password"
                    :counter="50"
                    label="Password"
                    type="password"
                    required
                    :error-messages="passwordErrors"
                    @input="$v.form.password.$touch()"
                    @blur="$v.form.password.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="form.licence"
                    :counter="29"
                    label="Licence Key"
                    :error-messages="licenceErrors"
                    @input="$v.form.licence.$touch()"
                    @blur="$v.form.licence.$touch()"
                    v-mask="`XXXXX-XXXXX-XXXXX-XXXXX-XXXXX`"
                    style="text-transform: uppercase"
                    type="text"
                  ></v-text-field>

                  <v-alert
                    class="mt-2"
                    text
                    prominent
                    type="error"
                    icon="mdi-alert-octagram"
                    v-if="$v.$dirty && this.$v.$invalid"
                  >
                    Please complete all required fields.
                  </v-alert>
                  <v-alert
                    class="mt-2"
                    text
                    prominent
                    type="info"
                    icon="mdi-alert-octagram"
                    v-if="isReplayMsg"
                  >
                    {{ ReplayMsgTxt }}
                  </v-alert>

                  <v-btn
                    color="primary"
                    class="mt-3"
                    @click="saveChanges"
                    :disabled="isLoading"
                    :loading="isLoading"
                    >Save Changes
                  </v-btn>
                </v-col>
              </v-row>
            </v-form></v-card-text
          >
        </v-card>
      </v-container>
    </v-main>
    <v-footer app="app">
      <v-container>
        <v-row no-gutters style="flex-wrap: nowrap">
          <v-col cols="4" class="flex-grow-0 flex-shrink-0">
            <div>
              Designed, Created and Developed by
              <a href="https://www.brandiie.com"> Brandiie</a>.<v-btn
                icon
                color="black"
                to="/"
                class="mx-10"
              >
                <v-icon large>mdi-home-lock</v-icon>
              </v-btn>
            </div></v-col
          >
          <v-col
            cols="4"
            style="min-width: 100px; max-width: 100%"
            class="flex-grow-1 flex-shrink-0"
            ><div></div
          ></v-col>
          <v-col
            cols="4"
            style="min-width: 100px"
            class="flex-grow-0 flex-shrink-1"
          >
            <div>
              © Copyrights 2016 - {{ new Date().getFullYear() }} Brandiie
              <sup>®</sup>™ and BECO Drive <sup>®</sup>™. All Rights Reserved.
            </div></v-col
          >
        </v-row>
      </v-container>
      <!-- <v-flex class="text-xs-center"
        >
        <v-spacer></v-spacer></v-flex
      > -->
    </v-footer>
  </v-app>
</template>

<script>
import {
  M_DD,
  GET_TITLES,
  M_OA,
  ACTIVATE_LICENCE,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import {
  required,
  alpha,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
export default {
  name: "AccountSetup",
  directives: { mask },
  data: () => ({
    form: {
      title: "",
      fname: "",
      mname: "",
      lname: "",
      mobile: "",
      inemail: "",
      password: "",
      licence: "",
    },
    isFormLocked: false,
    isLoading: false,
    isReplayMsg: false,
    ReplayMsgTxt: "",
  }),
  validations: {
    form: {
      title: {
        required,
      },
      fname: {
        required,
        alpha,
        maxLength: maxLength(40),
      },
      mname: {
        alpha,
        maxLength: maxLength(120),
      },
      lname: {
        required,
        alpha,
        maxLength: maxLength(40),
      },
      mobile: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },
      inemail: {
        required,
        email,
        maxLength: maxLength(60),
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(30),
      },
      licence: {
        required,
        minLength: minLength(29),
        maxLength: maxLength(29),
      },
    },
  },
  computed: {
    ...mapGetters({
      titlesList1: `${M_DD}${GET_TITLES}`,
    }),
    titlesList() {
      return [
        { id: "Baron", txt: "Baron" },
        { id: "Dr", txt: "Dr" },
        { id: "Duke", txt: "Duke" },
        { id: "Lady", txt: "Lady" },
        { id: "Lord", txt: "Lord" },
        { id: "Madame", txt: "Madame" },
        { id: "Master", txt: "Master" },
        { id: "Miss", txt: "Miss" },
        { id: "Mr", txt: "Mr" },
        { id: "Mrs", txt: "Mrs" },
        { id: "Ms", txt: "Ms" },
        { id: "Prof.", txt: "Prof." },
        { id: "Sir", txt: "Sir" },
      ];
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.form.title.$dirty) return errors;
      !this.$v.form.title.required && errors.push("Title is required.");
      return errors;
    },
    fnameErrors() {
      const errors = [];
      if (!this.$v.form.fname.$dirty) return errors;
      !this.$v.form.fname.maxLength &&
        errors.push("Name must be at most 40 characters long");
      !this.$v.form.fname.required && errors.push("Name is required.");

      return errors;
    },
    mnameErrors() {
      const errors = [];
      if (!this.$v.form.mname.$dirty) return errors;
      !this.$v.form.mname.maxLength &&
        errors.push("Name must be at most 120 characters long");
      !this.$v.form.mname.alpha && errors.push("Only letters are allowed.");
      return errors;
    },
    lnameErrors() {
      const errors = [];
      if (!this.$v.form.lname.$dirty) return errors;
      !this.$v.form.lname.maxLength &&
        errors.push("Name must be at most 40 characters long");
      !this.$v.form.lname.required && errors.push("Name is required.");
      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (!this.$v.form.mobile.$dirty) return errors;
      !this.$v.form.mobile.maxLength &&
        errors.push("Mobile number can not be more than 11 digits");
      !this.$v.form.mobile.minLength &&
        errors.push("Mobile number can not be less than 9 digits");
      !this.$v.form.mobile.numeric && errors.push("Only numbers are allowed.");
      !this.$v.form.mobile.required &&
        errors.push("Mobile number is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.inemail.$dirty) return errors;
      !this.$v.form.inemail.maxLength &&
        errors.push("E-mail address is too long. Max 60 characters allowed.");
      !this.$v.form.inemail.email && errors.push("Invalid e-mail address");
      !this.$v.form.inemail.required && errors.push("E-mail is required.");

      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.maxLength &&
        errors.push("Password is too long. Max 60 characters allowed.");
      !this.$v.form.password.required && errors.push("Password is required.");

      return errors;
    },
    licenceErrors() {
      const errors = [];
      if (!this.$v.form.licence.$dirty) return errors;
      !this.$v.form.licence.maxLength &&
        errors.push("Licence Key is too long. Max 29 characters allowed.");
      !this.$v.form.licence.required && errors.push("Licence Key is reqired.");

      return errors;
    },
  },
  methods: {
    ...mapActions({
      activateLicence: `${M_OA}${ACTIVATE_LICENCE}`,
    }),
    saveChanges() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        let self = this;
        this.digestMessage(this.password).then(function (outP) {
          console.log(outP);
          let frm = {
            form: {
              title: self.form.title,
              fname: self.form.fname,
              mname: self.form.mname,
              lname: self.form.lname,
              mobile: self.form.mobile,
              inemail: self.form.inemail,
              licence: self.form.licence.replace("-", ""),
              p: outP,
            },
          };
          self
            .activateLicence(frm)
            .then((replay) => {
              console.log(replay);
              if (replay.result.code != 0) {
                self.ReplayMsgTxt = replay.result.text;
                self.isReplayMsg = true;
              }
            })
            .finally(() => {
              self.isLoading = false;
            });
        });
      }
      console.log("Time to save changes");
    },
    async digestMessage(message) {
      const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
      const hashBuffer = await crypto.subtle.digest("SHA-512", msgUint8); // hash the message
      const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
      const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join(""); // convert bytes to hex string
      return hashHex;
    },
  },
};
</script>

<style scoped>
.header {
  position: relative;
  height: 450px;
  background-image: url("../assets/key-rs.jpg");
  /* background-image: linear-gradient(#ff9d2f, #ff6126); */
}

h1 {
  padding: 100px 0;
  font: 44px "Arial";
  text-align: center;
}

.header h1 {
  color: white;
}

svg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
}
.bg {
  /* background: url("../assets/logbg.jpg") no-repeat center center fixed !important; */
  background: url("../assets/actbg.png") no-repeat center center fixed !important;
  background-size: cover;
}
</style>
