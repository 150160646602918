<template>
  <div>
    <v-card class="point" @click="dialog = true" v-if="basicObj">
      <v-card-title
        class="text-h6 grey lighten-2"
        style="justify-content: center"
      >
        Update Address &amp; Documents
      </v-card-title>
      <v-img
        :src="require(`@/assets/img/updateinfo/uploadditdocs.webp`)"
        max-height="300"
        contain
        class="grey lighten-3 pa-5"
      ></v-img>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title>
            <span class="text-h5"
              >Update Instructor Information With New Address</span
            >
          </v-card-title>
        </v-toolbar>
        <v-card-text style="height: 70vh">
          <v-container>
            <v-row>
              <v-col>
                <span>Permanent Home Address</span>
                <v-text-field
                  v-model="form.pafline"
                  name="pafline"
                  outlined
                  :counter="50"
                  label="Address Line 1"
                  required
                  :error-messages="paflineErrors"
                  @input="$v.form.pafline.$touch()"
                  @blur="$v.form.pafline.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.pasline"
                  name="pasline"
                  outlined
                  :counter="50"
                  label="Address Line 2"
                  :error-messages="paslineErrors"
                  @input="$v.form.pasline.$touch()"
                  @blur="$v.form.pasline.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.pacity"
                  name="pacity"
                  outlined
                  :counter="50"
                  label="City"
                  required
                  :error-messages="pacityErrors"
                  @input="$v.form.pacity.$touch()"
                  @blur="$v.form.pacity.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.pacounty"
                  name="pacounty"
                  outlined
                  :counter="50"
                  label="County"
                  :error-messages="pacountyErrors"
                  @input="$v.form.pacounty.$touch()"
                  @blur="$v.form.pacounty.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.papostcode"
                  name="papostcode"
                  outlined
                  :counter="9"
                  label="Postcode"
                  style="width: 50%"
                  required
                  :error-messages="papostcodeErrors"
                  @input="$v.form.papostcode.$touch()"
                  @blur="$v.form.papostcode.$touch()"
                ></v-text-field>
                <v-divider></v-divider>
                <span>Current Address</span>
                <v-text-field
                  v-model="form.cufline"
                  name="cufline"
                  outlined
                  :counter="50"
                  label="Address Line 1"
                  required
                  :error-messages="cuflineErrors"
                  @input="$v.form.cufline.$touch()"
                  @blur="$v.form.cufline.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.cusline"
                  name="cusline"
                  outlined
                  :counter="50"
                  label="Address Line 2"
                  :error-messages="cuslineErrors"
                  @input="$v.form.cusline.$touch()"
                  @blur="$v.form.cusline.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.cucity"
                  name="cucity"
                  outlined
                  :counter="50"
                  label="City"
                  required
                  :error-messages="cucityErrors"
                  @input="$v.form.cucity.$touch()"
                  @blur="$v.form.cucity.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.cucounty"
                  name="cucounty"
                  outlined
                  :counter="50"
                  label="County"
                  :error-messages="cucountyErrors"
                  @input="$v.form.cucounty.$touch()"
                  @blur="$v.form.cucounty.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.cupostcode"
                  name="cupostcode"
                  outlined
                  :counter="9"
                  label="Postcode"
                  style="width: 50%"
                  required
                  :error-messages="cupostcodeErrors"
                  @input="$v.form.cupostcode.$touch()"
                  @blur="$v.form.cupostcode.$touch()"
                ></v-text-field>

                <v-file-input
                  v-model="form.addressProf1"
                  id="poadoc1"
                  label="Proof of Address 1"
                  :rules="rules"
                  outlined
                  clearable
                  show-size
                  :error-messages="addressProf1Errors"
                  accept="application/pdf"
                  placeholder="Select PDF File"
                  @change="onPoadoc1($event)"
                  @input="$v.form.addressProf1.$touch()"
                  @blur="$v.form.addressProf1.$touch()"
                ></v-file-input>
                <v-file-input
                  v-model="form.addressProf2"
                  id="poadoc2"
                  label="Proof of Address 2"
                  :rules="rules"
                  outlined
                  clearable
                  show-size
                  :error-messages="addressProf2Errors"
                  accept="application/pdf"
                  placeholder="Select PDF File"
                  @change="onPoadoc2($event)"
                  @input="$v.form.addressProf2.$touch()"
                  @blur="$v.form.addressProf2.$touch()"
                ></v-file-input>
                <v-file-input
                  v-model="form.licenceCopy"
                  id="fldl"
                  label="Driving Licence"
                  :rules="rules"
                  outlined
                  clearable
                  show-size
                  :error-messages="licenceCopyErrors"
                  accept="application/pdf"
                  placeholder="Select PDF File"
                  @change="onFldl($event)"
                  @input="$v.form.licenceCopy.$touch()"
                  @blur="$v.form.licenceCopy.$touch()"
                ></v-file-input>
                <v-text-field
                  v-model="form.licenceexp"
                  label="Driving Licence Expiry Date"
                  name="licenceexp"
                  outlined
                  required
                  :error-messages="licenceexpErrors"
                  @input="$v.form.licenceexp.$touch()"
                  @blur="$v.form.licenceexp.$touch()"
                  type="date"
                ></v-text-field>
                <v-file-input
                  v-model="form.adilicenceCopy"
                  id="fladil"
                  label="ADI/PDI Licence"
                  :rules="rules"
                  outlined
                  clearable
                  show-size
                  :error-messages="adilicenceCopyErrors"
                  accept="application/pdf"
                  placeholder="Select PDF File"
                  @change="onFladil($event)"
                  @input="$v.form.adilicenceCopy.$touch()"
                  @blur="$v.form.adilicenceCopy.$touch()"
                ></v-file-input>
                <v-text-field
                  v-model="form.adilicenceexp"
                  name="adilicenceexp"
                  label="ADI/PDI Licence Expiry Date"
                  outlined
                  required
                  :error-messages="adilicenceexpErrors"
                  @input="$v.form.adilicenceexp.$touch()"
                  @blur="$v.form.adilicenceexp.$touch()"
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning darken-1"
            text
            @click="saveChanges()"
            :disabled="$v.form.$invalid"
          >
            Update Address
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { M_ADI, SAVE_ADI_COMPLIANCE_DOCS } from "@/store/mutation-list";
export default {
  name: "UpdateAddress",
  props: ["basicObj", "compliance"],
  data() {
    return {
      dialog: false,
      rules: [
        (value) =>
          !value ||
          value.size < 4000000 ||
          "Address proof file size should be less than 4 MB!",
      ],
      form: {
        adiid: null, //readonly
        //permaddr: "",
        pafline: "",
        pasline: "",
        pacity: "",
        pacounty: "",
        papostcode: "",

        //curaddr: "",
        cufline: "",
        cusline: "",
        cucity: "",
        cucounty: "",
        cupostcode: "",

        addressProf1: null,
        addressProf2: null,
        licenceCopy: null,

        licenceexp: "",
        adilicenceCopy: null,
        adilicenceexp: "",
      },
      fls: {
        poadoc1: null,
        poadoc2: null,
        fldl: null,
        fladil: null,
      },
    };
  },
  validations: {
    form: {
      pafline: { required, maxLength: maxLength(50) },
      pasline: { maxLength: maxLength(50) },
      pacity: { required, maxLength: maxLength(50) },
      pacounty: { maxLength: maxLength(50) },
      papostcode: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(9),
      },
      cufline: { required, maxLength: maxLength(50) },
      cusline: { maxLength: maxLength(50) },
      cucity: { required, maxLength: maxLength(50) },
      cucounty: { maxLength: maxLength(50) },
      cupostcode: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(8),
      },
      addressProf1: { required },
      addressProf2: { required },

      licenceCopy: { required },
      licenceexp: { required },
      adilicenceCopy: { required },
      adilicenceexp: { required },
    },
  },
  computed: {
    paflineErrors() {
      const errors = [];
      if (!this.$v.form.pafline.$dirty) return errors;
      !this.$v.form.pafline.maxLength &&
        errors.push("Address Line 1 is too long. Max 50 characters allowed.");
      !this.$v.form.pafline.required &&
        errors.push("Address Line 1 is required.");

      return errors;
    },
    paslineErrors() {
      const errors = [];
      if (!this.$v.form.pasline.$dirty) return errors;
      !this.$v.form.pasline.maxLength &&
        errors.push("Address Line 2 is too long. Max 60 characters allowed.");

      return errors;
    },
    pacityErrors() {
      const errors = [];
      if (!this.$v.form.pacity.$dirty) return errors;
      !this.$v.form.pacity.maxLength &&
        errors.push("City name is too long. Max 60 characters allowed.");
      !this.$v.form.pacity.required && errors.push("City  is required.");

      return errors;
    },
    pacountyErrors() {
      const errors = [];
      if (!this.$v.form.pacounty.$dirty) return errors;
      !this.$v.form.pacounty.maxLength &&
        errors.push("County name is too long. Max 60 characters allowed.");

      return errors;
    },
    papostcodeErrors() {
      const errors = [];
      if (!this.$v.form.papostcode.$dirty) return errors;
      !this.$v.form.papostcode.minLength &&
        errors.push("Postcode is too short. Min 6 characters required.");
      !this.$v.form.papostcode.maxLength &&
        errors.push("Postcode is too long. Max 9 characters allowed.");
      !this.$v.form.papostcode.required &&
        errors.push("Postcode  is required.");

      return errors;
    },

    cuflineErrors() {
      const errors = [];
      if (!this.$v.form.cufline.$dirty) return errors;
      !this.$v.form.cufline.maxLength &&
        errors.push("Address Line 1 is too long. Max 50 characters allowed.");
      !this.$v.form.cufline.required &&
        errors.push("Address Line 1 is required.");

      return errors;
    },
    cuslineErrors() {
      const errors = [];
      if (!this.$v.form.cusline.$dirty) return errors;
      !this.$v.form.cusline.maxLength &&
        errors.push("Address Line 2 is too long. Max 60 characters allowed.");

      return errors;
    },
    cucityErrors() {
      const errors = [];
      if (!this.$v.form.cucity.$dirty) return errors;
      !this.$v.form.cucity.maxLength &&
        errors.push("City name is too long. Max 60 characters allowed.");
      !this.$v.form.cucity.required && errors.push("City  is required.");

      return errors;
    },
    cucountyErrors() {
      const errors = [];
      if (!this.$v.form.cucounty.$dirty) return errors;
      !this.$v.form.cucounty.maxLength &&
        errors.push("County name is too long. Max 60 characters allowed.");

      return errors;
    },
    cupostcodeErrors() {
      const errors = [];
      if (!this.$v.form.cupostcode.$dirty) return errors;
      !this.$v.form.cupostcode.minLength &&
        errors.push("Postcode is too short. Min 6 characters required.");
      !this.$v.form.cupostcode.maxLength &&
        errors.push("Postcode is too long. Max 9 characters allowed.");
      !this.$v.form.cupostcode.required &&
        errors.push("Postcode  is required.");

      return errors;
    },
    addressProf1Errors() {
      const errors = [];
      if (!this.$v.form.addressProf1.$dirty) return errors;
      !this.$v.form.addressProf1.required &&
        errors.push("Address Proof 1 is required.");

      return errors;
    },
    addressProf2Errors() {
      const errors = [];
      if (!this.$v.form.addressProf2.$dirty) return errors;
      !this.$v.form.addressProf2.required &&
        errors.push("Address Proof 2 is required.");

      return errors;
    },
    licenceCopyErrors() {
      const errors = [];
      if (!this.$v.form.licenceCopy.$dirty) return errors;
      !this.$v.form.licenceCopy.required &&
        errors.push("Copy of Driving Licence is required.");
      return errors;
    },
    licenceexpErrors() {
      const errors = [];
      if (!this.$v.form.licenceexp.$dirty) return errors;
      !this.$v.form.licenceexp.required &&
        errors.push("Driving Licence Expiry Day required.");
      return errors;
    },
    adilicenceCopyErrors() {
      const errors = [];
      if (!this.$v.form.adilicenceCopy.$dirty) return errors;
      !this.$v.form.adilicenceCopy.required &&
        errors.push("Copy of ADI/PDI Licence is required.");
      return errors;
    },
    adilicenceexpErrors() {
      const errors = [];
      if (!this.$v.form.adilicenceexp.$dirty) return errors;
      !this.$v.form.adilicenceexp.required &&
        errors.push("ADI/PDI Licence Expiry Day required.");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      saveNewAddress: `${M_ADI}${SAVE_ADI_COMPLIANCE_DOCS}`,
    }),
    saveChanges() {
      this.saveNewAddress({
        id: this.form.adiid,
        complianceid: this.compliance,
        form: this.form,
        upfiles: this.fls,
      }).then((rep) => {
        if (rep == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Address documents uploaded successfully. Local data will updated on next refresh.",
            type: "success",
            //timer: 3000,
            allowOutsideClick: false,
          }).then((r) => {
            this.dialog = false;
            console.log(r.value);
            //clearing form
            this.$v.form.$reset();
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error uploading address document. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
      //need to unmask sortcode
      this.dialog = false;
      /*

      let fd = new FormData(updateadiadvance);
            fd.append("updateadvanceadiinfo",this.adiainfo.adiid);
            fd.append("compid",this.adiainfo.complianceid);
            let self = this;

            axios  
                .post(serverpathou,fd)
                .then(function (response){
                    let reply = response.data;

                    if(typeof reply.result.code != 'undefined'){
                        if(reply.result.code == 1){
                            window.location.reload();
                        }else{
                            self.isErrorAlert = true;
                            self.errorMSG = reply.result.text;

                        }
                    }
                    
                })
                .catch(function (error){
                    self.isErrorAlert = true;
                    self.errorMSG = "Error submitting data. Please try again later";
                })

      */
    },
    onPoadoc1(e) {
      this.fls.poadoc1 = e;
    },
    onPoadoc2(e) {
      this.fls.poadoc2 = e;
    },
    onFldl(e) {
      this.fls.fldl = e;
    },
    onFladil(e) {
      this.fls.fladil = e;
    },
  },
  mounted() {
    this.form.adiid = this.basicObj;
  },
};
</script>

<style></style>
