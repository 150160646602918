<template>
  <v-col cols="12" sm="6" md="4" lg="4" xl="4">
    <v-card class="pa-1 text-center" outlined tile elevation="2">
      <v-card-title class="grey lighten-4" style="flex-direction: column">
        <span class="text-h6 text-center" v-html="getLongDate()"></span>
      </v-card-title>
      <v-img
        :src="getImgUrl('tasks.webp')"
        height="250px"
        contain
        class="ma-2"
      ></v-img>
      <v-list class="pa-0">
        <v-divider></v-divider>
        <v-list-item link to="/dashboard/viewactivecomplaints">
          <v-list-item-content>
            <v-list-item-title>
              <v-badge
                :content="actCompTot"
                :value="actCompTot"
                color="red darken-1"
                inline
                style="margin-top: -16px"
                ><span>Active Complaints</span></v-badge
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link to="/dashboard/pendinginstructors">
          <v-list-item-content>
            <v-list-item-title>
              <v-badge
                :content="pendAdiTot"
                :value="pendAdiTot"
                color="red darken-1"
                inline
                style="margin-top: -16px"
                ><span>View Pending Instructors</span></v-badge
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link to="/dashboard/assigninstructor">
          <v-list-item-content>
            <v-list-item-title>
              <v-badge
                :content="assignLearTot"
                :value="assignLearTot"
                color="red darken-1"
                inline
                style="margin-top: -16px"
                ><span>Assign Learners</span></v-badge
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link to="/dashboard/bookpendinglearners">
          <v-list-item-content>
            <v-list-item-title>
              <v-badge
                :content="pendBookTot"
                :value="pendBookTot"
                color="red darken-1"
                inline
                style="margin-top: -16px"
                ><span>Pending Bookings</span></v-badge
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import { M_OU, GET_NOTIFICATION_DATA } from "@/store/mutation-list";
export default {
  name: "TodaysTasks",
  computed: {
    ...mapGetters({
      notfData: `${M_OU}${GET_NOTIFICATION_DATA}`,
    }),
    actCompTot() {
      if (this.notfData) {
        return Number(this.notfData["tot_act_comps"]);
      } else {
        return 0;
      }
    },
    pendAdiTot() {
      if (this.notfData) {
        return Number(this.notfData["tot_pend_adis"]);
      } else {
        return 0;
      }
    },
    assignLearTot() {
      if (this.notfData) {
        return Number(this.notfData["tot_pend_learners"]);
      } else {
        return 0;
      }
    },
    pendBookTot() {
      if (this.notfData) {
        return Number(this.notfData["totl_pend_bookings"]);
      } else {
        return 0;
      }
    },
  },
  methods: {
    getImgUrl(pic) {
      return require("../assets/img/dash/" + pic);
    },
    getLongDate: function () {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const d = new Date();

      const dayName = days[d.getDay()];
      const monthName = months[d.getMonth()];
      const year = d.getFullYear();
      const date = d.getDate();

      return `Today's Tasks - <span class="font-italic" style="font-size: .8em"> ${dayName}, ${this.getSuffix(
        date
      )} ${monthName} ${year}</span>`;
    },
    getSuffix: function (dateNO) {
      dateNO += "";
      let lastDigit = dateNO.slice(-1);
      // let lastDigit = dateNO.charAt(dateNO.length -1);
      lastDigit = parseInt(lastDigit);
      if (parseInt(dateNO) <= 11 && parseInt(dateNO) >= 13) {
        return `${dateNO}<sup>th</sup>`;
      } else if (lastDigit == 1) {
        return `${dateNO}<sup>st</sup>`;
      } else if (lastDigit == 2) {
        return `${dateNO}<sup>nd</sup>`;
      } else if (lastDigit == 3) {
        return `${dateNO}<sup>rd</sup>`;
      } else {
        return `${dateNO}<sup>th</sup>`;
      }
    },
  },
};
</script>

<style></style>
