<template>
  <div>
    <v-card class="point" @click="dialog = true" v-if="basicObj">
      <v-card-title
        class="text-h6 grey lighten-2"
        style="justify-content: center"
      >
        Edit Covering Areas
      </v-card-title>
      <v-img
        :src="require(`@/assets/img/updateinfo/coareas.webp`)"
        max-height="300"
        contain
        class="grey lighten-3 pa-5"
      ></v-img>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title>
            <span class="text-h5">Update Covering Postcodes</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text style="height: 70vh">
          <v-container>
            <v-row>
              <v-col>
                <v-combobox
                  v-model="form.coveringareas"
                  name="coveringareas[]"
                  label="Covering Postcodes"
                  multiple
                  outlined
                  chips
                  placeholder="Add covering postcodes"
                  hint="Please enter outer postcode only. E.g: 'E7'. Press <Enter> key to add next postcode."
                  persistent-hint
                  :error-messages="coveringareasErrors"
                  @input="$v.form.coveringareas.$touch()"
                  @blur="$v.form.coveringareas.$touch()"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      <v-icon color="accent white--text" left
                        >mdi-map-marker
                      </v-icon>
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning darken-1"
            text
            @click="saveChanges()"
            :disabled="$v.form.$invalid"
          >
            Save Update
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { M_ADI, SAVE_ADI_COVERING_AREAS } from "@/store/mutation-list";

export default {
  name: "UpdateCoveringAreas",
  props: ["basicObj"],
  data() {
    return {
      dialog: false,
      form: {
        adiid: null, //readonly
        coveringareas: [],
      },
    };
  },
  validations: {
    form: {
      coveringareas: { required },
    },
  },
  computed: {
    coveringareasErrors() {
      const errors = [];
      if (!this.$v.form.coveringareas.$dirty) return errors;
      !this.$v.form.coveringareas.required &&
        errors.push("Covering Postcodes required.");
      return errors;
    },
    getSubmitArray() {
      if (this.form.coveringareas) {
        let temp = [];

        this.form.coveringareas.forEach((pc) => {
          temp.push(`${pc}`);
        });
        return temp;
      } else {
        return "[]";
      }
    },
  },
  methods: {
    ...mapActions({
      savePCs: `${M_ADI}${SAVE_ADI_COVERING_AREAS}`,
    }),
    saveChanges() {
      this.savePCs({
        id: this.form.adiid,
        pcs: this.form.coveringareas,
      }).then((rep) => {
        if (rep == "SUCCESS") {
          this.$fire({
            title: "Covering Postcodes Updated",
            text: "Covering postcodes updated successfully.",
            type: "success",
            //timer: 3000,
            allowOutsideClick: false,
          }).then((r) => {
            this.dialog = false;
            console.log(r.value);
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error updating covering postcodes. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
  },
  created() {
    this.form.adiid = this.basicObj.adiid;
    this.basicObj.coveringareas.forEach((area) => {
      this.form.coveringareas.push(area.pc);
    });
  },
};
</script>

<style></style>
