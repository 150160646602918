<template>
  <div class="mt-5">
    <h3 class="text-center">Summary Chart</h3>
    <GChart
      type="LineChart"
      :settings="{ packages: ['corechart'] }"
      :data="genGChart"
      :options="chartOptions"
      :title="chartOptions.title"
    />
  </div>
</template>
<script>
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "ComplaintsLineChart",
  components: { GChart },
  props: [
    "tableData",
    "chartTitle",
    "chartSubTitle",
    "dataTitles",
    "dataVals",
    "hAxisLable",
    "vAxisLable",
  ],
  data() {
    return {
      chartOptions: {
        chart: {
          title: this.chartTitle,
          subtitle: this.chartSubTitle,
        },
        legend: { topic: "top", position: "right", maxLines: 3 },
        // isStacked: "percent",
        height: 600,
        // curveType: "function",
        animation: {
          startup: true,
          duration: 2000,
          easing: "out",
        },
        vAxis: {
          title: this.vAxisLable,
          minValue: 1,
        },
        hAxis: {
          title: this.hAxisLable,
        },
        is3D: true,
        lineWidth: 4,
        pointSize: 8,
      },
    };
  },
  methods: {},
  computed: {
    genGChart() {
      //this will generate the whole line chart
      if (this.dataTitles && this.dataVals) {
        let arr = this.dataVals;
        arr.unshift(this.dataTitles);
        return arr;
      }
      return [];
    },
  },
};
</script>
