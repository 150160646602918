import axios from "axios";
import {
  ACTIVATE_LICENCE,
  REGISTER_STAFF,
  UPDATE_STAFF,
  BLOCK_STAFF,
  UNBLOCK_STAFF,
  DELETE_STAFF,
  GET_ADIS_FOR_APPROVAL,
  GET_ADIS_FOR_APPROVAL_LOADING,
  APPROVE_ADI,
  DISAPPROVE_ADI,
  REST_STAFF_PASS,
  GET_STAFF_TABLE,
  GET_STAFF_INFO,
  SET_FRANCHISE_PLAN,
  REMOVE_FRANCHISE_PLAN,
  SET_LESSON_PLAN,
  REMOVE_LESSON_PLAN,
  FULL_RESET,
  LOAD_OFFICE_STAFF,
  SET_QUICK_INFO,
  SET_OFFICE_STAFFS,
  SET_DIALOG_ACTIVE,
  SET_DIALOG_INACTIVE,
  SET_BACKGROUND_SYNC_ACTIVE,
  SET_BACKGROUND_SYNC_INACTIVE,
  SET_TANDC_DOC,
  GET_LEARNER_TC,
  GET_ADI_TC,
  GET_PDI_TC,
  GET_TRAINIG_PACK,
  LOAD_LEARNER_TC,
  LOAD_ADI_TC,
  LOAD_PDI_TC,
  LOAD_TRAINIG_PACK,
  DOWNLOAD_TC_DOC,
  SAVE_LEARNER_FEEDBACK_GROUP,
  SAVE_INSTRUCTOR_FEEDBACK_GROUP,
  LOAD_ALL_LEARNER_FEEDBACK_QUESTIONS,
  LOAD_ALL_INSTRUCTOR_FEEDBACK_QUESTIONS,
  GET_ALL_LEARNER_FEEDBACK_QUESTIONS,
  GET_ALL_INSTRUCTOR_FEEDBACK_QUESTIONS,
  SAVE_LEARNER_FEEDBACK_QUESTION,
  SAVE_INSTRUCTOR_FEEDBACK_QUESTION,
  DELETE_LEARNER_FEEDBACK_QUESTION,
  DELETE_INSTRUCTOR_FEEDBACK_QUESTION,
  M_COMMON,
} from "@/store/mutation-list.js";

export const officeAdmin = {
  namespaced: true,
  state: {
    officeStaff: [],
    learnerTandC: [],
    adiTandC: [],
    pdiTandC: [],
    trainingPack: [],
    learnerFeedbackQuestions: [],
    instructorFeedbackQuestions: [],
  },
  getters: {
    [GET_STAFF_TABLE]: (state) => {
      //return state.officeStaff;
      //console.log(state);
      /* let retObj = [];
      if (state.officeStaff.length > 1) {
        state.officeStaff.forEach((staff) => {
          let temp = {
            id: staff.staff_id,
            name: staff.title + " " + staff.fname + " " + staff.lname,
            mobile: staff.mobile,
            email: staff.email,
            accState: staff.accState,
          };
          retObj.push(temp);
        });
      }
      return retObj;*/
      return state.officeStaff.map((staff) => {
        const newOrder = {};
        newOrder["id"] = staff.staff_id;
        newOrder["name"] = `${staff.title} ${staff.fname} ${staff.lname}`;
        newOrder["mobile"] = staff.mobile;
        newOrder["email"] = staff.email;
        newOrder["accState"] = staff.accState;
        return newOrder;
      });
    },
    [GET_STAFF_INFO]: (state) => (id) => {
      return state.officeStaff.find((staff) => staff.staff_id === id);
    },
    [GET_ADIS_FOR_APPROVAL]: (state) => {
      return state.approvalPendingADIs.lst;
    },
    [GET_ADIS_FOR_APPROVAL_LOADING]: (state) => {
      return state.approvalPendingADIs.isLoaded;
    },
    [GET_LEARNER_TC]: (state) => state.learnerTandC,
    [GET_ADI_TC]: (state) => state.adiTandC,
    [GET_PDI_TC]: (state) => state.pdiTandC,
    [GET_TRAINIG_PACK]: (state) => state.trainingPack,
    [GET_ALL_LEARNER_FEEDBACK_QUESTIONS]: (state) =>
      state.learnerFeedbackQuestions,
    [GET_ALL_INSTRUCTOR_FEEDBACK_QUESTIONS]: (state) =>
      state.instructorFeedbackQuestions,
  },
  actions: {
    //this function submits the licence activation data
    [ACTIVATE_LICENCE](store, payload) {
      console.log(payload);
      //need to provide outer PC and student ID
      let indata = payload.form;
      console.log(indata);
      let fd = new FormData();
      fd.append("p", null); //p is the secure hashed password
      fd.append("title", indata.title);
      fd.append("fname", indata.fname);
      fd.append("mname", indata.mname);
      fd.append("lname", indata.lname);
      fd.append("mobile", indata.mobile);
      fd.append("inemail", indata.inemail);
      fd.append("licence", indata.licence);
      fd.append("setupaccount", "setupaccount");

      return axios.post("setup.php", fd).then(function (response) {
        let rep = response.data;
        //console.log(rep);
        return rep;
      });
    },
    [REGISTER_STAFF]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("addofficestafffrm", "addofficestafffrm");
      fd.append("mobile", payload.mobile);
      fd.append("inemail", payload.email);
      fd.append("title", payload.title);
      fd.append("fname", payload.fname);
      fd.append("mname", payload.mname);
      fd.append("lname", payload.lname);
      fd.append("staffp[]", payload.privlist);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SET_QUICK_INFO]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("actionquickdata", "actionquickdata");
      let indata = payload.frm;
      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SET_FRANCHISE_PLAN]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("addfranchiseplan", "addfranchiseplan");
      fd.append("initialfee", payload.initial_fee);
      fd.append("weeklyfee", payload.weekly_fee);
      fd.append("weeklyhours", payload.weekly_hours);
      fd.append("wortktype", payload.work_type);
      fd.append("promocode", payload.promo_code);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SET_LESSON_PLAN]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("addlessonplan", "addlessonplan");
      fd.append("desc", payload.desc);
      fd.append("price", payload.price);
      fd.append("hours", payload.hours);
      fd.append("gears", payload.gears);
      fd.append("promocode", payload.promocode);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_OFFICE_STAFF]({ commit, dispatch, getters, rootGetters }) {
      //need to set the background loaded
      //console.log("Loadding Office Staff");
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //do the data request
      //console.log("sending request");
      getters["M_AUTH/TOKEN"];
      const token = rootGetters["M_AUTH/TOKEN"];
      //console.log("Tokeb" + token);
      let fd = new FormData();
      fd.append("token", token);
      fd.append("loadOfficeStaffTable", "loadOfficeStaffTable");
      axios.post("ctroaV2.php", fd).then(function (response) {
        //console.log("Got a response");
        let res = response.data;
        commit("SET_OFFICE_STAFFS", res);
        //console.log(response.data);
      });
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
        root: true,
      });
      //stop the background loading
    },
    [UPDATE_STAFF]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("editofficestafffrm", payload.staff_id);
      fd.append("mobile", payload.mobile);
      fd.append("inemail", payload.email);
      fd.append("title", payload.title);
      fd.append("fname", payload.fname);
      fd.append("mname", payload.mname);
      fd.append("lname", payload.lname);
      fd.append("staffp[]", payload.privlist);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [BLOCK_STAFF]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("blockofficestaff", payload.staff_id);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [UNBLOCK_STAFF]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("unblockofficestaff", payload.staff_id);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [DELETE_STAFF]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("deleteofficestaff", payload.staff_id);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [REST_STAFF_PASS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("resetstaffpass", payload.staff_id);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },

    [APPROVE_ADI]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("approvePendingInstructor", payload.adi_id);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [DISAPPROVE_ADI]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("deletePendingInstructor", payload.adi_id);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [REMOVE_FRANCHISE_PLAN]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("deletefranchiseplan", payload.plan_id);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [REMOVE_LESSON_PLAN]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("deletelessonplan", payload.plan_id);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SET_TANDC_DOC]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      let indata = payload.form;

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }
      fd.append("fltc", payload.upfile, "doc.pdf");

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_LEARNER_TC]({ commit, dispatch, getters, rootGetters }) {
      //need to set the background loaded
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //do the data request

      getters["M_AUTH/TOKEN"];
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token);
      fd.append("getlearneruploads", "getlearneruploads");

      axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let res = response.data;
          commit("LOAD_LEARNER_TC", res);
          //console.log(response.data);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_ADI_TC]({ commit, dispatch, getters, rootGetters }) {
      //need to set the background loaded
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //do the data request

      getters["M_AUTH/TOKEN"];
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token);
      fd.append("getinstructoruploads", "getinstructoruploads");

      axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let res = response.data;
          commit("LOAD_ADI_TC", res);
          //console.log(response.data);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_PDI_TC]({ commit, dispatch, getters, rootGetters }) {
      //need to set the background loaded
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //do the data request

      getters["M_AUTH/TOKEN"];
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token);
      fd.append("gettraineeinstructoruploads", "gettraineeinstructoruploads");

      axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let res = response.data;
          commit("LOAD_PDI_TC", res);
          //console.log(response.data);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_TRAINIG_PACK]({ commit, dispatch, getters, rootGetters }) {
      //need to set the background loaded
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //do the data request

      getters["M_AUTH/TOKEN"];
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token);
      fd.append("gettrainingpackuploads", "gettrainingpackuploads");

      axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let res = response.data;
          commit("LOAD_TRAINIG_PACK", res);
          //console.log(response.data);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [DOWNLOAD_TC_DOC]({ dispatch, getters, rootGetters }, payload) {
      //need to set the background loaded
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //do the data request

      getters["M_AUTH/TOKEN"];
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token);
      let indata = payload;

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }

      return axios
        .post("docmanoa.php", fd, { responseType: "blob" })
        .then(function (response) {
          let res = response.data;
          return res;
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },

    [SAVE_LEARNER_FEEDBACK_GROUP]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("addlearnerfeedbackgroup", "addlearnerfeedbackgroup");
      fd.append("groupname", payload.groupname);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SAVE_INSTRUCTOR_FEEDBACK_GROUP]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("addinstructorfeedbackgroup", "addinstructorfeedbackgroup");
      fd.append("groupname", payload.groupname);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [LOAD_ALL_LEARNER_FEEDBACK_QUESTIONS]({
      commit,
      dispatch,
      getters,
      rootGetters,
    }) {
      //need to set the background loaded
      //console.log("Loadding Office Staff");
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //do the data request
      //console.log("sending request");
      getters["M_AUTH/TOKEN"];
      const token = rootGetters["M_AUTH/TOKEN"];
      //console.log("Tokeb" + token);
      let fd = new FormData();
      fd.append("token", token);
      fd.append(
        "getlearnerallfeedbackquestions",
        "getlearnerallfeedbackquestions"
      );
      axios.post("ctroaV2.php", fd).then(function (response) {
        //console.log("Got a response");
        let res = response.data;
        commit("LOAD_ALL_LEARNER_FEEDBACK_QUESTIONS", res);
        //console.log(response.data);
      });
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
        root: true,
      });
      //stop the background loading
    },
    [LOAD_ALL_INSTRUCTOR_FEEDBACK_QUESTIONS]({
      commit,
      dispatch,
      getters,
      rootGetters,
    }) {
      //need to set the background loaded
      //console.log("Loadding Office Staff");
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });
      //do the data request
      //console.log("sending request");
      getters["M_AUTH/TOKEN"];
      const token = rootGetters["M_AUTH/TOKEN"];
      //console.log("Tokeb" + token);
      let fd = new FormData();
      fd.append("token", token);
      fd.append(
        "getinstructorallfeedbackquestions",
        "getinstructorallfeedbackquestions"
      );
      axios.post("ctroaV2.php", fd).then(function (response) {
        //console.log("Got a response");
        let res = response.data;
        commit("LOAD_ALL_INSTRUCTOR_FEEDBACK_QUESTIONS", res);
        //console.log(response.data);
      });
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
        root: true,
      });
      //stop the background loading
    },

    [SAVE_LEARNER_FEEDBACK_QUESTION]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("addlearnerfeedbackquestion", payload.question);
      fd.append("groupname", payload.groupname);
      fd.append("groupid", payload.groupid);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },

    [SAVE_INSTRUCTOR_FEEDBACK_QUESTION]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("addinstructorfeedbackquestion", payload.question);
      fd.append("groupname", payload.groupname);
      fd.append("groupid", payload.groupid);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [DELETE_LEARNER_FEEDBACK_QUESTION]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("deletelearnerfeedbackquestion", payload.question_id);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [DELETE_INSTRUCTOR_FEEDBACK_QUESTION]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("deleteinstructorfeedbackquestion", payload.question_id);

      return axios
        .post("ctroaV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    //lets return the staff list in actions
    [GET_STAFF_TABLE](state) {
      /* let retObj = [];
      if (state.officeStaff.length > 1) {
        state.officeStaff.forEach((staff) => {
          let temp = {
            id: staff.staff_id,
            name: staff.title + " " + staff.fname + " " + staff.lname,
            mobile: staff.mobile,
            email: staff.email,
            accState: staff.accState,
          };
          retObj.push(temp);
        });
      }
      // return retObj;
*/
      return state.officeStaff.map((staff) => {
        const newOrder = {};
        newOrder["id"] = staff.staff_id;
        newOrder["name"] = `${staff.title} ${staff.fname} ${staff.lname}`;
        newOrder["mobile"] = staff.mobile;
        newOrder["email"] = staff.email;
        newOrder["accState"] = staff.accState;
        return newOrder;
      });
    },
  },
  mutations: {
    [SET_OFFICE_STAFFS](state, payload) {
      state.officeStaff = payload;
    },
    [SET_FRANCHISE_PLAN](state, payload) {
      state.franchisePlans.push(payload);
    },
    [LOAD_LEARNER_TC](state, payload) {
      state.learnerTandC = payload;
    },
    [LOAD_ADI_TC](state, payload) {
      state.adiTandC = payload;
    },
    [LOAD_PDI_TC](state, payload) {
      state.pdiTandC = payload;
    },
    [LOAD_TRAINIG_PACK](state, payload) {
      state.trainingPack = payload;
    },
    [LOAD_ALL_LEARNER_FEEDBACK_QUESTIONS](state, payload) {
      state.learnerFeedbackQuestions = payload;
    },
    [LOAD_ALL_INSTRUCTOR_FEEDBACK_QUESTIONS](state, payload) {
      state.instructorFeedbackQuestions = payload;
    },
    //this happens on logut
    [FULL_RESET](state) {
      state.officeStaff = [];
      state.learnerTandC = [];
      state.adiTandC = [];
      state.pdiTandC = [];
      state.trainingPack = [];
      state.learnerFeedbackQuestions = [];
      state.instructorFeedbackQuestions = [];
    },
  },
};
