<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <ListTable
        :pending-list="pendingFeedbacks"
        :is-loading="dataLoading"
        type="learner"
      />
    </v-col>
  </div>
</template>

<script>
import ListTable from "@/components/Feedback/ListTable.vue";
import { GET_PENDING_FEEDBACK_LEARNERS, M_OU } from "@/store/mutation-list";
import { mapActions } from "vuex";
export default {
  name: "RecordLearnerFeedbacks",
  components: { ListTable },
  data() {
    return {
      dataLoading: false,
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Record Learner Feedbacks About Instructors",
          disabled: true,
          href: "/dashboard/recordlearnerfeedbacks",
        },
      ],
      // eslint-disable-next-line prettier/prettier
      pendingFeedbacks: [],
    };
  },
  methods: {
    ...mapActions({
      loadTable: `${M_OU}${GET_PENDING_FEEDBACK_LEARNERS}`,
    }),
    actionLoad() {
      this.dataLoading = true;
      this.loadTable()
        .then((res) => {
          if (res) {
            this.pendingFeedbacks = res;
          } else {
            this.pendingFeedbacks = [];
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
  },
  created() {
    this.actionLoad();
  },
};
</script>

<style></style>
