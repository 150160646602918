<template>
  <div>
    <v-card class="point" @click="dialog = true" v-if="adiinfo">
      <v-card-title
        class="text-h6 grey lighten-2"
        style="justify-content: center"
      >
        Update Basic Information
      </v-card-title>
      <v-img
        :src="require(`@/assets/img/updateinfo/updateadi.webp`)"
        max-height="300"
        contain
        class="grey lighten-3 pa-5"
      ></v-img>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title>
            <span class="text-h5">Update Instructor Basic Information</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text style="height: 70vh">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  :value="getADIID"
                  outlined
                  style="width: 50%"
                  label="Driving Instructor ID"
                  readonly
                ></v-text-field>
                <v-select
                  v-model="form.title"
                  :items="visibleTitlesList"
                  name="title"
                  outlined
                  label="Title"
                  style="width: 50%"
                  required
                  :error-messages="titleErrors"
                  @input="$v.form.title.$touch()"
                  @blur="$v.form.title.$touch()"
                >
                </v-select>

                <v-text-field
                  v-model="form.fname"
                  name="fname"
                  outlined
                  :counter="50"
                  label="First Name"
                  required
                  :error-messages="fnameErrors"
                  @input="$v.form.fname.$touch()"
                  @blur="$v.form.fname.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.mname"
                  name="mname"
                  outlined
                  :counter="50"
                  label="Middle Name(s)"
                  :error-messages="mnameErrors"
                  @input="$v.form.mname.$touch()"
                  @blur="$v.form.mname.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.lname"
                  name="lname"
                  outlined
                  :counter="50"
                  label="Surename"
                  required
                  :error-messages="lnameErrors"
                  @input="$v.form.lname.$touch()"
                  @blur="$v.form.lname.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.mobile"
                  name="mobile"
                  outlined
                  :counter="11"
                  label="Mobile Number*"
                  required
                  :error-messages="mobileErrors"
                  @input="$v.form.mobile.$touch()"
                  @blur="$v.form.mobile.$touch()"
                  type="tel"
                ></v-text-field>
                <v-text-field
                  v-model="form.inemail"
                  name="inemail"
                  label="E-mail*"
                  outlined
                  :counter="60"
                  required
                  :error-messages="emailErrors"
                  @input="$v.form.inemail.$touch()"
                  @blur="$v.form.inemail.$touch()"
                  type="email"
                ></v-text-field>
                <v-text-field
                  v-model="form.dlno"
                  name="dlno"
                  outlined
                  :counter="16"
                  label="Driving Licence Number"
                  required
                  :error-messages="dlnoErrors"
                  @input="$v.form.dlno.$touch()"
                  @blur="$v.form.dlno.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="form.adilno"
                  name="adilno"
                  outlined
                  :counter="6"
                  label="ADI/PDI Licence Number"
                  required
                  :error-messages="adilnoErrors"
                  @input="$v.form.adilno.$touch()"
                  @blur="$v.form.adilno.$touch()"
                ></v-text-field>
                <v-select
                  v-model="form.aditgender"
                  name="aditgender"
                  :items="genders"
                  outlined
                  item-text="txt"
                  item-value="id"
                  label="Teaching Gender"
                  style="width: 50%"
                  required
                  :error-messages="aditgenderErrors"
                  @input="$v.form.aditgender.$touch()"
                  @blur="$v.form.aditgender.$touch()"
                >
                </v-select>
                <v-select
                  v-model="form.geartype"
                  name="geartype"
                  :items="gears"
                  outlined
                  item-text="txt"
                  item-value="id"
                  label="Teaching Gear Type"
                  style="width: 50%"
                  required
                  :error-messages="geartypeErrors"
                  @input="$v.form.geartype.$touch()"
                  @blur="$v.form.geartype.$touch()"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning darken-1"
            text
            @click="saveChanges()"
            :disabled="$v.form.$invalid"
          >
            Update Instructor
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  numeric,
  email,
} from "vuelidate/lib/validators";
import {
  M_DD,
  GET_TITLES,
  M_ADI,
  SAVE_ADI_BASIC_INFO,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UpdateBasicInfo",
  props: ["adiinfo"],
  data() {
    return {
      dialog: false,
      genders: [
        { id: "any", txt: "Any" },
        { id: "female", txt: "Female" },
        { id: "male", txt: "Male" },
      ],
      gears: [
        { id: "automatic", txt: "Automatic" },
        { id: "both", txt: "Automatic & Manual" },
        { id: "manual", txt: "Manual" },
      ],

      form: {
        adiid: null, //readonly
        title: "",
        fname: "",
        mname: "",
        lname: "",
        mobile: "",
        inemail: "",
        dlno: "",
        adilno: "",
        aditgender: null,
        geartype: null,
      },
    };
  },
  validations: {
    form: {
      title: { required },
      fname: { required, maxLength: maxLength(50) },
      mname: { maxLength: maxLength(50) },
      lname: { required, maxLength: maxLength(50) },

      mobile: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },

      inemail: {
        required,
        email,
        maxLength: maxLength(60),
      },

      dlno: { required, maxLength: maxLength(16), minLength: minLength(16) },
      adilno: { required, maxLength: maxLength(6), minLength: minLength(6) },
      aditgender: { required },
      geartype: { required },
    },
  },
  computed: {
    ...mapGetters({
      titlesList: `${M_DD}${GET_TITLES}`,
    }),
    visibleTitlesList() {
      if (this.titlesList) {
        //lets convert this to vuerify array
        /*
        {
            text: string | number | object,
            value: string | number | object,
            disabled: boolean,
            divider: boolean,
            header: string
          }
        */
        let converted = [];
        let fav = [];
        this.titlesList.forEach((title) => {
          //console.log(title.txt);
          //need to remove master and miss

          converted.push({
            text: title.txt,
            value: title.id,
            disabled: false,
            divider: false,
          });
          //get favorite to a list
          if (title.favourite == 1) {
            fav.push({
              text: title.txt,
              value: title.id,
              disabled: false,
              divider: false,
            });
          }
        });
        //separator line
        fav.push({
          text: "---",
          value: null,
          disabled: true,
          divider: true,
        });
        let retArr = [...fav, ...converted];

        //had to filter it twice to get the title out.

        return retArr
          .filter((elem) => elem.value !== "Miss")
          .filter((elem) => elem.value !== "Master");
      }
      return [];
    },
    getADIID() {
      if (this.form.adiid) {
        return "A" + this.form.adiid;
      }
      return "";
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.form.title.$dirty) return errors;
      !this.$v.form.title.required && errors.push("Title is required.");
      return errors;
    },

    fnameErrors() {
      const errors = [];
      if (!this.$v.form.fname.$dirty) return errors;
      !this.$v.form.fname.maxLength &&
        errors.push("Name must be at most 50 characters long");
      !this.$v.form.fname.required && errors.push("Name is required.");

      return errors;
    },
    mnameErrors() {
      const errors = [];
      if (!this.$v.form.mname.$dirty) return errors;
      !this.$v.form.mname.maxLength &&
        errors.push("Name must be at most 50 characters long");
      return errors;
    },
    lnameErrors() {
      const errors = [];
      if (!this.$v.form.lname.$dirty) return errors;
      !this.$v.form.lname.maxLength &&
        errors.push("Name must be at most 50 characters long");
      !this.$v.form.lname.required && errors.push("Name is required.");
      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (!this.$v.form.mobile.$dirty) return errors;
      !this.$v.form.mobile.maxLength &&
        errors.push("Mobile number can not be more than 11 digits");
      !this.$v.form.mobile.minLength &&
        errors.push("Mobile number can not be less than 9 digits");
      !this.$v.form.mobile.numeric && errors.push("Only numbers are allowed.");
      !this.$v.form.mobile.required &&
        errors.push("Mobile number is required.");
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.form.inemail.$dirty) return errors;
      !this.$v.form.inemail.maxLength &&
        errors.push("E-mail address is too long. Max 60 characters allowed.");
      !this.$v.form.inemail.email && errors.push("Invalid e-mail address");
      !this.$v.form.inemail.required && errors.push("E-mail is required.");

      return errors;
    },
    dlnoErrors() {
      const errors = [];
      if (!this.$v.form.dlno.$dirty) return errors;
      !this.$v.form.dlno.maxLength && errors.push("Invalid Licence Number");
      !this.$v.form.dlno.required && errors.push("Licence Number is required.");

      return errors;
    },
    adilnoErrors() {
      const errors = [];
      if (!this.$v.form.adilno.$dirty) return errors;
      !this.$v.form.adilno.maxLength &&
        errors.push("Invalid ADI/PDI Licence Number");
      !this.$v.form.adilno.required &&
        errors.push("ADI/ADI Licence Number is required.");

      return errors;
    },
    aditgenderErrors() {
      const errors = [];
      if (!this.$v.form.aditgender.$dirty) return errors;
      !this.$v.form.aditgender.required &&
        errors.push("Teaching Gender is required.");
      return errors;
    },
    geartypeErrors() {
      const errors = [];
      if (!this.$v.form.geartype.$dirty) return errors;
      !this.$v.form.geartype.required &&
        errors.push("Teaching Gear Type required.");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      saveBasicChanges: `${M_ADI}${SAVE_ADI_BASIC_INFO}`,
    }),
    saveChanges() {
      this.$v.$touch();
      if (this.$v.$invalid == false) {
        this.saveBasicChanges({
          id: this.form.adiid,
          form: this.form,
        }).then((rep) => {
          if (rep == "SUCCESS") {
            this.$fire({
              title: "Instructor Updated",
              text: "Instructor basic information updated successfully.",
              type: "success",
              //timer: 3000,
              allowOutsideClick: false,
            }).then((r) => {
              this.dialog = false;
              console.log(r.value);
            });
          } else {
            this.$fire({
              title: "Error",
              text: "Error updating Instructor basic information. Please try again.",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
    },
    assignData(DATA) {
      this.form = DATA;
    },
  },
  created() {
    this.assignData(this.adiinfo);
  },
};
</script>

<style></style>
