<template>
  <div>
    <v-card class="point" @click="dialog = true" v-if="basicObj">
      <v-card-title
        class="text-h6 grey lighten-2"
        style="justify-content: center"
      >
        Update VED Dates & Vehicle Info
      </v-card-title>
      <v-img
        :src="require(`@/assets/img/updateinfo/editcar.webp`)"
        max-height="300"
        contain
        class="grey lighten-3 pa-5"
      ></v-img>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="650px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title>
            <span class="text-h5"
              >Update VED Expiry Date and Vehicle Information</span
            >
          </v-card-title>
        </v-toolbar>
        <v-card-text style="height: 60vh">
          <v-container>
            <v-row
              ><v-alert type="error" v-if="activeCars.length < 1">
                No Active Cars to display</v-alert
              >
              <v-col md="6" v-for="(car, i) in activeCars" :key="i"
                ><v-card elevation="2" outlined shaped
                  ><v-img
                    height="250"
                    :src="require(`@/assets/img/updateinfo/carupdate.webp`)"
                  ></v-img
                  ><v-card-title>{{ car.make }} {{ car.model }} </v-card-title>

                  <v-card-text>
                    <div class="my-4 text-subtitle-1">
                      <v-icon> mdi-counter</v-icon> {{ car.c_reg }}
                    </div>
                    <div class="my-4 text-subtitle-1">
                      <v-icon> mdi-palette</v-icon> {{ car.colour }}
                    </div>
                    <div class="my-4 text-subtitle-1">
                      <v-icon> mdi-car-sports</v-icon
                      >{{ getCarTypeName(car.c_type_id) }}
                    </div>
                    <div class="my-4 text-subtitle-1">
                      <v-icon> mdi-gas-station</v-icon
                      ><span style="text-transform: uppercase">{{
                        car.c_fuel
                      }}</span>
                    </div>
                  </v-card-text>

                  <v-divider class="mx-4"></v-divider>

                  <v-card-actions>
                    <v-btn color="red lighten-2" text @click="carSelected(i)">
                      Select
                    </v-btn>
                  </v-card-actions></v-card
                ></v-col
              ></v-row
            ><v-row v-if="selectMode" transition="scroll-y-transition">
              <v-col
                ><div>
                  <v-form ref="form">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-text-field
                        v-if="selectedCar"
                        name="leagalowner"
                        outlined
                        label="Selected Vehicle"
                        required
                        :value="`${selectedCar.make} ${selectedCar.model} (${selectedCar.c_reg})`"
                        readonly
                      ></v-text-field>
                      <v-select
                        v-model="form.dualcontrol"
                        :items="dualCont"
                        name="dualcontols"
                        outlined
                        item-text="txt"
                        item-value="id"
                        label="Does the vehicle have dual controls?"
                        @change="isFormChanged = true"
                      ></v-select>
                      <v-select
                        v-model="form.carownertype"
                        :items="vehicleOwnerTypes"
                        name="carownertype"
                        outlined
                        item-text="desc"
                        item-value="type_id"
                        label="The vehicle you teach in is:"
                        @change="isFormChanged = true"
                      ></v-select>
                      <v-text-field
                        v-model="form.caroneleagalowner"
                        name="leagalowner"
                        outlined
                        :counter="50"
                        label="Name of the Legal Registered Owner &amp; Keeper of the Vehicle"
                        required
                        readonly
                        disabled
                      ></v-text-field>
                      <v-text-field
                        v-model="form.caronetaxexp"
                        name="taxexp"
                        label="VED Expiry Date"
                        outlined
                        required
                        :error-messages="caronetaxexpErrors"
                        @input="$v.form.caronetaxexp.$touch()"
                        @blur="$v.form.caronetaxexp.$touch()"
                        type="date"
                        @change="isFormChanged = true"
                      ></v-text-field>
                    </v-card-text>
                  </v-form></div
              ></v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="selectMode"
            color="warning darken-1"
            text
            @click="saveChanges()"
            :disabled="$v.form.$invalid || !isFormChanged"
          >
            Update Records
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  GET_FUEL_TYPES,
  GET_VEHICLE_TYPES,
  M_DD,
  M_ADI,
  SAVE_ADI_CAR_CHANGES,
  GET_VEHICLE_OWNER_TYPES,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
import { maxLength } from "vuelidate/lib/validators";
export default {
  name: "UpdateVehicleInfo",
  props: ["basicObj"],
  data() {
    return {
      dialog: false,
      gears: [
        { id: "automatic", txt: "Automatic" },
        { id: "manual", txt: "Manual" },
      ],
      dualCont: [
        { id: 2, txt: "No" },
        { id: 1, txt: "Yes" },
      ],
      rules: [
        (value) =>
          !value ||
          value.size < 4000000 ||
          "PDF Document file size should be less than 4 MB!",
      ],
      selectMode: false,
      selectedCar: {},
      //activeCars: [],
      form: {
        adiid: null, //readonly
        carid: null,

        dualcontrol: null,
        carownertype: null,
        caroneleagalowner: "",

        caronetaxexp: "",
      },
      //check for form changes
      isFormChanged: false,
    };
  },
  validations: {
    form: {
      caronetaxexp: {
        maxLength: maxLength(10),
      },
    },
  },

  computed: {
    ...mapGetters({
      fuelTypes: `${M_DD}${GET_FUEL_TYPES}`,
      vehicleTypes: `${M_DD}${GET_VEHICLE_TYPES}`,
      vehicleOwnerTypes: `${M_DD}${GET_VEHICLE_OWNER_TYPES}`,
    }),
    //error messages

    caronetaxexpErrors() {
      const errors = [];
      if (!this.$v.form.caronetaxexp.$dirty) return errors;
      !this.$v.form.caronetaxexp.maxLength &&
        errors.push("Vehicle VED Expiry Date is invalid.");
      return errors;
    },

    activeCars() {
      return this.basicObj.cars.filter((car) => car.rec_status === 1);
    },
  },
  methods: {
    ...mapActions({
      updateCarInfo: `${M_ADI}${SAVE_ADI_CAR_CHANGES}`,
    }),
    carSelected(index) {
      this.selectMode = false;
      this.clearForm();
      this.selectMode = true;
      this.selectedCar = this.activeCars[index];

      this.form.caroneleagalowner = this.activeCars[index].leagal_owner;
      this.form.dualcontrol = this.activeCars[index].dual_ctrl;
      this.form.carownertype = this.activeCars[index].owner_type;
    },
    saveChanges() {
      //need to do some processing in here.
      //passing object
      let passObj = {};
      passObj.id = this.form.adiid;
      passObj.carid = this.selectedCar.c_id;
      let frm = {}; //this will hold primitive data

      //add dual ctrl and car owner
      frm.dualcontrol = this.form.dualcontrol;
      frm.carownertype = this.form.carownertype;
      frm.taxexp = this.form.caronetaxexp;
      passObj.form = frm; //assign form

      //lets upload

      console.log("Lets look at pass Object");
      console.log(passObj);
      this.updateCarInfo(passObj).then((rep) => {
        if (rep == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Vehicle information updated successfully. Local data will updated on next refresh.",
            type: "success",
            //timer: 3000,
            allowOutsideClick: false,
          }).then((r) => {
            this.dialog = false;
            console.log(r.value);
            //clearing form
            this.clearForm();
            this.selectMode = false;
            this.$refs.form.resetValidation();
            this.$v.form.$reset();
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error updating information document. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },

    clearForm() {
      this.form = {
        adiid: this.form.adiid, //readonly
        carid: this.form.carid,

        caroneleagalowner: "",
        caronetaxexp: "",
        dualcontrol: null,
        carownertype: null,
      };
    },
    //this method will return car type
    getCarTypeName(key) {
      if (key) {
        let res = this.vehicleTypes.filter((data) => {
          return data.id == key;
        });
        return res[0]["txt"];
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.form.adiid = this.basicObj.adiid;
    /*this.basicObj.cars.forEach((car) => {
        if (car.rec_status == 1) {
          this.activeCars.push(car);
        }
      });*/
  },
};
</script>

<style></style>
