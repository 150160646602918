<template>
  <div class="row">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5"
        ><h2 class="text-warning">Driving Instructor Not Selected</h2>
        <p class="sub-title">
          Please select driving instructor from
          <router-link :to="{ name: 'ViewEditInstructors' }"
            >View/Edit Instructors
          </router-link>
        </p></v-sheet
      ></v-col
    >
  </div>
</template>

<script>
export default {
  name: "MissingID",
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "View/Edit Instructors",
          disabled: false,
          href: "/dashboard/vieweditinstructors",
        },
        {
          text: "Edit Instructor",
          disabled: true,
          href: "/dashboard/edit",
        },
      ],
    };
  },
};
</script>

<style></style>
