<template>
  <div>
    <v-form ref="form">
      <v-row>
        <v-col md="4">
          <v-text-field
            v-model="form.inemail"
            name="inemail"
            label="E-mail*"
            outlined
            :counter="60"
            required
            :error-messages="emailErrors"
            @input="$v.form.inemail.$touch()"
            @blur="$v.form.inemail.$touch()"
            type="email"
            @change="currentState()"
          ></v-text-field>

          <v-text-field
            v-model="form.hp1"
            name="hp1"
            outlined
            :counter="11"
            label="Home Landline Number 1"
            :error-messages="hp1Errors"
            @input="$v.form.hp1.$touch()"
            @blur="$v.form.hp1.$touch()"
            type="tel"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.hp2"
            name="hp2"
            outlined
            :counter="11"
            label="Home Landline Number 2"
            :error-messages="hp2Errors"
            @input="$v.form.hp1.$touch()"
            @blur="$v.form.hp1.$touch()"
            type="tel"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.mobile"
            name="mobile"
            outlined
            :counter="11"
            label="Mobile Number 1*"
            required
            :error-messages="mobileErrors"
            @input="$v.form.mobile.$touch()"
            @blur="$v.form.mobile.$touch()"
            type="tel"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.mp2"
            name="mp2"
            outlined
            :counter="11"
            label="Mobile Number 2"
            :error-messages="mp2Errors"
            @input="$v.form.mp2.$touch()"
            @blur="$v.form.mp2.$touch()"
            type="tel"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.wp1"
            name="wp1"
            outlined
            :counter="11"
            label="Work Landline Number 1"
            :error-messages="wp1Errors"
            @input="$v.form.wp1.$touch()"
            @blur="$v.form.wp1.$touch()"
            type="tel"
            @change="currentState()"
          ></v-text-field>
          <v-text-field
            v-model="form.wp2"
            name="wp2"
            outlined
            :counter="11"
            label="Work Landline Number 2"
            :error-messages="wp2Errors"
            @input="$v.form.wp1.$touch()"
            @blur="$v.form.wp1.$touch()"
            type="tel"
            @change="currentState()"
          ></v-text-field> </v-col
      ></v-row>
    </v-form>
  </div>
</template>

<script>
//import { mapGetters } from "vuex";
import {
  required,
  minLength,
  maxLength,
  numeric,
  email,
} from "vuelidate/lib/validators";
export default {
  name: "Contact",
  data() {
    return {
      form: {
        inemail: "",
        hp1: "",
        hp2: "",
        mobile: "",
        mp2: "",

        wp1: "",
        wp2: "",
      },
    };
  },
  validations: {
    form: {
      inemail: {
        required,
        email,
        maxLength: maxLength(60),
      },

      hp1: {
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },
      hp2: {
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },
      mobile: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },
      mp2: {
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },

      wp1: {
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },
      wp2: {
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },
    },
  },
  methods: {
    currentState() {
      this.$emit("contact-update", {
        state: this.$v.form.$invalid,
        data: this.form,
        fd: new FormData(this.$refs.form.$el),
      });
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.form.inemail.$dirty) return errors;
      !this.$v.form.inemail.maxLength &&
        errors.push("E-mail address is too long. Max 60 characters allowed");
      !this.$v.form.inemail.email && errors.push("Invalid e-mail address");
      !this.$v.form.inemail.required && errors.push("E-mail is required.");

      return errors;
    },
    hp1Errors() {
      const errors = [];
      if (!this.$v.form.hp1.$dirty) return errors;
      !this.$v.form.hp1.maxLength &&
        errors.push("Home Landline number can not be more than 11 digits");
      !this.$v.form.hp1.minLength &&
        errors.push("Home Landline number can not be less than 9 digits");
      !this.$v.form.hp1.numeric && errors.push("Only numbers are allowed.");

      return errors;
    },
    hp2Errors() {
      const errors = [];
      if (!this.$v.form.hp2.$dirty) return errors;
      !this.$v.form.hp2.maxLength &&
        errors.push("Home Landline number can not be more than 11 digits");
      !this.$v.form.hp2.minLength &&
        errors.push("Home Landline number can not be less than 9 digits");
      !this.$v.form.hp2.numeric && errors.push("Only numbers are allowed.");

      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (!this.$v.form.mobile.$dirty) return errors;
      !this.$v.form.mobile.maxLength &&
        errors.push("Mobile number can not be more than 11 digits");
      !this.$v.form.mobile.minLength &&
        errors.push("Mobile number can not be less than 9 digits");
      !this.$v.form.mobile.numeric && errors.push("Only numbers are allowed.");
      !this.$v.form.mobile.required &&
        errors.push("Mobile number is required.");
      return errors;
    },
    mp2Errors() {
      const errors = [];
      if (!this.$v.form.mp2.$dirty) return errors;
      !this.$v.form.mp2.maxLength &&
        errors.push("Mobile Landline number can not be more than 11 digits");
      !this.$v.form.hp2.minLength &&
        errors.push("Mobile Landline number can not be less than 9 digits");
      !this.$v.form.mp2.numeric && errors.push("Only numbers are allowed.");

      return errors;
    },
    wp1Errors() {
      const errors = [];
      if (!this.$v.form.wp1.$dirty) return errors;
      !this.$v.form.wp1.maxLength &&
        errors.push("Home Landline number can not be more than 11 digits");
      !this.$v.form.wp1.minLength &&
        errors.push("Home Landline number can not be less than 9 digits");
      !this.$v.form.wp1.numeric && errors.push("Only numbers are allowed.");

      return errors;
    },
    wp2Errors() {
      const errors = [];
      if (!this.$v.form.wp2.$dirty) return errors;
      !this.$v.form.wp2.maxLength &&
        errors.push("Home Landline number can not be more than 11 digits");
      !this.$v.form.wp2.minLength &&
        errors.push("Home Landline number can not be less than 9 digits");
      !this.$v.form.wp2.numeric && errors.push("Only numbers are allowed.");

      return errors;
    },
  },
};
</script>

<style></style>
