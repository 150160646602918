<template>
  <v-footer :color="FooterColour" dark padless class="mt-5">
    <v-row class="py-0 px-10 indigo darken-1">
      <v-col md="3"
        >Full Address
        <!-- <p class="liltext">{{ getMutilineaddress(quickInfo.address) }}</p> -->
        <span
          class="liltext"
          v-for="(val, key) in quickInfo.address_lines"
          :key="key"
        >
          {{ val }}
        </span>
      </v-col>
      <v-col md="2"
        >Opening Hours
        <table light>
          <template>
            <tbody>
              <tr v-for="(val, key) in quickInfo.opening_hours" :key="key">
                <th class="lft">{{ key }}</th>
                <td class="mid"></td>
                <td class="rit">
                  <pre>{{ val }}</pre>
                </td>
              </tr>
            </tbody>
          </template>
        </table>
      </v-col>
      <v-col md="3"
        >Contact Details
        <table>
          <tbody>
            <tr v-for="(val, key) in quickInfo.contact_details" :key="key">
              <th class="lft">{{ key }}</th>
              <td class="mid"></td>
              <td class="rit">
                <pre>{{ val }}</pre>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col md="4"
        >Bank Details

        <table>
          <colgroup>
            <col width="40%" />
            <col width="0%" />
            <col width="60%" />
          </colgroup>
          <tr v-for="(val, name, index) in quickInfo.bank_details" :key="index">
            <th class="lft">{{ foo.bdLbl[name] }}</th>
            <td class="mid"></td>
            <td class="rit">{{ val }}</td>
          </tr>
        </table>
      </v-col>
      <v-col md="12" cols="py-0"
        ><div class="text-center">
          Designed, Created and Developed by Brandiie. © Copyrights 2016 -
          {{ new Date().getFullYear() }} Brandiie <sup>®</sup>™ and BECO Drive
          <sup>®</sup>™. All Rights Reserved.
          <!--  (v{{ ver }}) -->
        </div></v-col
      >
    </v-row>
    <!-- <v-row class="indigo darken-1">
      <v-col md="12">
             <v-card-text class="white--text center"
        >© Copyrights 2016 - {{ new Date().getFullYear() }} Brandiie. All
        Rights Reserved (v{{ ver }}).
      </v-card-text>

      </v-col>
 
    </v-row> -->

    <!-- <v-card
      flat
      tile
      class="blue darken-4 white--text text-center"
      :class="FooterColour"
      width="100%"
    > --><!--
    <v-card
      flat
      tile
      class="indigo darken-1 white--text text-center pa-0"
      :class="FooterColour"
      width="100%"
    >
      <v-divider></v-divider>

      <v-card-text class="white--text center"
        >© Copyrights 2016 - {{ new Date().getFullYear() }} Brandiie. All
        Rights Reserved (v{{ ver }}).
      </v-card-text>
    </v-card>-->
  </v-footer>
</template>
<script>
import { AppSetup } from "@/store/sitedata";
import { GET_QUICK_INFO, M_COMMON } from "@/store/mutation-list";
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  props: ["FooterColour"],
  data: () => ({
    foo: {
      fullAddress: "",

      oh: [],
      cd: [],
      bd: [],
      bdLbl: [
        "Bank",
        "Account Name",
        "Sort Code",
        "Account Number",
        "Payment Reference",
      ],
    },

    ver: AppSetup.version,
  }),
  computed: {
    ...mapGetters({
      quickInfo: `${M_COMMON}${GET_QUICK_INFO}`,
    }),
  },
  methods: {
    getMutilineaddress(val) {
      if (val) {
        let adr = val;
        adr = adr.replace(",", "\n");
        return adr;
      }
      return "";
    },
  },
};
</script>
<style scoped>
.liltext {
  font-size: 0.8rem;
  margin-top: 0.5em;
  display: block;
}
table {
  font-size: 0.8rem;
  margin-top: 1em;
}
.lft {
  text-align: left;
  width: 40%;
}
.mid {
  width: 5%;
}
.rit {
  text-align: left;
  width: 60%;
}
pre {
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  /* font-size: 0.8rem; */
  /* display: table-cell; */
  /* vertical-align: inherit; */
  /* font-weight: bold; */
}
</style>
