<template>
  <v-dialog v-model="dialog" max-width="960px" scrollable persistent>
    <v-card>
      <v-card-title class="text-h5 blue lighten-2">
        <span>{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-simple-table v-if="PersonSummary" id="summarytbl">
            <thead>
              <tr>
                <th class="th">{{ PersonSummary[0].label }}</th>
                <th>{{ PersonSummary[0].data }}</th>
                <th class="th">{{ PersonSummary[1].label }}</th>
                <th>{{ PersonSummary[1].data }}</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th class="th">{{ PersonSummary[2].label }}</th>
                <th>
                  <v-rating
                    dense
                    :value="PersonSummary[2].data"
                    background-color="grey  lighten-2"
                    half-increments
                    color="warning"
                    size="30"
                    readonly
                  ></v-rating>
                </th>
                <th class="th">{{ PersonSummary[3].label }}</th>
                <th>{{ getTotalPersonCount }}</th>
              </tr>
            </thead>
          </v-simple-table>
          <v-divider></v-divider>

          <!-- This div will display when more clicked -->
          <div v-if="selectTable" class="mt-5">
            <h3>Please Select {{ getRoleLabel }} From Table.</h3>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="fcol">ID</th>
                  <th class="scol">{{ getRoleLabel }}</th>
                  <th class="fcol">Rating</th>
                  <th class="scol">Total Questions Answered</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in SingleSummaries"
                  :key="index"
                  @click="loadRatings(item)"
                >
                  <th>{{ getID(item) }}</th>
                  <td>{{ getName(item) }}</td>
                  <td>
                    <v-rating
                      dense
                      :value="getFloat(item)"
                      background-color="grey  lighten-2"
                      half-increments
                      color="warning"
                      size="20"
                      readonly
                    ></v-rating>
                  </td>
                  <td>{{ item.total }}</td>
                </tr>
              </tbody>
              <tbody></tbody>
            </v-simple-table>
          </div>
          <!-- This div will display when more clicked -->
          <v-card v-if="detailedTable">
            <h3 class="pa-3">
              {{ getRoleLabel }}: {{ dPerson.id }} - {{ dPerson.name }}
            </h3>
            <v-card-text>
              <v-data-table
                :headers="detailedHeaders"
                :items-per-page="-1"
                class="elevation-1"
                :items="dTableData"
                :loading="dTableLoading"
                loading-text="Loading ratings. Please wait."
              >
                <template v-slot:[`item.booking_id`]="{ item }">
                  <strong> B{{ item.booking_id }}</strong>
                </template>
                <template v-slot:[`item.feedback_rating`]="{ item }">
                  <v-rating
                    dense
                    :value="item.feedback_rating"
                    background-color="grey  lighten-2"
                    color="warning"
                    size="20"
                    readonly
                  ></v-rating>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog()">Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import {
  M_OU,
  GET_ALL_LEARNER_FEEDBACKS,
  GET_ALL_INSTRUCTOR_FEEDBACKS,
} from "@/store/mutation-list";
export default {
  name: "FeedbackSummaryDialog",
  props: ["dialog", "title", "PersonSummary", "type", "SingleSummaries"],
  data() {
    return {
      detailedHeaders: [
        { text: "Booking", value: "booking_id", width: "12%" },
        { text: "Question", value: "question_text", width: "38%" },
        { text: "Rating", value: "feedback_rating", width: "15%" },
        { text: "Lesson Date", value: "booking_started", width: "15%" },
      ],
      selectTable: true,
      detailedTable: false,
      dTableLoading: true,
      dTableData: [],
      dPerson: { id: "", name: "" },
    };
  },
  methods: {
    ...mapActions({
      getAllLearnerFBs: `${M_OU}${GET_ALL_LEARNER_FEEDBACKS}`,
      getAllInstructorFBs: `${M_OU}${GET_ALL_INSTRUCTOR_FEEDBACKS}`,
    }),
    getID(record) {
      if (this.type == "learner") {
        return `L${record.stu_id}`;
      } else {
        return `A${record.adi_id}`;
      }
    },
    getName(record) {
      if (this.type == "learner") {
        return `${record.stu_title} ${record.stu_fname} ${record.stu_lname}`;
      } else {
        return `${record.adi_title} ${record.adi_fname} ${record.adi_lname}`;
      }
    },
    getFloat(val) {
      //adi return differnt name so lets sort that in here
      if (this.type == "learner") {
        return parseFloat(val.round_average);
      } else {
        return parseFloat(val.read_average);
      }
      //return parseFloat(val);
    },
    loadRatings(recordInfo) {
      //console.log(recordInfo);
      //step 0: fill info from summary
      if (this.type == "learner") {
        this.dPerson = {
          id: "L" + recordInfo.stu_id,
          name: `${recordInfo.stu_title} ${recordInfo.stu_fname} ${recordInfo.stu_lname}`,
        };
      } else {
        this.dPerson = {
          id: "A" + recordInfo.adi_id,
          name: `${recordInfo.adi_title} ${recordInfo.adi_fname} ${recordInfo.adi_lname}`,
        };
      }
      //step 1: hide select table
      this.selectTable = false;
      //step 2: show loading table
      this.detailedTable = true;
      //step 3: dispatch load
      if (this.type == "learner") {
        this.getAllLearnerFBs({
          adiid: recordInfo.adi_id,
          learnerid: recordInfo.stu_id,
        })
          .then((res) => {
            if (res) {
              this.dTableData = res;
            } else {
              this.dTableData = [];
            }
          })
          .finally(() => {
            this.dTableLoading = false;
          });
      } else {
        this.getAllInstructorFBs({
          adiid: recordInfo.adi_id,
          learnerid: recordInfo.stu_id,
        })
          .then((res) => {
            if (res) {
              this.dTableData = res;
            } else {
              this.dTableData = [];
            }
          })
          .finally(() => {
            this.dTableLoading = false;
          });
      }

      //step 4. stop load and attch data
    },
    closeDialog() {
      //this is the close function
      //need to reset everything and emit close
      this.selectTable = true;
      this.detailedTable = false;
      this.dTableLoading = true;
      this.dTableData = [];
      this.dPerson = { id: "", name: "" };
      this.$emit("close");
    },
  },
  computed: {
    getRoleLabel() {
      if (this.type == "adi") {
        return "Instructor";
      } else {
        return "Learner";
      }
    },
    getTotalPersonCount() {
      if (this.SingleSummaries) {
        return this.SingleSummaries.length;
      } else {
        return null;
      }
    },
  },
};
</script>
<style>
.th {
  font-weight: 700;
}
#summarytbl {
  border: 1px;
}
.fcol {
  width: 10%;
}
.scol {
  width: 40%;
}
</style>
