<template>
  <v-col md="4">
    <h3 class="mb-5">Gender Ratios</h3>
    <v-card class="mx-auto" :elevation="important" :outlined="isMain"
      ><v-simple-table v-if="tableData">
        <template v-slot:default>
          <thead>
            <tr class="text-center">
              <th class="text-center">Gender</th>
              <th class="text-center">Frequency</th>
              <th class="text-center">Precentage</th>
            </tr>
          </thead>
          <tbody>
            <!--tr v-for="(item, key) in tableData" :key="key">
                  <td>{{ item.name }}</td>
                  <td>{{ item.calories }}</td>
                </tr-->
            <tr
              v-for="(ratio, i) in getGenderRatios"
              :key="i"
              class="text-center"
            >
              <td>{{ ratio[0] }}</td>
              <td>{{ ratio[1] }}</td>
              <td>{{ ratio[1] | precentage(tableData.length) }}</td>
            </tr>
            <tr class="tots">
              <th class="text-center">Total</th>
              <th class="text-center">{{ tableData.length }}</th>
              <th class="text-center">100%</th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p v-else class="warning">No Data Provided</p></v-card
    ></v-col
  >
</template>

<script>
export default {
  name: "GenderRatioTable",
  props: ["tableData", "isMain"],
  computed: {
    getGenderRatios() {
      let M = 0,
        F = 0;
      if (this.tableData) {
        this.tableData.forEach((rec) => {
          switch (rec.gender) {
            case "m":
              M++;
              break;
            case "f":
              F++;
              break;

            default:
              break;
          }
        });
      }
      return {
        F: [`Female`, F],
        M: [`Male`, M],
      };
    },
    important() {
      if (this.isMain) {
        return 24;
      } else {
        return 2;
      }
    },
  },
};
</script>

<style></style>
