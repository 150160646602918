<template>
  <div>
    <h2>{{ title }}</h2>
    <div class="subtitle-1">{{ SubTitle }}</div>
    <v-card>
      <v-card-title>
        <v-text-field
          v-if="isSearch"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        @click:row="selected"
        :headers="headers"
        :items="dataarray"
        :search="search"
        :loading="isLoading"
      >
        <template v-slot:[`item.adi_id`]="{ item }">
          <strong>A{{ item.adi_id }}</strong>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span>{{ getName(item.title, item.fname, item.lname) }}</span>
        </template>
        <template v-slot:[`item.mobile`]="{ item }">
          <span>{{ item.mobile | mobileno }}</span>
        </template>
        <template v-slot:[`item.teaching_gears`]="{ item }">
          {{ item.teaching_gears | capitalize }}
        </template>
        <template v-slot:[`item.teaching_gens`]="{ item }">
          {{ item.teaching_gens | capitalize }}
        </template>
        <template v-if="isStatus" v-slot:[`item.accState`]="{ item }">
          {{ item.accState | adiStatus }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "InstructorTable",
  props: [
    "title",
    "dataarray",
    "isLoading",
    "SubTitle",
    "isSearch",
    "isStatus", //if true status will show in the table
  ],
  data() {
    return {
      search: "",
      /*headers: [
        { text: "ADI ID", value: "adi_id" },
        { text: "Name", value: "name" },
        { text: "Mobile", value: "mobile" },
        { text: "E-mail", value: "email" },
        { text: "Gear Type", value: "teaching_gears" },
        { text: "Teaching Gender", value: "teaching_gens" },
      ],*/
    };
  },
  methods: {
    getName(title, fname, lname) {
      return `${title} ${fname} ${lname}`;
    },
    selected(item) {
      this.$emit("choosen", { record: item });
    },
  },
  computed: {
    headers() {
      if (this.isStatus) {
        return [
          { text: "ADI ID", value: "adi_id" },
          { text: "Name", value: "name" },
          { text: "Mobile", value: "mobile" },
          { text: "E-mail", value: "email" },
          { text: "Gear Type", value: "teaching_gears" },
          { text: "Teaching Gender", value: "teaching_gens" },
          { text: "Account Status", value: "accState" },
        ];
      } else {
        return [
          { text: "ADI ID", value: "adi_id" },
          { text: "Name", value: "name" },
          { text: "Mobile", value: "mobile" },
          { text: "E-mail", value: "email" },
          { text: "Gear Type", value: "teaching_gears" },
          { text: "Teaching Gender", value: "teaching_gens" },
        ];
      }
    },
  },
};
</script>

<style></style>
