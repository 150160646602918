<template>
  <v-sheet color="white" elevation="1" class="pa-5">
    <h2>
      {{ getFrmTitle }}<span v-if="isSaveChangeBtn">: L{{ learner }}</span>
    </h2>
    <v-divider class="mt-3 mb-3"></v-divider>
    <v-form :disabled="isFormLocked">
      <v-row justify="center">
        <v-col md="4">
          <v-select
            v-model="form.title"
            :items="visibleTitlesList"
            label="Title"
            required
            :error-messages="titleErrors"
            @input="$v.form.title.$touch(), selectGender()"
            @blur="$v.form.title.$touch(), selectGender()"
          >
          </v-select>
          <!--v-select
            v-model="form.gender"
            :items="genders"
            item-text="txt"
            item-value="id"
            label="Gender"
            required
            :error-messages="genderErrors"
            @input="$v.form.gender.$touch()"
            @blur="$v.form.gender.$touch()"
          >
          </v-select-->
          <v-text-field
            v-model="form.fname"
            :counter="40"
            label="First Name"
            required
            :error-messages="fnameErrors"
            @input="$v.form.fname.$touch()"
            @blur="$v.form.fname.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="form.mname"
            :counter="120"
            label="Middle Name(s)"
            :error-messages="mnameErrors"
            @input="$v.form.mname.$touch()"
            @blur="$v.form.mname.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="form.lname"
            :counter="40"
            label="Surname"
            required
            :error-messages="lnameErrors"
            @input="$v.form.lname.$touch()"
            @blur="$v.form.lname.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="form.mobile"
            :counter="11"
            label="Mobile Number"
            required
            :error-messages="mobileErrors"
            @input="$v.form.mobile.$touch()"
            @blur="$v.form.mobile.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="form.landline"
            :counter="11"
            label="Landline Number"
            :error-messages="landlineErrors"
            @input="$v.form.landline.$touch()"
            @blur="$v.form.landline.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="form.inemail"
            label="E-mail"
            :counter="60"
            required
            :error-messages="emailErrors"
            @input="$v.form.inemail.$touch()"
            @blur="$v.form.inemail.$touch()"
          ></v-text-field>
          <!-- <DatePicker
            :pre-date="form.dob"
            v-on:dateSelected="acceptDate"
            label="Date of Birth"
            :givenerror="dobErrors"
          /> -->
          <v-text-field
            v-model="form.dob"
            name="dob"
            label="Date of Birth"
            required
            :error-messages="dobErrors"
            @input="$v.form.dob.$touch()"
            @blur="$v.form.dob.$touch()"
            type="date"
          ></v-text-field>
          <v-text-field
            v-model="form.dlno"
            :counter="16"
            label="Provisional Licence Number"
            required
            :error-messages="dlnoErrors"
            @input="$v.form.dlno.$touch()"
            @blur="$v.form.dlno.$touch()"
          ></v-text-field>
          <v-select
            v-model="form.lesstype"
            :items="[
              { id: 'automatic', txt: 'Automatic' },
              { id: 'manual', txt: 'Manual' },
            ]"
            item-text="txt"
            item-value="id"
            label="Lesson Type"
            required
            :error-messages="lestypeErrors"
            @input="$v.form.lesstype.$touch()"
            @blur="$v.form.lesstype.$touch()"
          >
          </v-select>
          <!-- <v-divider></v-divider> -->
          <v-alert
            class="mt-2"
            text
            prominent
            type="error"
            icon="mdi-alert-octagram"
            v-if="$v.$dirty"
          >
            Please complete all required fields.
          </v-alert>
        </v-col>
        <v-col md="4">
          <!-- <v-header>Home Address</v-header> -->
          <v-text-field
            v-model="form.haline1"
            :counter="50"
            label="Address Line 1"
            required
            :error-messages="addressLn1Errors"
            @input="$v.form.haline1.$touch()"
            @blur="$v.form.haline1.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="form.haline2"
            :counter="50"
            label="Address Line 2"
            :error-messages="addressLn2Errors"
            @input="$v.form.haline2.$touch()"
            @blur="$v.form.haline2.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="form.hacity"
            :counter="50"
            label="City"
            required
            :error-messages="cityErrors"
            @input="$v.form.hacity.$touch()"
            @blur="$v.form.hacity.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="form.hcounty"
            :counter="50"
            label="County"
            :error-messages="countyErrors"
            @input="$v.form.hcounty.$touch()"
            @blur="$v.form.hcounty.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="form.hapostcode"
            :counter="9"
            label="Postcode"
            required
            :error-messages="postcodeErrors"
            @input="$v.form.hapostcode.$touch()"
            @blur="$v.form.hapostcode.$touch()"
          ></v-text-field>
          <!-- <v-divider></v-divider> -->
          <v-text-field
            v-model="form.pickpostcode"
            :counter="9"
            label="Pickup Postcode"
            required
            :error-messages="pickPCErrors"
            @input="$v.form.pickpostcode.$touch()"
            @blur="$v.form.pickpostcode.$touch()"
          ></v-text-field>
          <v-select
            v-model="form.adigender"
            :items="[
              { id: 'any', txt: 'Any' },
              { id: 'female', txt: 'Female' },
              { id: 'male', txt: 'Male' },
            ]"
            item-text="txt"
            item-value="id"
            label="Preferred ADI Gender"
            required
            :error-messages="adiGenErrors"
            @input="$v.form.adigender.$touch()"
            @blur="$v.form.adigender.$touch()"
            messages="This is a preference and cannot always be guaranteed as the availability of the instructors in the learner's area determines the type of instructor."
          ></v-select>

          <v-select
            v-model="form.adilang"
            :items="adilangs"
            item-text="txt"
            item-value="id"
            label="Preferred ADI Secondary Language"
            messages="Primary language is English."
          >
          </v-select>
          <v-select
            v-model="form.cartype"
            :items="cartypes"
            item-text="txt"
            item-value="id"
            label="Preferred Vehicle Type"
            required
            :error-messages="prefcartypeErrors"
            @input="$v.form.cartype.$touch()"
            @blur="$v.form.cartype.$touch()"
            messages="Standard vehicle type is the instructor's vehicle."
          >
          </v-select>
          <v-select
            v-if="isSaveChangeBtn"
            v-model="form.state"
            :items="accountStates"
            item-text="txt"
            item-value="id"
            label="Account Status"
            required
          >
          </v-select>
          <v-text-field
            v-if="!isSaveChangeBtn"
            type="password"
            label="Password"
            value="**********"
            messages="Password will be generated automatically and e-mailed to learner."
            readonly
          ></v-text-field>
          <v-btn color="success" class="mt-3" @click="submit" v-if="isRegBtn"
            >Register Learner
          </v-btn>

          <span v-if="isEditableBtn">
            <v-btn
              color="warning"
              class="mt-3 mr-3"
              v-if="isEditableBtn && isFormLocked"
              :disabled="!isFormLocked"
              @click="isFormLocked = false"
            >
              <v-icon>mdi-lock-open-variant</v-icon>
            </v-btn>
            <v-btn
              color="warning"
              class="mt-3 mr-3"
              v-if="isEditableBtn && !isFormLocked"
              :disabled="isFormLocked"
              @click="isFormLocked = true"
            >
              <v-icon>mdi-lock</v-icon>
            </v-btn>
          </span>
          <v-btn
            color="primary"
            class="mt-3"
            @click="saveChanges"
            v-if="isEditableBtn"
            :disabled="isFormLocked"
            >Save Changes
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-sheet>
</template>

<script>
// import DatePicker from "@/components/DatePicker";
import {
  GET_TITLES,
  GET_LANGUAGES,
  GET_VEHICLE_TYPES,
  REGISTER_LEARNER,
  GET_LEARNER_BY_ID,
  UPDATE_LEARNER,
  GET_RECENT_LEARNERS,
  M_DD,
  M_OU,
  LOAD_RECENT_LEARNERS,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";

import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
export default {
  name: "LearnerForm",
  // components: { DatePicker },
  props: ["FormType", "learner", "frmLock"], //FormType - new,edit,view(readonly)
  data: () => ({
    genders: [
      { id: "f", txt: "Female" },
      { id: "m", txt: "Male" },
    ],
    datePickDirty: false,
    form: {
      title: "",
      gender: "",
      fname: "",
      mname: "",
      lname: "",
      mobile: "",
      landline: "",
      inemail: "",
      dob: "",
      dlno: "",
      lesstype: "",
      haline1: "",
      haline2: "",
      hacity: "",
      hcounty: "",
      hapostcode: "",
      pickpostcode: "",
      adigender: "",
      adilang: "",
      cartype: "",
      state: "",
    },
    accountStates: [
      { id: 1, txt: "Active" },
      { id: 0, txt: "Blocked" },
      { id: 3, txt: "New Learner" },
      { id: 2, txt: "Read Only" },
    ],
    frmTitle: "",
    isFormLocked: false,
  }),
  validations: {
    form: {
      title: {
        required,
      },
      gender: {
        required,
      },
      fname: {
        required,
        maxLength: maxLength(40),
      },
      mname: {
        maxLength: maxLength(120),
      },
      lname: {
        required,
        maxLength: maxLength(40),
      },
      mobile: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },
      landline: {
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },
      inemail: {
        required,
        email,
        maxLength: maxLength(60),
      },
      dob: {
        required,
      },
      dlno: {
        required,
        maxLength: maxLength(16),
      },
      lesstype: {
        required,
      },
      haline1: {
        required,
        maxLength: maxLength(50),
      },
      haline2: {
        maxLength: maxLength(50),
      },

      hacity: {
        required,
        maxLength: maxLength(50),
      },
      hcounty: {
        maxLength: maxLength(50),
      },
      hapostcode: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(9),
      },
      pickpostcode: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(9),
      },
      adigender: {
        required,
      },
      cartype: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      titlesList: `${M_DD}${GET_TITLES}`,
      adilangs: `${M_DD}${GET_LANGUAGES}`,
      cartypes: `${M_DD}${GET_VEHICLE_TYPES}`,
      //this function need to be repalced
      getAll: `${M_OU}${GET_RECENT_LEARNERS}`,
    }),
    //this section is to generate titles with  fav and duplicates and separate
    visibleTitlesList() {
      if (this.titlesList) {
        //lets convert this to vuerify array
        /*
        {
            text: string | number | object,
            value: string | number | object,
            disabled: boolean,
            divider: boolean,
            header: string
          }
        */
        let converted = [];
        let fav = [];
        this.titlesList.forEach((title) => {
          converted.push({
            text: title.txt,
            value: title.id,
            disabled: false,
            divider: false,
          });
          //get favorite to a list
          if (title.favourite == 1) {
            fav.push({
              text: title.txt,
              value: title.id,
              disabled: false,
              divider: false,
            });
          }
        });
        //separator line
        fav.push({
          text: "---",
          value: null,
          disabled: true,
          divider: true,
        });

        return [...fav, ...converted];
      }
      return [];
    },
    //this section is for errors
    titleErrors() {
      const errors = [];
      if (!this.$v.form.title.$dirty) return errors;
      !this.$v.form.title.required && errors.push("Title is required.");
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.form.gender.$dirty) return errors;
      !this.$v.form.gender.required && errors.push("Gender is required.");
      return errors;
    },
    fnameErrors() {
      const errors = [];
      if (!this.$v.form.fname.$dirty) return errors;
      !this.$v.form.fname.maxLength &&
        errors.push("Name must be at most 40 characters long");
      !this.$v.form.fname.required && errors.push("Name is required.");

      return errors;
    },
    mnameErrors() {
      const errors = [];
      if (!this.$v.form.mname.$dirty) return errors;
      !this.$v.form.mname.maxLength &&
        errors.push("Name must be at most 120 characters long");
      return errors;
    },
    lnameErrors() {
      const errors = [];
      if (!this.$v.form.lname.$dirty) return errors;
      !this.$v.form.lname.maxLength &&
        errors.push("Name must be at most 40 characters long");
      !this.$v.form.lname.required && errors.push("Name is required.");
      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (!this.$v.form.mobile.$dirty) return errors;
      !this.$v.form.mobile.maxLength &&
        errors.push("Mobile number can not be more than 11 digits");
      !this.$v.form.mobile.minLength &&
        errors.push("Mobile number can not be less than 9 digits");
      !this.$v.form.mobile.numeric && errors.push("Only numbers are allowed.");
      !this.$v.form.mobile.required &&
        errors.push("Mobile number is required.");
      return errors;
    },
    landlineErrors() {
      const errors = [];
      if (!this.$v.form.landline.$dirty) return errors;
      !this.$v.form.landline.maxLength &&
        errors.push("Landline number can not be more than 11 digits");
      !this.$v.form.landline.minLength &&
        errors.push("Landline number can not be less than 9 digits");
      !this.$v.form.landline.numeric &&
        errors.push("Only numbers are allowed.");

      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.inemail.$dirty) return errors;
      !this.$v.form.inemail.maxLength &&
        errors.push("E-mail address is too long. Max 60 characters allowed.");
      !this.$v.form.inemail.email && errors.push("Invalid e-mail address");
      !this.$v.form.inemail.required && errors.push("E-mail is required.");

      return errors;
    },
    dobErrors() {
      const errors = [];
      if (!this.$v.form.dob.$dirty) return errors;
      !this.$v.form.dob.required && errors.push("Date of birth  is required.");

      return errors;
    },
    dlnoErrors() {
      const errors = [];
      if (!this.$v.form.dlno.$dirty) return errors;
      !this.$v.form.dlno.maxLength && errors.push("Invalid Licence Number");
      !this.$v.form.dlno.required && errors.push("Licence Number is required.");

      return errors;
    },
    lestypeErrors() {
      const errors = [];
      if (!this.$v.form.lesstype.$dirty) return errors;
      !this.$v.form.lesstype.required &&
        errors.push("Please select lessons type.");

      return errors;
    },
    addressLn1Errors() {
      const errors = [];
      if (!this.$v.form.haline1.$dirty) return errors;
      !this.$v.form.haline1.maxLength &&
        errors.push("Address Line 1 is too long. Max 60 characters allowed.");
      !this.$v.form.haline1.required &&
        errors.push("Address Line 1 is required.");

      return errors;
    },
    addressLn2Errors() {
      const errors = [];
      if (!this.$v.form.haline2.$dirty) return errors;
      !this.$v.form.haline2.maxLength &&
        errors.push("Address Line 2 is too long. Max 60 characters allowed.");

      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.form.hacity.$dirty) return errors;
      !this.$v.form.hacity.maxLength &&
        errors.push("City name is too long. Max 60 characters allowed.");
      !this.$v.form.hacity.required && errors.push("City  is required.");

      return errors;
    },
    countyErrors() {
      const errors = [];
      if (!this.$v.form.hcounty.$dirty) return errors;
      !this.$v.form.hcounty.maxLength &&
        errors.push("County name is too long. Max 60 characters allowed.");

      return errors;
    },
    postcodeErrors() {
      const errors = [];
      if (!this.$v.form.hapostcode.$dirty) return errors;
      !this.$v.form.hapostcode.minLength &&
        errors.push("Postcode is too short. Min 6 characters required.");
      !this.$v.form.hapostcode.maxLength &&
        errors.push("Postcode is too long. Max 9 characters allowed.");
      !this.$v.form.hapostcode.required &&
        errors.push("Postcode  is required.");

      return errors;
    },
    pickPCErrors() {
      const errors = [];
      if (!this.$v.form.pickpostcode.$dirty) return errors;
      !this.$v.form.pickpostcode.minLength &&
        errors.push("Postcode is too short. Min 6 characters required.");
      !this.$v.form.pickpostcode.maxLength &&
        errors.push("Postcode is too long. Max 9 characters allowed.");
      !this.$v.form.pickpostcode.required &&
        errors.push("Postcode  is required.");

      return errors;
    },
    adiGenErrors() {
      const errors = [];
      if (!this.$v.form.adigender.$dirty) return errors;
      !this.$v.form.adigender.required &&
        errors.push("Preferred ADI Gender required.");

      return errors;
    },
    prefcartypeErrors() {
      const errors = [];
      if (!this.$v.form.cartype.$dirty) return errors;
      !this.$v.form.cartype.required &&
        errors.push("Preferred Vehicle Type required.");

      return errors;
    },
    //
    getFrmTitle() {
      if (this.FormType == null || this.FormType == "new") {
        return "Register New Learner";
      } else if (this.FormType == "edit") {
        return "Edit Learner";
      } else if (this.FormType == "view") {
        return "View Learner";
      }
      return null;
    },
    isRegBtn() {
      if (this.FormType == null || this.FormType == "new") {
        return true;
      } else if (this.FormType == "edit") {
        return false;
      } else if (this.FormType == "view") {
        return false;
      }
      return false;
    },
    isEditableBtn() {
      if (this.FormType == null || this.FormType == "new") {
        return false;
      } else if (this.FormType == "edit") {
        return true;
      } else if (this.FormType == "view") {
        return false;
      }
      return false;
    },
    isSaveChangeBtn() {
      if (this.FormType == null || this.FormType == "new") {
        return false;
      } else if (this.FormType == "edit") {
        return true;
      } else if (this.FormType == "view") {
        return false;
      }
      return false;
    },
  },
  methods: {
    ...mapGetters({
      //there is an issue with the getter. it is returning emptu function
      getLearner: `${M_OU}${GET_LEARNER_BY_ID}`,
    }),
    ...mapActions({
      saveNewLearner: `${M_OU}${REGISTER_LEARNER}`,
      loadLearners: `${M_OU}${LOAD_RECENT_LEARNERS}`,
      saveLearnerChanges: `${M_OU}${UPDATE_LEARNER}`,
    }),
    acceptDate({ D, T }) {
      this.form.dob = D;
      if (T) {
        this.$v.form.dob.$touch();
      }
    },
    //this function select the correct gender based on selected title
    selectGender() {
      if (this.form.title && this.titlesList) {
        //there are some data.
        //lets filter through and find out the correct ones
        this.form.gender = this.titlesList.find(
          (rec) => rec.id === this.form.title
        ).gen;
      }
    },
    //to save new learner
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveNewLearner({
          // the data that need to sent to store/server
          form: this.form,
        }).then((res) => {
          //response from store. All complete just need to show to popup message
          //console.log(res);
          if (res == "SUCCESS") {
            this.$fire({
              title: "Success",
              text: "Learner registered successfully.",
              type: "success",
              timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });

            //reload learner info here
            this.loadLearners();
            this.clear();
          } else {
            this.$fire({
              title: "Error",
              text: "Error registering learner. Please try again.",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
    },
    //to save edits
    saveChanges() {
      //
      //console.log("Save Changes...");
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$v.$reset();
        this.saveLearnerChanges({
          // the data that need to sent to store/server
          form: this.form,
          studentid: this.learner,
        }).then((res) => {
          //response from store. All complete just need to show to popup message
          //console.log(res);
          if (res == "SUCCESS") {
            this.$fire({
              title: "Updated",
              text: "Learner updated successfully.",
              type: "success",
              timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });

            //reload learner info here
            this.loadLearners();
          } else {
            this.$fire({
              title: "Error",
              text: "Error updating learner infromation. Please try again.",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
      this.$emit("saveDone");
    },

    clear() {
      this.$v.$reset();

      this.form = {
        title: "",
        gender: "",
        fname: "",
        mname: "",
        lname: "",
        mobile: "",
        landline: "",
        inemail: "",
        dob: "",
        dlno: "",
        lesstype: "",
        haline1: "",
        haline2: "",
        hacity: "",
        hcounty: "",
        hapostcode: "",
        pickpostcode: "",
        adigender: "",
        adilang: "",
        cartype: "",
        state: "",
      };
      this.$emit("saveDone");
      this.$destroy;
    },
    fillData() {
      let temp = this.getAll.find((stu) => stu.student_id === this.learner);
      this.form = {
        title: temp.title,
        gender: temp.gender,
        fname: temp.fname,
        mname: temp.mname,
        lname: temp.lname,
        mobile: temp.mobile,
        landline: temp.landline,
        inemail: temp.email,
        dob: temp.dob,
        dlno: temp.driving_licence_no,
        lesstype: temp.lessons_type,
        haline1: temp.address.line1,
        haline2: temp.address.line2,
        hacity: temp.address.city,
        hcounty: temp.address.county,
        hapostcode: temp.address.postcode,
        pickpostcode: temp.pickup_pc,
        adigender: temp.req_adi_gender.toLowerCase(),
        adilang: temp.req_language,
        cartype: temp.req_car_type,
        state: temp.accState,
      };
    },
  },
  watch: {
    learner: function (newLeaner, oldLearner) {
      if (newLeaner && this.FormType != "new") {
        this.isFormLocked = !this.frmLock;
        this.fillData();
      }
      //console.log(newLeaner);
      console.log(oldLearner);
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.isFormLocked = !this.frmLock;
      //lets use the learner ID to populate data
      //let temp = this.getLearner(this.learner);
      //console.log(temp.fname);

      // console.log(this.FormType);
      //console.log(this.learner);
      if (this.FormType != "new") {
        this.fillData();
      }
      // console.log(this.isFormLocked);
    });
  },
};
</script>

<style></style>
