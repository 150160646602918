<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5"
        ><h2>Accept Learner Payments</h2>
        <v-data-table
          :headers="headers"
          :items="recentLearners"
          :search="keyword"
          class="elevation-1"
          @click:row="openEdit"
        >
          <template v-slot:top>
            <v-text-field
              v-model="keyword"
              label="Search"
              placeholder="Enter keyword to search"
              class="ma-4"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-dialog v-model="dialog" max-width="960px" scrollable persistent>
              <v-card>
                <v-card-title class="text-h5 blue lighten-2">
                  <span>Accept Learner Payment</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-stepper v-model="e1">
                      <v-stepper-header>
                        <v-stepper-step :complete="e1 > 1" step="1">
                          Confirm Learner
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="e1 > 2" step="2">
                          Payment Deatils
                        </v-stepper-step>
                      </v-stepper-header>

                      <v-stepper-items>
                        <v-stepper-content step="1">
                          <v-card
                            class="mb-12"
                            color="grey lighten-1"
                            height="400px"
                          >
                            <v-simple-table v-if="selectedLearner">
                              <thead>
                                <tr>
                                  <th class="text-left" style="width: 20%"></th>
                                  <th class="text-left" style="width: 80%"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Learner ID</th>
                                  <th>L{{ selectedLearner.student_id }}</th>
                                </tr>
                                <tr>
                                  <th>Name</th>
                                  <td>
                                    {{ selectedLearner.title }}
                                    {{ selectedLearner.fname }}
                                    {{ selectedLearner.lname }}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Mobile Number</th>
                                  <td>
                                    {{ selectedLearner.mobile | mobileno }}
                                  </td>
                                </tr>
                                <tr>
                                  <th>E-mail</th>
                                  <td>{{ selectedLearner.email }}</td>
                                </tr>
                                <tr>
                                  <th>Provisional Licence Number</th>
                                  <td>
                                    {{ selectedLearner.driving_licence_no }}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Lesson Type</th>
                                  <td style="text-transform: capitalize">
                                    {{ selectedLearner.lessons_type }}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Address</th>
                                  <td>
                                    {{
                                      selectedLearner.address.line1
                                        | commaForAddr
                                    }}
                                    {{
                                      selectedLearner.address.line2
                                        | commaForAddr
                                    }}
                                    {{
                                      selectedLearner.address.city
                                        | commaForAddr
                                    }}
                                    {{ selectedLearner.address.postcode }}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Pickup Postcode</th>
                                  <td>{{ selectedLearner.pickup_pc }}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </v-card>

                          <v-btn color="primary" @click="e1 = 2">
                            Continue
                          </v-btn>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                          <v-card class="mb-12" color="grey lighten-1">
                            <v-simple-table v-if="selectedLearner">
                              <thead>
                                <tr>
                                  <th class="text-left" style="width: 20%"></th>
                                  <th class="text-left" style="width: 80%"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Learner</th>
                                  <th>
                                    L{{ selectedLearner.student_id }} -
                                    {{ selectedLearner.title }}
                                    {{ selectedLearner.fname }}
                                    {{ selectedLearner.lname }}
                                  </th>
                                </tr>
                                <tr>
                                  <th>Lesson Plan</th>
                                  <td>
                                    <!--v-select
                                      v-model="form.lessonplanid"
                                      :items="getSuitablePlans"
                                      label="Lesson Package"
                                      item-text="text_description"
                                      item-value="plan_id"
                                      outlined
                                      return-object
                                    ></v-select-->
                                    <v-select
                                      v-model="form.lessonplanid"
                                      label="Lesson Package"
                                      :items="getSuitablePlans"
                                      item-value="plan_id"
                                      outlined
                                      return-object
                                    >
                                      <template v-slot:item="{ item }">
                                        <div class="mainrow">
                                          <div class="colA">
                                            {{ `${item.text_description}` }}
                                          </div>
                                          <div class="colB">£</div>
                                          <div class="colC text-right">
                                            {{ `${item.plan_price}` }}
                                          </div>
                                        </div>
                                      </template>
                                      <template v-slot:selection="{ item }">
                                        <span>
                                          {{ `${item.text_description}` }}</span
                                        >
                                      </template>
                                    </v-select>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Additional Costs</th>
                                  <td>£00.00</td>
                                </tr>
                                <tr>
                                  <th>Card Details</th>
                                  <td></td>
                                </tr>
                                <tr>
                                  <th></th>
                                  <td>
                                    <v-text-field
                                      label="Name (as it appears on the card)"
                                      placeholder="Name as it appears on the card"
                                      outlined
                                      clearable
                                      counter="22"
                                    ></v-text-field>
                                    <v-text-field
                                      label="Card Number"
                                      placeholder="16 Digit Card number without spaces"
                                      outlined
                                      clearable
                                      counter="16"
                                    ></v-text-field>
                                    <v-text-field
                                      label="Expiary Date"
                                      placeholder="MM/YY"
                                      outlined
                                      clearable
                                      counter="5"
                                    ></v-text-field>
                                    <v-text-field
                                      label="Security Code or CVV2"
                                      placeholder="3 or 4 digits normally displayed on the back of the card"
                                      outlined
                                      clearable
                                      counter="4"
                                    ></v-text-field>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Billing Information</th>
                                  <td></td>
                                </tr>
                                <tr>
                                  <th></th>
                                  <td>
                                    <v-text-field
                                      label="Card Holder's Name"
                                      placeholder="Card holder's name"
                                      outlined
                                      clearable
                                      counter="50"
                                    ></v-text-field>
                                    <v-text-field
                                      label="First Line of Address"
                                      placeholder="First line of address where the card is registered"
                                      outlined
                                      clearable
                                      counter="16"
                                    ></v-text-field>
                                    <v-text-field
                                      label="Postcode"
                                      placeholder="Postcode where the card is registered"
                                      outlined
                                      clearable
                                      counter="9"
                                    ></v-text-field>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Total Ammount</th>
                                  <td>{{ getTotal }}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </v-card>

                          <v-btn color="primary" @click="processPay()">
                            Process Payment
                          </v-btn>

                          <v-btn text @click="e1 = 1"> Back </v-btn>
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                  </v-container>
                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:[`item.student_id`]="{ item }">
            <strong>{{ "L" + item.student_id }}</strong>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ `${item.title} ${item.fname} ${item.lname}` }}
          </template>
          <template v-slot:[`item.mobile`]="{ item }">
            {{ item.mobile | mobileno }}
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_RECENT_LEARNERS,
  GET_LESSON_PLANS_ACTIVE,
  PROCESS_LEARNER_PAYMENT,
  LOAD_RECENT_LEARNERS,
  M_OU,
  M_COMMON,
} from "@/store/mutation-list";
export default {
  name: "AcceptPayments",
  //   components: { LearnerForm },
  data: () => {
    return {
      e1: 1,
      frmType: "edit",
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Accept Payments",
          disabled: true,
          href: "/dashboard/acceptpayments",
        },
      ],
      dialog: false,
      //passingID: null,
      selectedLearner: null,
      //data table realated data
      keyword: "",
      headers: [
        { text: "Learner ID", value: "student_id" },
        { text: "Name", value: "name" },
        { text: "Mobile", value: "mobile" },
        { text: "E-mail", value: "email" },
        { text: "Pickup Postcode", value: "pickup_pc" },
        { text: "Date Joined", value: "created" },
      ],
      form: {
        learnerid: 0,
        lessonplanid: null,
      },
      payObj: {},
    };
  },
  computed: {
    ...mapGetters({
      recentLearners: `${M_OU}${GET_RECENT_LEARNERS}`, //make sure to filter for active learners
      lessonPacks: `${M_COMMON}${GET_LESSON_PLANS_ACTIVE}`,
    }),
    getSuitablePlans() {
      //console.log("Will return suitable plans");
      if (this.selectedLearner) {
        return this.lessonPacks.filter((pack) => {
          return pack.gear_type == this.selectedLearner.lessons_type;
        });
      }
      return null;
      //return suit;
    },
    getTotal() {
      if (this.form.lessonplanid) {
        return "£" + this.form.lessonplanid.plan_price;
      }
      return "N/A";
    },
  },
  methods: {
    ...mapActions({
      proceesLP: `${M_OU}${PROCESS_LEARNER_PAYMENT}`,
      loadLearners: `${M_OU}${LOAD_RECENT_LEARNERS}`,
    }),
    openEdit(learner) {
      //console.log(learner);
      this.form.learnerid = learner.student_id;
      this.selectedLearner = learner;
      //do all the work then
      this.dialog = true;
    },
    processPay() {
      //this method will process payment.
      //need to sure datafiels not empty
      if (this.form.lessonplanid) {
        //lets hide the dialog without clearing
        this.dialog = false;
        this.proceesLP({
          // the data that need to sent to store/server
          form: {
            learnerid: this.form.learnerid,
            lessonplanid: this.form.lessonplanid.plan_id,
          },
        }).then((res) => {
          //response from store. All complete just need to show to popup message
          //console.log(res);
          this.close();
          if (res == "SUCCESS") {
            this.$fire({
              title: "Success",
              text: "Payment proccessed successfuly. Please assign an Instructor to learner",
              type: "success",
              timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          } else {
            this.$fire({
              title: "Error",
              text: "Error processing payment. Please try again.",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
    },
    close() {
      //remove data object
      this.dialog = false;
      this.e1 = 1;
      this.form.learnerid = 0;
      this.form.lessonplanid = null;
    },
  },
  created() {
    this.loadLearners();
  },
};
</script>

<style>
.mainrow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 5px;
  width: 100%;
}
.mainrow .colA {
  width: 70%;
  flex-grow: 4;
}
.mainrow .colB {
  display: inline;
  width: 1%;
}
.mainrow .colC {
  display: inline;
  width: 29%;
  flex-grow: 2;
}
</style>
