<template>
  <v-col cols="12">
    <v-divider />
    <div class="mt-5">
      <h3 class="text-center">Summary Chart</h3>
      <GChart
        type="AreaChart"
        :settings="{ packages: ['corechart'] }"
        :data="genGChart"
        :options="chartOptions"
        :title="chartOptions.chart.title"
      />
    </div>
  </v-col>
</template>
<script>
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "InstructorPCandComplaintChart",
  components: { GChart },
  props: ["pcData", "compData"],
  data() {
    return {
      chartOptions: {
        chart: {
          title: "Instructor Covering Postcode and Complaints Levels",
          subtitle: "Instructors and Complaints per Postcode",
        },
        legend: { topic: "top", position: "right", maxLines: 3 },
        // isStacked: "percent",
        height: 600,
        // curveType: "function",
        // vAxis: { minValue: 1 },
        is3D: true,
      },
    };
  },
  methods: {
    getTableKeys(arr) {
      let keys = [];
      if (arr) {
        arr.forEach(function (rec) {
          var key = JSON.stringify(rec.key).replaceAll('"', "");
          keys.push(key);
        });
      }
      return keys;
    },
  },
  computed: {
    genGChart() {
      //this will generate the whole line chart
      let retar = [];

      if (this.compData) {
        retar.push(["Outer Postcode", "Covering ADIs", "Complaints"]);
        this.pcData.forEach((rec) => {
          let obj = this.compData.find((comp) => comp.key === rec.key);
          //there is a match
          if (obj) {
            //lets add that to array
            retar.push([rec.key, rec.count, obj.count]);
          } else {
            //ther is no match so add zero
            retar.push([rec.key, rec.count, 0]);
          }
        });
      } else {
        retar.push(["Outer Postcode", "Covering ADIs"]);
        this.pcData.forEach((rec) => {
          retar.push([rec.key, rec.count]);
        });
      }

      return retar;
    },
  },
};
</script>
