<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5">
        <h2>Current Lesson Packages</h2>
        <v-divider></v-divider>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Package</th>
                <th class="text-left">Gear Type</th>
                <th class="text-left">Credits</th>
                <th class="text-right">Price</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in currentPackages" :key="item.plan_id">
                <td>{{ item.text_description }}</td>
                <td style="text-transform: capitalize">{{ item.gear_type }}</td>
                <td>{{ item.hours }}</td>
                <td class="text-right">£{{ item.plan_price }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-sheet>
      <v-sheet color="white" elevation="1" class="pa-5">
        <h2>Create New Lesson Package</h2>
        <v-divider></v-divider>
        <form>
          <v-row justify="center">
            <v-col md="4">
              <!-- <v-header name="Holds"> &nbsp;</v-header> -->
              <v-text-field
                v-model="form.desc"
                label="Plan Description"
                counter="150"
                required
                placeholder="eg:- Manual 1 Hours Lesson - £25"
                prepend-inner-icon="mdi-form-textbox"
                :error-messages="descErrors"
                @input="$v.form.desc.$touch()"
                @blur="$v.form.desc.$touch()"
              ></v-text-field>

              <v-text-field
                v-model="form.price"
                label="Package Price"
                type="number"
                required
                placeholder="eg:- 25.00"
                prepend-inner-icon="mdi-currency-gbp"
                :error-messages="priceErrors"
                @input="$v.form.price.$touch()"
                @blur="$v.form.price.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="form.hours"
                label="Package Total Hours"
                required
                type="number"
                placeholder="eg:- 1"
                prepend-inner-icon="mdi-timer-settings"
                :error-messages="hoursErrors"
                @input="$v.form.hours.$touch()"
                @blur="$v.form.hours.$touch()"
              ></v-text-field>
              <v-select
                v-model="form.gears"
                :items="gearTypesList"
                item-text="txt"
                item-value="id"
                label="Gear Type"
                required
                type="number"
                placeholder="eg:- 1"
                prepend-inner-icon="mdi-cog "
                :error-messages="gearsErrors"
                @input="$v.form.gears.$touch()"
                @blur="$v.form.gears.$touch()"
              >
              </v-select>

              <v-text-field
                v-model="form.promocode"
                :counter="10"
                label="Promotion Code"
                clearable
                prepend-inner-icon="mdi-sale"
                :error-messages="promoCodeErrors"
                @input="$v.form.promocode.$touch()"
                @blur="$v.form.promocode.$touch()"
              ></v-text-field>

              <v-btn color="success" class="mt-3" @click="submit"
                >Add Package
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import {
  SET_LESSON_PLAN,
  GET_LESSON_PLANS_ACTIVE,
  LOAD_LESSONS_PLANS,
  M_COMMON,
  M_OA,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";

import {
  required,
  numeric,
  decimal,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "AddLessonPlan",

  data: () => ({
    items: [
      {
        text: "Admin Dashboard",
        disabled: false,
        href: "/dashboard/adminhome",
      },
      {
        text: "Add Lesson Plan",
        disabled: true,
        href: "/dashboard/addlessonplan",
      },
    ],
    gearTypesList: [
      { id: "automatic", txt: "Automatic" },
      { id: "manual", txt: "Manual" },
    ],
    genders: [
      { id: "f", txt: "Female" },
      { id: "m", txt: "Male" },
    ],
    datePickDirty: false,
    form: {
      desc: "",
      price: "",
      hours: "",
      gears: "",
      promocode: "",
    },
  }),
  validations: {
    form: {
      desc: { required, maxLength: maxLength(150) },
      price: { required, decimal },
      hours: { required, numeric },
      gears: { required },
      promocode: { maxLength: maxLength(10) },
    },
  },
  computed: {
    ...mapGetters({
      currentPackages: `${M_COMMON}${GET_LESSON_PLANS_ACTIVE}`,
    }),

    descErrors() {
      const errors = [];
      if (!this.$v.form.desc.$dirty) return errors;
      !this.$v.form.desc.maxLength && errors.push("Description is too long");
      !this.$v.form.desc.required && errors.push("Description is required.");
      return errors;
    },
    priceErrors() {
      const errors = [];
      if (!this.$v.form.price.$dirty) return errors;
      !this.$v.form.price.decimal &&
        errors.push("Package Price must be a number");
      !this.$v.form.price.required && errors.push("Package Price is required.");
      return errors;
    },
    hoursErrors() {
      const errors = [];
      if (!this.$v.form.hours.$dirty) return errors;
      !this.$v.form.hours.numeric &&
        errors.push("Package Total Hours must be a number.");
      !this.$v.form.hours.required &&
        errors.push("Package Total Hours is required.");
      return errors;
    },
    gearsErrors() {
      const errors = [];
      if (!this.$v.form.gears.$dirty) return errors;
      !this.$v.form.gears.required && errors.push("Gear Type is required.");
      return errors;
    },
    promoCodeErrors() {
      const errors = [];
      if (!this.$v.form.promocode.$dirty) return errors;
      !this.$v.form.promocode.maxLength &&
        errors.push("Promotion code can not be more than 10 characters long");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      saveNewPlan: `${M_OA}${SET_LESSON_PLAN}`,
      loadPlans: `${M_COMMON}${LOAD_LESSONS_PLANS}`,
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveNewPlan({
          // the data that need to sent to store/server
          desc: this.form.desc,
          price: this.form.price,
          hours: this.form.hours,
          gears: this.form.gears,
          promocode: this.form.promocode,
        }).then((res) => {
          //response from store. All complete just need to show to popup message
          //console.log(res);
          if (res == "SUCCESS") {
            this.$fire({
              title: "Success",
              text: "Lesson Package added successfully",
              type: "success",
              timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
            this.clear();
            this.loadPlans();
          } else {
            this.$fire({
              title: "Error",
              text: "Error adding lesson package. Please try again",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
    },
    clear() {
      this.$v.$reset();

      this.form = {
        desc: "",
        price: "",
        hours: "",
        gears: "",
        promocode: "",
      };
    },
  },
};
</script>

<style></style>
