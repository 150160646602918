<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col
      ><v-sheet color="white" elevation="1" class="pa-5">
        <h2>Add/Manage Quick Information</h2>
        <v-divider class="pt-5"></v-divider>
        <v-row>
          <v-col md="3">
            <h4>Full Address</h4>
          </v-col>
          <v-col md="4">
            <v-text-field
              v-model="form.adtxt1"
              :counter="50"
              label="Company Name"
              placeholder="Company Name"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.adtxt2"
              :counter="50"
              label="Line 1"
              placeholder="Address Line 1"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.adtxt3"
              :counter="50"
              label="Line 2"
              placeholder="Address Line 2"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.adtxt4"
              :counter="50"
              label="City"
              placeholder="City"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.adtxt5"
              :counter="50"
              label="County"
              placeholder="County"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.adtxt6"
              :counter="9"
              label="Postcode"
              placeholder="Postcode"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.adtxt7"
              :counter="56"
              label="Country"
              placeholder="Country"
              filled
              clearable
            ></v-text-field>
          </v-col>
          <v-col md="4"> </v-col>
        </v-row>
        <v-divider class="pt-5"></v-divider>
        <v-row>
          <v-col md="3">
            <h4>Opening Hours</h4>
          </v-col>
          <v-col md="4">
            <v-text-field
              v-model="form.oalbl1"
              :counter="15"
              label="Day"
              placeholder="Day"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.oalbl2"
              :counter="15"
              label="Day"
              placeholder="Day"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.oalbl3"
              :counter="15"
              label="Day"
              placeholder="Day"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.oalbl4"
              :counter="15"
              label="Day"
              placeholder="Day"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.oalbl5"
              :counter="15"
              label="Day"
              placeholder="Day"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.oalbl6"
              :counter="15"
              label="Day"
              placeholder="Day"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.oalbl7"
              :counter="15"
              label="Day"
              placeholder="Day"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.oalbl8"
              :counter="15"
              label="Day"
              placeholder="Day"
              hint="Bank Holidays or Special Days"
              filled
              clearable
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <v-text-field
              v-model="form.oatxt1"
              :counter="15"
              label="Opening Times"
              placeholder="(Opening) To (Closing)"
              hint="eg:- 08:00 - 19:00"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.oatxt2"
              :counter="15"
              label="Opening Times"
              placeholder="(Opening) To (Closing)"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.oatxt3"
              :counter="15"
              label="Opening Times"
              placeholder="(Opening) To (Closing)"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.oatxt4"
              :counter="15"
              label="Opening Times"
              placeholder="(Opening) To (Closing)"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.oatxt5"
              :counter="15"
              label="Opening Times"
              placeholder="(Opening) To (Closing)"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.oatxt6"
              :counter="15"
              label="Opening Times"
              placeholder="(Opening) To (Closing)"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.oatxt7"
              :counter="15"
              label="Opening Times"
              placeholder="(Opening) To (Closing)"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.oatxt8"
              :counter="15"
              label="Opening Times"
              placeholder="(Opening) To (Closing)"
              hint="Just add Closed if you are not opening"
              filled
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="pt-5"></v-divider>
        <v-row>
          <v-col md="3">
            <h4>Contact Details</h4>
          </v-col>
          <v-col md="4">
            <v-text-field
              v-model="form.ctlbl1"
              :counter="15"
              label="Title"
              placeholder="Title"
              hint="Landline 1"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.ctlbl2"
              :counter="15"
              label="Title"
              placeholder="Title"
              hint="Landline 2"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.ctlbl3"
              :counter="15"
              label="Title"
              placeholder="Title"
              hint="E-mail"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.ctlbl4"
              :counter="15"
              label="Title"
              placeholder="Title"
              hint="Out of Office"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.ctlbl5"
              :counter="15"
              label="Title"
              placeholder="Title"
              hint="Complaints"
              filled
              clearable
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <v-text-field
              v-model="form.cttxt1"
              :counter="50"
              label="Value"
              placeholder="Value"
              hint="eg:- 020 8123 4567"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.cttxt2"
              :counter="50"
              label="Value"
              placeholder="Value"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.cttxt3"
              :counter="50"
              label="Value"
              placeholder="Value"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.cttxt4"
              :counter="50"
              label="Value"
              placeholder="Value"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.cttxt5"
              :counter="50"
              label="Value"
              placeholder="Value"
              filled
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="pt-5"></v-divider>
        <v-row>
          <v-col md="3">
            <h4>Bank Details</h4>
          </v-col>
          <v-col md="4">
            <v-text-field
              v-model="form.bdtxt1"
              :counter="150"
              label="Bank Name"
              placeholder="Bank Name"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.bdtxt2"
              :counter="150"
              label="Account Name"
              placeholder="Account Name"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.bdtxt3"
              :counter="10"
              label="Sort Code"
              placeholder="Sort Code"
              hint="xx-xx-xx"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.bdtxt4"
              :counter="10"
              label="Account Number"
              placeholder="Account Number"
              filled
              clearable
            ></v-text-field>
            <v-text-field
              v-model="form.bdtxt5"
              :counter="100"
              label="Payment Reference"
              placeholder="Payment Reference"
              filled
              clearable
            ></v-text-field>
          </v-col>
          <v-col md="4"> </v-col>
        </v-row>
        <v-row>
          <v-col md="4"></v-col>
          <v-col md="4"
            ><v-btn color="success" @click="saveQuickInfo"
              >Save Quick Info</v-btn
            ></v-col
          >
        </v-row>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import {
  GET_QUICK_INFO,
  M_COMMON,
  M_OA,
  LOAD_QUICK_INFO,
  SET_QUICK_INFO,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ManageQuickInfo",

  data: () => ({
    items: [
      {
        text: "Admin Dashboard",
        disabled: false,
        href: "/dashboard/adminhome",
      },
      {
        text: "Manage Quick Info",
        disabled: true,
        href: "/dashboard/managequickinfo",
      },
    ],
    form: {
      adtxt1: "",
      adtxt2: "",
      adtxt3: "",
      adtxt4: "",
      adtxt5: "",
      adtxt6: "",
      adtxt7: "",

      oalbl1: "",
      oatxt1: "",

      oalbl2: "",
      oatxt2: "",

      oalbl3: "",
      oatxt3: "",

      oalbl4: "",
      oatxt4: "",

      oalbl5: "",
      oatxt5: "",

      oalbl6: "",
      oatxt6: "",

      oalbl7: "",
      oatxt7: "",

      oalbl8: "",
      oatxt8: "",

      ctlbl1: "",
      cttxt1: "",

      ctlbl2: "",
      cttxt2: "",

      ctlbl3: "",
      cttxt3: "",

      ctlbl4: "",
      cttxt4: "",

      ctlbl5: "",
      cttxt5: "",

      bdtxt1: "",
      bdtxt2: "",
      bdtxt3: "",
      bdtxt4: "",
      bdtxt5: "",
    },
  }),
  computed: {
    ...mapGetters({
      quickInfoData: `${M_COMMON}${GET_QUICK_INFO}`,
    }),
  },
  methods: {
    ...mapActions({
      saveChanges: `${M_OA}${SET_QUICK_INFO}`,
      loadQuickInfo: `${M_COMMON}${LOAD_QUICK_INFO}`,
    }),
    initData() {
      this.loadQuickInfo();
      //let qidata = this.$store.getters["M_COMMON/GET_QUICK_INFO"];
      let qidata = this.quickInfoData;
      //console.log(qidata);
      //need to do this just for temp
      //qidata = JSON.parse(qidata);

      let io = 1;
      for (const [key, val] of Object.entries(qidata.opening_hours)) {
        this.form["oalbl" + io] = key;
        this.form["oatxt" + io] = val;
        io++;
      }

      let ic = 1;
      for (const [key, val] of Object.entries(qidata.contact_details)) {
        this.form["ctlbl" + ic] = key;
        this.form["cttxt" + ic] = val;
        ic++;
      }

      let ib = 1;
      for (const [, val] of Object.entries(qidata.bank_details)) {
        this.form["bdtxt" + ib] = val;
        ib++;
      }

      let ad = 1;
      for (const [, val] of Object.entries(qidata.address_lines)) {
        this.form["adtxt" + ad] = val;
        ad++;
      }
    },
    saveQuickInfo() {
      this.saveChanges({
        // the data that need to sent to store/server
        frm: this.form,
      }).then((res) => {
        //response from store. All complete just need to show to popup message
        //console.log(res);
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Quick Information updated.",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
          this.loadQuickInfo();
        } else {
          this.$fire({
            title: "Error",
            text: "Error updating quick infomarion. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
  },
  mounted: function () {
    //lets get the data and fill it in form
    this.initData();
  },
};
</script>

<style></style>
