<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5"
        ><h2>Financial & Management Reports</h2>
        <!-- <p class="sub-title">Choose Report</p> -->
        <v-row>
          <v-col md="4">
            <v-list dense>
              <v-subheader><h2>Learner Reports</h2></v-subheader>
              <v-list-item-group v-model="repselectMan" color="primary">
                <v-list-item
                  v-for="(item, i) in manReports"
                  :key="i"
                  @click="clikcedRep(2, item.id)"
                  :disabled="item.disabled"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><span v-html="`${item.id}. ${item.text}`"></span
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col md="4">
            <v-list dense>
              <v-subheader><h2>Instructor Reports</h2></v-subheader>
              <v-list-item-group v-model="repselectADI" color="primary">
                <v-list-item
                  v-for="(item, i) in instructorReports"
                  :key="i"
                  @click="clikcedRep(5, item.id)"
                  :disabled="item.disabled"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><span v-html="`${item.id}. ${item.text}`"></span
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-list dense>
              <v-subheader><h2>Feedback Reports</h2></v-subheader>
              <v-list-item-group v-model="repselectFeedB" color="primary">
                <v-list-item
                  v-for="(item, i) in feedbackReports"
                  :key="i"
                  @click="clikcedRep(3, item.id)"
                  :disabled="item.disabled"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><span v-html="`${item.id}. ${item.text}`"></span
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col md="4"
            ><v-list dense>
              <v-subheader><h2>Complaints Reports</h2></v-subheader>
              <v-list-item-group v-model="repselectComp" color="primary">
                <v-list-item
                  v-for="(item, i) in complaintReports"
                  :key="i"
                  @click="clikcedRep(4, item.id)"
                  :disabled="item.disabled"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><span v-html="`${item.id}. ${item.text}`"></span
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-list dense>
              <v-subheader><h2>Financial Reports</h2></v-subheader>
              <v-list-item-group v-model="repselectFin" color="primary">
                <v-list-item
                  v-for="(item, i) in financialReports"
                  :key="i"
                  @click="clikcedRep(1, item.id)"
                  :disabled="item.disabled"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><span v-html="`${item.id}. ${item.text}`"></span
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-dialog
            v-model="dialog"
            max-width="1100"
            transition="dialog-top-transition"
          >
            <v-card>
              <v-card-title class="text-h5">
                Please select reporting time range
              </v-card-title>

              <v-card-text>
                <DateRangePicker
                  @ranged="genReport"
                  v-if="selectedReport"
                  :key="selectedReport"
                />
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning darken-1" text @click="dialog = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import DateRangePicker from "@/components/Reports/DateRangePicker";
import {
  LOAD_MANAGEMENT_REPORT,
  M_OU,
  LOAD_FEEDBACK_REPORT,
  LOAD_COMPLAINTS_REPORT,
  LOAD_INSTUCTOR_REPORTS,
} from "@/store/mutation-list";
import { mapActions } from "vuex";
export default {
  name: "ManagementReportView",
  components: {
    DateRangePicker,
  },
  data: function () {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Financial & Management Reports",
          disabled: true,
          href: "/dashboard/managementreports",
        },
      ],
      repselectFin: null,
      repselectMan: null,
      repselectFeedB: null,
      repselectComp: null,
      repselectADI: null,
      selectedReport: "",
      selectedRepType: "",
      reportingTypes: [
        { id: 1, text: "Financial Reports", disabled: false },
        { id: 2, text: "Management Reports", disabled: false },
        { id: 3, text: "Feedback Reports", disabled: false },
        { id: 4, text: "Complaint Reports", disabled: false },
        { id: 5, text: "Instructor Reports", disabled: false },
      ],

      showTable: false,
      tableData: null,
      showRatingsProgress: false,
      ratingsData: null,
      list1: 0,
      showComplaintsTable: false,
      complaintsData: null,
      //addtional data
      compalintStatus: null,
      outpcData: null,
      //adi reports
      showADIVehicleTable: false,
      adiVehicleData: null,
      dialog: false, //the date picking dialog
    };
  },
  methods: {
    routeto(val) {
      this.$router.push({
        name: "ViewReport",
        params: {
          obj: {
            repName: this.reports[this.selectedReport - 1],
            selRepType: this.selectedRepType,
            reportNo: this.selectedReport,
            start: val.data.s,
            end: val.data.e,
          },
        },
      });
    },
    ...mapActions({
      requestReport: `${M_OU}${LOAD_MANAGEMENT_REPORT}`,
      requestFeedbackReport: `${M_OU}${LOAD_FEEDBACK_REPORT}`,
      requestComplaintsReport: `${M_OU}${LOAD_COMPLAINTS_REPORT}`,
      requestInstructorReport: `${M_OU}${LOAD_INSTUCTOR_REPORTS}`,
    }),
    //this method to select which report is bean selected
    clikcedRep(repCat, selrep) {
      this.dialog = true;
      switch (repCat) {
        case 1:
          this.repselectMan = null;
          this.repselectFeedB = null;
          this.repselectComp = null;
          this.repselectADI = null;
          this.selectedRepType = 1;
          break;
        case 2:
          //this.repselectMan = null;
          this.repselectFeedB = null;
          this.repselectComp = null;
          this.repselectADI = null;
          this.selectedRepType = 2;
          break;
        case 3:
          this.repselectMan = null;
          //this.repselectFeedB = null;
          this.repselectComp = null;
          this.repselectADI = null;
          this.selectedRepType = 3;
          break;
        case 4:
          this.repselectMan = null;
          this.repselectFeedB = null;
          //this.repselectComp = null;
          this.repselectADI = null;
          this.selectedRepType = 4;
          break;
        case 5:
          this.repselectMan = null;
          this.repselectFeedB = null;
          this.repselectComp = null;
          //this.repselectADI = null;
          this.selectedRepType = 5;
          break;

        default:
          break;
      }
      this.clearSubReport();
      //this.selectedReport = null;
      this.selectedReport = selrep;
    },
    genReport(val) {
      //console.log("Generete report");
      //console.log(val.data.e);
      //console.log(val.data.s);
      //doing the request
      this.routeto(val);
    },
    clearSubReport() {
      this.showTable = null;
      this.tableData = null;
      this.showRatingsProgress = null;
      this.complaintsData = null;
      this.compalintStatus = null;
      this.adiVehicleData = null;
    },
    clearMainReport() {
      this.clearSubReport();
      this.selectedReport = null;
    },
  },
  computed: {
    reports() {
      if (this.selectedRepType == 1) {
        return this.financialReports;
      } else if (this.selectedRepType == 2) {
        return this.manReports;
      } else if (this.selectedRepType == 3) {
        return this.feedbackReports;
      } else if (this.selectedRepType == 4) {
        return this.complaintReports;
      } else if (this.selectedRepType == 5) {
        return this.instructorReports;
      } else {
        return this.manReports;
      }
    },
    manReports() {
      return [
        { id: 1, text: "Total Number of New Learners", disabled: false },
        {
          id: 2,
          text: "Time to Schedule the 1<sup>st</sup> Lesson in Comparison to Date of Making the 1<sup>st</sup> Payment",
          disabled: false,
        },
        {
          id: 3,
          text: "Total Number of Learners Rescheduled their Bookings",
          disabled: false,
        },
        {
          id: 4,
          text: "Total Number of Learners Cancelled their Bookings",
          disabled: false,
        },
        {
          id: 5,
          text: "Total Number of Learners Departed the Company",
          disabled: true,
        },
        {
          id: 6,
          text: "Total Number of Learners Passed their Test at 1<sup>st</sup> Attempt",
          disabled: false,
        },
        {
          id: 7,
          text: "Total Number of Learners Passed their Test at 2<sup>nd</sup> Attempt",
          disabled: false,
        },
        {
          id: 8,
          text: "Total Number of Learners Passed their Test at 3<sup>rd</sup> Attempt",
          disabled: false,
        },
        {
          id: 9,
          text: "Total Number of Learners Passed their Test at 4<sup>th</sup> Attempt",
          disabled: false,
        },
        {
          id: 10,
          text: "Total Number of Learners Passed their Test at 5<sup>th</sup> Attempt",
          disabled: false,
        },
        {
          id: 11,
          text: "Total Number of Learners Passed their Test at 6<sup>th</sup> Attempt",
          disabled: false,
        },
        {
          id: 12,
          text: "Total Number of Learners Failed their Test at 1<sup>st</sup> Attempt",
          disabled: false,
        },
        {
          id: 13,
          text: "Total Number of Learners Failed their Test at 2<sup>nd</sup> Attempt",
          disabled: false,
        },
        {
          id: 14,
          text: "Total Number of Learners Failed their Test at 3<sup>rd</sup> Attempt",
          disabled: false,
        },
        {
          id: 15,
          text: "Total Number of Learners Failed their Test at 4<sup>th</sup> Attempt",
          disabled: false,
        },
        {
          id: 16,
          text: "Total Number of Learners Failed their Test at 5<sup>th</sup> Attempt",
          disabled: false,
        },
        {
          id: 17,
          text: "Total Number of Learners Failed their Test at 6<sup>th</sup> Attempt",
          disabled: false,
        },
        { id: 18, text: "Total Number of Lessons Taken", disabled: false },
        { id: 19, text: "Ratio of Gender", disabled: false },
        { id: 20, text: "Ratio of Age Groups", disabled: false },
        { id: 21, text: "Ratio of Gear Types ", disabled: false },
        {
          id: 22,
          text: "Total Number of Learners in  Geographical Areas",
          disabled: false,
        },
      ];
    },
    feedbackReports() {
      return [
        { id: 1, text: "All Learner Feedbacks", disabled: false },
        {
          id: 2,
          text: "Total Number of Learner Feedback Questions Asked",
          disabled: false,
        },
        {
          id: 3,
          text: "Total Number of Learner Feedback Questions Answered",
          disabled: false,
        },
        {
          id: 4,
          text: "Learner Feedback Ratings Stars",
          disabled: false,
        },
        { id: 5, text: "All Instructor Feedbacks", disabled: false },
        {
          id: 6,
          text: "Total Number of Instructor Feedback Questions Asked",
          disabled: false,
        },
        {
          id: 7,
          text: "Total Number of Instructor Feedback Questions Answered",
          disabled: false,
        },
        {
          id: 8,
          text: "Instructor Feedback Ratings Stars",
          disabled: false,
        },
      ];
    },
    complaintReports() {
      return [
        {
          id: 1,
          text: "Active Complaints Report",
          disabled: false,
        },
        {
          id: 2,
          text: "Resolved Complaints Report",
          disabled: false,
        },
        {
          id: 3,
          text: "Number of Total Complaints",
          disabled: false,
        },
        {
          id: 4,
          text: "Number of Total Complaints Resolved",
          disabled: false,
        },
        {
          id: 5,
          text: "Number of Total Complaints Unresolved and Time-out",
          disabled: false,
        },
        {
          id: 6,
          text: "Complaint Types",
          disabled: false,
        },
        {
          id: 7,
          text: "Average Time Duration to Resolve Each Complaints Type",
          disabled: false,
        },
      ];
    },
    instructorReports() {
      return [
        { id: 1, text: "Instructor Vehicles", disabled: false },
        {
          id: 2,
          text: "Instructor Teaching Genders & Secondary Languages",
          disabled: false,
        },
        {
          id: 3,
          text: "Instructor Genders & Age Categories",
          disabled: false,
        },
        { id: 4, text: "Instructor Retention", disabled: false },
        {
          id: 5,
          text: "Instructor Postcode Coverage & Complaints per Postcode",
          disabled: false,
        },
        {
          id: 6,
          text: "Total Number of Lessons Provided &#10010;",
          disabled: false,
        },
        {
          id: 7,
          text: "Total Number of Lessons Rescheduled  &#10010;",
          disabled: false,
        },
        {
          id: 8,
          text: "Ratio of Gender  &#10010;",
          disabled: false,
        },
        {
          id: 9,
          text: "Ratio of Age Groups  &#10010;",
          disabled: false,
        },
        {
          id: 10,
          text: "Total Number of Instructors in Geographical Areas  &#10010;",
          disabled: false,
        },
        {
          id: 11,
          text: "Total Number of Instructors Departed the Company  &#10010;",
          disabled: false,
        },
        {
          id: 12,
          text: "Total Number of New Instructors  &#10010;",
          disabled: false,
        },
      ];
    },
    financialReports() {
      return [
        {
          id: 1,
          text: "Franchise Fee by Postcode of the Instructor",
          disabled: false,
        },
        {
          id: 2,
          text: "Total Number of Instructors vs. Total Amount of Franchise Fees",
          disabled: false,
        },
        {
          id: 3,
          text: "Income Generated by Part-Time vs. Full-Time Active Instructors",
          disabled: false,
        },
        {
          id: 4,
          text: "Male vs. Female Instructor Franchise Fees",
          disabled: false,
        },
        {
          id: 5,
          text: "Number of Instructors in Arrears of Franchise Fees ",
          disabled: true,
        },
        {
          id: 6,
          text: "Income Generated by Number of Bookings",
          disabled: false,
        },
      ];
    },
  },
};
</script>

<style></style>
