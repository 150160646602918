/* eslint-disable vue/valid-v-slot */

<template>
  <v-sheet color="white" elevation="1" class="pa-5"
    ><h2 v-html="getTableTitle()"></h2>
    <v-data-table
      :headers="getHeaders"
      :items="RatingList"
      :search="keyword"
      class="elevation-1"
      @click:row="openDialog"
    >
      <template v-slot:top>
        <v-text-field
          v-model="keyword"
          label="Search"
          placeholder="Enter keyword to search"
          class="ma-4"
          clearable
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        <FeedbackSummaryDialog
          :title="getDialogTitle"
          :dialog="dialog"
          :person-summary="getSummaryObj"
          :single-summaries="passingIndividualRecords"
          :type="type"
          @close="dialog = false"
        />
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <strong>{{ getID(item) }}</strong>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ getName(item) }}
      </template>

      <template v-slot:[`item.ratings`]="{ item }">
        <v-rating
          dense
          background-color="grey lighten-2"
          color="warning"
          half-increments
          length="5"
          readonly
          size="35"
          :value="getVal(item.ratings)"
        ></v-rating>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import { mapActions } from "vuex";
import FeedbackSummaryDialog from "@/components/Feedback/FeedbackSummaryDialog.vue";
import {
  GET_LEARNERLIST_FOR_ADI_FEEDBACKS,
  M_OU,
  GET_ADILIST_FOR_LEARNER_FEEDBACKS,
} from "@/store/mutation-list";
export default {
  name: "SummaryTable",
  components: { FeedbackSummaryDialog },
  props: ["RatingList", "type"],
  data: () => {
    return {
      dialog: false,
      selectedRating: null,
      //passingID: null,
      //data table realated data
      keyword: "",
      passingIndividualRecords: null,
    };
  },
  computed: {
    //this function method will return headders based on type of table
    getHeaders() {
      if (this.type == "learner") {
        return [
          { text: "Instructor ID", value: "id" },
          { text: "Instructor Name", value: "name" },
          { text: "Average Ratings", value: "ratings" },
          { text: "Total Reviews", value: "total_feedbacks" },
        ];
      } else {
        //for adi
        return [
          { text: "Learner ID", value: "id" },
          { text: "Learner Name", value: "name" },
          { text: "Average Ratings", value: "ratings" },
          { text: "Total Reviews", value: "total_feedbacks" },
        ];
      }
    },
    //this funciton will return the tittle of the dialog base on type of table
    getDialogTitle() {
      if (this.type == "learner") {
        return "Feedback Summary of Instructor";
      } else {
        return "Feedback Summary of Learner";
      }
    },
    getSummaryObj() {
      if (this.type == "learner" && this.selectedRating) {
        return [
          {
            label: "Instructor",
            data:
              "A" +
              this.selectedRating.adi_id +
              " - " +
              this.selectedRating.title +
              " " +
              this.selectedRating.fname +
              " " +
              this.selectedRating.lname,
          },
          {
            label: "Total Lesson Feedbacks",
            data: parseFloat(this.selectedRating.total_feedbacks),
          },
          {
            label: "Average Ratings",
            data: parseFloat(this.selectedRating.ratings),
          },
          {
            label: "Total Learners",
            data: 0,
          },
        ];
      } else if (this.type == "adi" && this.selectedRating) {
        return [
          {
            label: "Learner",
            data:
              "L" +
              this.selectedRating.stu_id +
              " - " +
              this.selectedRating.title +
              " " +
              this.selectedRating.fname +
              " " +
              this.selectedRating.lname,
          },
          {
            label: "Total Lesson Feedbacks",
            data: parseFloat(this.selectedRating.total_feedbacks),
          },
          {
            label: "Average Ratings",
            data: parseFloat(this.selectedRating.ratings),
          },
          {
            label: "Total Instructors",
            data: 0,
          },
        ];
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions({
      getLearnerList: `${M_OU}${GET_LEARNERLIST_FOR_ADI_FEEDBACKS}`,
      getADIsList: `${M_OU}${GET_ADILIST_FOR_LEARNER_FEEDBACKS}`,
    }),
    getTableTitle() {
      if (this.type == "learner") {
        return "Learner Feedbacks Regarding Instructors";
      } else {
        //for adi
        return "Instructor Feedbacks Regarding Learners <span style='color: red; font-weight: 500'>(DO NOT Share With Learners - for internal use only)</span>";
      }
    },
    getID(item) {
      //this will return suitable ID based on role
      if (this.type == "learner") {
        return "L" + item.adi_id;
      } else {
        //for adi
        return "A" + item.stu_id;
      }
    },
    getName(item) {
      if (item) {
        return `${item.title} ${item.fname} ${item.lname}`;
      } else {
        return null;
      }
    },
    getVal(val) {
      return parseFloat(val);
    },
    openDialog(record) {
      //start the spinner
      //lets load the data

      //need to load the all records here
      if (this.type == "learner") {
        //need to pass adi id
        this.getLearnerList({ id: record.adi_id })
          .then((res) => {
            if (res) {
              this.passingIndividualRecords = res;
            } else {
              this.passingIndividualRecords = [];
            }
          })
          .finally(() => {
            this.dialog = true;
          });
      } else {
        //for adi
        this.getADIsList({ id: record.stu_id })
          .then((res) => {
            if (res) {
              this.passingIndividualRecords = res;
            } else {
              this.passingIndividualRecords = [];
            }
          })
          .finally(() => {
            this.dialog = true;
          });
      }

      this.selectedRating = record;
    },
    acctionAssign() {
      //this funciton will asssign ADI
      this.close();
    },

    close() {
      //hide dialog
      this.dialog = false;
      //remove data object
      this.selectedRating = null;
      this.questions = [{ id: null, question: null }];
    },
  },
};
</script>

<style></style>
