<template>
  <div class="row">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5 ma-5">
        <h2>Edit/Update Driving Instructor</h2>
        <v-sheet
          color="white"
          elevation="3"
          min-height="100"
          class="text-center pa-5 ma-5"
        >
          <div v-if="passesADI.adi_id != undefined">
            <v-alert color="info" dark>
              <h3>
                <v-icon large> mdi-clipboard-account</v-icon>
                {{
                  `A${this.passesADI.adi_id} - ${this.passesADI.title} ${
                    this.passesADI.fname
                  } ${this.passesADI.lname}, ${this.$options.filters.mobileno(
                    this.passesADI.mobile
                  )} , ${this.passesADI.email}`
                }}
              </h3>
            </v-alert>
          </div>
          <div v-else>
            <v-alert color="red" dark
              ><h3>
                <v-icon large dark> mdi-shield-alert-outline</v-icon>
                Page refreshed or navigated with invalid link. Please visit
                View/Edit Instructors to select Driving Instructor.
              </h3></v-alert
            >
          </div>
        </v-sheet>

        <v-row class="mt-5" v-if="passesADI.adi_id != undefined">
          <v-col cols="3">
            <UpdateBasicInfo v-if="!isEmpty(passesADI)" :adiinfo="basicInfo" />
          </v-col>
          <v-col cols="3">
            <UpdateAdvanceInfo
              v-if="!isEmpty(passesADI)"
              :basic-obj="advanceInfo"
            />
          </v-col>
          <v-col cols="3">
            <UpdateAddress
              v-if="!isEmpty(passesADI)"
              :basic-obj="passesADI.adi_id"
              :compliance="advanceInfo.compid"
            />
          </v-col>
          <v-col cols="3">
            <UpdateFranchiseAgreement
              v-if="!isEmpty(passesADI)"
              :basic-obj="currentFranchise"
            />
          </v-col>
          <v-col cols="3">
            <AddCar v-if="!isEmpty(passesADI)" :basic-obj="passesADI.adi_id" />
          </v-col>
          <v-col cols="3">
            <RemoveCar
              v-if="!isEmpty(passesADI)"
              :basic-obj="currentCars"
              @deletecar="removeCarOnDelete($event)"
            />
          </v-col>
          <v-col cols="3">
            <UploadCarDocs
              v-if="!isEmpty(passesADI)"
              :basic-obj="currentCars"
            />
          </v-col>
          <v-col cols="3">
            <UpdateVehicleInfo
              v-if="!isEmpty(passesADI)"
              :basic-obj="currentCars"
            />
          </v-col>

          <v-col cols="4">
            <UpdateCoveringAreas
              v-if="!isEmpty(passesADI)"
              :basic-obj="currentAreas"
            />
          </v-col>
          <v-col cols="4">
            <UpdateLangs v-if="!isEmpty(passesADI)" :basic-obj="currentLangs" />
          </v-col>
          <v-col cols="4">
            <UpdateTeachVehiType
              v-if="!isEmpty(passesADI)"
              :basic-obj="currentVehiTypes"
            />
          </v-col>
        </v-row> </v-sheet
    ></v-col>
  </div>
</template>
<script>
import UpdateBasicInfo from "@/components/Instructor/Edit/UpdateBasicInfo";
import UpdateAdvanceInfo from "@/components/Instructor/Edit/UpdateAdvanceInfo";
import AddCar from "@/components/Instructor/Edit/AddCar";
import RemoveCar from "@/components/Instructor/Edit/RemoveCar";
import UploadCarDocs from "@/components/Instructor/Edit/UploadCarDocs";
import UpdateAddress from "@/components/Instructor/Edit/UpdateAddress";
import UpdateFranchiseAgreement from "@/components/Instructor/Edit/UpdateFranchiseAgreement";
import UpdateCoveringAreas from "@/components/Instructor/Edit/UpdateCoveringAreas";
import UpdateLangs from "@/components/Instructor/Edit/UpdateLangs";
import UpdateTeachVehiType from "@/components/Instructor/Edit/UpdateTeachVehiType";
import UpdateVehicleInfo from "@/components/Instructor/Edit/UpdateVehicleInfo";
export default {
  name: "Edit",
  components: {
    UpdateBasicInfo,
    UpdateAdvanceInfo,
    AddCar,
    RemoveCar,
    UploadCarDocs,
    UpdateAddress,
    UpdateCoveringAreas,
    UpdateLangs,
    UpdateTeachVehiType,
    UpdateVehicleInfo,
    UpdateFranchiseAgreement,
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "View/Edit Instructors",
          disabled: false,
          href: "/dashboard/vieweditinstructors",
        },
        {
          text: "Edit Instructor",
          disabled: true,
          href: "/dashboard/edit",
        },
      ],
      di: false,
      passesADI: {},
      basicInfo: {},
      advanceInfo: {},
      currentCars: [],
      currentAreas: [],
      currentLangs: [],
      currentVehiTypes: [],
      currentFranchise: [],
    };
  },
  methods: {
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    setADIInfo(DATA) {
      this.passesADI = DATA;
    },
    setBasicInfo(DATA) {
      this.basicInfo = {
        adiid: DATA.adi_id,
        title: DATA.title,
        fname: DATA.fname,
        mname: DATA.mname,
        lname: DATA.lname,
        mobile: DATA.mobile,
        inemail: DATA.email,
        dlno: DATA.driving_licence_no,
        adilno: DATA.instructor_licence_no,
        aditgender: DATA.teaching_gens,
        geartype: DATA.teaching_gears,
      };
    },
    setadvanceInfo(DATA) {
      this.advanceInfo = {
        adiid: DATA.adi_id,
        compid: DATA.complianceid,
        gender: DATA.gender,
        dob: DATA.dateOfBirth,

        hp1: DATA.homePhone1,
        hp2: DATA.homePhone2,
        mp2: DATA.mobilePhone2,

        wp1: DATA.workPhone1,
        wp2: DATA.workPhone2,

        crtbref: Number(DATA.crtbRef),
        fbname: DATA.bankInfo.bankname,
        scode: DATA.bankInfo.sortcode,
        iscode: DATA.bankInfo.sortcode, //visible one with mask

        accno: DATA.bankInfo.accno,
        naob: DATA.bankInfo.nameonacc,
        nino: DATA.nino,
      };
    },
    setcurrentCars(DATA) {
      this.currentCars = {
        adiid: DATA.adi_id,
        cars: DATA.car,
      };
    },
    removeCarOnDelete(cid) {
      let indexObj = this.currentCars.cars.findIndex(
        (car) => car.c_id === cid.carid
      );
      if (indexObj > -1) {
        this.currentCars.cars[indexObj].rec_status = 3;
      }

      console.log(indexObj);
      console.log(this.currentCars.cars[indexObj]);
      console.log(this.currentCars.cars[7]);
    },
    setcurrentAreas(DATA) {
      this.currentAreas = {
        adiid: DATA.adi_id,
        coveringareas: DATA.covering_pcs,
      };
    },
    setcurrentLangs(DATA) {
      this.currentLangs = {
        adiid: DATA.adi_id,
        aditlang: DATA.teaching_langs,
      };
    },
    setcurrentVehiTypes(DATA) {
      this.currentVehiTypes = {
        adiid: DATA.adi_id,
        carttype: DATA.teaching_car_types,
      };
    },
    setcurrentFranchisePlan(DATA) {
      this.currentFranchise = {
        adiid: DATA.adi_id,
        franchiseplan: DATA.adi_franchise,
      };
    },
  },
  computed: {},

  mounted() {
    if (this.$route.params.info) {
      this.setADIInfo(this.$route.params.info);
      //setting Objects
      this.setBasicInfo(this.$route.params.info);
      this.setadvanceInfo(this.$route.params.info);
      this.setcurrentCars(this.$route.params.info);
      this.setcurrentAreas(this.$route.params.info);
      this.setcurrentLangs(this.$route.params.info);
      this.setcurrentVehiTypes(this.$route.params.info);
      this.setcurrentFranchisePlan(this.$route.params.info);
    } else {
      this.setADIInfo({});
    }
  },
};
</script>

<style scoped>
.point {
  cursor: pointer;
}
</style>
