<template>
  <div>
    <v-card class="point" @click="dialog = true" v-if="basicObj">
      <v-card-title
        class="text-h6 grey lighten-2"
        style="justify-content: center"
      >
        Edit Teaching Vehicle Types
      </v-card-title>
      <v-img
        :src="require(`@/assets/img/updateinfo/chgvehic.webp`)"
        max-height="300"
        contain
        class="grey lighten-3 pa-5"
      ></v-img>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title>
            <span class="text-h5">Edit Teaching Vehicle Types</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text style="height: 70vh">
          <v-container>
            <v-row>
              <v-col>
                <v-select
                  v-model="form.carttype"
                  name="carttype[]"
                  :items="cartypes"
                  chips
                  label="Teaching Vehicle Types"
                  item-text="txt"
                  item-value="id"
                  multiple
                  outlined
                  :error-messages="carttypeErrors"
                  @input="$v.form.carttype.$touch()"
                  @blur="$v.form.carttype.$touch()"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning darken-1"
            text
            @click="saveChanges()"
            :disabled="$v.form.$invalid"
          >
            Save Update
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  GET_VEHICLE_TYPES,
  M_DD,
  M_ADI,
  SAVE_ADI_TEACHING_VEHICLE_TYPES,
} from "@/store/mutation-list";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UpdateTeachVehiType",
  props: ["basicObj"],
  data() {
    return {
      dialog: false,
      form: {
        adiid: null, //readonly
        carttype: [],
      },
    };
  },
  validations: {
    form: {
      carttype: { required },
    },
  },
  computed: {
    ...mapGetters({
      cartypes: `${M_DD}${GET_VEHICLE_TYPES}`,
    }),
    carttypeErrors() {
      const errors = [];
      if (!this.$v.form.carttype.$dirty) return errors;
      !this.$v.form.carttype.required &&
        errors.push("Teaching Vehicle Types required.");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      saveTeachVehi: `${M_ADI}${SAVE_ADI_TEACHING_VEHICLE_TYPES}`,
    }),
    saveChanges() {
      this.saveTeachVehi({
        id: this.form.adiid,
        tvs: this.form.carttype,
      }).then((rep) => {
        if (rep == "SUCCESS") {
          this.$fire({
            title: "Teaching Vehicle Types Updated",
            text: "Teaching Vehicle Types successfully.",
            type: "success",
            //timer: 3000,
            allowOutsideClick: false,
          }).then((r) => {
            this.dialog = false;
            console.log(r.value);
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error updating teaching vehicle types. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
      /*

      let fd = new FormData(updateadiadvance);
            fd.append("updateadvanceadiinfo",this.adiainfo.adiid);
            fd.append("compid",this.adiainfo.complianceid);
            let self = this;

            axios  
                .post(serverpathou,fd)
                .then(function (response){
                    let reply = response.data;

                    if(typeof reply.result.code != 'undefined'){
                        if(reply.result.code == 1){
                            window.location.reload();
                        }else{
                            self.isErrorAlert = true;
                            self.errorMSG = reply.result.text;

                        }
                    }
                    
                })
                .catch(function (error){
                    self.isErrorAlert = true;
                    self.errorMSG = "Error submitting data. Please try again later";
                })

      */
    },
  },
  created() {
    this.form.adiid = this.basicObj.adiid;
    this.basicObj.carttype.forEach((type) => {
      this.form.carttype.push(type.type_id);
    });
  },
};
</script>

<style></style>
