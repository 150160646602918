<template>
  <v-dialog transition="dialog-top-transition" v-model="dialog" max-width="850">
    <v-card v-if="headers && dataarray">
      <v-toolbar color="primary" dark>{{ DialogTitle }}</v-toolbar>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        @click:row="selected"
        :headers="headers"
        :items="dataarray"
        :search="search"
      >
        <template v-slot:[`item.id`]="{ item }">
          <strong v-if="type == 'learner'">L{{ item.student_id }}</strong>
          <strong v-else>A{{ item.adi_id }}</strong>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span>{{ item.title + " " + item.fname + " " + item.lname }}</span>
        </template>
        <template v-slot:[`item.mobile`]="{ item }">
          <span>{{ item.mobile | mobileno }}</span>
        </template>
      </v-data-table>
      <v-card-actions class="justify-end">
        <v-btn text @click.native="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SelectFromTableDialog",
  props: ["DialogTitle", "dialog", "headers", "dataarray", "type"],
  data() {
    return {
      search: "",
    };
  },
  methods: {
    selected(item) {
      if (this.type == "learner") {
        this.$emit("choosen", {
          id: item.student_id,
          name: item.title + " " + item.fname + " " + item.lname,
        });
        this.$emit("closed", false);
      } else {
        this.$emit("choosen", {
          id: item.adi_id,
          name: item.title + " " + item.fname + " " + item.lname,
        });
        this.$emit("closed", false);
      }
    },
    close() {
      this.$emit("closed", false);
    },
  },
};
</script>

<style></style>
