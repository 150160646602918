import axios from "axios";
import {
  SET_DIALOG_ACTIVE,
  GET_DIALOG,
  SET_DIALOG_INACTIVE,
  SET_BACKGROUND_SYNC_ACTIVE,
  GET_BACKGROUND_SYNC,
  SET_BACKGROUND_SYNC_INACTIVE,
  GET_LESSON_PLANS,
  GET_LESSON_PLANS_ACTIVE,
  LOAD_ADIS_FOR_APPROVAL,
  LOAD_OFFICE_STAFF,
  LOAD_QUICK_INFO,
  LOAD_ACTIVE_INSTRUCTORS,
  GET_QUICK_INFO,
  LOAD_FRANCHISE_PLANS,
  LOAD_ADI_PENDING_LEARNERS,
  LOAD_LESSONS_PLANS,
  GET_FRANCHISE_PLANS,
  GET_FRANCHISE_PLAN,
  GET_FRANCHISE_PLANS_ACTIVE,
  LOAD_DATA_AT_LOGIN,
  LOAD_ADI_SUMMARY,
  LOAD_ACTIVE_COMPLAINTS,
  //SET_VEHICLE_TYPES,
  //SET_VEHICLE_OWNER_TYPES,
  LOAD_ALL_LEARNER_FEEDBACK_GROUPS,
  LOAD_ALL_INSTRUCTOR_FEEDBACK_GROUPS,
  GET_ALL_LEARNER_FEEDBACK_GROUPS,
  GET_ALL_INSTRUCTOR_FEEDBACK_GROUPS,
  SET_DATADICTIONARY_DATA,
  FULL_RESET,
} from "@/store/mutation-list.js";
import { M_DD, M_OA, M_OU } from "../mutation-list";

export const sharedsync = {
  namespaced: true,
  state: {
    //loading spinners
    loadingSpinner: {
      dialog: false,
    },
    backgroundSpinner: {
      isActive: false,
    },
    //set data
    lessonPackages: [],

    quickInfo: {},
    franchisePlans: [],
    learnerFeedbackQuestionGroups: [],
    instructorFeedbackQuestionGroups: [],
  },
  getters: {
    [GET_DIALOG]: (state) => state.loadingSpinner.dialog,
    [GET_BACKGROUND_SYNC]: (state) => state.backgroundSpinner.isActive,
    [GET_LESSON_PLANS]: (state) => state.lessonPackages,
    [GET_LESSON_PLANS_ACTIVE]: (state) =>
      state.lessonPackages.filter((item) => {
        return item.status == 1;
      }),
    [GET_QUICK_INFO]: (state) => state.quickInfo,
    [GET_FRANCHISE_PLANS]: (state) => {
      return state.franchisePlans;
    },
    [GET_FRANCHISE_PLANS_ACTIVE]: (state) =>
      state.franchisePlans.filter((item) => {
        return item.state == 1;
      }),
    [GET_FRANCHISE_PLAN]: (state) => (id) => {
      return state.franchisePlans.find((plan) => plan.plan_id === id);
    },
    [GET_ALL_LEARNER_FEEDBACK_GROUPS]: (state) => {
      return state.learnerFeedbackQuestionGroups;
    },
    [GET_ALL_INSTRUCTOR_FEEDBACK_GROUPS]: (state) => {
      return state.instructorFeedbackQuestionGroups;
    },
  },
  actions: {
    [SET_DIALOG_ACTIVE]({ commit }) {
      commit("SET_DIALOG_ACTIVE");
    },
    [SET_DIALOG_INACTIVE]({ commit }) {
      commit("SET_DIALOG_INACTIVE");
    },
    [SET_BACKGROUND_SYNC_ACTIVE]({ commit }) {
      commit("SET_BACKGROUND_SYNC_ACTIVE");
    },
    [SET_BACKGROUND_SYNC_INACTIVE]({ commit }) {
      commit("SET_BACKGROUND_SYNC_INACTIVE");
    },
    [LOAD_FRANCHISE_PLANS]({ commit, rootGetters }) {
      //need to set the background loaded
      commit("SET_BACKGROUND_SYNC_ACTIVE");
      //do the data request
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token);
      fd.append("getallfranchiseplans", "getallfranchiseplans");
      axios.post("ctroaV2.php", fd).then(function (response) {
        //console.log("Got a response");
        let res = response.data;
        commit("LOAD_FRANCHISE_PLANS", res);
        //console.log(response.data);
      });
      commit("SET_BACKGROUND_SYNC_INACTIVE");
      //stop the background loading
    },
    [LOAD_LESSONS_PLANS]({ commit, rootGetters }) {
      //need to set the background loaded
      commit("SET_BACKGROUND_SYNC_ACTIVE");
      //do the data request
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token);
      fd.append("getalllessonplans", "getalllessonplans");
      axios.post("ctroaV2.php", fd).then(function (response) {
        //console.log("Got a response");
        let res = response.data;
        commit("LOAD_LESSONS_PLANS", res);
        //console.log(response.data);
      });
      commit("SET_BACKGROUND_SYNC_INACTIVE");
      //stop the background loading
    },
    [LOAD_QUICK_INFO]({ commit, rootGetters }) {
      //need to set the background loaded
      commit("SET_BACKGROUND_SYNC_ACTIVE");
      //do the data request
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token);
      fd.append("getquickdata", "getquickdata");
      axios.post("ctroaV2.php", fd).then(function (response) {
        //console.log("Got a response");
        let res = response.data;
        commit("LOAD_QUICK_INFO", res);
        //console.log(response.data);
      });
      commit("SET_BACKGROUND_SYNC_INACTIVE");
      //stop the background loading
    },
    [LOAD_ALL_LEARNER_FEEDBACK_GROUPS]({ commit, rootGetters }) {
      //need to set the background loaded
      commit("SET_BACKGROUND_SYNC_ACTIVE");
      //do the data request
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token);
      fd.append("getlearnerfeedbackgroups", "getlearnerfeedbackgroups");
      axios.post("ctroaV2.php", fd).then(function (response) {
        //console.log("Got a response");
        let res = response.data;
        commit("LOAD_ALL_LEARNER_FEEDBACK_GROUPS", res);
        //console.log(response.data);
      });
      commit("SET_BACKGROUND_SYNC_INACTIVE");
      //stop the background loading
    },
    [LOAD_ALL_INSTRUCTOR_FEEDBACK_GROUPS]({ commit, rootGetters }) {
      //need to set the background loaded
      commit("SET_BACKGROUND_SYNC_ACTIVE");
      //do the data request
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token);
      fd.append("getinstructorfeedbackgroups", "getinstructorfeedbackgroups");
      axios.post("ctroaV2.php", fd).then(function (response) {
        //console.log("Got a response");
        let res = response.data;
        commit("LOAD_ALL_INSTRUCTOR_FEEDBACK_GROUPS", res);
        //console.log(response.data);
      });
      commit("SET_BACKGROUND_SYNC_INACTIVE");
      //stop the background loading
    },
    [LOAD_DATA_AT_LOGIN]({ commit, dispatch }, payload) {
      //need to set the background loaded
      commit("SET_DIALOG_ACTIVE");

      dispatch(`${M_OU}${LOAD_ADIS_FOR_APPROVAL}`, null, {
        root: true,
      });
      dispatch(`${M_OU}${LOAD_ADI_PENDING_LEARNERS}`, null, {
        root: true,
      });
      dispatch(`${M_OU}${LOAD_ACTIVE_INSTRUCTORS}`, null, {
        root: true,
      });
      dispatch(`${M_OU}${LOAD_ADI_SUMMARY}`, null, {
        root: true,
      });
      dispatch(`${M_OU}${LOAD_ACTIVE_COMPLAINTS}`, null, {
        root: true,
      });

      /*dispatch(`${M_DD}${SET_VEHICLE_TYPES}`, null, {
        root: true,
      }); //load vehicle types
      dispatch(`${M_DD}${SET_VEHICLE_OWNER_TYPES}`, null, {
        root: true,
      }); //load vehicle owner types
      */
      dispatch(`${M_DD}${SET_DATADICTIONARY_DATA}`, null, {
        root: true,
      }); //load all data dictionay data
      dispatch("LOAD_FRANCHISE_PLANS");
      dispatch("LOAD_LESSONS_PLANS");
      dispatch("LOAD_QUICK_INFO");
      dispatch("LOAD_ALL_LEARNER_FEEDBACK_GROUPS");
      dispatch("LOAD_ALL_INSTRUCTOR_FEEDBACK_GROUPS");
      if (payload.isAdmin) {
        //load admin related data
        dispatch(`${M_OA}${LOAD_OFFICE_STAFF}`, null, {
          root: true,
        });
      }
      //stop the background loading
      commit("SET_DIALOG_INACTIVE");
      //stop the background loading
    },
  },
  mutations: {
    [SET_DIALOG_ACTIVE](state) {
      state.loadingSpinner.dialog = true;
    },
    [SET_DIALOG_INACTIVE](state) {
      state.loadingSpinner.dialog = false;
    },
    [SET_BACKGROUND_SYNC_ACTIVE](state) {
      state.backgroundSpinner.isActive = true;
    },
    [SET_BACKGROUND_SYNC_INACTIVE](state) {
      state.backgroundSpinner.isActive = false;
    },
    [LOAD_FRANCHISE_PLANS](state, payload) {
      state.franchisePlans = payload;
    },
    [LOAD_LESSONS_PLANS](state, payload) {
      state.lessonPackages = payload;
    },
    [LOAD_QUICK_INFO](state, payload) {
      state.quickInfo = payload;
    },
    [LOAD_ALL_LEARNER_FEEDBACK_GROUPS](state, payload) {
      state.learnerFeedbackQuestionGroups = payload;
    },
    [LOAD_ALL_INSTRUCTOR_FEEDBACK_GROUPS](state, payload) {
      state.instructorFeedbackQuestionGroups = payload;
    },
    //this happens on logut
    [FULL_RESET](state) {
      state.loadingSpinner.dialog = false;
      state.backgroundSpinner.isActive = false;
      state.lessonPackages = [];
      state.quickInfo = [];
      state.franchisePlans = [];
      state.learnerFeedbackQuestionGroups = [];
      state.instructorFeedbackQuestionGroups = [];
    },
  },
};
