import axios from "axios";
import {
  SET_DIALOG_ACTIVE,
  SET_DIALOG_INACTIVE,
  M_COMMON,
  // M_OU,
  SAVE_ADI_BASIC_INFO,
  SAVE_ADI_ADVANCE_INFO,
  SAVE_ADI_NEW_CAR,
  SAVE_ADI_REMOVE_CAR,
  SAVE_ADI_CAR_DOCS,
  SAVE_ADI_CAR_CHANGES,
  SAVE_ADI_COMPLIANCE_DOCS,
  SAVE_ADI_COVERING_AREAS,
  SAVE_ADI_COVERING_LANGS,
  SAVE_ADI_TEACHING_VEHICLE_TYPES,
  REGISTER_NEW_INSTRUCTOR,
  SAVE_FRANCHISE_CHANGE,
  // LOAD_ADIS_FOR_APPROVAL,
} from "@/store/mutation-list.js";
export const adiEdits = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    [SAVE_ADI_BASIC_INFO]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("updatebasicadiinfo", payload.id);
      let indata = payload.form;

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SAVE_ADI_ADVANCE_INFO]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("updateadvanceadiinfo", payload.id);
      let indata = payload.form;

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },

    [SAVE_ADI_NEW_CAR]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //console.log(payload);
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("addanewadicar", payload.id);
      let indata = payload.form;

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }
      fd.append("fllogbook1", payload.upfiles.fllogbook1, "v5c.pdf");
      fd.append("flmot1", payload.upfiles.flmot1, "mot.pdf");
      fd.append("flinsurance1", payload.upfiles.flinsurance1, "insurance.pdf");

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SAVE_ADI_REMOVE_CAR]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("caradi", payload.id);
      fd.append("deletethiscar", payload.carid);
      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SAVE_ADI_CAR_DOCS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //console.log(payload);
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("caradi", payload.id);
      fd.append("uploadcardocs", payload.carid);
      let indata = payload.form;

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }
      if ("fllogbook1" in payload.upfiles) {
        fd.append("fllogbook1", payload.upfiles.fllogbook1, "v5c.pdf");
      }
      if ("flmot1" in payload.upfiles) {
        fd.append("flmot1", payload.upfiles.flmot1, "mot.pdf");
      }
      if ("flinsurance1" in payload.upfiles) {
        fd.append(
          "flinsurance1",
          payload.upfiles.flinsurance1,
          "insurance.pdf"
        );
      }

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SAVE_ADI_CAR_CHANGES]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //console.log(payload);
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("caradi", payload.id);
      fd.append("uploadcarinfo", payload.carid);
      let indata = payload.form;

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SAVE_ADI_COMPLIANCE_DOCS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //console.log(payload);
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("addadinewaddress", payload.id);
      fd.append("currentcompid", payload.complianceid);
      let indata = payload.form;

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }
      fd.append("poadoc1", payload.upfiles.poadoc1, "proof1.pdf");
      fd.append("poadoc2", payload.upfiles.poadoc2, "proof2.pdf");
      fd.append("fldl", payload.upfiles.fldl, "dlicence.pdf");
      fd.append("fladil", payload.upfiles.fladil, "adilicence.pdf");

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SAVE_ADI_COVERING_AREAS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("updatecoveringpcs", payload.id);
      let indata = payload.pcs;

      for (const [, value] of Object.entries(indata)) {
        //console.log(key);
        fd.append(`coveringareas[]`, `${value}`);
      }

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SAVE_ADI_COVERING_LANGS]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("updateteachinglangs", payload.id);
      let indata = payload.lns;

      for (const [, value] of Object.entries(indata)) {
        //console.log(key);
        fd.append(`teachinglangs[]`, `${value}`);
      }

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SAVE_ADI_TEACHING_VEHICLE_TYPES]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("updateteachingvehicales", payload.id);
      let indata = payload.tvs;

      for (const [, value] of Object.entries(indata)) {
        //console.log(key);
        fd.append(`teachingvehicles[]`, `${value}`);
      }

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [REGISTER_NEW_INSTRUCTOR]({ dispatch }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      /*const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      //fd.append("addadiinofficeadvancedv2", "addadiinofficeadvancedv2");
      //let indata = payload.form;

      for (const [, value] of Object.entries(indata)) {
        //console.log(key);
        fd.append(`teachingvehicles[]`, `${value}`);
      }*/

      return axios
        .post("ctrouV2.php", payload)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            dispatch(`M_OU/LOAD_ADIS_FOR_APPROVAL`);
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SAVE_FRANCHISE_CHANGE]({ dispatch, rootGetters }, payload) {
      dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
        root: true,
      });
      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("changeadifranchise", payload.id);
      let indata = payload.form;

      for (const [key, value] of Object.entries(indata)) {
        fd.append(`${key}`, `${value}`);
      }

      return axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          if (rep.result.code == 1) {
            return "SUCCESS";
          }
          return "ERROR";
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        });
    },
  },
  mutations: {},
};
