<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col
      ><v-sheet color="white" elevation="1" class="pa-5">
        <h2>Add New Staff</h2>
        <v-divider></v-divider>
        <form>
          <v-row justify="center">
            <v-col md="4">
              <!-- <v-header name="Holds"> &nbsp;</v-header> -->
              <v-select
                v-model="form.title"
                :items="titlesList"
                item-text="txt"
                item-value="id"
                label="Title"
                required
                :error-messages="titleErrors"
                @input="$v.form.title.$touch()"
                @blur="$v.form.title.$touch()"
              >
              </v-select>

              <v-text-field
                v-model="form.fname"
                :counter="20"
                label="First Name"
                required
                :error-messages="fnameErrors"
                @input="$v.form.fname.$touch()"
                @blur="$v.form.fname.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="form.mname"
                :counter="20"
                label="Middle Name(s)"
                :error-messages="mnameErrors"
                @input="$v.form.mname.$touch()"
                @blur="$v.form.mname.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="form.lname"
                :counter="20"
                label="Surname"
                required
                :error-messages="lnameErrors"
                @input="$v.form.lname.$touch()"
                @blur="$v.form.lname.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="form.mobile"
                :counter="11"
                label="Mobile Number"
                required
                :error-messages="mobileErrors"
                @input="$v.form.mobile.$touch()"
                @blur="$v.form.mobile.$touch()"
              ></v-text-field>

              <v-text-field
                v-model="form.inemail"
                label="E-mail"
                :counter="60"
                required
                :error-messages="emailErrors"
                @input="$v.form.inemail.$touch()"
                @blur="$v.form.inemail.$touch()"
              ></v-text-field>
              <v-text-field
                type="text"
                label="Password"
                value="30onStreet"
                messages="Please change the password on login."
                readonly
              ></v-text-field>
              <v-btn color="success" class="mt-3" @click="submit"
                >Add Office Staff
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import { GET_TITLES, REGISTER_STAFF, M_DD, M_OA } from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";

import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "AddStaff",

  data: () => ({
    items: [
      {
        text: "Admin Dashboard",
        disabled: false,
        href: "/dashboard/adminhome",
      },
      {
        text: "Add New Staff",
        disabled: true,
        href: "/dashboard/addstaff",
      },
    ],
    genders: [
      { id: "f", txt: "Female" },
      { id: "m", txt: "Male" },
    ],
    datePickDirty: false,
    form: {
      title: "",
      gender: "",
      fname: "",
      mname: "",
      lname: "",
      mobile: "",
      inemail: "",
      privlist: ["18", "19", "20", "21", "22", "23"],
    },
  }),
  validations: {
    form: {
      title: {
        required,
      },
      fname: {
        required,
        maxLength: maxLength(20),
      },
      mname: {
        maxLength: maxLength(20),
      },
      lname: {
        required,
        maxLength: maxLength(20),
      },
      mobile: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(11),
      },
      inemail: {
        required,
        email,
        maxLength: maxLength(60),
      },
    },
  },
  computed: {
    ...mapGetters({
      titlesList: `${M_DD}${GET_TITLES}`,
    }),
    //this section is for errors
    titleErrors() {
      const errors = [];
      if (!this.$v.form.title.$dirty) return errors;
      !this.$v.form.title.required && errors.push("Title is required.");
      return errors;
    },
    fnameErrors() {
      const errors = [];
      if (!this.$v.form.fname.$dirty) return errors;
      !this.$v.form.fname.maxLength &&
        errors.push("Name must be at most 20 characters long");
      !this.$v.form.fname.required && errors.push("Name is required.");

      return errors;
    },
    mnameErrors() {
      const errors = [];
      if (!this.$v.form.mname.$dirty) return errors;
      !this.$v.form.mname.maxLength &&
        errors.push("Name must be at most 20 characters long");
      return errors;
    },
    lnameErrors() {
      const errors = [];
      if (!this.$v.form.lname.$dirty) return errors;
      !this.$v.form.lname.maxLength &&
        errors.push("Name must be at most 20 characters long");
      !this.$v.form.lname.required && errors.push("Name is required.");
      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (!this.$v.form.mobile.$dirty) return errors;
      !this.$v.form.mobile.maxLength &&
        errors.push("Mobile number can not be more than 11 digits");
      !this.$v.form.mobile.minLength &&
        errors.push("Mobile number can not be less than 9 digits");
      !this.$v.form.mobile.numeric && errors.push("Only numbers are allowed.");
      !this.$v.form.mobile.required &&
        errors.push("Mobile number is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.inemail.$dirty) return errors;
      !this.$v.form.inemail.maxLength &&
        errors.push("E-mail address is too long. Max 60 characters allowed.");
      !this.$v.form.inemail.email && errors.push("Invalid e-mail address");
      !this.$v.form.inemail.required && errors.push("E-mail is required.");

      return errors;
    },
  },
  methods: {
    ...mapActions({
      saveNewStaff: `${M_OA}${REGISTER_STAFF}`,
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveNewStaff({
          // the data that need to sent to store/server
          staff_id: this.form.staff_id,
          mobile: this.form.mobile,
          email: this.form.inemail,
          title: this.form.title,
          fname: this.form.fname,
          mname: this.form.mname,
          lname: this.form.lname,
          privlist: ["11", "12", "13", "14", "15", "16", "17"],
        }).then((res) => {
          //response from store. All complete just need to show to popup message
          //console.log(res);
          if (res == "SUCCESS") {
            this.$fire({
              title: "Success",
              text: "Staff Added to system successfully",
              type: "success",
              timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          } else {
            this.$fire({
              title: "Error",
              text: "Error adding staff. Please contact administrator.",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
    },
    clear() {
      this.$v.$reset();

      this.form = {
        title: "",

        fname: "",
        mname: "",
        lname: "",
        mobile: "",

        inemail: "",
      };
    },
  },
};
</script>

<style></style>
