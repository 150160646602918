<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5"
        ><h2>Instructors Pending Approval</h2>
        <p class="sub-title">
          Search Instructors by their ID, Name, E-mail or Mobile Numbers
        </p>
        <InstructorTable
          title=""
          sub-title=""
          :dataarray="resultArry"
          :is-loading="!isLoading"
          :is-search="true"
          @choosen="selected"
        />
      </v-sheet>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="900"
        v-model="dialog"
        persistent
        ><v-card>
          <v-toolbar color="primary" dark>Instructor Information</v-toolbar>
          <!-- <v-card-title class="text-h5">
            Use Google's location service?
          </v-card-title> -->

          <v-card-text>
            <div class="text-center py-5">
              <!-- <v-icon x-large>mdi-check-decagram-outline </v-icon> -->
              <img
                src="@/assets/img/dash/qinfo.webp"
                alt="..."
                class="img-thumbnail mx-auto d-block"
              />
              <br />
              <br />
              <p>
                <strong>Name : </strong
                >{{ adi.title + " " + adi.fname + " " + adi.lname }}
              </p>
              <p><strong>Mobile : </strong>{{ adi.mobile | mobileno }}</p>
              <p><strong>Landline : </strong>{{ adi.landline | landlineno }}</p>
              <p><strong>E-mail : </strong>{{ adi.email }}</p>
              <p>
                <strong>ADI/PDI Licence No : </strong
                >{{ adi.driving_licence_no }}
              </p>
              <p><strong>DBS Reference: </strong>{{ adi.crtbref }}</p>
              <p><strong>Registered On : </strong>{{ adi.added }}</p>
              <p>
                <strong>Registered by - ID : </strong
                >{{ adi.reg_by_name + " - " + adi.reg_by_id }}
              </p>
              <p>
                <strong
                  >Approve ADI:
                  <span style="color: salmon" v-if="!isAdmin">
                    Please contact Office Administrator to approve this Driving
                    Instructor.</span
                  >
                  <span style="color: salmon" v-else>
                    <v-btn class="warning" @click="approve()">Approve </v-btn>
                    Instructor can accept learners</span
                  >
                </strong>
              </p>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary darken-1" text @click="closeDialog">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import InstructorTable from "@/components/Instructor/InstructorTable";
import {
  IS_ADMIN,
  M_AUTH,
  M_OA,
  M_OU,
  LOAD_ADIS_FOR_APPROVAL,
  APPROVE_ADI,
  DISAPPROVE_ADI,
  GET_ADIS_FOR_APPROVAL,
  GET_ADIS_FOR_APPROVAL_LOADING,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PendingInstructors",
  components: { InstructorTable },
  data: function () {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "View Pending Instructors",
          disabled: true,
          href: "/dashboard/pendinginstructors",
        },
      ],

      //resultArry: [],
      //isLoading: true,
      dialog: false,

      adi: {},
    };
  },
  methods: {
    ...mapActions({
      loadADIs: `${M_OU}${LOAD_ADIS_FOR_APPROVAL}`,
      approveADI: `${M_OA}${APPROVE_ADI}`,
      deleteADI: `${M_OA}${DISAPPROVE_ADI}`,
    }),
    selected(instructor) {
      this.adi = instructor.record;
      //console.log(instructor.record);

      this.dialog = true;
    },
    //this only can be reached with admin account
    approve() {
      if (this.isAdmin) {
        this.approveADI({ adi_id: this.adi.adi_id }).then((res) => {
          //response from store. All complete just need to show to popup message
          //console.log(res);
          this.closeDialog();
          if (res == "SUCCESS") {
            this.$fire({
              title: "Success",
              text: "Driving Instructor Approved successfully.",
              type: "success",
              timer: 3000,
            }).then((r) => {
              console.log(r.value);
              this.loadADIs();
            });
          } else {
            this.$fire({
              title: "Error!",
              text: "Error with updating driving instructor record. Please try again.",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
    },
    closeDialog() {
      this.dialog = false;
      //resetting values
      this.adi = {};
    },
  },
  computed: {
    ...mapGetters({
      isAdmin: `${M_AUTH}${IS_ADMIN}`,
      isLoading: `${M_OU}${GET_ADIS_FOR_APPROVAL_LOADING}`,
      resultArry: `${M_OU}${GET_ADIS_FOR_APPROVAL}`,
    }),
  },
  mounted() {
    this.loadADIs();
  },
};
</script>

<style lang="scss">
#summarytbl {
  td {
    padding: 5px;
  }
}
#summarytbl2 {
  td {
    padding: 5px;
  }
}
</style>
