<template>
  <div class="mt-5">
    <h3 class="text-center">Summary Chart</h3>
    <GChart
      type="ColumnChart"
      :settings="{ packages: ['corechart'] }"
      :data="getChartData"
      :options="chartOptions"
      :title="chartOptions.title"
    />
  </div>
</template>
<script>
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "ComplaintsColumnChart",
  components: { GChart },
  props: ["tableData", "hAxisLable", "vAxisLable"],
  data() {
    return {
      /*chartData: [
          [
            "Complaint Type",
            "Total",
            "Self Resolve Attempt",         
          ],
          ["Type a", "3", "1"],
          ["Type b", "5", "0"],
          ["Type c", "2", "1"],
          ["Type d", "3", "1"],
   
        ],*/
      chartOptions: {
        chart: {
          title: "Complaints Ratios",
          subtitle: "Complaints Ratios and Self-resolve Attempts:",
        },
        legend: { topic: "top", position: "right", maxLines: 3 },
        //isStacked: "percent",
        height: 600,
        animation: {
          startup: true,
          duration: 2000,
          easing: "out",
        },
        vAxis: {
          title: this.vAxisLable,
          minValue: 1,
        },
        hAxis: {
          title: this.hAxisLable,
        },
        is3D: true,
        bar: { groupWidth: "60%" },
        colors: ["#ff7f0e", "#2ca02c"],
      },
    };
  },
  methods: {},
  computed: {
    populateChartTitles() {
      let retArray = [];

      //Add Age ratios titles
      let arryAgeRatio = [
        "17 - 25",
        "26 - 35",
        "36 - 45",
        "46 - 55",
        "56 - 65",
        "65 & over",
      ];

      //add Gender ratio titles
      let arryGenderRatio = ["Female", "Male"];
      retArray.concat(arryGenderRatio);

      //lets sort out the PC list
      let arryGeoRatio = Object.keys(this.getOPCRatios);
      retArray.concat(arryGeoRatio);

      //add gear ratios titles
      let arryGearRatio = ["Automatic", "Manual"];

      //if Gear ratios requested send all otherwise sent only last three
      if (this.gearRatios) {
        retArray = [
          //lets add title
          ...arryAgeRatio,
          ...arryGenderRatio,
          ...arryGearRatio,
          ...arryGeoRatio,
        ];
      } else {
        retArray = [...arryAgeRatio, ...arryGenderRatio, ...arryGeoRatio];
      }
      //console.log(retArray);
      return retArray;
    },
    populateChartData() {
      //step 1 : need to know full with of tabel i.e No of postcodes at the end of the table. This section fils requred section rest will be zerro

      //size/ with of data tabel

      if (this.gearRatios) {
        return [
          this.getAgeRatioColumn,
          this.getGenderRatioColumn,
          this.getGearRatioColumn,
          this.getGeoAreaColumn,
        ];
      } else {
        return [
          this.getAgeRatioColumn,
          this.getGenderRatioColumn,
          this.getGeoAreaColumn,
        ];
      }
    },
    //this will return google charData
    getChartData() {
      //just need to loop through data and create a simple table
      let ar = [];
      ar.push(["Complaints Type", "Total", "Self-resolve Attempted"]);
      this.tableData.forEach((rec) => {
        ar.push([
          rec.complaint_type_name,
          rec.complaints_count,
          rec.self_resolve_count,
        ]);
      });

      //console.log(ar);
      return ar;
    },
  },
};
</script>
