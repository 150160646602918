<template>
  <v-dialog v-model="dialog" max-width="960px" scrollable persistent>
    <v-card>
      <v-card-title class="text-h5 blue lighten-2">
        <span>{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-simple-table v-if="summary" id="summarytbl">
            <tbody>
              <tr v-for="(item, index) in summary" :key="index">
                <th class="fcol">{{ item.label }}</th>
                <td class="scol">{{ item.data }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-divider></v-divider>
          <v-simple-table>
            <thead>
              <tr>
                <th class="fcol"></th>
                <th class="scol"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Question 1</th>
                <td v-if="FeedbackQuestions[0]">
                  {{ FeedbackQuestions[0].question }}
                </td>
              </tr>
              <tr>
                <th>Feedback 1</th>
                <td><FaceSelector @clicked-face="firstAnswer" /></td>
              </tr>

              <tr>
                <th>Question 2</th>
                <td v-if="FeedbackQuestions[1]">
                  {{ FeedbackQuestions[1].question }}
                </td>
              </tr>
              <tr>
                <th>Feedback 2</th>
                <td><FaceSelector @clicked-face="secondAnswer" /></td>
              </tr>

              <tr>
                <th>Question 3</th>
                <td v-if="FeedbackQuestions[2]">
                  {{ FeedbackQuestions[2].question }}
                </td>
              </tr>
              <tr>
                <th>Feedback 3</th>
                <td><FaceSelector @clicked-face="thirdAnswer" /></td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closing()">Close </v-btn>
        <v-btn
          color="primary darken-1"
          @click="saveFeedback"
          :disabled="!isFrmValid"
        >
          Save Feedback
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FaceSelector from "@/components/CustomInputs/FaceSelector.vue";
import {
  SAVE_LEARNER_FEEDBACK,
  M_OU,
  SAVE_INSTRUCTOR_FEEDBACK,
} from "@/store/mutation-list";
import { mapActions } from "vuex";
export default {
  name: "QuestionsDialog",
  props: ["dialog", "title", "summary", "FeedbackQuestions", "bid", "type"],
  components: { FaceSelector },
  data() {
    return {
      clickedFacevarA1: 0,
      clickedFacevarA2: 0,
      clickedFacevarA3: 0,
    };
  },
  methods: {
    ...mapActions({
      saveLearnerFeedbackAns: `${M_OU}${SAVE_LEARNER_FEEDBACK}`,
      saveInstructorFeedbackAns: `${M_OU}${SAVE_INSTRUCTOR_FEEDBACK}`,
    }),
    closing() {
      this.clickedFacevarA1 = 0;
      this.clickedFacevarA2 = 0;
      this.clickedFacevarA3 = 0;
      this.$emit("close");
    },
    success() {
      this.clickedFacevarA1 = 0;
      this.clickedFacevarA2 = 0;
      this.clickedFacevarA3 = 0;
      this.$emit("success");
    },
    saveFeedback() {
      //in herer depending on type of the table/dialg data will be submited to different endpoints
      //
      if (this.FeedbackQuestions.length == 3) {
        let returnFeedback = {
          bookingid: this.bid,
          feedback: [
            {
              qid: this.FeedbackQuestions[0].id,
              rating: this.clickedFacevarA1,
            },
            {
              qid: this.FeedbackQuestions[1].id,
              rating: this.clickedFacevarA2,
            },
            {
              qid: this.FeedbackQuestions[2].id,
              rating: this.clickedFacevarA3,
            },
          ],
        };
        if (this.type == "learner") {
          this.saveLearnerFeedbackAns({
            feedbacks: JSON.stringify(returnFeedback),
          }).then((res) => {
            if (res == "SUCCESS") {
              this.$fire({
                title: "Success",
                text: "Feedback Recorded.",
                type: "success",
                timer: 3000,
              }).then((r) => {
                console.log(r.value);
                this.success();
              });
            } else {
              this.$fire({
                title: "Error",
                text: "Error recording feedback. Please try again.",
                type: "error",
                allowOutsideClick: false,
                //timer: 3000,
              }).then((r) => {
                console.log(r.value);
              });
            }
          });
        } else if (this.type == "adi") {
          console.log(returnFeedback);
          this.saveInstructorFeedbackAns({
            feedbacks: JSON.stringify(returnFeedback),
          }).then((res) => {
            if (res == "SUCCESS") {
              this.$fire({
                title: "Success",
                text: "Feedback Recorded.",
                type: "success",
                timer: 3000,
              }).then((r) => {
                console.log(r.value);
                this.success();
              });
            } else {
              this.$fire({
                title: "Error",
                text: "Error recording feedback. Please try again.",
                type: "error",
                allowOutsideClick: false,
                //timer: 3000,
              }).then((r) => {
                console.log(r.value);
              });
            }
          });
        }
      }
    },
    firstAnswer(d) {
      this.clickedFacevarA1 = d;
    },
    secondAnswer(d) {
      this.clickedFacevarA2 = d;
    },
    thirdAnswer(d) {
      this.clickedFacevarA3 = d;
    },
  },
  computed: {
    isFrmValid: function () {
      if (
        this.clickedFacevarA1 > 0 &&
        this.clickedFacevarA2 > 0 &&
        this.clickedFacevarA3 > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style>
#summarytbl {
  border: 1px;
}
.fcol {
  width: 15%;
}
.scol {
  width: 85%;
}
</style>
