<template>
  <div class="row">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5">
        <h2>Book Learners</h2>
        <div class="subtitle-1">Please select driving instructor</div>
        <v-divider class="my-5"></v-divider>
        <v-row class="">
          <v-col class="col-sm-4">
            <v-select
              v-model="selected.adi"
              label="Driving Instructor"
              :items="adiList"
              item-value="adi_id"
              outlined
              :loading="!isADIsLoading"
              :disabled="autoADIdisable"
              @change="loadLearners"
            >
              <template v-slot:item="{ item }">
                <strong> A{{ item.adi_id }}</strong> &nbsp;-&nbsp;
                {{ item.title }}
                {{ item.fname }}
                {{ item.lname }}
              </template>
              <template v-slot:selection="{ item }">
                <strong> A{{ item.adi_id }}</strong
                >&nbsp;-&nbsp; {{ item.title }}
                {{ item.fname }}
                {{ item.lname }}
              </template>
            </v-select>
          </v-col>
          <v-col class="col-sm-4">
            <v-select
              v-model="selected.learner"
              label="Learner"
              :items="learnerList"
              item-value="stu_id"
              outlined
              :loading="isLearnersLoading"
              :disabled="disableLearner || learnerSelectDisable"
              @change="fillPuckupPC"
            >
              <template v-slot:item="{ item }">
                <strong>L{{ item.stu_id }} </strong>&nbsp; -&nbsp;
                {{ item.title }} {{ item.fname }} {{ item.lname }}
                <strong>
                  &nbsp;(Credit balance: {{ item.credit_balance }})</strong
                >
              </template>
              <template v-slot:selection="{ item }">
                <strong>L{{ item.stu_id }} </strong> &nbsp;-&nbsp;
                {{ item.title }} {{ item.fname }} {{ item.lname }}
                <strong>
                  &nbsp;(Credit balance: {{ item.credit_balance }})</strong
                >
              </template>
            </v-select>
          </v-col>
          <v-col class="col-sm-4"
            ><v-btn
              class="primary"
              x-large
              :disabled="!isBtnState"
              :loading="isCredLoading"
              @click="loadCredits"
              >{{ btnTxt }}</v-btn
            >
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-5">
          <v-col class="col-md-2 shrink">
            <v-expand-transition>
              <!-- All learner credits displayed in here -->
              <div id="creditBlock" v-show="isCalendar">
                <!-- Will add Pickup Location input here -->

                <h3>
                  Pickup Location
                  <v-icon color="red" x-large>mdi-map-marker-up </v-icon>
                </h3>
                <v-spacer></v-spacer>
                <v-text-field
                  dense
                  label="First line of address"
                  v-model="pickupline1"
                  counter="50"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Postcode"
                  v-model="pickupc"
                  counter="8"
                ></v-text-field>
                <v-divider></v-divider>
                <h3>
                  Drop off Location
                  <v-icon color="red" x-large>mdi-map-marker-down </v-icon>
                </h3>
                <v-spacer></v-spacer>
                <v-text-field
                  dense
                  label="First line of address"
                  v-model="dropoffline1"
                  counter="50"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Postcode"
                  v-model="droppc"
                  counter="8"
                ></v-text-field>
                <v-divider></v-divider>

                <h3>Available Credit</h3>

                <v-btn
                  class="my-1 dragLesson"
                  color="primary"
                  dark
                  v-for="(cred, index) in learnerCredits"
                  :id="cred.id"
                  :key="cred.id"
                  v-bind:data-event="getJSONeventData(cred.id)"
                  ><v-icon>mdi-steering</v-icon> 1H Lesson
                  {{ index + 1 }}</v-btn
                >
              </div>
            </v-expand-transition>
            <v-divider></v-divider>
            <v-expand-transition>
              <v-btn
                class="success"
                v-if="newBookings.length > 0"
                @click="SaveBookings"
                >Save Change</v-btn
              >
            </v-expand-transition>
            <v-divider></v-divider>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col class="col-md-8 shrink">
            <v-expand-transition>
              <FullCalendar :options="calendarOptions" />
            </v-expand-transition>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col class="col-md-2 shrink"><CalendarLegend /></v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import {
  M_OU,
  LOAD_ADI_SUMMARY,
  GET_ADI_SUMMARY,
  IS_ADI_SUMMARY_LOADED,
  GET_ADI_LEARNERS,
  GET_ADI_CUREENT_BOOKINGS,
  GET_ADI_OLD_BOOKINGS,
  GET_LEARNER_CREDITS,
  SET_NEW_BOOKINGS,
} from "@/store/mutation-list";
// import { mapGetters } from "vuex";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

import CalendarLegend from "@/components/Booking/CalendarLegend";
import { mapActions, mapGetters } from "vuex";

document.addEventListener;
export default {
  name: "BookLearners",
  components: {
    FullCalendar,
    CalendarLegend,
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Book Learners",
          disabled: true,
          href: "/dashboard/booklearners",
        },
      ],
      //adiList: [],
      //isADIsLoading: false,
      adiSelectDisable: false,
      learnerList: [],
      isLearnersLoading: false,
      learnerSelectDisable: false,

      adiCurrentBookings: [],
      isCredLoading: false,
      creditLoaded: false,
      learnerCredits: [],

      //pickupLocation
      pickupline1: "",
      pickupc: "",

      //drop off location
      dropoffline1: "",
      droppc: "",

      selected: {
        adi: null,
        learner: null,
      },
      //lessons data
      lessonDuration: "01:00",
      newBookings: [],

      isCalendar: false,
      //calendar data
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
        initialView: "dayGridMonth",

        //height: 650,
        //new times slots
        slotDuration: "00:15",
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
        },

        aspectRatio: 2,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },

        buttonText: {
          today: "Today",
          month: "Month",
          week: "Week",
          day: "Day",
          list: "List",
        },
        fixedWeekCount: false,
        locale: "en-gb",
        firstDay: 1,
        allDaySlot: false,
        navLinks: true,
        editable: true,
        eventDurationEditable: false,
        droppable: true,
        // themeSystem: "bootstrap",
        eventOverlap: false,
        businessHours: this.getBusinessHours(),
        nowIndicator: true,
        eventTimeFormat: {
          // like '14:30:00'
          hour: "2-digit",
          minute: "2-digit",
          meridiem: false,
        },
        //prevent dropping on old dates
        /*validRange: function (nowDate) {
          let temp = new Date();
          //add 3 months
          temp.setDate(nowDate.getDate() + 90);
          return {
            start: nowDate,
            end: temp,
          };
        },*/
        validRange: function (nowDate) {
          let temp = new Date();
          //add 3 months
          temp.setDate(nowDate.getDate() + 90);
          const oldDay = new Date();
          oldDay.setDate(nowDate.getDate() - 90);
          return {
            start: oldDay,
            end: temp,
          };
        },
        events: [],
        drop: function (info) {
          //remove the lesson when dropped
          info.draggedEl.parentNode.removeChild(info.draggedEl);
        },
        dropAccept: ".dragLesson",
        eventDrop: this.handleMoves,
        eventReceive: this.handleDrops,
      },
      addEvents: true,
    };
  },

  methods: {
    ...mapActions({
      LoadADIs: `${M_OU}${LOAD_ADI_SUMMARY}`,
      LoadADIsLearners: `${M_OU}${GET_ADI_LEARNERS}`,
      LoadADIBookings: `${M_OU}${GET_ADI_CUREENT_BOOKINGS}`,
      LoadADIOldBookings: `${M_OU}${GET_ADI_OLD_BOOKINGS}`,
      LoadLearnerActiveCredits: `${M_OU}${GET_LEARNER_CREDITS}`,
      SaveNewBookings: `${M_OU}${SET_NEW_BOOKINGS}`,
    }),
    fillPuckupPC(id) {
      let i = this.learnerList.findIndex((item) => {
        return item.stu_id === id;
      });
      this.pickupc = this.learnerList[i].pick_up;
    },
    loadLearners(adiID) {
      //this method will load learners
      this.isLearnersLoading = true;
      //need to clear selected learner
      this.selected.learner = null;

      //console.log(adiID);

      this.learnerList = [];
      this.LoadADIsLearners({
        id: adiID,
      })
        .then((res) => {
          if (res) {
            this.learnerList = res;
          } else {
            this.learnerList = [];
          }
        })
        .finally(() => {
          this.isLearnersLoading = false;
        });
    },
    loadCredits() {
      //this function will load adi calendar and the credit for selected learner

      //this will load credit or do reset
      if (this.creditLoaded) {
        this.creditLoaded = false;
        this.$router.go();
      } else {
        this.isCredLoading = true;

        this.adiCurrentBookings = []; // clear adi current bookings
        this.learnerCredits = []; // clear any learner credits

        this.adiSelectDisable = true; // disable adi select
        this.learnerSelectDisable = true; // disable learner select

        let loadedBookingsData = [];

        let sd = this.getNewDate;
        let ed = this.getThreeMonthBeforeDate;
        //lets push non edit area
        this.adiCurrentBookings.push({
          start: ed.toString(),
          end: sd.toString(),
          overlap: false,
          display: "background",
          color: "#ffebe6",
        });

        this.LoadADIBookings({ id: this.selected.adi })
          .then((rep) => {
            loadedBookingsData = rep;
            //console.log(loadedBookingsData);
          })
          .finally(() => {
            loadedBookingsData.forEach((booking) => {
              let addressStr = "Pick Up: ";
              if (booking.addr_line && booking.addr_pc) {
                addressStr += booking.addr_line + ", ";
              } else if (booking.addr_line && !booking.addr_pc) {
                addressStr += booking.addr_line;
              }
              if (booking.addr_pc) {
                addressStr += booking.addr_pc;
              }
              if (!booking.addr_line && !booking.addr_pc) {
                addressStr = "";
              }

              let dropAddStr = " Drop off: ";
              if (booking.drop_line && booking.drop_pc) {
                dropAddStr += booking.drop_line + ", ";
              } else if (booking.drop_line && !booking.drop_pc) {
                dropAddStr += booking.drop_line;
              }
              if (booking.drop_pc) {
                dropAddStr += booking.drop_pc;
              }
              if (!booking.drop_line && !booking.drop_pc) {
                dropAddStr = "";
              }
              if (addressStr && dropAddStr) {
                dropAddStr = " ," + dropAddStr;
              }

              let temp = {
                id: booking.booking_id,
                title:
                  booking.stu_title +
                  " " +
                  booking.stu_fname +
                  " " +
                  booking.stu_lname +
                  " (L" +
                  booking.stu_id +
                  ") " +
                  addressStr +
                  dropAddStr,
                start: booking.lesson_starts,
                end: booking.lesson_end,
                color: this.getEventColour(
                  booking.booking_lesson_type,
                  booking.booking_state
                ),
                editable: false,
              };
              this.adiCurrentBookings.push(temp);
            });
          });

        //now load the credits
        this.LoadLearnerActiveCredits({ id: this.selected.learner })
          .then((rep) => {
            this.learnerCredits = rep;
          })
          .finally(() => {
            this.creditLoaded = true;
            this.isCredLoading = false;

            //let try to add the events
            this.calendarOptions.events = this.adiCurrentBookings;
            //let add the draggable info
            this.calActions();
            this.isCalendar = true;
          });

        //lets load old bookings
        this.LoadADIOldBookings({ id: this.selected.adi }).then((rep) => {
          console.log(rep);
          let oldBookings = rep;
          if (oldBookings) {
            oldBookings.forEach((booking) => {
              let addressStr = "Pick Up: ";
              if (booking.addr_line && booking.addr_pc) {
                addressStr += booking.addr_line + ", ";
              } else if (booking.addr_line && !booking.addr_pc) {
                addressStr += booking.addr_line;
              }
              if (booking.addr_pc) {
                addressStr += booking.addr_pc;
              }
              if (!booking.addr_line && !booking.addr_pc) {
                addressStr = "";
              }

              let dropAddStr = " Drop off: ";
              if (booking.drop_line && booking.drop_pc) {
                dropAddStr += booking.drop_line + ", ";
              } else if (booking.drop_line && !booking.drop_pc) {
                dropAddStr += booking.drop_line;
              }
              if (booking.drop_pc) {
                dropAddStr += booking.drop_pc;
              }
              if (!booking.drop_line && !booking.drop_pc) {
                dropAddStr = "";
              }
              if (addressStr && dropAddStr) {
                dropAddStr = " ," + dropAddStr;
              }

              let temp = {
                id: booking.booking_id,
                title:
                  booking.stu_title +
                  " " +
                  booking.stu_fname +
                  " " +
                  booking.stu_lname +
                  " (L" +
                  booking.stu_id +
                  ") " +
                  addressStr +
                  dropAddStr,
                start: booking.lesson_starts,
                end: booking.lesson_end,
                color: this.getEventColour(
                  booking.booking_lesson_type,
                  booking.booking_state
                ),
                editable: false,
              };
              this.calendarOptions.events.push(temp);
            });
          }
        });
      }
    },
    getJSONeventData(credID) {
      let myLearner = this.selected.learner;

      let cl = this.learnerList.filter(
        (learner) => learner.stu_id == myLearner
      );
      //array to 1 object
      cl = cl[0];
      //console.log(`{"id": ${credID}, "title": "${cl.title} ${cl.fname} ${cl.lname}  L${cl.stu_id}", "duration": "${this.lessonDuration}", "extendedProps": { "eventType": "newbooking", "learnerid" : ${cl.stu_id}}}`);
      return `{"id": ${credID}, "title": "${cl.title} ${cl.fname} ${cl.lname}  L${cl.stu_id}", "duration": "${this.lessonDuration}", "extendedProps": { "eventType": "newbooking", "learnerid" : ${cl.stu_id}}}`;
    },
    calActions() {
      //var Draggable =interactionPlugin.Draggable;
      var lessonContainer = document.getElementById("creditBlock");

      new Draggable(lessonContainer, {
        itemSelector: ".dragLesson",
      });
    },
    //this method will work on event drops
    handleDrops(ev) {
      let tempobj = {
        id: ev.event.id,
        start: ev.event.start,
        bookingType: ev.event.extendedProps.eventType,
        durationEditable: false,
      };

      this.newBookings.push(tempobj);
      //console.log(tempobj);
      //console.log(this.newBookings);
    },
    //this method will work on move events
    handleMoves(dropInfo) {
      let objIndex = this.newBookings.findIndex(
        (obj) => obj.id == dropInfo.event.id
      );
      this.newBookings[objIndex].start = dropInfo.event.start;
    },
    getEventColour(type, state) {
      let newlearner = "#66CC00";
      let normalLesson = "#337ab7";
      let test = "#FF6600";
      let rescheduled = "#9C27B0";
      let canceled = "#FF3333";
      type = parseInt(type);
      state = parseInt(state);

      switch (state) {
        case 1:
          //scheduled
          switch (type) {
            case 1:
              return newlearner;

            case 2:
              return normalLesson;

            case 3:
              return test;

            case 4:
              return test;

            default:
              return normalLesson;
          }

        case 2:
          return rescheduled;
        //resceduled

        case 3:
          return canceled;
        //canceled

        default:
          return normalLesson;
      }
    },
    getBusinessHours() {
      return [
        {
          daysOfWeek: [1, 2, 3, 4, 5], // Monday
          startTime: "07:00", // 8am
          endTime: "20:00", // 6pm
        },
        /*{
            daysOfWeek: [2], // Tue
            startTime: "07:30", // 8am
            endTime: "21:00", // 6pm
          },
          {
            daysOfWeek: [3], // Wed
            startTime: "07:30", // 8am
            endTime: "21:00", // 6pm
          },
          {
            daysOfWeek: [4], // Thu
            startTime: "08:00", // 8am
            endTime: "22:00", // 6pm
          },
          {
            daysOfWeek: [5], // Fri
            startTime: "07:00", // 8am
            endTime: "17:00", // 6pm
          },*/
        {
          daysOfWeek: [6], // Sat
          startTime: "09:00", // 8am
          endTime: "16:00", // 6pm
        },
        {
          daysOfWeek: [0], // Sun
          startTime: "09:00", // 8am
          endTime: "12:00", // 6pm
        },
      ];
    },
    getTwoDigits(num) {
      return ("0" + num).slice(-2);
    },
    //this function convert date time to YYYY-MM-DD HH:mm:ss format
    convertDate(dtVal) {
      let d = new Date(dtVal);
      let YYYY = d.getFullYear();
      let MM = d.getMonth();

      MM++;
      MM = this.getTwoDigits(MM);
      let DD = d.getDate();
      DD = this.getTwoDigits(DD);
      let HH = d.getHours();
      HH = this.getTwoDigits(HH);
      let mm = d.getMinutes();
      mm = this.getTwoDigits(mm);

      return `${YYYY}-${MM}-${DD} ${HH}:${mm}:00`;
    },
    //this method will save all bookings
    SaveBookings() {
      this.newBookings.forEach((item, index) => {
        this.newBookings[index].start = this.convertDate(item.start);
      });
      let temp = JSON.stringify(this.newBookings);
      //console.log(temp);
      this.SaveNewBookings({
        bookings: temp,
        adi: this.selected.adi,
        learner: this.selected.learner,
        pickup: { line1: this.pickupline1, pc: this.pickupc },
        dropoff: { line1: this.dropoffline1, pc: this.droppc },
      }).then((res) => {
        //response from store. All complete just need to show to popup message
        //console.log(res);
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "New Bookings Placed.",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
            //clear all and reloadclear()
            this.clear();
            this.$router.go();
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error placing bookings. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },

    //this method clear all data
    clear() {
      this.adiSelectDisable = false;
      this.learnerList = [];
      this.isLearnersLoading = false;

      this.adiCurrentBookings = [];
      this.isCredLoading = false;
      this.creditLoaded = false;
      this.learnerCredits = [];

      this.selected = {
        adi: null,
        learner: null,
      };
      //lessons data
      this.lessonDuration = "01:00";
      this.newBookings = [];
    },
  },
  computed: {
    ...mapGetters({
      adiList: `${M_OU}${GET_ADI_SUMMARY}`,
      isADIsLoading: `${M_OU}${IS_ADI_SUMMARY_LOADED}`,
    }),
    autoADIdisable() {
      if (!this.isADIsLoading || this.adiSelectDisable) {
        return true;
      } else return false;
    },
    getConstrtain() {
      return { start: this.getStartDate, end: this.getEndDate };
    },
    disableLearner() {
      if (this.selected.adi || this.isLearnersLoading) {
        return false;
      }
      return true;
    },
    btnTxt() {
      if (this.creditLoaded) {
        return "Reset";
      } else {
        return "Load Credit";
      }
    },
    isBtnState() {
      if (this.selected.learner) {
        return true;
      } else {
        return false;
      }
    },
    //this function will return current data in ISO format
    getNewDate() {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      return today.toISOString().substring(0, 10);
    },
    //this funtion return 90 days before now
    getThreeMonthBeforeDate() {
      let oldDay = new Date();
      oldDay.setDate(oldDay.getMonth() - 90);
      return oldDay.toISOString().substring(0, 10);
    },
  },

  mounted() {
    this.LoadADIs();
  },
};
</script>
