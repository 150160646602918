<template>
  <div>
    <v-form ref="form">
      <v-row>
        <v-col md="4">
          <v-file-input
            v-model="form.licenceCopy"
            id="fldl"
            label="Driving Licence"
            :rules="rules"
            outlined
            clearable
            show-size
            :error-messages="licenceCopyErrors"
            accept="application/pdf"
            placeholder="Select PDF File"
            @input="$v.form.licenceCopy.$touch()"
            @blur="$v.form.licenceCopy.$touch()"
            @change="currentState()"
          ></v-file-input>
          <v-text-field
            v-model="form.licenceexp"
            label="Driving Licence Expiry Date"
            name="licenceexp"
            outlined
            required
            :error-messages="licenceexpErrors"
            @input="$v.form.licenceexp.$touch()"
            @blur="$v.form.licenceexp.$touch()"
            type="date"
            @change="currentState()"
          ></v-text-field>
          <v-file-input
            v-model="form.adilicenceCopy"
            id="fladil"
            label="ADI/PDI Licence"
            :rules="rules"
            outlined
            clearable
            show-size
            :error-messages="adilicenceCopyErrors"
            accept="application/pdf"
            placeholder="Select PDF File"
            @input="$v.form.adilicenceCopy.$touch()"
            @blur="$v.form.adilicenceCopy.$touch()"
            @change="currentState()"
          ></v-file-input>
          <v-text-field
            v-model="form.adilicenceexp"
            name="adilicenceexp"
            label="ADI/PDI Licence Expiry Date"
            outlined
            required
            :error-messages="adilicenceexpErrors"
            @input="$v.form.adilicenceexp.$touch()"
            @blur="$v.form.adilicenceexp.$touch()"
            type="date"
            @change="currentState()"
          ></v-text-field> </v-col
      ></v-row>
    </v-form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "Licences",
  data() {
    return {
      rules: [
        (value) =>
          !value ||
          value.size < 4000000 ||
          "PDF Document file size should be less than 4 MB!",
      ],
      form: {
        licenceCopy: null,
        licenceexp: "",
        adilicenceCopy: null,
        adilicenceexp: "",
      },
    };
  },
  validations: {
    form: {
      licenceCopy: { required },
      licenceexp: { required },
      adilicenceCopy: { required },
      adilicenceexp: { required },
    },
  },
  computed: {
    licenceCopyErrors() {
      const errors = [];
      if (!this.$v.form.licenceCopy.$dirty) return errors;
      !this.$v.form.licenceCopy.required &&
        errors.push("Copy of Driving Licence is required.");
      return errors;
    },
    licenceexpErrors() {
      const errors = [];
      if (!this.$v.form.licenceexp.$dirty) return errors;
      !this.$v.form.licenceexp.required &&
        errors.push("Driving Licence Expiry Day required.");
      return errors;
    },
    adilicenceCopyErrors() {
      const errors = [];
      if (!this.$v.form.adilicenceCopy.$dirty) return errors;
      !this.$v.form.adilicenceCopy.required &&
        errors.push("Copy of ADI/PDI Licence is required.");
      return errors;
    },
    adilicenceexpErrors() {
      const errors = [];
      if (!this.$v.form.adilicenceexp.$dirty) return errors;
      !this.$v.form.adilicenceexp.required &&
        errors.push("ADI/PDI Licence Expiry Day required.");
      return errors;
    },
  },
  methods: {
    currentState() {
      if (this.form.licenceCopy && this.form.adilicenceCopy) {
        //there are licence uploades
        let formdata = new FormData(this.$refs.form.$el);

        let drivingLPDF = document.getElementById("fldl").files[0];
        let adiLPDF = document.getElementById("fladil").files[0];
        formdata.set("fldl", drivingLPDF, "dlicence.pdf");

        formdata.set("fladil", adiLPDF, "adilicence.pdf");

        this.$emit("licences-update", {
          state: this.$v.form.$invalid,
          data: this.form,
          fd: formdata,
        });
      } else {
        this.$emit("licences-update", {
          state: this.$v.form.$invalid,
          data: this.form,
          fd: new FormData(this.$refs.form.$el),
        });
      }
    },
  },
};
</script>

<style></style>
