<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5"
        ><h2>Manage Learners (View/Edit Learners)</h2>
        <v-data-table
          :headers="headers"
          :items="recentLearners"
          :search="keyword"
          class="elevation-1"
          @click:row="openEdit"
        >
          <template v-slot:top>
            <v-text-field
              v-model="keyword"
              label="Search"
              placeholder="Enter keyword to search"
              class="ma-4"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-dialog
              v-model="dialog"
              max-width="960px"
              scrollable
              persistent
              @click:outside="outsideclick()"
            >
              <v-card>
                <v-card-title class="text-h5 blue lighten-2">
                  <span>View/Edit Learners</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <LearnerForm
                      v-if="passingID"
                      :form-type="frmType"
                      :learner="passingID"
                      :is-locked="true"
                      @saveDone="close"
                    />
                  </v-container>
                  <v-sheet color="white" elevation="1" class="pa-5">
                    <h2>Instructor Info</h2>
                    <v-divider class="mt-3 mb-3"></v-divider>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead style="font-weight: 600">
                          <tr>
                            <th class="text-left">ADI ID</th>
                            <th class="text-left">ADI Name</th>
                            <th class="text-left">Assign Status</th>
                            <th class="text-left">Assigned On</th>
                            <th class="text-left">Updated On</th>
                          </tr>
                        </thead>
                        <tbody v-if="assignedADI.lst.length > 0">
                          <tr
                            v-for="adirec in assignedADI.lst"
                            :key="adirec.assingn_id"
                          >
                            <td style="font-weight: 600">
                              A{{ adirec.adi_id }}
                            </td>
                            <td>
                              {{
                                `${adirec.adi_title} ${adirec.adi_fname} ${adirec.adi_lanme}`
                              }}
                            </td>
                            <td>{{ getAssignState(adirec.assign_status) }}</td>
                            <td>{{ adirec.assign_on | dateReOrder }}</td>
                            <td>{{ adirec.assign_updated | dateReOrder }}</td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="5">No Data to display</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-sheet>
                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:[`item.student_id`]="{ item }">
            <strong>{{ "L" + item.student_id }}</strong>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.title + " " + item.fname + " " + item.lname }}
          </template>
          <template v-slot:[`item.mobile`]="{ item }">
            {{ item.mobile | mobileno }}
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import LearnerForm from "@/components/LearnerComps/LearnerForm.vue";
import { mapActions, mapGetters } from "vuex";
import {
  GET_RECENT_LEARNERS,
  M_OU,
  LOAD_RECENT_LEARNERS,
  LOAD_ASSINGED_LEARERS,
  GET_ASSINGED_LEARERS,
} from "@/store/mutation-list";
export default {
  name: "ManageLearners",
  components: { LearnerForm },
  data: () => {
    return {
      frmType: "edit",
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Manage Learners",
          disabled: true,
          href: "/dashboard/managelearners",
        },
      ],
      dialog: false,
      passingID: null,
      //data table realated data
      keyword: "",
      headers: [
        { text: "Learner ID", value: "student_id" },
        { text: "Name", value: "name" },
        { text: "Mobile", value: "mobile" },
        { text: "E-mail", value: "email" },
        { text: "Pickup Postcode", value: "pickup_pc" },
        { text: "Date Joined", value: "created" },
      ],
      //this array holds adi list of this learner
      assignedADI: { lst: [], isLoaded: false },
    };
  },
  computed: {
    ...mapGetters({
      recentLearners: `${M_OU}${GET_RECENT_LEARNERS}`,
      getAssgnedLearners: `${M_OU}${GET_ASSINGED_LEARERS}`,
    }),
  },
  methods: {
    ...mapActions({
      loadLearners: `${M_OU}${LOAD_RECENT_LEARNERS}`,
      loadAssignLearners: `${M_OU}${LOAD_ASSINGED_LEARERS}`,
    }),
    openEdit(learner) {
      this.assignedADI = { lst: [], isLoaded: false };
      //console.log(learner);
      this.passingID = learner.student_id;
      //do all the work then
      this.dialog = true;
      //fill the current learner ADI history

      this.assignedADI.lst = this.getAssgnedLearners.filter(
        (rec) => rec.stu_id === learner.student_id
      );

      this.assignedADI.isLoaded = true;
    },
    close() {
      //remove data object
      this.assignedADI = { lst: [], isLoaded: false };
      this.dialog = false;
    },
    outsideclick() {
      var errAudi = new Audio("/img/sound/honk2.mp3");
      errAudi.play();
    },
    getAssignState(typeID) {
      let rep;
      switch (typeID) {
        case 0:
          rep = "Blocked";
          break;
        case 1:
          rep = "Active";
          break;
        case 2:
          rep = "Re-Assigned";
          break;
        case 3:
          rep = "abandoned";
          break;
        case 4:
          rep = "Passed and Complete";
          break;
        case 5:
          rep = "No Communcation";
          break;
        case 6:
          rep = "Joined Other School";
          break;
        default:
          rep = typeID;
          break;
      }
      return rep;
    },
  },
  created() {
    this.loadLearners();
    this.loadAssignLearners();
  },
};
</script>

<style></style>
