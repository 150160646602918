<template>
  <v-col cols="12">
    <v-divider />
    <div class="mt-5">
      <h3 class="text-center">Summary Chart</h3>
      <GChart
        type="ColumnChart"
        :settings="{ packages: ['corechart'] }"
        :data="getChartData"
        :options="chartOptions"
        :title="chartOptions.title"
      />
    </div>
  </v-col>
</template>
<script>
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "InstructorTeachingChart",
  components: { GChart },
  props: ["adiLang", "learnerLang", "teachGen"],
  data() {
    return {
      /*chartData: [
            [
              "Ratio",
              "Male",
              "Female",
              "17 - 25",
              "26 - 35 ",
              "36 - 45 ",
              "46 - 55",
              "56 - 65",
              "65 & over",
              "Automatic",
              "Manual",
              "E8",
              "E7",
            ],
            ["Gender Ratio", 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            ["Age Ratios", 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
            ["Gear Ratios", 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0],
            ["Geographical Areas", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1],
          ],*/
      chartOptions: {
        chart: {
          title: "Instructor Teaching Report Ratios",
          subtitle:
            "Teaching Secondary Languages, Learners Per Secondary Language and Teaching Genders",
        },
        legend: { topic: "top", position: "right", maxLines: 3 },
        isStacked: "percent",
        height: 600,
        vAxis: { minValue: 1 },
        is3D: true,
      },
    };
  },
  methods: {
    getTableKeys(arr) {
      let keys = [];
      if (arr) {
        arr.forEach(function (rec) {
          var key = JSON.stringify(rec.key).replaceAll('"', "");
          keys.push(key);
        });
      }
      return keys;
    },
  },
  computed: {
    populateChartTitles() {
      let retArray = [];
      // add Language Ratios
      let arrADILangRatio = this.getTableKeys(this.adiLang);

      //add learner Langs
      let arrLearnerLangRatio = this.getTableKeys(this.learnerLang);

      //add teaching Genders
      let arrTeachGenRatio = this.getTableKeys(this.teachGen);
      retArray = [
        //lets add title
        ...arrADILangRatio,
        ...arrLearnerLangRatio,
        ...arrTeachGenRatio,
      ];

      //console.log(retArray);
      return retArray;
    },
    populateChartData() {
      //step 1 : need to know full with of tabel i.e No of postcodes at the end of the table. This section fils requred section rest will be zerro

      //size/ with of data tabel

      return [
        this.getADILangColumn,
        this.getLearnerLangColumn,
        this.getADITeachGenColumn,
      ];
    },
    getADILangColumn() {
      let row = []; // returning row
      let c = 0; // this is the static counter
      //mesure the size of  previus tables
      let previousTitlesSize = 0;
      //mesure the size of current table

      for (let i = 0; i < this.getFullWidth; i++) {
        if (i == 0) {
          //ignote becase we added title
        } else if (i <= previousTitlesSize) {
          // add 0 to previous table with and value to current table width
          row.push(0);
        } else if (
          i > previousTitlesSize &&
          i <= previousTitlesSize + this.adiLang.length
        ) {
          row.push(this.adiLang[c].count);
          c++; //increase c for next title
          // the right elemt space
        } else {
          // i>previousTitlesSize + this.duelCtrlRatios.length
          //loop gone pass the title area
          row.push(0);
          //add 0 to rest of the table widths
        }
      }
      row.unshift("Teaching Secondary Languages");
      return row;
    },
    getLearnerLangColumn() {
      let row = []; // returning row
      let c = 0; // this is the static counter
      //mesure the size of  previus tables
      let previousTitlesSize = this.adiLang.length;
      //mesure the size of current table

      for (let i = 0; i < this.getFullWidth; i++) {
        if (i == 0) {
          //ignote becase we added title
        } else if (i <= previousTitlesSize) {
          // add 0 to previous table with and value to current table width
          row.push(0);
        } else if (
          i > previousTitlesSize &&
          i <= previousTitlesSize + this.learnerLang.length
        ) {
          row.push(this.learnerLang[c].count);
          c++; //increase c for next title
          // the right elemt space
        } else {
          // i>previousTitlesSize + this.duelCtrlRatios.length
          //loop gone pass the title area
          row.push(0);
          //add 0 to rest of the table widths
        }
      }
      row.unshift("Learners per Secondary Language");
      return row;
    },
    getADITeachGenColumn() {
      let row = []; // returning row
      let c = 0; // this is the static counter
      //mesure the size of  previus tables
      let previousTitlesSize = this.adiLang.length + this.learnerLang.length;
      //mesure the size of current table

      for (let i = 0; i < this.getFullWidth; i++) {
        if (i == 0) {
          //ignote becase we added title
        } else if (i <= previousTitlesSize) {
          // add 0 to previous table with and value to current table width
          row.push(0);
        } else if (
          i > previousTitlesSize &&
          i <= previousTitlesSize + this.teachGen.length
        ) {
          row.push(this.teachGen[c].count);
          c++; //increase c for next title
          // the right elemt space
        } else {
          // i>previousTitlesSize + this.duelCtrlRatios.length
          //loop gone pass the title area
          row.push(0);
          //add 0 to rest of the table widths
        }
      }
      row.unshift("Teaching Genders");
      return row;
    },

    getTableval(arr) {
      let keysAndValues = {};
      if (arr) {
        this.arr.forEach(function (rec) {
          var val = JSON.stringify(rec.count).replaceAll('""', "");
          keysAndValues.push(val);
        });
      }
      return keysAndValues;
    },
    //this will return google charData
    getChartData() {
      let titles = this.populateChartTitles;
      titles.unshift("Ratio");
      let ar = this.populateChartData;
      ar.unshift(titles);
      //console.log(ar);
      return ar;
    },
    //this will return number of titles in the table (expect first one)
    getFullWidth() {
      if (this.populateChartTitles) {
        return this.populateChartTitles.length;
      }
      return 0;
    },
  },
};
</script>
