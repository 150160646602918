<template>
  <div>
    <v-form ref="form">
      <v-row>
        <v-col md="4">
          <v-select
            v-model="form.aditgender"
            name="aditgender"
            :items="genders"
            outlined
            item-text="txt"
            item-value="id"
            label="Teaching Gender"
            style="width: 50%"
            required
            :error-messages="aditgenderErrors"
            @input="$v.form.aditgender.$touch()"
            @blur="$v.form.aditgender.$touch()"
            @change="currentState()"
          >
          </v-select>
          <v-select
            v-model="form.geartype"
            name="geartype"
            :items="gears"
            outlined
            item-text="txt"
            item-value="id"
            label="Teaching Gear Type"
            style="width: 50%"
            required
            :error-messages="geartypeErrors"
            @input="$v.form.geartype.$touch()"
            @blur="$v.form.geartype.$touch()"
            @change="currentState()"
          >
          </v-select>
          <v-combobox
            v-model="form.coveringareas"
            name="coveringareas[]"
            label="Covering Postcodes"
            multiple
            outlined
            chips
            placeholder="Add covering postcodes"
            hint="Please enter outer postcode only. E.g: 'E7'. Press <Enter> key to add next postcode."
            persistent-hint
            @change="currentState()"
            :error-messages="coveringareasErrors"
            @input="$v.form.coveringareas.$touch()"
            @blur="$v.form.coveringareas.$touch()"
          >
            <template v-slot:selection="data">
              <v-chip
                :key="JSON.stringify(data.item)"
                v-bind="data.attrs"
                :input-value="data.selected"
                :disabled="data.disabled"
                @click:close="data.parent.selectItem(data.item)"
              >
                <v-icon color="accent white--text" left>mdi-map-marker </v-icon>
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
          <v-select
            v-model="form.aditlang"
            name="aditlang[]"
            :items="adilangs"
            chips
            label="Teaching Languages"
            item-text="txt"
            item-value="id"
            multiple
            outlined
            :error-messages="aditlangErrors"
            @input="$v.form.aditlang.$touch()"
            @blur="$v.form.aditlang.$touch()"
            @change="currentState()"
          ></v-select>
          <v-select
            v-model="form.carttype"
            name="carttype[]"
            :items="cartypes"
            chips
            label="Teaching Vehicle Types"
            item-text="txt"
            item-value="id"
            multiple
            outlined
            :error-messages="carttypeErrors"
            @input="$v.form.carttype.$touch()"
            @blur="$v.form.carttype.$touch()"
            @change="currentState()"
          ></v-select> </v-col
      ></v-row>
    </v-form>
  </div>
</template>

<script>
import { GET_LANGUAGES, GET_VEHICLE_TYPES, M_DD } from "@/store/mutation-list";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  name: "Teaching",
  data() {
    return {
      genders: [
        { id: "any", txt: "Any" },
        { id: "female", txt: "Female" },
        { id: "male", txt: "Male" },
      ],
      gears: [
        { id: "automatic", txt: "Automatic" },
        { id: "both", txt: "Automatic & Manual" },
        { id: "manual", txt: "Manual" },
      ],
      form: {
        aditgender: null,
        geartype: null,
        coveringareas: ["E7"],
        aditlang: [10],
        carttype: [],
      },
    };
  },
  validations: {
    form: {
      aditgender: { required },
      geartype: { required },
      coveringareas: { required },
      aditlang: { required },
      carttype: { required },
    },
  },
  computed: {
    ...mapGetters({
      adilangs: `${M_DD}${GET_LANGUAGES}`,
      cartypes: `${M_DD}${GET_VEHICLE_TYPES}`,
    }),
    aditgenderErrors() {
      const errors = [];
      if (!this.$v.form.aditgender.$dirty) return errors;
      !this.$v.form.aditgender.required &&
        errors.push("Teaching Gender is required.");
      return errors;
    },
    geartypeErrors() {
      const errors = [];
      if (!this.$v.form.geartype.$dirty) return errors;
      !this.$v.form.geartype.required &&
        errors.push("Teaching Gear Type required.");
      return errors;
    },

    coveringareasErrors() {
      const errors = [];
      if (!this.$v.form.coveringareas.$dirty) return errors;
      !this.$v.form.coveringareas.required &&
        errors.push("Covering Postcodes required.");
      return errors;
    },
    aditlangErrors() {
      const errors = [];
      if (!this.$v.form.aditlang.$dirty) return errors;
      !this.$v.form.aditlang.required &&
        errors.push("Teaching Languages required.");
      return errors;
    },
    carttypeErrors() {
      const errors = [];
      if (!this.$v.form.carttype.$dirty) return errors;
      !this.$v.form.carttype.required &&
        errors.push("Teaching Vehicle Types required.");
      return errors;
    },
  },
  methods: {
    async currentState() {
      await this.$nextTick();
      //lets manually create a form data and fill it this correct data.
      let fd = new FormData();
      //joinig postcodes
      this.form.coveringareas.forEach((ca) => {
        fd.append("coveringareas[]", ca);
      });
      //joinig languages
      this.form.aditlang.forEach((tl) => {
        fd.append("aditlang[]", tl);
      });
      //joinig cartypes
      this.form.carttype.forEach((ct) => {
        fd.append("carttype[]", ct);
      });
      //add teaching gendar
      fd.append("aditgender", this.form.aditgender);
      //add teaching gear type
      fd.append("geartype", this.form.geartype);

      /*for (const [key, value] of new FormData(this.$refs.form.$el)) {
        console.log(`${key}: ${value}`);
      }*/
      this.$emit("teaching-update", {
        state: this.$v.form.$invalid,
        data: this.form,
        fd: fd,
        // fd: new FormData(this.$refs.form.$el),
      });
    },
  },
};
</script>

<style></style>
