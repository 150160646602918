<template>
  <v-col md="4">
    <h3 class="mb-5">Gear Ratios</h3>
    <v-card class="mx-auto" :elevation="important" :outlined="isMain"
      ><v-simple-table v-if="tableData">
        <template v-slot:default>
          <thead>
            <tr class="text-center">
              <th class="text-center">Gear Ratio</th>
              <th class="text-center">Frequency</th>
              <th class="text-center">Precentage</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ratio, label, i) in getGearRatios"
              :key="i"
              class="text-center"
            >
              <td style="text-transform: capitalize">
                {{ label }}
              </td>
              <td>{{ ratio }}</td>
              <td>{{ ratio | precentage(tableData.length) }}</td>
            </tr>
            <tr class="tots">
              <th class="text-center">Total</th>
              <th class="text-center">{{ tableData.length }}</th>
              <th class="text-center">100%</th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p v-else class="warning">No Data Provided</p></v-card
    ></v-col
  >
</template>

<script>
export default {
  name: "GearRatioTable",
  props: ["tableData", "isMain"],
  computed: {
    getGearRatios() {
      let counter = {};
      this.tableData.forEach(function (rec) {
        var key = JSON.stringify(rec.lesson_type).replaceAll('"', "");
        counter[key] = (counter[key] || 0) + 1;
      });
      return counter;
    },
    important() {
      if (this.isMain) {
        return 24;
      } else {
        return 2;
      }
    },
  },
};
</script>

<style></style>
