<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <SummaryTable :rating-list="ratingsSummary" type="adi" />
    </v-col>
  </div>
</template>

<script>
import SummaryTable from "@/components/Feedback/SummaryTable.vue";
import { mapActions, mapGetters } from "vuex";
import {
  GET_INSTRUCTOR_FEEDBACK_SUMMARY,
  LOAD_INSTRUCTOR_FEEDBACK_SUMMARY,
  M_OU,
} from "@/store/mutation-list";
export default {
  name: "ViewInstructorFeedback",
  components: { SummaryTable },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "View Instructor Feedbacks",
          disabled: true,
          href: "/dashboard/viewinstructorfeedback",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      ratingsSummary: `${M_OU}${GET_INSTRUCTOR_FEEDBACK_SUMMARY}`,
    }),
  },
  methods: {
    ...mapActions({
      loadADIFBs: `${M_OU}${LOAD_INSTRUCTOR_FEEDBACK_SUMMARY}`,
    }),
  },
  created() {
    this.loadADIFBs();
  },
};
</script>

<style></style>
