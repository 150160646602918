<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col
      ><v-sheet color="white" elevation="1" class="pa-5">
        <h2>Resets Office Staff Password</h2>
        <v-divider></v-divider>
        <v-alert border="top" colored-border type="info" elevation="2">
          Password will reset to <strong>'NewRoad2020'</strong>
        </v-alert>
        <v-data-table
          :headers="headers"
          :items="allstaff"
          sort-by="calories"
          class="elevation-1"
        >
          <template v-slot:[`item.mobile`]="{ item }">
            {{ item.mobile | mobileno }}
          </template>
          <template v-slot:[`item.accState`]="{ item }">
            <v-chip :color="getChipColour(item.accState)" dark>
              {{ getChipText(item.accState) }}
            </v-chip>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>All Office Staff</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>

              <v-dialog v-model="dialogReset" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to reset this staff password?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="resetStaffPassConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="item.accState == 1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="amber"
                    class="mr-2"
                    @click="resetStaffPassword(item)"
                    v-bind="attrs"
                    v-on="on"
                    dark
                    ><v-icon left> mdi-lock-reset </v-icon>
                    Reset Password
                  </v-btn>
                </template>
                <span>Reset Password</span>
              </v-tooltip>
            </div>
            <div v-if="item.accState == 0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    disabled
                    color="amber"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    light
                    ><v-icon left> mdi-alert-circle </v-icon>
                    Account Blocked
                  </v-btn>
                </template>
                <span>Blocked Account</span>
              </v-tooltip>
            </div>
            <div v-if="item.accState > 1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    disabled
                    color="amber"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    light
                    ><v-icon left> mdi-alert-circle </v-icon>
                    Account Deleted
                  </v-btn>
                </template>
                <span>Deleted Account</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize"> Reset </v-btn>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import {
  GET_TITLES,
  REST_STAFF_PASS,
  GET_STAFF_TABLE,
  GET_STAFF_INFO,
  M_OA,
  M_DD,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ResetStaffPass",
  data: () => ({
    items: [
      {
        text: "Admin Dashboard",
        disabled: false,
        href: "/dashboard/adminhome",
      },
      {
        text: "Reset Password",
        disabled: true,
        href: "/dashboard/resetstaffpass",
      },
    ],
    genders: [
      { id: "f", txt: "Female" },
      { id: "m", txt: "Male" },
    ],

    //table related

    dialogReset: false,
    resettingStaffID: null,

    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      { text: "Name", value: "name" },
      { text: "Mobile", value: "mobile" },
      { text: "E-mail", value: "email" },
      { text: "Account Status", value: "accState" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    allstaff: [],
  }),

  computed: {
    ...mapGetters({
      titlesList: `${M_DD}${GET_TITLES}`,
      staffList: `${M_OA}${GET_STAFF_TABLE}`,
      getStaffInfo: `${M_OA}${GET_STAFF_INFO}`,
    }),
  },
  /*watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },*/

  created() {
    this.initialize();
  },
  methods: {
    ...mapActions({
      resetPass: `${M_OA}${REST_STAFF_PASS}`,
    }),

    initialize() {
      this.allstaff = this.staffList;
    },
    getChipColour(accState) {
      accState = parseInt(accState);
      let retVal = "";
      switch (accState) {
        case 0:
          retVal = "orange";
          break;
        case 1:
          retVal = "green";
          break;
        case 2:
          retVal = "blue-grey";
          break;
        case 3:
          retVal = "red";
          break;

        default:
          retVal = "red";
          break;
      }
      return retVal;
    },
    getChipText(accState) {
      accState = parseInt(accState);
      let retVal = "";
      switch (accState) {
        case 0:
          retVal = "Blocked";
          break;
        case 1:
          retVal = "Active";
          break;
        case 2:
          retVal = "Readonly";
          break;
        case 3:
          retVal = "Deleted";
          break;

        default:
          retVal = "Error";
          break;
      }
      return retVal;
    },

    resetStaffPassword(item) {
      //console.log(item);
      this.editedIndex = this.allstaff.indexOf(item);
      this.resettingStaffID = this.allstaff[this.editedIndex].id;
      //now this will be submited to store
      this.editedItem = Object.assign({}, item);
      this.form = Object.assign({}, item);
      this.dialogReset = true;
    },
    resetStaffPassConfirm() {
      //pass the data to store and manage any pop ups
      this.close();
      this.resetPass({
        staff_id: this.resettingStaffID,
      }).then((res) => {
        //response from store. All complete just need to show to popup message
        //console.log(res);
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Staff password resetted successfully.",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        } else {
          this.$fire({
            title: "Error!",
            text: "Error with resetting staff password. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },

    close() {
      this.dialogReset = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      //just need to pass the changes to store and upadte the table
      if (this.editedIndex > -1) {
        Object.assign(this.allstaff[this.editedIndex], this.editedItem);
      } else {
        this.allstaff.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style></style>
