<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col
      ><v-sheet color="white" elevation="1" class="pa-5">
        <h2>Manage Learner Feedback Questions</h2>
        <v-divider></v-divider>

        <v-data-table
          :headers="headers"
          :items="allQuestions"
          :items-per-page="15"
          sort-by="plan_id"
          class="elevation-1 mt-5"
        >
          <template v-slot:[`item.state`]="{ item }">
            <v-chip :color="getChipColour(item.state)" label outlined dark>
              {{ getChipText(item.state) }}
            </v-chip>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>All Learner Feedback Questions</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialogRemove" max-width="650px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this feedback
                    question?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteQuestionConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              dark
              color="red"
              small
              v-if="item.state === 1"
              @click="deletePlan(item)"
            >
              <v-icon left small> mdi-trash-can-outline </v-icon>Remove</v-btn
            >
            <v-btn light color="red" small v-else disabled> No Actions</v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize"> Reset </v-btn>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-row justify="center" class="mt-5">
          <v-col md="12">
            <v-toolbar-title>Add Learner Feedback Questions</v-toolbar-title>
          </v-col>
          <v-col md="6">
            <!-- <v-header name="Holds"> &nbsp;</v-header> -->
            <v-select
              v-model="form.questionGroup"
              :items="questionGroups"
              item-text="reporting_text"
              item-value="group_no"
              label="Question Group"
              required
              :error-messages="questionGroupErrors"
              @input="$v.form.questionGroup.$touch()"
              @blur="$v.form.questionGroup.$touch()"
            >
            </v-select>

            <v-text-field
              v-model="form.question"
              :counter="150"
              label="Feedback Question"
              required
              :error-messages="questionErrors"
              @input="$v.form.question.$touch()"
              @blur="$v.form.question.$touch()"
            ></v-text-field>

            <v-btn color="success" class="mt-3" @click="submit"
              >Add Feedback Question
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_ALL_LEARNER_FEEDBACK_QUESTIONS,
  LOAD_ALL_LEARNER_FEEDBACK_QUESTIONS,
  SAVE_LEARNER_FEEDBACK_QUESTION,
  DELETE_LEARNER_FEEDBACK_QUESTION,
  M_OA,
  M_COMMON,
  GET_ALL_LEARNER_FEEDBACK_GROUPS,
} from "@/store/mutation-list";

import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "ManageLearnerFeedbackQuestions",

  data: () => ({
    items: [
      {
        text: "Admin Dashboard",
        disabled: false,
        href: "/dashboard/adminhome",
      },
      {
        text: "View/Manage Learner Feesback Questions",
        disabled: true,
        href: "/dashboard/managelearnerfeedbackquestions",
      },
    ],
    dialogRemove: false,
    dialogDelete: false,
    headers: [
      { text: "Question ID", value: "id" },
      { text: "Question", value: "question" },
      { text: "Question Group", value: "question_report_title" },
      { text: "Added On", value: "created" },
      { text: "Updated On", value: "updated" },
      { text: "Status", value: "state" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    localPlans: [],
    removingQuestionID: 0,
    editedIndex: -1,
    form: {
      questionGroup: "",
      question: "",
    },
  }),
  validations: {
    form: {
      questionGroup: {
        required,
      },
      question: {
        required,
        maxLength: maxLength(150),
      },
    },
  },
  computed: {
    ...mapGetters({
      allQuestions: `${M_OA}${GET_ALL_LEARNER_FEEDBACK_QUESTIONS}`,
      questionGroups: `${M_COMMON}${GET_ALL_LEARNER_FEEDBACK_GROUPS}`,
    }),
    getSelectedGroupName() {
      if (this.form.questionGroup && this.questionGroups) {
        //something is selected
        let itm = this.questionGroups.filter(
          (group) => group.group_no == this.form.questionGroup
        );
        return itm[0].reporting_text;
      }
      return "";
    },
    questionGroupErrors() {
      const errors = [];
      if (!this.$v.form.questionGroup.$dirty) return errors;
      !this.$v.form.questionGroup.required &&
        errors.push("Question Group is required.");
      return errors;
    },
    questionErrors() {
      const errors = [];
      if (!this.$v.form.question.$dirty) return errors;
      !this.$v.form.question.maxLength &&
        errors.push("Feedback question must be at most 150 characters long");
      !this.$v.form.question.required &&
        errors.push("Feedback question is required.");

      return errors;
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      loadFeedbackQuestions: `${M_OA}${LOAD_ALL_LEARNER_FEEDBACK_QUESTIONS}`,
      saveFeedbackQuestion: `${M_OA}${SAVE_LEARNER_FEEDBACK_QUESTION}`,
      removeFeedbackQuestion: `${M_OA}${DELETE_LEARNER_FEEDBACK_QUESTION}`,
    }),
    initialize() {
      // this.localPlans = this.allQuestions;//for some reason there is a array in array
      //lets load all the plans
      this.loadFeedbackQuestions();
    },
    getChipColour(accState) {
      accState = parseInt(accState);
      let retVal = "";
      switch (accState) {
        case 0:
          retVal = "red";
          break;
        case 1:
          retVal = "green";
          break;
        default:
          retVal = "red";
          break;
      }
      return retVal;
    },
    getChipText(accState) {
      accState = parseInt(accState);
      let retVal = "";
      switch (accState) {
        case 0:
          retVal = "Removed";
          break;
        case 1:
          retVal = "Active";
          break;

        default:
          retVal = "Error";
          break;
      }
      return retVal;
    },
    deletePlan(item) {
      //assign removing plan id
      this.removingQuestionID = item.id;
      //show confirm dialog
      this.dialogRemove = true;
    },
    deleteQuestionConfirm() {
      //need to forward this to  store and get a confirmation
      //console.log("removing question ID is:" + this.removingQuestionID);
      this.closeDelete();
      this.removeFeedbackQuestion({
        // the data that need to sent to store/server
        question_id: this.removingQuestionID,
      }).then((res) => {
        //response from store. All complete just need to show to popup message
        //console.log(res);
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Feednack question removed successfully",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
          this.loadFeedbackQuestions();
        } else {
          this.$fire({
            title: "Error",
            text: "Error removing feedback question. Please try again",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
    closeDelete() {
      this.dialogRemove = false;
      /*this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });*/
    },
    submit() {
      this.$v.$touch();
      //console.log(this.form.question);
      //console.log(this.form.questionGroup);
      //console.log(this.getSelectedGroupName);

      if (!this.$v.$invalid) {
        this.saveFeedbackQuestion({
          // the data that need to sent to store/server
          question: this.form.question,
          groupname: this.getSelectedGroupName,
          groupid: this.form.questionGroup,
        }).then((res) => {
          //response from store. All complete just need to show to popup message
          //console.log(res);
          if (res == "SUCCESS") {
            this.$fire({
              title: "Success",
              text: "Feedback question added to system successfully",
              type: "success",
              timer: 3000,
            }).then((r) => {
              console.log(r.value);
              this.initialize();
              this.Clear();
            });
          } else {
            this.$fire({
              title: "Error",
              text: "Error adding feedback question. Please contact administrator.",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
    },
    clear() {
      this.$v.$reset();

      this.form = {
        questionGroup: "",
        question: "",
      };
    },
  },
};
</script>

<style></style>
