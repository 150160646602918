import axios from "axios";
import {
  IS_ADMIN,
  LOGOUT,
  LOGIN,
  TOKEN,
  FULL_RESET,
  M_OA,
  M_OU,
  M_COMMON,
  SET_DIALOG_ACTIVE,
  SET_DIALOG_INACTIVE,
} from "@/store/mutation-list";
import { M_DD } from "../mutation-list";

export const auth = {
  namespaced: true,
  state: {
    user: {
      id: 0,
      accLevel: 0,
      full_name: "", //string
      isAdmin: false,
      created: "", //2021-11-03T12:56:27.468Z
      permissions: [], // user admin user  "admin", "reports"
      token: "",
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.user,
    [IS_ADMIN]: (state) => state.user.isAdmin,
    /*StatePosts: (state) => state.posts,
    StateUser: (state) => state.user,*/
    getPermissions: (state) => state.user.permissions,
    [TOKEN]: (state) => state.user.token,
  },
  actions: {
    async register({ dispatch }, form) {
      await axios.post("register", form);
      let UserForm = new FormData();
      UserForm.append("username", form.usename);
      UserForm.append("password", form.password);
      await dispatch("LogIn", UserForm);
    },
    async [LOGIN]({ commit }, User) {
      //commit("setUser", User);
      //console.log("data sent");
      return await axios.post("loginctr.php", User).then(function (response) {
        let res = response.data.result;
        //console.log(response.data);
        //console.log(JSON.parse(response.data));
        if (res) {
          if (res.code == 1) {
            //success
            commit("setUser", res.data);
          } else {
            console.log(response);
            console.log("Login error");
          }
          return res;
        }
        //console.log(res);
      });
      //await commit("setUser", User.get("username"));
    },
    async CreatePost({ dispatch }, post) {
      await axios.post("post", post);
      await dispatch("GetPosts");
    },
    async GetPosts({ commit }) {
      let response = await axios.get("posts");
      commit("setPosts", response.data);
    },
    async [LOGOUT]({ commit, dispatch, state }) {
      return new Promise((resolve) => {
        dispatch(`${M_COMMON}${SET_DIALOG_ACTIVE}`, null, {
          root: true,
        });
        let user = null;
        //need ask server to log out as well
        let fd = new FormData();
        fd.append("logout", state.user.token);
        axios
          .post("loginctr.php", fd)
          .then(function (response) {
            let rep = response.data;
            //console.log(rep);
            if (rep.result.code == 1) {
              console.log("Logged out from server");
            } else {
              console.log("error server logging out");
            }
          })
          .finally(() => {
            dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
              root: true,
            });
            commit("LOGOUT", user);
            commit(`${M_OA}${FULL_RESET}`, null, { root: true });
            commit(`${M_OU}${FULL_RESET}`, null, { root: true });
            commit(`${M_COMMON}${FULL_RESET}`, null, { root: true });
            commit(`${M_DD}${FULL_RESET}`, null, { root: true });
            /*setTimeout(() => {
          dispatch(`${M_COMMON}${SET_DIALOG_INACTIVE}`, null, {
            root: true,
          });
        }, 1000);*/
            resolve("DONE");
          });
      });
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user.id = payload.id;
      state.user.accLevel = payload.accLevel;
      state.user.full_name = payload.full_name;
      state.user.isAdmin = payload.isAdmin;
      state.user.created = payload.created;
      state.user.permissions = payload.permissions;
      state.user.token = payload.token;

      //state.user = payload;
    },
    setPosts(state, posts) {
      state.posts = posts;
    },
    [LOGOUT](state) {
      state.user.id = "";
      state.user.accLevel = "";
      state.user.full_name = "";
      state.user.isAdmin = false;
      state.user.created = "";
      state.user.permissions = [];
      state.user.token = "";
    },
  },
};
