<template>
  <v-col cols="12">
    <v-divider />
    <div class="mt-5">
      <h3 class="text-center">Summary Chart</h3>
      <GChart
        type="ColumnChart"
        :settings="{ packages: ['corechart'] }"
        :data="getChartData"
        :options="chartOptions"
        :title="chartOptions.title"
      />
    </div>
  </v-col>
</template>
<script>
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "InstructorInfoChart",
  components: { GChart },
  props: ["adiGender", "ageGroup"],
  data() {
    return {
      chartOptions: {
        chart: {
          title: "Instructor Information Report Ratios",
          subtitle: "Instructor Genders and Age Categories",
        },
        legend: { topic: "top", position: "right", maxLines: 3 },
        isStacked: "percent",
        height: 600,
        vAxis: { minValue: 1 },
        is3D: true,
      },
    };
  },
  methods: {
    getTableKeys(arr) {
      let keys = [];
      if (arr) {
        arr.forEach(function (rec) {
          var key = JSON.stringify(rec.key).replaceAll('"', "");
          keys.push(key);
        });
      }
      return keys;
    },
  },
  computed: {
    populateChartTitles() {
      let retArray = [];
      // add age categoirs
      let arrAgeCat = this.getTableKeys(this.ageGroup);

      //add gender
      let arrGender = this.getTableKeys(this.adiGender);

      retArray = [
        //lets add title
        ...arrAgeCat,
        ...arrGender,
      ];
      //console.log(retArray);
      return retArray;
    },
    populateChartData() {
      return [this.getADIAgeColumn, this.getADIGenderColumn];
    },
    getADIAgeColumn() {
      let row = []; // returning row
      let c = 0; // this is the static counter
      //mesure the size of  previus tables
      let previousTitlesSize = 0;
      //mesure the size of current table

      for (let i = 0; i < this.getFullWidth; i++) {
        if (i == 0) {
          //ignote becase we added title
        } else if (i <= previousTitlesSize) {
          // add 0 to previous table with and value to current table width
          row.push(0);
        } else if (
          i > previousTitlesSize &&
          i <= previousTitlesSize + this.ageGroup.length
        ) {
          row.push(this.ageGroup[c].count);
          c++; //increase c for next title
          // the right elemt space
        } else {
          // i>previousTitlesSize + this.duelCtrlRatios.length
          //loop gone pass the title area
          row.push(0);
          //add 0 to rest of the table widths
        }
      }
      row.unshift("Instructor Age Categories");
      return row;
    },
    getADIGenderColumn() {
      let row = []; // returning row
      let c = 0; // this is the static counter
      //mesure the size of  previus tables
      let previousTitlesSize = this.ageGroup.length;
      //mesure the size of current table

      for (let i = 0; i < this.getFullWidth; i++) {
        if (i == 0) {
          //ignote becase we added title
        } else if (i <= previousTitlesSize) {
          // add 0 to previous table with and value to current table width
          row.push(0);
        } else if (
          i > previousTitlesSize &&
          i <= previousTitlesSize + this.adiGender.length
        ) {
          row.push(this.adiGender[c].count);
          c++; //increase c for next title
          // the right elemt space
        } else {
          // i>previousTitlesSize + this.duelCtrlRatios.length
          //loop gone pass the title area
          row.push(0);
          //add 0 to rest of the table widths
        }
      }
      row.unshift("Instructor Genders");
      return row;
    },
    getTableval(arr) {
      let keysAndValues = {};
      if (arr) {
        this.arr.forEach(function (rec) {
          var val = JSON.stringify(rec.count).replaceAll('""', "");
          keysAndValues.push(val);
        });
      }
      return keysAndValues;
    },
    //this will return google charData
    getChartData() {
      let titles = this.populateChartTitles;
      titles.unshift("Ratio");
      let ar = this.populateChartData;
      ar.unshift(titles);
      //console.log(ar);
      return ar;
    },
    //this will return number of titles in the table (expect first one)
    getFullWidth() {
      if (this.populateChartTitles) {
        return this.populateChartTitles.length;
      }
      return 0;
    },
  },
};
</script>
