<template>
  <v-col :md="tblSize">
    <h4 class="mb-5">{{ tableTitle }}</h4>
    <v-card class="mx-auto"
      ><v-simple-table v-if="tableData">
        <template v-slot:default>
          <thead>
            <tr class="text-center">
              <th class="text-center">Outer Postcode</th>
              <th class="text-center">Frequency</th>
              <th class="text-center">Precentage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ratio, i) in tableData" :key="i" class="text-center">
              <td>{{ ratio.outer_pc }}</td>
              <td>{{ ratio.total }}</td>
              <td>{{ ratio.total | precentage(total) }}</td>
            </tr>
            <tr class="tots">
              <th class="text-center">Total</th>
              <th class="text-center">{{ total }}</th>
              <th class="text-center">100%</th>
            </tr>
            <tr v-if="tableData.length == 0">
              <th colspan="2">No records to display</th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p v-else class="warning">No Data Provided</p></v-card
    ></v-col
  >
</template>

<script>
export default {
  name: "ComplaintsGeoAreasTable",
  props: ["tableData", "tots", "tableTitle", "tableType", "tblSize"],
  computed: {
    total() {
      let t = 0;
      if (this.tableData) {
        this.tableData.forEach((rec) => {
          t += rec.total;
        });
        return t;
      }
      return 0;
    },
  },
};
</script>

<style></style>
