<template>
  <div>
    <!-- <v-divider class="my-10" /> -->
    <h2 class="mb-5">
      <span v-html="reportType.text"></span>
      <span
        class="subtitle-1 font-weight-bold"
        v-if="reportType.id == 1 || reportType.id == 5"
      >
        ({{ ratingsData.frq.length }} questions answered)</span
      >
    </h2>
    <v-divider class="my-10" />
    <v-row v-if="reportType.id == 1 || reportType.id == 5">
      <v-col sm="12" v-for="(rec, index) in getResult" :key="index">
        <p>{{ rec.q }} ({{ rec.no }} Rating{{ rec.no | plurelText }})</p>
        <v-progress-linear
          :value="rec.p"
          height="35"
          striped
          :color="getColourByPrecent(rec.p)"
        >
          <strong>{{ rec.p }}%</strong>
        </v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-if="reportType.id == 2">
      <v-card class="mx-auto" v-if="ratingsData.frq">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class="text-center">
                <th>Question</th>
                <th class="text-center">Number of Answers</th>
                <th class="text-center">Answer Precentage</th>
                <th class="text-center">Ratings</th>
                <th class="text-center">Ratings Precentage</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rec, i) in ratingsData.frq" :key="i">
                <td>{{ rec.quest_text }}</td>
                <td class="text-center">{{ rec.no_of_ratings }}</td>
                <td class="text-center">
                  {{ rec.no_of_ratings | precentage(getTotalQuestions) }}
                </td>
                <td class="text-center">{{ rec.ratings_total }}</td>
                <td class="text-center">
                  {{ rec.ratings_total | precentage(getTotalRatings) }}
                </td>
              </tr>
              <tr v-if="ratingsData.frq.length == 0">
                <th class="text-center" colspan="5">No Data to display</th>
              </tr>
            </tbody>
          </template>
        </v-simple-table></v-card
      >
      <p v-else class="warning">No Data Provided</p>
      <FeedbackLineChart
        chartTitle="Learner Feedback Questions"
        chartSubTitle="Total Questions asked"
        :dataTitles="getFeedbackChartTitles"
        :dataVals="getFeedbackChartValues"
      />
    </v-row>
    <v-row v-if="reportType.id == 3">
      <v-card class="mx-auto" v-if="ratingsData.frq">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class="text-center">
                <th>Question</th>
                <th class="text-center">Number of Answers</th>
                <th class="text-center">Answer Precentage</th>
                <th class="text-center">Ratings</th>
                <th class="text-center">Ratings Precentage</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rec, i) in ratingsData.frq" :key="i">
                <td>{{ rec.quest_text }}</td>
                <td class="text-center">{{ rec.no_of_ratings }}</td>
                <td class="text-center">
                  {{ rec.no_of_ratings | precentage(getTotalQuestions) }}
                </td>
                <td class="text-center">{{ rec.ratings_total }}</td>
                <td class="text-center">
                  {{ rec.ratings_total | precentage(getTotalRatings) }}
                </td>
              </tr>
              <tr v-if="ratingsData.frq.length == 0">
                <th class="text-center" colspan="5">No Data to display</th>
              </tr>
            </tbody>
          </template>
        </v-simple-table></v-card
      >
      <p v-else class="warning">No Data Provided</p>
      <FeedbackLineChart
        chartTitle="Learner Feedback Questions"
        chartSubTitle="Total Questions Answered"
        :dataTitles="getFeedbackChartTitles"
        :dataVals="getFeedbackChartValues"
      />
    </v-row>
    <v-row v-if="reportType.id == 4">
      <v-card class="mx-auto" v-if="ratingsData.frq">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class="text-center">
                <th>Stars</th>
                <th class="text-center"></th>
                <th class="text-center">Ratings</th>
                <th class="text-center">Ratings Precentage</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rec, i) in ratingsData.frq" :key="i">
                <td>{{ rec.rating }}</td>
                <td>
                  <v-rating
                    dense
                    background-color="grey lighten-2"
                    color="warning"
                    half-increments
                    length="5"
                    readonly
                    size="35"
                    :value="rec.rating"
                  ></v-rating>
                </td>

                <td class="text-center">
                  {{ rec.amount }}
                </td>
                <td class="text-center">
                  {{ rec.amount | precentage(getTotalStarRatings) }}
                </td>
              </tr>
              <tr v-if="ratingsData.frq.length == 0">
                <th class="text-center" colspan="5">No Data to display</th>
              </tr>
            </tbody>
          </template>
        </v-simple-table></v-card
      >
      <p v-else class="warning">No Data Provided</p>
      <FeedbackLineChart
        chartTitle="Learner Feedback Ratings"
        chartSubTitle="Total Feedback Ratings given"
        :dataTitles="[
          'Time',
          '1 ★Star',
          '2 ★Stars',
          '3 ★Stars',
          '4 ★Stars',
          '5 ★Stars',
        ]"
        :dataVals="getFeedbackStarsChartValues"
      />
    </v-row>
    <v-row v-if="reportType.id == 6">
      <v-card class="mx-auto" v-if="ratingsData.frq">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class="text-center">
                <th>Question</th>
                <th class="text-center">Number of Answers</th>
                <th class="text-center">Answer Precentage</th>
                <th class="text-center">Ratings</th>
                <th class="text-center">Ratings Precentage</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rec, i) in ratingsData.frq" :key="i">
                <td>{{ rec.quest_text }}</td>
                <td class="text-center">{{ rec.no_of_ratings }}</td>
                <td class="text-center">
                  {{ rec.no_of_ratings | precentage(getTotalQuestions) }}
                </td>
                <td class="text-center">{{ rec.ratings_total }}</td>
                <td class="text-center">
                  {{ rec.ratings_total | precentage(getTotalRatings) }}
                </td>
              </tr>
              <tr v-if="ratingsData.frq.length == 0">
                <th class="text-center" colspan="5">No Data to display</th>
              </tr>
            </tbody>
          </template>
        </v-simple-table></v-card
      >
      <p v-else class="warning">No Data Provided</p>
      <FeedbackLineChart
        chartTitle="Instructor Feedback Questions"
        chartSubTitle="Total Questions asked"
        :dataTitles="getFeedbackChartTitles"
        :dataVals="getFeedbackChartValues"
    /></v-row>
    <v-row v-if="reportType.id == 7">
      <v-card class="mx-auto" v-if="ratingsData.frq">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class="text-center">
                <th>Question</th>
                <th class="text-center">Number of Answers</th>
                <th class="text-center">Answer Precentage</th>
                <th class="text-center">Ratings</th>
                <th class="text-center">Ratings Precentage</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rec, i) in ratingsData.frq" :key="i">
                <td>{{ rec.quest_text }}</td>
                <td class="text-center">{{ rec.no_of_ratings }}</td>
                <td class="text-center">
                  {{ rec.no_of_ratings | precentage(getTotalQuestions) }}
                </td>
                <td class="text-center">{{ rec.ratings_total }}</td>
                <td class="text-center">
                  {{ rec.ratings_total | precentage(getTotalRatings) }}
                </td>
              </tr>
              <tr v-if="ratingsData.frq.length == 0">
                <th class="text-center" colspan="5">No Data to display</th>
              </tr>
            </tbody>
          </template>
        </v-simple-table></v-card
      >
      <p v-else class="warning">No Data Provided</p>
      <FeedbackLineChart
        chartTitle="Instructor Feedback Questions"
        chartSubTitle="Total Ratings"
        :dataTitles="['Time', 'Number of Ratings']"
        :dataVals="getFeedbackChartValues"
    /></v-row>
    <v-row v-if="reportType.id == 8">
      <v-card class="mx-auto" v-if="ratingsData.frq">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class="text-center">
                <th>Stars</th>
                <th class="text-center"></th>
                <th class="text-center">Ratings</th>
                <th class="text-center">Ratings Precentage</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rec, i) in ratingsData.frq" :key="i">
                <td>{{ rec.rating }}</td>
                <td>
                  <v-rating
                    dense
                    background-color="grey lighten-2"
                    color="warning"
                    half-increments
                    length="5"
                    readonly
                    size="35"
                    :value="rec.rating"
                  ></v-rating>
                </td>

                <td class="text-center">
                  {{ rec.amount }}
                </td>
                <td class="text-center">
                  {{ rec.amount | precentage(getTotalStarRatings) }}
                </td>
              </tr>
              <tr v-if="ratingsData.frq.length == 0">
                <th class="text-center" colspan="5">No Data to display</th>
              </tr>
            </tbody>
          </template>
        </v-simple-table></v-card
      >
      <p v-else class="warning">No Data Provided</p>
      <FeedbackLineChart
        chartTitle="Learner Feedback Ratings"
        chartSubTitle="Total Feedback Ratings given"
        :dataTitles="[
          'Time',
          '1 ★Star',
          '2 ★Stars',
          '3 ★Stars',
          '4 ★Stars',
          '5 ★Stars',
        ]"
        :dataVals="getFeedbackStarsChartValues"
    /></v-row>
  </div>
</template>

<script>
import FeedbackLineChart from "@/components/Reports/Charts/FeedbackLineChart";
export default {
  name: "ProgressBars",
  props: ["ratingsData", "reportType"],
  data() {
    return {
      knowledge: 50,
    };
  },
  components: { FeedbackLineChart },
  methods: {
    getColourByPrecent(p) {
      if (p) {
        let percent = parseInt(p);
        if (percent == 100) {
          return "green accent-3";
        } else if (percent < 100 && percent >= 80) {
          return "lime accent-3";
        } else if (percent < 80 && percent >= 60) {
          return "yellow accent-3";
        } else if (percent < 60 && percent >= 40) {
          return "orange accent-4";
        } else if (percent < 40) {
          return "red darken-3";
        } else {
          return "red darken-3";
        }
      } else {
        return "red darken-3";
      }
    },
  },
  computed: {
    getResult() {
      if (this.ratingsData.frq) {
        let retArr = [];
        this.ratingsData.frq.forEach((rec) => {
          let percenttage = Math.ceil(
            (parseInt(rec.ratings_total) * 20) / parseInt(rec.no_of_ratings)
          );
          retArr.push({
            q: rec.quest_text,
            no: rec.no_of_ratings,
            p: percenttage,
          });
        });
        return retArr;
      }
      return [];
    },
    getTotalQuestions() {
      if (
        this.ratingsData.frq.length > 0 &&
        Object.hasOwn(this.ratingsData.frq[0], "no_of_ratings")
      ) {
        let t = 0;
        this.ratingsData.frq.forEach((rec) => {
          t += parseInt(rec.no_of_ratings);
        });
        return t;
      }
      return 0;
    },
    getTotalRatings() {
      if (
        this.ratingsData.frq.length > 0 &&
        Object.hasOwn(this.ratingsData.frq[0], "ratings_total")
      ) {
        let t = 0;
        this.ratingsData.frq.forEach((rec) => {
          t += parseInt(rec.ratings_total);
        });
        return t;
      }
      return 0;
    },
    getTotalStarRatings() {
      if (
        this.ratingsData.frq.length > 0 &&
        Object.hasOwn(this.ratingsData.frq[0], "amount")
      ) {
        let t = 0;
        this.ratingsData.frq.forEach((rec) => {
          t += parseInt(rec.amount);
        });
        return t;
      }
      return 0;
    },
    getFeedbackChartTitles() {
      if (this.ratingsData.graph) {
        return ["Time", "Number of Questions"];
      }
      return [];
    },
    getFeedbackChartValues() {
      if (this.ratingsData.graph) {
        let ret = [];
        this.ratingsData.graph.forEach((rec) => {
          ret.push([rec.asked_on, rec.no_of_questions]);
        });
        return ret;
      } else {
        return [];
      }
    },
    getFeedbackStarsChartTitles() {
      if (this.ratingsData.graph && this.ratingsData.frq) {
        let ar = [];
        ar.push("Time"); // time is the first column and it is default
        this.ratingsData.frq.forEach((rec) => {
          ar.push(
            this.$options.filters.pluralisation(
              rec.rating,
              `${rec.rating} Star`
            )
          );
        });
        return ar;
      }
      return [];
    },
    getFeedbackStarsChartValues() {
      if (this.ratingsData.graph) {
        let ret = []; //[time,1s,2s,3s,4s,5s]
        this.ratingsData.graph.forEach((rec) => {
          console.log(rec);

          let r = rec.rating;
          switch (r) {
            case 1:
              ret.push([rec.rated_on, rec.amount, 0, 0, 0, 0]);
              break;
            case 2:
              ret.push([rec.rated_on, 0, rec.amount, 0, 0, 0]);
              break;
            case 3:
              ret.push([rec.rated_on, 0, 0, rec.amount, 0, 0]);
              break;
            case 4:
              ret.push([rec.rated_on, 0, 0, 0, rec.amount, 0]);
              break;
            case 5:
              ret.push([rec.rated_on, 0, 0, 0, 0, rec.amount]);
              break;
            default:
              break;
          }
        });

        return ret;
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped>
p {
  font-weight: 700;
  font-size: 1.25em;
}
</style>
