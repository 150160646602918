<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5">
        <h2>Current Instructor Question Groups</h2>
        <v-divider></v-divider>
        <v-row justify="center">
          <v-col md="6">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Group Number</th>
                    <th class="text-left">Group Name</th>
                    <th class="text-left">Added on</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in currentFeedbackGroups"
                    :key="item.group_no"
                  >
                    <td>{{ item.group_no }}</td>
                    <td style="text-transform: capitalize">
                      {{ item.reporting_text }}
                    </td>
                    <td>{{ item.created_on | dateReOrderRMtime }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet color="white" elevation="1" class="pa-5">
        <h2>Create New Feedback Questions Group</h2>
        <v-divider></v-divider>
        <form>
          <v-row justify="center pa-5">
            <v-col md="4">
              <!-- <v-header name="Holds"> &nbsp;</v-header> -->
              <p>
                Question Groups can be usefull for reporting by grouping
                questions.
              </p>
              <v-text-field
                v-model="form.desc"
                label="Goup Name"
                counter="150"
                required
                placeholder="eg:- Manual 1 Hours Lesson - £25"
                prepend-inner-icon="mdi-form-textbox"
                :error-messages="descErrors"
                @input="$v.form.desc.$touch()"
                @blur="$v.form.desc.$touch()"
              ></v-text-field>

              <v-btn color="success" class="mt-3" @click="submit"
                >Add Group
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import {
  SAVE_INSTRUCTOR_FEEDBACK_GROUP,
  GET_ALL_INSTRUCTOR_FEEDBACK_GROUPS,
  LOAD_ALL_INSTRUCTOR_FEEDBACK_GROUPS,
  M_COMMON,
  M_OA,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";

import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "addinstructorquestiongroup",

  data: () => ({
    items: [
      {
        text: "Admin Dashboard",
        disabled: false,
        href: "/dashboard/adminhome",
      },
      {
        text: "Add Instructor Question Group",
        disabled: true,
        href: "/dashboard/addinstructorquestiongroup",
      },
    ],

    form: {
      desc: "",
    },
  }),
  validations: {
    form: {
      desc: { required, maxLength: maxLength(150) },
    },
  },
  computed: {
    ...mapGetters({
      currentFeedbackGroups: `${M_COMMON}${GET_ALL_INSTRUCTOR_FEEDBACK_GROUPS}`,
    }),

    descErrors() {
      const errors = [];
      if (!this.$v.form.desc.$dirty) return errors;
      !this.$v.form.desc.maxLength && errors.push("Group name is too long");
      !this.$v.form.desc.required && errors.push("Group name is required.");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      saveNewGroup: `${M_OA}${SAVE_INSTRUCTOR_FEEDBACK_GROUP}`,
      loadFeedbackGroups: `${M_COMMON}${LOAD_ALL_INSTRUCTOR_FEEDBACK_GROUPS}`,
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveNewGroup({
          // the data that need to sent to store/server
          groupname: this.form.desc,
        }).then((res) => {
          //response from store. All complete just need to show to popup message
          //console.log(res);
          if (res == "SUCCESS") {
            this.$fire({
              title: "Success",
              text: "Instructor Feedback Group added successfully",
              type: "success",
              timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
            this.clear();
            this.loadFeedbackGroups();
          } else {
            this.$fire({
              title: "Error",
              text: "Error adding instructor feedback group. Please try again",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
    },
    clear() {
      this.$v.$reset();

      this.form = {
        desc: "",
      };
    },
  },
};
</script>

<style></style>
