<template>
  <v-col cols="12">
    <v-divider />
    <div class="mt-5">
      <h3 class="text-center">Summary Chart</h3>
      <GChart
        type="ColumnChart"
        :settings="{ packages: ['corechart'] }"
        :data="getChartData"
        :options="chartOptions"
        :title="chartOptions.title"
      />
    </div>
  </v-col>
</template>
<script>
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "FinacialIncomeGeneratedChart",
  components: { GChart },
  props: ["tableData"],
  data() {
    return {
      /*chartData: [
              [
                "Ratio",
                "Male",
                "Female",                
                "E8",
                "E7",
                "E7-Female",
                "E7-Male",
                "E8-Male"
              ],
              ["Gender Ratio", 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              ["Geographical Areas", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1],
              ["Geographical Areas and gender", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1],
            ],*/
      chartOptions: {
        chart: {
          title: "Breakdown Of Income Generated By Number Of Bookings",
          //subtitle: "Teaching Secondary Languages, Learners Per Secondary Language and Teaching Genders",
        },
        legend: { topic: "top", position: "right", maxLines: 3 },
        isStacked: "true", //"percent",
        height: 600,
        vAxis: { minValue: 1 },
        is3D: true,
      },
    };
  },
  methods: {
    getTableKeys(arr, keyName) {
      let keys = [];
      if (arr) {
        arr.forEach(function (rec) {
          var key = JSON.stringify(rec[keyName]).replaceAll('"', "");
          keys.push(key);
        });
      }
      return keys;
    },
    getTableKeysMulti(arr, keyName1, keyName2) {
      let keys = [];
      if (arr) {
        arr.forEach(function (rec) {
          var key =
            JSON.stringify(rec[keyName1]).replaceAll('"', "") +
            "-" +
            JSON.stringify(rec[keyName2]).replaceAll('"', "");
          keys.push(key);
        });
      }
      return keys;
    },
  },
  computed: {
    populateChartTitles() {
      let retArray = [];
      let arrGenRatio = [];
      if (this.tableData.gender_totals) {
        //add gender Reatios
        arrGenRatio = this.getTableKeys(this.tableData.gender_totals, "gender");
      }
      console.log(arrGenRatio);
      let arrPCRatio = [];
      if (this.tableData.postcode_totals) {
        //add postcode ratios
        arrPCRatio = this.getTableKeys(
          this.tableData.postcode_totals,
          "postcode"
        );
      }
      console.log(arrPCRatio);

      let arrPCGenRatio = [];
      if (this.tableData.postcode_gender_totals) {
        //add postcode and gender ratios
        arrPCGenRatio = this.getTableKeysMulti(
          this.tableData.postcode_gender_totals,
          "postcode",
          "gender"
        );
      }
      console.log(arrPCGenRatio);

      retArray = [
        //lets add all  togeather
        ...arrGenRatio,
        ...arrPCRatio,
        ...arrPCGenRatio,
      ];

      console.log(retArray);
      return retArray;
    },
    populateChartData() {
      //step 1 : need to know full with of tabel i.e No of postcodes at the end of the table. This section fils requred section rest will be zerro

      //size/ with of data tabel

      return [
        this.getMoneyGenderCol,
        this.getMoneyPostcodeCol,
        this.getMoneyPostcodeGenderCol,
      ];
    },
    getMoneyGenderCol() {
      let row = []; // returning row
      let counter = 0; // static counter to cont palce of record

      let previousTitlesSize = 0; //this is the size of current table

      for (let i = 0; i < this.getFullWidth; i++) {
        if (i == 0) {
          //ignore this to add title
        } else if (i <= previousTitlesSize) {
          //add 0 for old titles to fill emptydata
          row.push(0);
        } else if (
          i > previousTitlesSize &&
          i <= previousTitlesSize + this.tableData.gender_totals.length
        ) {
          row.push(parseFloat(this.tableData.gender_totals[counter].money));
          counter++;
          //increase the counter for next title
        } else {
          //loop is gone pass the title area
          row.push(0);
        }
      }
      row.unshift("Money Generated By Each Gender");

      return row;
    },
    getMoneyPostcodeCol() {
      let row = []; // returning row
      let counter = 0; // static counter to cont palce of record

      let previousTitlesSize = this.tableData.gender_totals.length; //this is the size of current table

      for (let i = 0; i < this.getFullWidth; i++) {
        if (i == 0) {
          //ignore this to add title
        } else if (i <= previousTitlesSize) {
          //add 0 for old titles to fill emptydata
          row.push(0);
        } else if (
          i > previousTitlesSize &&
          i <= previousTitlesSize + this.tableData.postcode_totals.length
        ) {
          row.push(parseFloat(this.tableData.postcode_totals[counter].money));
          counter++;
          //increase the counter for next title
        } else {
          //loop is gone pass the title area
          row.push(0);
        }
      }
      row.unshift("Money Generated From Each Postcode");

      return row;
    },
    getMoneyPostcodeGenderCol() {
      let row = []; // returning row
      let counter = 0; // static counter to cont palce of record

      let previousTitlesSize =
        this.tableData.gender_totals.length +
        this.tableData.postcode_totals.length; //this is the size of current table

      for (let i = 0; i < this.getFullWidth; i++) {
        if (i == 0) {
          //ignore this to add title
        } else if (i <= previousTitlesSize) {
          //add 0 for old titles to fill emptydata
          row.push(0);
        } else if (
          i > previousTitlesSize &&
          i <= previousTitlesSize + this.tableData.postcode_gender_totals.length
        ) {
          row.push(
            parseFloat(this.tableData.postcode_gender_totals[counter].money)
          );
          counter++;
          //increase the counter for next title
        } else {
          //loop is gone pass the title area
          row.push(0);
        }
      }
      row.unshift("Money Generated From Each Postcode And Gender");

      return row;
    },

    //this will return google charData
    getChartData() {
      let titles = this.populateChartTitles;
      titles.unshift("Ratio");
      let ar = this.populateChartData;
      ar.unshift(titles);
      //console.log(ar);
      return ar;
    },
    //this will return number of titles in the table (expect first one)
    getFullWidth() {
      if (this.populateChartTitles) {
        return this.populateChartTitles.length;
      }
      return 0;
    },
  },
};
</script>
