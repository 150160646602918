var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-forward")])]},proxy:true}])})],1),_c('v-col',[_c('v-sheet',{staticClass:"pa-5",attrs:{"color":"white","elevation":"1"}},[_c('h2',[_vm._v("Manage Lesson Plans")]),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.allPlans,"sort-by":"plan_id"},scopedSlots:_vm._u([{key:"item.plan_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("£" + item.plan_price)+" ")]}},{key:"item.gear_type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(item.gear_type))])]}},{key:"item.created_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateReOrderRMtime")(item.created_on))+" ")]}},{key:"item.updated_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateReOrderRMtime")(item.updated_on))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getChipColour(item.status),"label":"","outlined":"","dark":""}},[_vm._v(" "+_vm._s(_vm.getChipText(item.status))+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("All Lesson Packages")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"650px"},model:{value:(_vm.dialogRemove),callback:function ($$v) {_vm.dialogRemove=$$v},expression:"dialogRemove"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this lesson plan?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deletePlanConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status === 1)?_c('v-btn',{attrs:{"dark":"","color":"red","small":""},on:{"click":function($event){return _vm.deletePlan(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-trash-can-outline ")]),_vm._v("Remove")],1):_c('v-btn',{attrs:{"light":"","color":"red","small":"","disabled":""}},[_vm._v(" No Actions")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }