/* eslint-disable vue/valid-v-slot */

<template>
  <v-sheet color="white" elevation="1" class="pa-5"
    ><h2>Pending Feedbacks for All Completed Lessons</h2>
    <v-data-table
      :headers="getHeaders"
      :items="PendingList"
      :search="keyword"
      :loading="IsLoading"
      class="elevation-1"
      @click:row="openDialog"
    >
      <template v-slot:top>
        <v-text-field
          v-model="keyword"
          label="Search"
          placeholder="Enter keyword to search"
          class="ma-4"
          clearable
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        <QuestionsDialog
          :title="getDialogTitle"
          :dialog="dialog"
          :summary="getSummaryObj"
          :FeedbackQuestions="questions"
          :bid="bid"
          :type="type"
          @close="dialog = false"
          @success="feedbackDone()"
        />
      </template>

      <template v-slot:[`item.booking_id`]="{ item }">
        <strong>{{ "B" + item.booking_id }}</strong>
      </template>
      <template v-slot:[`item.s_id`]="{ item }">
        {{ "L" + item.s_id }}
      </template>
      <template v-slot:[`item.learner_name`]="{ item }">
        {{ item.s_title + " " + item.s_fname + " " + item.s_lname }}
      </template>

      <template v-slot:[`item.adi_id`]="{ item }">
        {{ "A" + item.adi_id }}
      </template>
      <template v-slot:[`item.adi_name`]="{ item }">
        {{ item.adi_title + " " + item.adi_fname + " " + item.adi_lname }}
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import { mapActions } from "vuex";
import QuestionsDialog from "@/components/Feedback/QuestionsDialog.vue";
import {
  M_OU,
  GET_FEEDBACK_LEARNER_QUESTIONS,
  GET_FEEDBACK_INSTRUCTOR_QUESTIONS,
} from "@/store/mutation-list";
export default {
  name: "ListTable",
  components: { QuestionsDialog },
  props: ["PendingList", "type", "IsLoading"],
  data: () => {
    return {
      dialog: false,
      selectedBooking: null,
      //passingID: null,
      //data table realated data
      keyword: "",
      questions: [{ id: null, question: null }],
      bid: 0,
    };
  },
  computed: {
    //this function method will return headders based on type of table
    getHeaders() {
      if (this.type == "learner") {
        return [
          { text: "Booking", value: "booking_id" },
          { text: "Learner ID", value: "s_id" },
          { text: "Learner Name", value: "learner_name" },
          { text: "Instructor ID", value: "adi_id" },
          { text: "Instructor Name", value: "adi_name" },
          { text: "Lesson Date & Time", value: "lesson_time" },
        ];
      } else {
        //for adi
        return [
          { text: "Booking ID", value: "booking_id" },
          { text: "Instructor ID", value: "adi_id" },
          { text: "Instructor Name", value: "adi_name" },
          { text: "Learner ID", value: "s_id" },
          { text: "Learner Name", value: "learner_name" },
          { text: "Lesson Date & Time", value: "lesson_time" },
        ];
      }
    },
    //this funciton will return the tittle of the dialog base on type of table
    getDialogTitle() {
      if (this.type == "learner") {
        return "Learner Feedback";
      } else {
        return "Instructor Feedback";
      }
    },
    getSummaryObj() {
      if (this.type == "learner" && this.selectedBooking) {
        return [
          {
            label: "Learner",
            data:
              "L" +
              this.selectedBooking.s_id +
              " - " +
              this.selectedBooking.s_title +
              " " +
              this.selectedBooking.s_fname +
              " " +
              this.selectedBooking.s_lname,
          },
          {
            label: "Instructor",
            data:
              "A" +
              this.selectedBooking.adi_id +
              " - " +
              this.selectedBooking.adi_title +
              " " +
              this.selectedBooking.adi_fname +
              " " +
              this.selectedBooking.adi_lname,
          },
          {
            label: "Booking",
            data:
              "B" +
              this.selectedBooking.booking_id +
              " On " +
              this.selectedBooking.lesson_time,
          },
        ];
      } else if (this.type == "adi" && this.selectedBooking) {
        return [
          {
            label: "Instructor",
            data:
              "A" +
              this.selectedBooking.adi_id +
              " - " +
              this.selectedBooking.adi_title +
              " " +
              this.selectedBooking.adi_fname +
              " " +
              this.selectedBooking.adi_lname,
          },
          {
            label: "Learner",
            data:
              "L" +
              this.selectedBooking.s_id +
              " - " +
              this.selectedBooking.s_title +
              " " +
              this.selectedBooking.s_fname +
              " " +
              this.selectedBooking.s_lname,
          },

          {
            label: "Booking",
            data:
              "B" +
              this.selectedBooking.booking_id +
              " On " +
              this.selectedBooking.lesson_time,
          },
        ];
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions({
      getLearnerQuestions: `${M_OU}${GET_FEEDBACK_LEARNER_QUESTIONS}`,
      getInstructorQuestions: `${M_OU}${GET_FEEDBACK_INSTRUCTOR_QUESTIONS}`,
    }),
    openDialog(record) {
      if (this.type == "learner") {
        this.getLearnerQuestions({ bookingID: record.booking_id }).then(
          (res) => {
            if (res) {
              this.bid = record.booking_id;
              this.questions = res;
              this.selectedBooking = record;
              this.dialog = true;
            } else {
              this.questions = [];
            }
          }
        );
      } else if (this.type == "adi") {
        this.getInstructorQuestions({ bookingID: record.booking_id }).then(
          (res) => {
            if (res) {
              this.bid = record.booking_id;
              this.questions = res;
              this.selectedBooking = record;
              this.dialog = true;
            } else {
              this.questions = [];
            }
          }
        );
      }
    },
    feedbackDone() {
      this.dialog = false;
      this.$router.go();
    },
    acctionAssign() {
      //this funciton will asssign ADI
      this.close();
    },

    close() {
      //hide dialog
      this.dialog = false;
      //remove data object
      this.selectedBooking = null;
      this.questions = [{ id: null, question: null }];
    },
  },
};
</script>

<style></style>
