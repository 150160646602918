<template>
  <div>
    <span>
      <v-radio-group
        row
        label="Time:"
        style="font-weight: 700"
        @change="valChanged"
        v-model.number="selectedRange"
      >
        <v-radio label="Week" :value="1"></v-radio>
        <v-radio label="Fortnight" :value="2"></v-radio>
        <v-radio label="Month" :value="3"></v-radio>
        <v-radio label="Quarter" :value="4"></v-radio>
        <v-radio label="6 Months" :value="5"></v-radio>
        <v-radio label="Year" :value="6"></v-radio>
        <v-radio label="Last 2 Years" :value="7"></v-radio>
        <v-radio label="Last 3 Years" :value="8"></v-radio>
        <v-radio label="Last 4 Years" :value="9"></v-radio>
        <v-radio label="Last 5 Years" :value="10"></v-radio>
        <v-radio label="Last 6 Years" :value="11"></v-radio>
        <v-radio label="Last 7 Years" :value="12"></v-radio>
        <v-radio label="Last 8 Years" :value="13"></v-radio>
        <v-radio label="Last 9 Years" :value="14"></v-radio>
        <v-radio label="Last 10 Years" :value="15"></v-radio>
        <v-radio label="Custom Range" :value="16"></v-radio> </v-radio-group
    ></span>
    <v-expand-transition>
      <div v-if="selWeek">
        <!-- Select Week -->
        <v-text-field
          label="Week Starting"
          placeholder="Select Week Starting Date"
          type="date"
          v-model="val"
          @change="setWeekRange()"
          :min="getMinWeek"
          :max="getMaxWeek"
        ></v-text-field>
      </div>
    </v-expand-transition>
    <!-- select fortnight -->
    <v-expand-transition>
      <div v-if="selFortnight">
        <v-text-field
          type="date"
          label="Fortnight"
          placeholder="Select Fortnight Starting Date"
          v-model="val"
          @change="setFortnightRange()"
          :min="getMinDate"
          :max="getMaxDate"
        ></v-text-field>
      </div>
    </v-expand-transition>
    <!-- Select Month -->
    <v-expand-transition>
      <div v-if="selMonth">
        <v-text-field
          type="month"
          label="Month"
          placeholder="Select Month"
          v-model="val"
          @change="setMonthRange()"
          :min="getMinMonth"
          :max="getMaxMonth"
        ></v-text-field>
      </div>
    </v-expand-transition>
    <!-- Select Quater -->
    <v-expand-transition>
      <div v-if="selQuarter">
        <v-select
          label="Reporting Quater"
          placeholder="Select Quater"
          :items="getQuatersList"
          item-text="text"
          v-model="quaterval"
          return-object
        ></v-select>
      </div>
    </v-expand-transition>
    <!-- select 6 months -->
    <v-expand-transition>
      <div v-if="selBianuual">
        <v-select
          label="Reporting 6 Months"
          placeholder="Select 6 Monsths"
          :items="getBiAnnualList"
          item-text="text"
          v-model="quaterval"
          return-object
        ></v-select>
      </div>
    </v-expand-transition>
    <!-- select annual -->
    <v-expand-transition>
      <div v-if="selAnnual">
        <v-select
          label="Reporting Year"
          placeholder="Select Year"
          :items="getAnnualList"
          item-text="text"
          v-model="quaterval"
          return-object
        ></v-select>
      </div>
    </v-expand-transition>
    <!-- select 2 annual -->

    <!-- select 11 annual -->
    <v-expand-transition>
      <div v-if="selAnnual11">
        <v-row>
          <v-col md="6">
            <v-text-field
              type="date"
              :min="getMinDate"
              :max="getYesterday"
              label="Starting Date"
              @input="rangeChanges()"
              v-model="customDateS"
            ></v-text-field>
          </v-col>
          <v-col md="6">
            <v-text-field
              type="date"
              :min="getMinDate"
              :max="getYesterday"
              label="Ending Date"
              @input="rangeChanges()"
              v-model="customDateE"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
    <!--v-expand-transition>
      <div v-if="val && selectedRange >= 2">
        <h4>Range</h4>
        <p>{{ getRangeStart }} - {{ getRangeEnd }}</p>
      </div>
    </v-expand-transition-->
    <!-- This one for quater values -->
    <v-expand-transition>
      <div v-if="quaterval">
        <h4>Range</h4>
        <p>
          {{ getRangeStart | dateReOrderRMtime }} to
          {{ getRangeEnd | dateReOrderRMtime }}
        </p>
      </div>
    </v-expand-transition>
    <v-expand-transition>
      <v-btn
        v-if="val || quaterval"
        color="primary"
        class="ma-2 white--text"
        @click="viewButton"
      >
        <v-icon right dark class="mr-1"> mdi-database-search</v-icon>
        View Report
      </v-btn>
    </v-expand-transition>
  </div>
</template>

<script>
import { AppSetup } from "@/store/sitedata";
export default {
  name: "DateRangePicker",
  data() {
    return {
      selectedRange: null,
      val: null,
      quaterval: null,
      selWeek: false,
      selFortnight: false,
      selMonth: false,
      selQuarter: false,
      selBianuual: false,
      selAnnual: false,
      selAnnual2: false,
      selAnnual3: false,
      selAnnual4: false,
      selAnnual5: false,
      selAnnual6: false,
      selAnnual7: false,
      selAnnual8: false,
      selAnnual9: false,
      selAnnual10: false,
      selAnnual11: false,
      customDateS: "",
      customDateE: "",
      appInfo: AppSetup,
    };
  },
  methods: {
    valChanged() {
      this.val = null;
      this.quaterval = null;
      //this.hideAllSelects();
      switch (this.selectedRange) {
        case 1:
          this.hideAllSelects();
          this.selWeek = true;
          break;
        case 2:
          this.hideAllSelects();
          this.selFortnight = true;
          break;
        case 3:
          this.hideAllSelects();
          this.selMonth = true;
          break;
        case 4:
          this.hideAllSelects();
          this.selQuarter = true;
          break;
        case 5:
          this.hideAllSelects();
          this.selBianuual = true;
          break;
        case 6:
          this.hideAllSelects();
          this.selAnnual = true;
          break;
        case 7:
          this.hideAllSelects();
          this.selAnnual2 = true;
          this.getOtherAnnualLst(2);
          break;
        case 8:
          this.hideAllSelects();
          this.selAnnual3 = true;
          this.getOtherAnnualLst(3);
          break;
        case 9:
          this.hideAllSelects();
          this.selAnnual4 = true;
          this.getOtherAnnualLst(4);
          break;
        case 10:
          this.hideAllSelects();
          this.selAnnual5 = true;
          this.getOtherAnnualLst(5);
          break;
        case 11:
          this.hideAllSelects();
          this.selAnnual6 = true;
          this.getOtherAnnualLst(6);
          break;
        case 12:
          this.hideAllSelects();
          this.selAnnual7 = true;
          this.getOtherAnnualLst(7);
          break;
        case 13:
          this.hideAllSelects();
          this.selAnnual8 = true;
          this.getOtherAnnualLst(8);
          break;
        case 14:
          this.hideAllSelects();
          this.selAnnual9 = true;
          this.getOtherAnnualLst(9);
          break;
        case 15:
          this.hideAllSelects();
          this.selAnnual10 = true;
          this.getOtherAnnualLst(10);
          break;
        case 16:
          this.hideAllSelects();
          this.selAnnual11 = true;
          break;
        default:
          //this.hideAllSelects();
          break;
      }
    },
    hideAllSelects() {
      this.selWeek = false;
      this.selFortnight = false;
      this.selMonth = false;
      this.selQuarter = false;
      this.selBianuual = false;
      this.selAnnual = false;
      this.selAnnual2 = false;
      this.selAnnual3 = false;
      this.selAnnual4 = false;
      this.selAnnual5 = false;
      this.selAnnual6 = false;
      this.selAnnual7 = false;
      this.selAnnual8 = false;
      this.selAnnual9 = false;
      this.selAnnual10 = false;
      this.selAnnual11 = false;
      //lets clear the custom values as well
      this.customDateS = "";
      this.customDateE = "";
    },
    setWeekRange() {
      let selectedD = new Date(this.val);
      let selectedDEnd = new Date(
        selectedD.getFullYear(),
        selectedD.getMonth() + 1,
        selectedD.getDate() + 7
      );
      //this.val = null;
      this.quaterval = {
        e: selectedDEnd.toISOString().slice(0, 10),
        s: selectedD.toISOString().slice(0, 10),
      };
      this.$nextTick();
    },
    setFortnightRange() {
      let selectedD = new Date(this.val);
      let selectedDEnd = new Date(
        selectedD.getFullYear(),
        selectedD.getMonth() + 1,
        selectedD.getDate() + 14
      );
      //this.val = null;
      this.quaterval = {
        e: selectedDEnd.toISOString().slice(0, 10),
        s: selectedD.toISOString().slice(0, 10),
      };
      this.$nextTick();
    },
    setMonthRange() {
      let selectedD = new Date(this.val + "-01");
      console.log(selectedD.toISOString());
      //selectedD.setDate()
      let selectedDEnd = new Date(
        selectedD.getFullYear(),
        selectedD.getMonth() + 1,
        selectedD.getDate()
      );
      //this.val = null;
      this.quaterval = {
        e: selectedDEnd.toISOString().slice(0, 10),
        s: selectedD.toISOString().slice(0, 10),
      };
      this.$nextTick();
    },
    getQStartAndEnd(y, q) {
      if (!q || !y) return [null, null];
      switch (q) {
        case 1:
          return { s: y + "-01-01", e: y + "-03-31", text: "Q1 - " + y };
        case 2:
          return { s: y + "-04-01", e: y + "-06-30", text: "Q2 - " + y };
        case 3:
          return { s: y + "-07-01", e: y + "-09-30", text: "Q3 - " + y };
        case 4:
          return { s: y + "-10-01", e: y + "-12-31", text: "Q4 - " + y };

        default:
          break;
      }
    },
    getBiAnnumStartAndEnd(y, q) {
      if (!q || !y) return [null, null];
      switch (q) {
        case 1:
          return {
            s: y + "-01-01",
            e: y + "-06-31",
            text: y + " - First 6 Months (Jan - Jun)",
          };
        case 2:
          return {
            s: y + "-07-01",
            e: y + "-12-31",
            text: y + " - Last 6 Months (Jul - Dec)",
          };

        default:
          break;
      }
    },
    viewButton() {
      let emitData = null;
      if (this.quaterval) {
        emitData = this.quaterval;
      } else {
        emitData = null;
      }

      if (emitData) {
        this.$emit("ranged", { data: emitData });
      }
    },

    getOtherAnnualList(noOfYears) {
      let appStart = this.appInfo.startDate;
      //let startYear = appStart.slice(0, 4);
      //let startMonth = appStart.slice(5, 7);
      //let startDate = appStart.slice(8, 10);

      //console.log(noOfYears);

      let todate = new Date();
      let StartDT = new Date(appStart);

      //this loop holds the datta
      let retArr = [];
      //two loops will create the list
      //just get the year difference
      //let yearDiff = todate.getFullYear() - StartDT.getFullYear();
      for (
        let i = todate.getFullYear() - 1;
        i > StartDT.getFullYear();
        i -= noOfYears
      ) {
        console.log("Counting down years" + i);
        let startYYYY = i - noOfYears;
        if (
          i == StartDT.getFullYear() &&
          StartDT.getMonth() == 0 &&
          StartDT.getDate() == 1
        ) {
          //do not ignore
          //console.log("Start Year" + i);
          retArr.push({
            s: startYYYY + "-01-01",
            e: i + "-12-31",
            text: `Years ${startYYYY} - ${i}`,
          });
        } else if (
          i == todate.getFullYear() &&
          todate.getMonth() == 11 &&
          todate.getDate() == 31
        ) {
          //do not ignore
          // console.log("End Year" + i);
          retArr.push({
            s: startYYYY + "-01-01",
            e: i + "-12-31",
            text: `Years ${startYYYY} - ${i}`,
          });
        } else if (i != StartDT.getFullYear() && i != todate.getFullYear()) {
          //add
          //console.log("Normal Year" + i);
          retArr.push({
            s: startYYYY + "-01-01",
            e: i + "-12-31",
            text: `Years ${startYYYY} - ${i}`,
          });
        } else {
          //sure to ignote matching years but not at the start on end
        }
      }
      console.log(retArr);

      return retArr;
    },
    getOtherAnnualLst(noOfYears) {
      let todate = new Date();
      todate.setDate(todate.getDate() - 1);

      let d = new Date();
      d = new Date(d.getFullYear() - noOfYears, d.getMonth(), d.getDate());

      this.quaterval = {
        s: d.toISOString().slice(0, 10),
        e: todate.toISOString().slice(0, 10),
        text: `Last ${noOfYears} Years`,
      };
    },
    async rangeChanges() {
      await this.$nextTick();
      console.log("Chaging s and e dates");

      if (this.customDateS && this.customDateE) {
        this.quaterval = {
          s: this.customDateS,
          e: this.customDateE,
          text: "Range Selected",
        };
      }
      console.log(this.quaterval);
    },
  },
  computed: {
    getMinWeek() {
      return this.appInfo.startDate;
    },
    getMaxWeek() {
      /* let d = new Date();
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      // Get first day of year
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      // Calculate full weeks to nearest Thursday
      var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      // Return array of year and week number
      return `${d.getUTCFullYear()}-W${weekNo}`;*/
      let d = new Date();
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate() - 7));
      return d.toISOString().slice(0, 10);
    },
    getMinDate() {
      return this.appInfo.startDate;
    },
    getMaxDate() {
      let d = new Date();
      d = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 14);
      return d.toISOString().slice(0, 10);
    },
    getMinMonth() {
      return this.appInfo.startMonth;
    },
    getMaxMonth() {
      let d = new Date();
      d = new Date(d.getFullYear(), d.getMonth(), 0);
      return d.toISOString().slice(0, 7);
    },
    //this will return yesterdays date
    getYesterday() {
      let d = new Date();
      d = new Date(d.getFullYear(), d.getMonth(), d.getDate());
      return d.toISOString().slice(0, 10);
    },
    // this function will return the end date based on selected range
    getRangeStart() {
      if (!this.selectedRange) return null;
      let retVal = null;
      let d = new Date(this.quaterval.s);

      switch (this.selectedRange) {
        case 1:
          retVal = d.toISOString().slice(0, 10);
          break;
        case 2:
          retVal = d.toISOString().slice(0, 10);
          break;
        case 3:
          d = new Date(d.getFullYear(), d.getMonth(), 1);
          retVal = d.toISOString().slice(0, 10);
          break;
        default:
          retVal = this.quaterval.s;
          break;
      }
      return retVal;
    },
    getRangeEnd() {
      if (!this.selectedRange) return null;
      let retVal = "";
      let d = new Date(this.quaterval.s);

      switch (this.selectedRange) {
        case 1:
          d = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 7);
          retVal = d.toISOString().slice(0, 10);
          break;
        case 2:
          d = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 14);
          retVal = d.toISOString().slice(0, 10);
          break;
        case 3:
          d = new Date(d.getFullYear(), d.getMonth() + 1, 0);
          retVal = d.toISOString().slice(0, 10);
          break;
        default:
          retVal = this.quaterval.e;
          break;
      }
      return retVal;
    },
    // this function will generate Quater numbers
    getQuatersList() {
      let appStart = this.appInfo.startDate;
      //let startYear = appStart.slice(0, 4);
      let startMonth = appStart.slice(5, 7);
      let startDate = appStart.slice(8, 10);

      let todate = new Date();
      let StartDT = new Date(appStart);

      //let use it in INT
      let intM = parseInt(startMonth);
      let intD = parseInt(startDate);

      let StartingQ = 0;

      if (intM > 0 && intM < 4) {
        //started on Q1 but if is not first day on the quater month we have to move to next quater
        if (intM == 1 && intD == 1) {
          //first day of quater and month
          //set this as starting que
          StartingQ = 1;
        } else {
          StartingQ = 2;
        }
      } else if (intM > 3 && intM < 7) {
        //started on Q2 but if is not first day on the quater month we have to move to next quater
        if (intM == 4 && intD == 1) {
          //first day of quater and month
          //set this as starting que
          StartingQ = 2;
        } else {
          StartingQ = 3;
        }
      } else if (intM > 6 && intM < 10) {
        //started on Q3 but if is not first day on the quater month we have to move to next quater
        if (intM == 7 && intD == 1) {
          //first day of quater and month
          //set this as starting que
          StartingQ = 3;
        } else {
          StartingQ = 4;
        }
      } else if (intM > 9 && intM < 13) {
        //started on Q4 but if is not first day on the quater month we have to move to next quater
        if (intM == 10 && intD == 1) {
          //first day of quater and month
          //set this as starting que
          StartingQ = 4;
        } else {
          StartingQ = 1;
        }
      }
      //let use it in INT
      let intEndM = parseInt(todate.getMonth());
      let intEndD = parseInt(todate.getDate());

      let EndingQ = 0;
      if (intEndM > 0 && intEndM < 4) {
        //if not last day of the monththen go back to last quater
        if (intEndM == 3 && intEndD == 31) {
          EndingQ = 1;
        } else {
          EndingQ = 4;
        }
      } else if (intEndM > 3 && intEndM < 7) {
        if (intEndM == 6 && intEndD == 30) {
          EndingQ = 2;
        } else {
          EndingQ = 1;
        }
      } else if (intEndM > 6 && intEndM < 10) {
        if (intEndM == 9 && intEndD == 30) {
          EndingQ = 3;
        } else {
          EndingQ = 2;
        }
      } else if (intEndM > 12 && intEndM < 13) {
        if (intEndM == 10 && intEndD == 31) {
          EndingQ = 4;
        } else {
          EndingQ = 3;
        }
      }
      //this loop holds the datta
      let retArr = [];
      //two loops will create the list
      //just get the year difference
      //let yearDiff = todate.getFullYear() - StartDT.getFullYear();
      for (let i = todate.getFullYear(); i > StartDT.getFullYear() - 1; --i) {
        //console.log("Year:" + i);
        for (let j = 4; j > 0; j--) {
          if (i == todate.getFullYear() && j > EndingQ) {
            //ignore
          } else if (i == StartDT.getFullYear() && j <= StartingQ) {
            //ignore
          } else {
            retArr.push(this.getQStartAndEnd(i, j));
            /* console.log(
              this.getQStartAndEnd(i, j).s +
                " to " +
                this.getQStartAndEnd(i, j).e
            );*/
          }
        }
      }
      //console.log("Starting Q:" + StartingQ);
      //console.log("Ending Q:" + EndingQ);

      return retArr;
    },
    //this function will generate Bi-anual numbers
    getBiAnnualList() {
      let appStart = this.appInfo.startDate;
      //let startYear = appStart.slice(0, 4);
      let startMonth = appStart.slice(5, 7);
      let startDate = appStart.slice(8, 10);

      let todate = new Date();
      let StartDT = new Date(appStart);

      //let use it in INT
      let intM = parseInt(startMonth);
      let intD = parseInt(startDate);

      let StartingH = 0;

      if (intM > 0 && intM < 7) {
        //started on Q1 but if is not first day on the biannum we have to move to next biannum
        if (intM == 1 && intD == 1) {
          //first day of biannum and month
          //set this as starting que
          StartingH = 1;
        } else {
          StartingH = 2;
        }
      } else if (intM > 6 && intM < 13) {
        //started on Q2 but if is not first day on the biannum month we have to move to next biannum
        if (intM == 7 && intD == 1) {
          //first day of biannum and month
          //set this as starting que
          StartingH = 2;
        } else {
          StartingH = 1;
        }
      }

      //let use it in INT
      let intEndM = parseInt(todate.getMonth() + 1);
      let intEndD = parseInt(todate.getDate());

      let EndingH = 0;
      if (intEndM > 0 && intEndM < 7) {
        //if not last day of the monththen go back to last biannum
        if (intEndM == 6 && intEndD == 31) {
          EndingH = 1;
        } else {
          EndingH = 2;
        }
      } else if (intEndM < 13 && intEndM > 7) {
        if (intEndM == 6 && intEndD == 30) {
          EndingH = 2;
        } else {
          EndingH = 1;
        }
      }

      //this loop holds the datta
      let retArr = [];
      //two loops will create the list
      //just get the year difference
      //let yearDiff = todate.getFullYear() - StartDT.getFullYear();
      for (let i = todate.getFullYear(); i > StartDT.getFullYear() - 1; --i) {
        for (let j = 2; j > 0; j--) {
          if (i == todate.getFullYear() && j > EndingH) {
            //ignore
            //console.log("End Ignore:" + i + " and Half:" + j);
          } else if (i == StartDT.getFullYear() && j < StartingH) {
            //ignore
            //console.log("Start Ignore:" + i + " and Half:" + j);
          } else {
            //console.log("year:" + i + " Half:" + j);
            retArr.push(this.getBiAnnumStartAndEnd(i, j));
            /*console.log(
              this.getQStartAndEnd(i, j).s +
                " to " +
                this.getQStartAndEnd(i, j).e
            );*/
          }
        }
      }
      /*console.log("Starting Q:" + StartingH);
      console.log("Ending Q:" + EndingH);*/

      return retArr;
    },
    getAnnualList() {
      let appStart = this.appInfo.startDate;
      //let startYear = appStart.slice(0, 4);
      //let startMonth = appStart.slice(5, 7);
      //let startDate = appStart.slice(8, 10);

      let todate = new Date();
      let StartDT = new Date(appStart);

      //this loop holds the datta
      let retArr = [];
      //two loops will create the list
      //just get the year difference
      //let yearDiff = todate.getFullYear() - StartDT.getFullYear();
      for (let i = todate.getFullYear(); i > StartDT.getFullYear() - 1; --i) {
        if (
          i == StartDT.getFullYear() &&
          StartDT.getMonth() == 0 &&
          StartDT.getDate() == 1
        ) {
          //do not ignore
          //console.log("Start Year" + i);
          retArr.push({ s: i + "-01-01", e: i + "-12-31", text: "Year " + i });
        } else if (
          i == todate.getFullYear() &&
          todate.getMonth() == 11 &&
          todate.getDate() == 31
        ) {
          //do not ignore
          // console.log("End Year" + i);
          retArr.push({ s: i + "-01-01", e: i + "-12-31", text: "Year " + i });
        } else if (i != StartDT.getFullYear() && i != todate.getFullYear()) {
          //add
          //console.log("Normal Year" + i);
          retArr.push({ s: i + "-01-01", e: i + "-12-31", text: "Year " + i });
        } else {
          //sure to ignote matching years but not at the start on end
        }
      }

      return retArr;
    },
  },
};
</script>

<style></style>
