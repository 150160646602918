<template>
  <div>
    <v-form ref="form">
      <v-row>
        <v-col md="12">
          <v-select
            v-model="form.fplan"
            :items="franchiseplans"
            outlined
            label="Franchise Plan"
            style="width: 50%"
            required
            :error-messages="fplanErrors"
            @input="$v.form.fplan.$touch()"
            @blur="$v.form.fplan.$touch()"
            @change="
              currentState();
              updateTable($event);
            "
            ><template v-slot:item="{ item }">
              <span v-html="getPopulatedLines(item)"></span>
            </template>
            <template v-slot:selection="{ item }">
              <span v-html="getPopulatedLines(item)"></span>
            </template>
          </v-select>
          <input type="hidden" v-model="form.francplan" name="fplan" />
          <v-text-field
            v-model="franchiseinfo.joiningfee"
            outlined
            label="Joining Fee"
            style="width: 50%"
            prepend-inner-icon="mdi-currency-gbp"
            readonly
            disabled
          ></v-text-field>
          <v-text-field
            v-model="franchiseinfo.weeklyfee"
            outlined
            label="Weekly Fee"
            style="width: 50%"
            prepend-inner-icon="mdi-currency-gbp"
            readonly
            disabled
          ></v-text-field>
          <v-text-field
            v-model="franchiseinfo.monthlyfee"
            outlined
            label="Monthly Fee"
            style="width: 50%"
            prepend-inner-icon="mdi-currency-gbp"
            readonly
            disabled
          ></v-text-field>
          <v-text-field
            v-model="franchiseinfo.quarterlyfee"
            outlined
            label="Quarterly Fee"
            style="width: 50%"
            prepend-inner-icon="mdi-currency-gbp"
            readonly
            disabled
          ></v-text-field>
          <v-text-field
            v-model="franchiseinfo.biannualfee"
            outlined
            label="Biannual Fee"
            style="width: 50%"
            prepend-inner-icon="mdi-currency-gbp"
            readonly
            disabled
          ></v-text-field>
          <v-text-field
            v-model="franchiseinfo.annualfee"
            outlined
            label="Annual  Fee"
            style="width: 50%"
            prepend-inner-icon="mdi-currency-gbp"
            readonly
            disabled
          ></v-text-field>
          <v-text-field
            v-model="franchiseinfo.workinghours"
            outlined
            label="Working Hours"
            style="width: 50%"
            prepend-inner-icon="mdi-clock-outline"
            readonly
            disabled
          ></v-text-field>
          <v-text-field
            v-model="franchiseinfo.weeklyhours"
            outlined
            label="Average Weekly Hours"
            style="width: 50%"
            prepend-inner-icon="mdi-timetable"
            readonly
            disabled
          ></v-text-field>
          <v-text-field
            v-model="franchiseinfo.promotioncode"
            outlined
            label="Promotion Code"
            style="width: 50%"
            prepend-inner-icon="mdi-sale"
            readonly
            disabled
          ></v-text-field>
          <v-select
            v-model="form.feeregularity"
            name="feeregularity"
            :items="payRegs"
            outlined
            item-text="txt"
            item-value="id"
            label="Payment Regularity"
            style="width: 50%"
            required
            :error-messages="feeregularityErrors"
            @input="$v.form.feeregularity.$touch()"
            @blur="
              $v.form.feeregularity.$touch();
              currentState();
            "
            @change="currentState()"
          >
          </v-select></v-col
      ></v-row>
    </v-form>
  </div>
</template>

<script>
import { GET_FRANCHISE_PLANS_ACTIVE, M_COMMON } from "@/store/mutation-list";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Financials",
  data() {
    return {
      franchiseplans: [],
      franchiseinfo: {
        joiningfee: 0.0,
        weeklyfee: 0.0,
        monthlyfee: 0.0,
        quarterlyfee: 0.0,
        biannualfee: 0.0,
        annualfee: 0.0,
        workinghours: "-",
        weeklyhours: "0 Hour",
        promotioncode: "",
      },
      payRegs: [
        { id: "1", txt: "Weekly" },
        { id: "4.33", txt: "Monthly" },
        { id: "13", txt: "Quarterly" },
        { id: "26", txt: "Biannualy" },
        { id: "52", txt: "Annualy" },
      ],
      form: {
        fplan: "",
        feeregularity: "",
        francplan: "", // selected plan id
      },
    };
  },
  validations: {
    form: {
      fplan: { required },
      feeregularity: { required },
      francplan: { required },
    },
  },
  methods: {
    async currentState() {
      //attach files need to be sent as well
      await this.$nextTick();
      this.$emit("financials-update", {
        state: this.$v.form.$invalid,
        data: this.form,
        fd: new FormData(this.$refs.form.$el),
      });
    },
    //this method will get franchise plans
    getFranchisePlans() {
      this.franchiseplans = this.fPlans;
    },
    getPopulatedLines(item) {
      let temp = `<strong> ${item.plan_id} </strong>- Joining £${item.initial_fee} | ${item.work_type} | ${item.weekly_hours} Hours | £${item.weekly_fee} per week `;
      if (item.promo_code) {
        temp += `| <strong style="color: red">${item.promo_code}</strong>`;
      }
      return temp;
    },
    updateTable(item) {
      let WF = Number(item.weekly_fee);
      this.franchiseinfo = {
        joiningfee: Number(item.initial_fee),
        weeklyfee: WF,
        monthlyfee: Math.round(WF * 4.34 * 100) / 100,
        quarterlyfee: (WF * 13).toLocaleString(),
        biannualfee: (WF * 26).toLocaleString(),
        annualfee: (WF * 52).toLocaleString(),
        workinghours: item.work_type,
        weeklyhours: item.weekly_hours + " Hours",
        promotioncode: item.promo_code,
      };
      this.form.francplan = item.plan_id;
      //console.log(item);
    },
  },
  computed: {
    ...mapGetters({
      fPlans: `${M_COMMON}${GET_FRANCHISE_PLANS_ACTIVE}`,
    }),
    fplanErrors() {
      const errors = [];
      if (!this.$v.form.fplan.$dirty) return errors;
      !this.$v.form.fplan.required &&
        errors.push("Franchise Plan is required.");
      return errors;
    },
    feeregularityErrors() {
      const errors = [];
      if (!this.$v.form.feeregularity.$dirty) return errors;
      !this.$v.form.feeregularity.required &&
        errors.push("Payment Regularity is required.");
      return errors;
    },
  },
  created() {
    this.getFranchisePlans();
  },
};
</script>

<style></style>
