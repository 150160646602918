<template>
  <div>
    <!-- <v-row> -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          @click="actDialog()"
          color="blue darken-4"
        >
          <v-icon>mdi-wallet-giftcard</v-icon></v-btn
        >
      </template>
      <span>Package Prices</span>
    </v-tooltip>
    <v-dialog v-model="dialog" width="700px" scrollable>
      <v-card>
        <v-toolbar color="primary" dark>Packages</v-toolbar>
        <v-card-title>
          <span class="text-h5">Lesson Packages</span>
        </v-card-title>
        <v-card-text style="height: 70vh">
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Package</th>
                    <th class="text-left">Gear Type</th>
                    <th class="text-center">Credits</th>
                    <th class="text-right">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in lessonPacakges" :key="item.plan_id">
                    <td>{{ item.text_description }}</td>
                    <td style="text-transform: capitalize">
                      {{ item.gear_type }}
                    </td>
                    <td class="text-center">{{ Math.trunc(item.hours) }}</td>
                    <td class="text-right">£{{ item.plan_price }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-title>
            <span class="text-h5">Franchise Packages </span>
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">ID</th>
                    <th class="text-right">Initial Fee</th>
                    <th class="text-right">Weekly Fee</th>
                    <th class="text-right">Weekly Hours (Avg.)</th>
                    <th class="text-left">Work Type</th>
                    <th class="text-left">Promo Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in franchisePacakges" :key="item.plan_id">
                    <td class="text-right">F{{ item.plan_id }}</td>
                    <td class="text-right">£{{ item.initial_fee }}</td>
                    <td class="text-right">£{{ item.weekly_fee }}</td>
                    <td class="text-right">{{ item.weekly_hours }}</td>
                    <td>{{ item.work_type }}</td>
                    <td style="color: red; font-weight: 500">
                      {{ item.promo_code }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- </v-row> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  M_COMMON,
  GET_LESSON_PLANS_ACTIVE,
  GET_FRANCHISE_PLANS_ACTIVE,
} from "@/store/mutation-list.js";
export default {
  name: "Packages",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    actDialog() {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    ...mapGetters({
      lessonPacakges: `${M_COMMON}${GET_LESSON_PLANS_ACTIVE}`,
      franchisePacakges: `${M_COMMON}${GET_FRANCHISE_PLANS_ACTIVE}`,
    }),
  },
};
</script>

<style></style>
