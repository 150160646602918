<template>
  <v-col :md="tblSize">
    <h4 class="mb-5">{{ tableTitle }}</h4>
    <v-card class="mx-auto"
      ><v-simple-table v-if="tableData">
        <template v-slot:default>
          <thead>
            <tr class="text-center">
              <th class="text-center">{{ tableType }} Status Type</th>
              <th class="text-center">Number of Complaints</th>
              <th class="text-center">Complaints Precentage</th>
              <th class="text-center">Self-resolve Attempts</th>
              <th class="text-center">Self-resolve Attempts Precentage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rec, i) in tableData" :key="i" class="text-center">
              <td>{{ rec.complaint_status_name }}</td>
              <td>{{ rec.complaints_count }}</td>
              <td>{{ rec.complaints_count | precentage(tots.comp) }}</td>
              <td>{{ rec.self_resolve_count }}</td>
              <td>{{ rec.self_resolve_count | precentage(tots.selfres) }}</td>
            </tr>
            <tr class="tots">
              <th class="text-center">Total</th>
              <th class="text-center">{{ tots.comp }}</th>
              <th class="text-center"></th>
              <th class="text-center">{{ tots.selfres }}</th>
              <th class="text-center"></th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p v-else class="warning">No Data Provided</p></v-card
    ></v-col
  >
</template>
<script>
export default {
  name: "ComplantStatusTable",
  props: ["tableData", "tots", "tableTitle", "tableType", "tblSize"],
};
</script>
