<template>
  <div class="row">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5">
        <h2>Record Test Outcome</h2>
        <div class="subtitle-1">Please complete the form</div>

        <v-form class="mt-5">
          <v-select
            v-model="selected.adi"
            label="Driving Instructor"
            :items="adiList"
            item-value="adi_id"
            outlined
            :loading="!isADIsLoading"
            :disabled="!isADIsLoading"
            @change="loadLearners"
          >
            <template v-slot:item="{ item }">
              A{{ item.adi_id }} - {{ item.title }} {{ item.fname }}
              {{ item.lname }}
            </template>
            <template v-slot:selection="{ item }">
              A{{ item.adi_id }} - {{ item.title }} {{ item.fname }}
              {{ item.lname }}
            </template>
          </v-select>
          <v-select
            v-model="selected.learner"
            label="Learner"
            :items="learnerList"
            item-value="stu_id"
            outlined
            :loading="isLearnersLoading"
            :disabled="disableLearner"
          >
            <template v-slot:item="{ item }">
              L{{ item.stu_id }} - {{ item.title }} {{ item.fname }}
              {{ item.lname }}
            </template>
            <template v-slot:selection="{ item }">
              L{{ item.stu_id }} - {{ item.title }} {{ item.fname }}
              {{ item.lname }}
            </template>
          </v-select>
          <v-text-field
            v-model="selected.testDate"
            label="Test Date"
            outlined
            type="date"
            :disabled="disableDate"
          ></v-text-field>
          <v-text-field
            v-model="selected.testTime"
            label="Test Time"
            outlined
            type="time"
            :disabled="disableTime"
          ></v-text-field>
          <v-select
            v-model="selected.testOutcome"
            label="Test Outcome"
            :items="outcomes"
            item-text="txt"
            item-value="val"
            outlined
            :disabled="disableOutcome"
          ></v-select>
          <v-btn class="success" @click="saveOutcome" :disabled="disableSave">
            Save Outcome</v-btn
          >
        </v-form>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import {
  M_OU,
  //GET_ACTIVE_INSTRUCTORS,
  GET_ADI_SUMMARY,
  IS_ADI_SUMMARY_LOADED,
  GET_ADI_LEARNERS,
  SET_TEST_OUTCOME,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "RecordTestOutcome",
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Record Test Outcome",
          disabled: true,
          href: "/dashboard/recordtestoutcome",
        },
      ],

      learnerList: [],
      isLearnersLoading: false,

      outcomes: [
        { val: 0, txt: "Failed" },
        { val: 1, txt: "Passed" },
      ],

      selected: {
        adi: null,
        learner: null,
        testDate: null,
        testTime: null,
        testOutcome: null,
      },
    };
  },

  methods: {
    ...mapActions({
      getLearners: `${M_OU}${GET_ADI_LEARNERS}`,
      saveForm: `${M_OU}${SET_TEST_OUTCOME}`,
    }),
    loadLearners(adiID) {
      this.isLearnersLoading = true;
      //this method will load learners
      //console.log(adiID);

      this.learnerList = [];
      this.getLearners({ id: adiID })
        .then((rep) => {
          if (rep) {
            this.learnerList = rep;
          } else {
            this.learnerList = [];
          }
        })
        .finally(() => {
          this.isLearnersLoading = false;
        });
    },
    saveOutcome() {
      this.saveForm({
        learner: this.selected.learner,
        testdate: this.selected.testDate + " " + this.selected.testTime,
        result: this.selected.testOutcome,
        adi: this.selected.adi,
      }).then((res) => {
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Test outcome Recorded.",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
            //clear all and reloadclear()
            this.resetForm();
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error recording Test outcome. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
    resetForm() {
      //this will reset everything

      //this.isADIsLoading = false;
      this.learnerList = [];
      this.isLearnersLoading = false;

      this.outcomes = [
        { val: 0, txt: "Failed" },
        { val: 1, txt: "Passed" },
      ];

      this.selected = {
        adi: null,
        learner: null,
        testDate: null,
        testTime: null,
        testOutcome: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      //adiList: `${M_OU}${GET_ACTIVE_INSTRUCTORS}`,
      adiList: `${M_OU}${GET_ADI_SUMMARY}`,
      isADIsLoading: `${M_OU}${IS_ADI_SUMMARY_LOADED}`,
    }),
    isDataLoading() {
      return this.adiList.length == 0 ? true : false;
    },
    disableLearner() {
      if (this.selected.adi || this.isLearnersLoading) {
        return false;
      }
      return true;
    },
    disableDate() {
      if (this.selected.learner) {
        return false;
      }
      return true;
    },
    disableTime() {
      if (this.selected.testDate) {
        return false;
      }
      return true;
    },
    disableOutcome() {
      if (this.selected.testTime) {
        return false;
      }
      return true;
    },
    disableSave() {
      if (this.selected.testOutcome != null) {
        return false;
      }
      return true;
    },
    //error section
  },
  mounted() {
    //this.loadADIS();
  },
};
</script>

<style></style>
