<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col
      ><v-sheet color="white" elevation="1" class="pa-5">
        <h2>Manage Franchise Plans</h2>
        <v-divider></v-divider>

        <v-data-table
          :headers="headers"
          :items="allPlans"
          sort-by="plan_id"
          class="elevation-1"
        >
          <template v-slot:[`item.initial_fee`]="{ item }">
            {{ "£" + item.initial_fee }}
          </template>
          <template v-slot:[`item.weekly_fee`]="{ item }">
            {{ "£" + item.weekly_fee }}
          </template>
          <template v-slot:[`item.created_on`]="{ item }">
            {{ item.created_on | dateReOrderRMtime }}
          </template>
          <template v-slot:[`item.updated_on`]="{ item }">
            {{ item.updated_on | dateReOrderRMtime }}
          </template>
          <template v-slot:[`item.state`]="{ item }">
            <v-chip :color="getChipColour(item.state)" label outlined dark>
              {{ getChipText(item.state) }}
            </v-chip>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>All Franchise Packages</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialogRemove" max-width="650px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this franchise
                    plan?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deletePlanConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              dark
              color="red"
              small
              v-if="item.state === 1"
              @click="deletePlan(item)"
            >
              <v-icon left small> mdi-trash-can-outline </v-icon>Remove</v-btn
            >
            <v-btn light color="red" small v-else disabled> No Actions</v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize"> Reset </v-btn>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  M_COMMON,
  M_OA,
  REMOVE_FRANCHISE_PLAN,
  GET_FRANCHISE_PLANS,
  LOAD_FRANCHISE_PLANS,
} from "@/store/mutation-list";

export default {
  name: "ManageFranchisePlans",

  data: () => ({
    items: [
      {
        text: "Admin Dashboard",
        disabled: false,
        href: "/dashboard/adminhome",
      },
      {
        text: "View/Manage Franchise Plans",
        disabled: true,
        href: "/dashboard/managefranchiseplans",
      },
    ],
    dialogRemove: false,
    dialogDelete: false,
    headers: [
      { text: "Plan ID", value: "plan_id" },
      { text: "Joining Fee", value: "initial_fee" },
      { text: "Weekly Fee", value: "weekly_fee" },
      { text: "Work Type", value: "work_type" },
      { text: "Weekly Hours", value: "weekly_hours" },
      { text: "Promo Code", value: "promo_code" },
      { text: "Added On", value: "created_on" },
      { text: "Updated On", value: "updated_on" },
      { text: "Status", value: "state" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    //localPlans: [],
    editedIndex: -1,
    deletingPlan: 0,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),
  computed: {
    ...mapGetters({
      allPlans: `${M_COMMON}${GET_FRANCHISE_PLANS}`,
    }),
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      loadPlans: `${M_COMMON}${LOAD_FRANCHISE_PLANS}`,
      removePlan: `${M_OA}${REMOVE_FRANCHISE_PLAN}`,
    }),
    initialize() {
      this.loadPlans(); // = this.allPlans; //for some reason there is a array in array
    },
    getChipColour(accState) {
      accState = parseInt(accState);
      let retVal = "";
      switch (accState) {
        case 0:
          retVal = "red";
          break;
        case 1:
          retVal = "green";
          break;
        default:
          retVal = "red";
          break;
      }
      return retVal;
    },
    getChipText(accState) {
      accState = parseInt(accState);
      let retVal = "";
      switch (accState) {
        case 0:
          retVal = "Removed";
          break;
        case 1:
          retVal = "Active";
          break;

        default:
          retVal = "Error";
          break;
      }
      return retVal;
    },
    deletePlan(item) {
      //console.log("DELETE CALL");
      //console.log(item);
      this.deletingPlan = item.plan_id;
      this.dialogRemove = true;
    },
    deletePlanConfirm() {
      //need to forward this to  store and get a confirmation
      this.closeDelete();
      this.removePlan({
        plan_id: this.deletingPlan,
      }).then((res) => {
        //response from store. All complete just need to show to popup message
        //console.log(res);
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Franchise plan removed successfully.",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
          this.loadPlans();
        } else {
          this.$fire({
            title: "Error!",
            text: "Error removing franchise. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
    closeDelete() {
      this.dialogRemove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style></style>
