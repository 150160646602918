<template>
  <div class="mt-5">
    <h3 class="text-center">Summary Chart</h3>
    <GChart
      type="ColumnChart"
      :settings="{ packages: ['corechart'] }"
      :data="getChartData"
      :options="chartOptions"
      :title="chartOptions.title"
    />
  </div>
</template>
<script>
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "ColumnChart",
  components: { GChart },
  props: ["tableData", "ageRatios", "genderRatios", "geoAreas", "gearRatios"],
  data() {
    return {
      /*chartData: [
        [
          "Ratio",
          "Male",
          "Female",
          "17 - 25",
          "26 - 35 ",
          "36 - 45 ",
          "46 - 55",
          "56 - 65",
          "65 & over",
          "Automatic",
          "Manual",
          "E8",
          "E7",
        ],
        ["Gender Ratio", 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ["Age Ratios", 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0],
        ["Gear Ratios", 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0],
        ["Geographical Areas", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1],
      ],*/
      chartOptions: {
        chart: {
          title: "Report Ratios",
          subtitle:
            "Age Ratios, Gender Ratios, Gear Ratios and Geographical Areas:",
        },
        legend: { topic: "top", position: "right", maxLines: 3 },
        isStacked: "percent",
        height: 600,
        vAxis: { minValue: 0 },
        is3D: true,
      },
    };
  },
  methods: {},
  computed: {
    populateChartTitles() {
      let retArray = [];

      //Add Age ratios titles
      let arryAgeRatio = [
        "17 - 25",
        "26 - 35",
        "36 - 45",
        "46 - 55",
        "56 - 65",
        "65 & over",
      ];

      //add Gender ratio titles
      let arryGenderRatio = ["Female", "Male"];
      retArray.concat(arryGenderRatio);

      //lets sort out the PC list
      let arryGeoRatio = Object.keys(this.getOPCRatios);
      retArray.concat(arryGeoRatio);

      //add gear ratios titles
      let arryGearRatio = ["Automatic", "Manual"];

      //if Gear ratios requested send all otherwise sent only last three
      if (this.gearRatios) {
        retArray = [
          //lets add title
          ...arryAgeRatio,
          ...arryGenderRatio,
          ...arryGearRatio,
          ...arryGeoRatio,
        ];
      } else {
        retArray = [...arryAgeRatio, ...arryGenderRatio, ...arryGeoRatio];
      }
      //console.log(retArray);
      return retArray;
    },
    populateChartData() {
      //step 1 : need to know full with of tabel i.e No of postcodes at the end of the table. This section fils requred section rest will be zerro

      //size/ with of data tabel

      if (this.gearRatios) {
        return [
          this.getAgeRatioColumn,
          this.getGenderRatioColumn,
          this.getGearRatioColumn,
          this.getGeoAreaColumn,
        ];
      } else {
        return [
          this.getAgeRatioColumn,
          this.getGenderRatioColumn,
          this.getGeoAreaColumn,
        ];
      }
    },
    getAgeRatioColumn() {
      let A = 0,
        B = 0,
        C = 0,
        D = 0,
        E = 0,
        F = 0;
      if (this.tableData) {
        this.tableData.forEach((rec) => {
          switch (rec.age_ratio) {
            case "A":
              A++;
              break;
            case "B":
              B++;
              break;
            case "C":
              C++;
              break;
            case "D":
              D++;
              break;
            case "E":
              E++;
              break;
            case "F":
              F++;
              break;

            default:
              break;
          }
        });
      }

      let row = [];
      this.populateChartTitles.forEach((title, i) => {
        if (i == 0) {
          //ignore becase we added title
        } else if (i == 1) {
          //`17 - 25 (A)`
          row.push(A);
        } else if (i == 2) {
          //`26 - 35 (B)`, B`
          row.push(B);
        } else if (i == 3) {
          //`36 - 45 (C)`, C
          row.push(C);
        } else if (i == 4) {
          //`46 - 55 (D)`, D
          row.push(D);
        } else if (i == 5) {
          //`56 - 65 (E)`, E
          row.push(E);
        } else if (i == 6) {
          //`65 & over (F)`, F
          row.push(F);
        } else {
          //other data. Lets push empty
          row.push(0);
        }
      });
      row.unshift("Age Ratios");
      //console.log(row);
      return row;
    },
    getGenderRatioColumn() {
      //step 2 lets do the second one
      let Male = 0,
        Female = 0;
      if (this.tableData) {
        this.tableData.forEach((rec) => {
          switch (rec.gender) {
            case "m":
              Male++;
              break;
            case "f":
              Female++;
              break;

            default:
              break;
          }
        });
      }

      let row = [];
      this.populateChartTitles.forEach((title, i) => {
        if (i == 0) {
          //ignote becase we added title
        } else if (i == 7) {
          //`Female`
          row.push(Female);
        } else if (i == 8) {
          //`Male`
          row.push(Male);
        } else {
          //other data. Lets push empty
          row.push(0);
        }
      });
      row.unshift("Gender Ratios");
      //console.log(row);
      return row;
    },
    getGearRatioColumn() {
      let a = 0,
        m = 0;
      if (this.tableData) {
        this.tableData.forEach((rec) => {
          switch (rec.lesson_type) {
            case "manual":
              m++;
              break;
            case "automatic":
              a++;
              break;

            default:
              break;
          }
        });
      }
      let row = [];
      this.populateChartTitles.forEach((title, i) => {
        if (i == 0) {
          //ignote becase we added title
        } else if (i == 9) {
          //`Female`
          row.push(a);
        } else if (i == 10) {
          //`Male`
          row.push(m);
        } else {
          //other data. Lets push empty
          row.push(0);
        }
      });
      row.unshift("Gear Ratios");
      //console.log(row);
      return row;
    },
    getGeoAreaColumn() {
      let row = [];
      this.populateChartTitles.forEach((title, i) => {
        if (i == 0) {
          //ignote becase we added title
        } else if (i <= 10) {
          //other data. Lets push empty
          row.push(0);
        } else {
          //other data. Lets push empty
          row.push(this.getOPCRatios[title]);
        }
      });
      row.unshift("Geographical Areas");
      //console.log(row);
      return row;
    },
    getOPCRatios() {
      let counter = {};
      this.tableData.forEach(function (rec) {
        var key = JSON.stringify(rec.outer_pc).replaceAll('"', "");
        counter[key] = (counter[key] || 0) + 1;
      });
      return counter;
    },
    //this will return google charData
    getChartData() {
      let titles = this.populateChartTitles;
      titles.unshift("Ratio");
      let ar = this.populateChartData;
      ar.unshift(titles);
      //console.log(ar);
      return ar;
    },
  },
};
</script>
