<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5"
        ><h2>Register New Driving Instructor</h2>
        <p class="sub-title">Please complete all required fields</p>
        <v-expansion-panels popout>
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              <span style="font-weight: 500; font-size: 1.2em"
                >Personal Details</span
              >
              <template v-slot:actions>
                <IconProvider :vals="personalIco" />
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Personal @peronal-update="personalFRMChange" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              <span style="font-weight: 500; font-size: 1.2em"
                >Contact Details</span
              >
              <template v-slot:actions>
                <IconProvider :vals="contactIco" />
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Contact @contact-update="contactFRMChange" />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              <span style="font-weight: 500; font-size: 1.2em">Compliance</span>
              <template v-slot:actions>
                <IconProvider :vals="complianceIco" />
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Compliance @compliance-update="complianceFRMChange" />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              <span style="font-weight: 500; font-size: 1.2em"
                >Teaching Preferences</span
              >
              <template v-slot:actions>
                <IconProvider :vals="teachingIco" />
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Teaching @teaching-update="teachingFRMChange" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              <span style="font-weight: 500; font-size: 1.2em"
                >Vehicle Details</span
              >
              <template v-slot:actions>
                <IconProvider :vals="vehiclesIco" />
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Vehicles @vehicles-update="vehiclesFRMChange" />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              <span style="font-weight: 500; font-size: 1.2em">Financials</span>
              <template v-slot:actions>
                <IconProvider :vals="financialsIco" />
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Financials @financials-update="financialsFRMChange" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              <span style="font-weight: 500; font-size: 1.2em">Licences</span>
              <template v-slot:actions>
                <IconProvider :vals="licencesIco" />
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Licences @licences-update="licencesFRMChange" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              <span style="font-weight: 500; font-size: 1.2em"
                >Submit Data</span
              >
              <template v-slot:actions>
                <IconProvider :vals="submitIco" />
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <h3>
                Please check each section carefully, ensuring all information
                and documents attached are correct before registering an
                instructor. If the <em>'Register Instructor'</em> button is
                inactive then there are some sections incomplete - kindly
                recheck.
              </h3>
              <v-btn class="success" :disabled="!isReadyToReg" @click="regADI"
                >Register Instructor
              </v-btn>
              <!-- <v-btn @click="printit()">Print Form Data</v-btn> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import Personal from "@/components/Instructor/Register/Personal";
import Contact from "@/components/Instructor/Register/Contact";
import Compliance from "@/components/Instructor/Register/Compliance";
import Teaching from "@/components/Instructor/Register/Teaching";
import Vehicles from "@/components/Instructor/Register/Vehicles";
import Financials from "@/components/Instructor/Register/Financials";
import Licences from "@/components/Instructor/Register/Licences";

import IconProvider from "@/components/Instructor/Register/IconProvider";
import {
  M_ADI,
  REGISTER_NEW_INSTRUCTOR,
  M_AUTH,
  TOKEN,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "RegisterInstructor",
  components: {
    IconProvider,
    Personal,
    Contact,
    Compliance,
    Teaching,
    Vehicles,
    Financials,
    Licences,
  },
  data: function () {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Register Instructor",
          disabled: true,
          href: "/dashboard/registerinstructor",
        },
      ],
      personalIco: 0, //0 - expand, 1-success , 2-error
      contactIco: 0,
      complianceIco: 0,
      teachingIco: 0,
      vehiclesIco: 0,
      financialsIco: 0,
      licencesIco: 0,
      //submitIco: this.submitICO,

      //individual OBJs
      personalObj: {},
      contactObj: {},
      complianceObj: {},
      teachingObj: {},
      teachingObjFD: new FormData(),
      vehiclesObj: {},
      financialsObj: {},
      licencesObj: {},

      //final form
      form: {},
      //final formdata
      allFD: new FormData(),
    };
  },
  methods: {
    ...mapActions({
      registerADI: `${M_ADI}${REGISTER_NEW_INSTRUCTOR}`,
    }),
    printit() {
      for (var pair of this.allFD.entries()) {
        if (pair[2] === undefined) {
          console.log(pair[0] + ", " + pair[1]);
        } else {
          console.log(pair[0] + ", " + pair[1] + ", " + pair[2]);
        }
        //console.log(pair[0] + ", " + pair[1]);
        //this.allFD.append();
      }
    },
    personalFRMChange(payload) {
      //true means error
      this.personalIco = this.boolToInt(!payload.state);
      this.personalObj = payload.data;
      for (var pair of payload.fd.entries()) {
        if (pair[2] === undefined) {
          this.allFD.set(pair[0], pair[1]);
        } else {
          this.allFD.set(pair[0], pair[1], pair[2]);
        }
      }
    },
    contactFRMChange(payload) {
      //true means error
      this.contactIco = this.boolToInt(!payload.state);
      this.contactObj = payload.data;
      for (var pair of payload.fd.entries()) {
        if (pair[2] === undefined) {
          this.allFD.set(pair[0], pair[1]);
        } else {
          this.allFD.set(pair[0], pair[1], pair[2]);
        }
      }
    },
    complianceFRMChange(payload) {
      //true means error
      this.complianceIco = this.boolToInt(!payload.state);
      this.complianceObj = payload.data;
      for (var pair of payload.fd.entries()) {
        if (pair[2] === undefined) {
          this.allFD.set(pair[0], pair[1]);
        } else {
          this.allFD.set(pair[0], pair[1], pair[2]);
        }
      }
    },
    teachingFRMChange(payload) {
      //true means error
      this.teachingIco = this.boolToInt(!payload.state);
      //console.log(payload);
      if (!payload.state) {
        this.teachingObj = payload.data;
        this.teachingObjFD = payload.fd;
        /* for (var pair of payload.fd.entries()) {
          if (pair[2] === undefined) {
            this.allFD.append(pair[0], pair[1]);
          } else {
            this.allFD.set(pair[0], pair[1], pair[2]);
          }*
        }*/
      }
      // for (const [key, value] of this.allFD.entries()) {
      //   //console.log(`${key}: ${value}`);
      // }
    },

    vehiclesFRMChange(payload) {
      //true means error
      this.vehiclesIco = this.boolToInt(!payload.state);
      this.vehiclesObj = payload.data;
      for (var pair of payload.fd.entries()) {
        console.log(pair);
        if (pair[2] === undefined) {
          this.allFD.set(pair[0], pair[1]);
        } else {
          this.allFD.set(pair[0], pair[1], pair[2]);
        }
      }
    },
    financialsFRMChange(payload) {
      //true means error
      this.financialsIco = this.boolToInt(!payload.state);
      this.financialsObj = payload.data;
      for (var pair of payload.fd.entries()) {
        console.log(pair);
        if (pair[2] === undefined) {
          this.allFD.set(pair[0], pair[1]);
        } else {
          this.allFD.set(pair[0], pair[1], pair[2]);
        }
      }
    },
    licencesFRMChange(payload) {
      //true means error
      this.licencesIco = this.boolToInt(!payload.state);
      this.licencesObj = payload.data;
      for (var pair of payload.fd.entries()) {
        if (pair[2] === undefined) {
          this.allFD.set(pair[0], pair[1]);
        } else {
          this.allFD.set(pair[0], pair[1], pair[2]);
        }
      }
    },
    //0 - expand, 1-success , 2-error this will simply return errors
    boolToInt(val) {
      if (val) {
        return 1;
      }
      return 2;
    },
    //submit sequence
    regADI() {
      this.form = {
        ...this.personalObj,
        ...this.contactObj,
        ...this.complianceObj,
        ...this.teachingObj,
        ...this.vehiclesObj,
        ...this.financialsObj,
        ...this.licencesObj,
      };
      //let join all to one form.
      //already done
      //we are adding teaching preferences in here beacuse muti values changeing and overing issues.
      for (var pair of this.teachingObjFD.entries()) {
        this.allFD.append(pair[0], pair[1]);
      }

      //lets add the endpoint name
      const token = this.getToken;
      this.allFD.set("token", token); //adding token
      this.allFD.set("addadiinofficeadvancedv2", "addadiinofficeadvancedv2");
      this.registerADI(this.allFD).then((res) => {
        console.log(res);
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Driving instructor registered successfully",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });

          //reload learner info here
          //this.loadLearners();
          //this.clear();
        } else {
          this.$fire({
            title: "Error",
            text: "Error registering driving instructor. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      getToken: `${M_AUTH}${TOKEN}`,
    }),
    //regBTN
    isReadyToReg() {
      if (
        this.personalIco == 1 &&
        this.contactIco == 1 &&
        this.complianceIco == 1 &&
        this.teachingIco == 1 &&
        this.vehiclesIco == 1 &&
        this.financialsIco == 1 &&
        this.licencesIco == 1
      ) {
        return true;
      }
      return false;
    },
    submitIco() {
      if (
        this.personalIco == 1 &&
        this.contactIco == 1 &&
        this.complianceIco == 1 &&
        this.teachingIco == 1 &&
        this.vehiclesIco == 1 &&
        this.financialsIco == 1 &&
        this.licencesIco == 1
      ) {
        return 1;
      }
      return 2;
    },
  },
};
</script>

<style></style>
