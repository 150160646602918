<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col
      ><v-sheet color="white" elevation="1" class="pa-5">
        <h2>Create New Franchise Package</h2>
        <v-divider></v-divider>
        <form>
          <v-row justify="center">
            <v-col md="4">
              <!-- <v-header name="Holds"> &nbsp;</v-header> -->
              <v-text-field
                v-model="form.initialfee"
                label="Initial/Joining Fee"
                required
                type="number"
                prepend-inner-icon="mdi-currency-gbp"
                :error-messages="joininFeeErrors"
                @input="$v.form.initialfee.$touch()"
                @blur="$v.form.initialfee.$touch()"
              ></v-text-field>

              <v-text-field
                v-model="form.weeklyfee"
                label="Weekly Fee"
                type="number"
                required
                prepend-inner-icon="mdi-currency-gbp"
                :error-messages="weeklyFeeErrors"
                @input="$v.form.weeklyfee.$touch()"
                @blur="$v.form.weeklyfee.$touch()"
              ></v-text-field>

              <v-select
                v-model="form.worktype"
                :items="worktypeList"
                item-text="txt"
                item-value="id"
                label="Work Type"
                required
                prepend-inner-icon="mdi-timeline-clock "
                :error-messages="workTypeErrors"
                @input="$v.form.worktype.$touch()"
                @blur="$v.form.worktype.$touch()"
              >
              </v-select>
              <v-text-field
                v-model="form.weeklyhours"
                label="Weekly Hours"
                required
                type="number"
                prepend-inner-icon="mdi-timer-settings"
                :error-messages="weeklyHoursErrors"
                @input="$v.form.weeklyhours.$touch()"
                @blur="$v.form.weeklyhours.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="form.promocode"
                :counter="10"
                label="Promotion Code"
                clearable
                prepend-inner-icon="mdi-sale"
                :error-messages="promoCodeErrors"
                @input="$v.form.promocode.$touch()"
                @blur="$v.form.promocode.$touch()"
              ></v-text-field>

              <v-btn color="success" class="mt-3" @click="submit"
                >Add Package
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import { SET_FRANCHISE_PLAN, M_OA } from "@/store/mutation-list";
import { mapActions } from "vuex";

import {
  required,
  numeric,
  decimal,
  maxLength,
  between,
} from "vuelidate/lib/validators";

export default {
  name: "AddFranchisePlan",

  data: () => ({
    items: [
      {
        text: "Admin Dashboard",
        disabled: false,
        href: "/dashboard/adminhome",
      },
      {
        text: "Add Franchise Plan",
        disabled: true,
        href: "/dashboard/addfranchiseplan",
      },
    ],
    worktypeList: [
      { id: "Full-Time", txt: "Full-Time" },
      { id: "Part-Time", txt: "Part-Time" },
    ],

    datePickDirty: false,
    form: {
      initialfee: "",
      weeklyfee: "",
      worktype: "",
      weeklyhours: "",
      promocode: "",
    },
  }),
  validations: {
    form: {
      initialfee: { required, decimal },
      weeklyfee: { required, decimal },
      worktype: { required },
      weeklyhours: {
        required,
        numeric,
        between: between(1, 168),
      },
      promocode: { maxLength: maxLength(10) },
      //need to remove reset
    },
  },
  computed: {
    //this section is for errors

    joininFeeErrors() {
      const errors = [];
      if (!this.$v.form.initialfee.$dirty) return errors;
      !this.$v.form.initialfee.decimal && errors.push("Fee must be a number");
      !this.$v.form.initialfee.required &&
        errors.push("Joining Fee is required.");
      return errors;
    },
    weeklyFeeErrors() {
      const errors = [];
      if (!this.$v.form.weeklyfee.$dirty) return errors;
      !this.$v.form.weeklyfee.decimal && errors.push("Fee must be a number");
      !this.$v.form.weeklyfee.required &&
        errors.push("Weekly Fee is required.");
      return errors;
    },
    workTypeErrors() {
      const errors = [];
      if (!this.$v.form.worktype.$dirty) return errors;
      !this.$v.form.worktype.required && errors.push("Work type is required.");
      return errors;
    },
    weeklyHoursErrors() {
      const errors = [];
      if (!this.$v.form.weeklyhours.$dirty) return errors;
      !this.$v.form.weeklyhours.numeric &&
        errors.push("Hours must be in whole numbers");
      !this.$v.form.weeklyhours.between &&
        errors.push("Hours must be more than 0 and less than 168");
      !this.$v.form.weeklyhours.required &&
        errors.push("Weekly Hours is required.");
      return errors;
    },
    promoCodeErrors() {
      const errors = [];
      if (!this.$v.form.promocode.$dirty) return errors;
      !this.$v.form.promocode.maxLength &&
        errors.push("Promotion code can not be more than 10 characters long");

      return errors;
    },
  },
  methods: {
    ...mapActions({
      saveNewPlan: `${M_OA}${SET_FRANCHISE_PLAN}`,
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveNewPlan({
          // the data that need to sent to store/server
          initial_fee: this.form.initialfee,
          weekly_fee: this.form.weeklyfee,
          work_type: this.form.worktype,
          weekly_hours: this.form.weeklyhours,
          promo_code: this.form.promocode,
        }).then((res) => {
          //response from store. All complete just need to show to popup message
          //console.log(res);
          if (res == "SUCCESS") {
            this.$fire({
              title: "Success",
              text: "Franchise Package Added successfully.",
              type: "success",
              timer: 3000,
            }).then((r) => {
              console.log(r.value);
              this.clear();
            });
          } else {
            this.$fire({
              title: "Error!",
              text: "Error adding franchise package. Please try again.",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
    },
    clear() {
      this.$v.$reset();

      this.form = {
        initialfee: "",
        weeklyfee: "",
        worktype: "",
        weeklyhours: "",
        promocode: "",
      };
    },
  },
};
</script>

<style></style>
