<template>
  <v-expand-transition>
    <v-alert
      color="white"
      light
      border="left"
      icon="mdi-palette "
      transition="scale-transition"
      >Legend
      <span class="n">New Learner</span>
      <span class="l">Lesson</span>
      <span class="t">Test</span>
      <span class="r">Rescheduled</span>
      <span class="c">Canceled</span>
    </v-alert>
  </v-expand-transition>
</template>

<script>
export default {
  name: "CalendarLegend",
};
</script>

<style scoped>
span {
  color: white;
  width: 100%;
  display: block;
  margin: 2px;
  padding: 2px;
  border-radius: 2px;
}
span.n {
  background-color: #66cc00;
}
span.l {
  /*        background-color: #3399FF;*/
  background-color: #337ab7;
}
span.t {
  /* background-color: #FF6600; */
  background-color: #f36b9b;
}

span.r {
  /* background-color: #9C27B0; */
  background-color: #bf5ed0;
}
span.c {
  /* background-color: #FF3333; */
  background-color: #a55757;
}
</style>
