<template>
  <div class="row">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5">
        <h2>Instructor Diary Management</h2>
        <div class="subtitle-1">Please select driving instructor</div>
        <v-divider class="my-5"></v-divider>
        <v-row class="">
          <v-col class="col-sm-4">
            <v-select
              v-model="selected.adi"
              label="Driving Instructor"
              :items="adiList"
              item-value="adi_id"
              outlined
              :loading="!isADIsLoading"
              :disabled="!isADIsLoading || !isADISelectable"
            >
              <template v-slot:item="{ item }">
                <strong> A{{ item.adi_id }} </strong> &nbsp;-&nbsp;
                {{ item.title }}
                {{ item.fname }}
                {{ item.lname }}
              </template>
              <template v-slot:selection="{ item }">
                <strong> A{{ item.adi_id }} </strong> &nbsp;-&nbsp;
                {{ item.title }}
                {{ item.fname }}
                {{ item.lname }}
              </template>
            </v-select>
          </v-col>
          <v-col class="col-sm-4">
            <v-slide-x-transition>
              <v-btn
                class="primary"
                x-large
                :disabled="!isBtnState"
                v-show="!isBookingsLoaded"
                @click="loadDiaryData"
                >Load Diary</v-btn
              ></v-slide-x-transition
            >
            <v-slide-x-reverse-transition
              ><v-btn
                class="success"
                x-large
                v-show="isBookingsLoaded"
                @click="resetADISelect"
                ><v-icon>mdi-refresh </v-icon>
              </v-btn>
            </v-slide-x-reverse-transition>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-5">
          <v-col class="col-md-2 shrink">
            <v-divider></v-divider>
            <v-expand-transition>
              <v-btn
                class="success"
                v-if="updatedBookings.length > 0"
                @click="SaveBookings"
                >Save Change</v-btn
              >
            </v-expand-transition>
            <v-divider></v-divider>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col class="col-md-8 shrink">
            <v-expand-transition>
              <FullCalendar :options="calendarOptions" />
            </v-expand-transition>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col class="col-md-2 shrink"><CalendarLegend /></v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import {
  //GET_ACTIVE_INSTRUCTORS,
  IS_ADI_SUMMARY_LOADED,
  GET_ADI_SUMMARY,
  LOAD_ADI_SUMMARY,
  GET_ADI_CUREENT_BOOKINGS,
  SET_BOOKIN_UPDATES,
  GET_ADI_OLD_BOOKINGS,
  M_OU,
} from "@/store/mutation-list";
// import { mapGetters } from "vuex";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

import CalendarLegend from "@/components/Booking/CalendarLegend";
import { mapActions, mapGetters } from "vuex";

document.addEventListener;
export default {
  name: "InstructorDiary",
  components: {
    FullCalendar,
    CalendarLegend,
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "View Instructor Diary",
          disabled: true,
          href: "/dashboard/instructordiary",
        },
      ],
      //adiList: [],
      //isADIsLoading: false,
      isADISelectable: true,
      adiCurrentBookings: [],
      isBookingsLoaded: false,
      selected: {
        adi: null,
      },
      //lessons data
      lessonDuration: "01:00",
      updatedBookings: [],

      isCalendar: false,
      //calendar data
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
        timeZone: "UTC",
        initialView: "dayGridMonth",
        //height: 650,
        aspectRatio: 2,
        //new times slots
        slotDuration: "00:15",
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
        },

        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },

        buttonText: {
          today: "Today",
          month: "Month",
          week: "Week",
          day: "Day",
          list: "List",
        },
        fixedWeekCount: false,
        locale: "en-gb",
        firstDay: 1,
        allDaySlot: false,
        navLinks: true,
        editable: true,
        //new lines
        eventResizableFromStart: false,
        //eventDurationEditable: false,
        droppable: true,
        // themeSystem: "bootstrap",
        eventOverlap: false,
        businessHours: this.getBusinessHours(),
        eventTimeFormat: {
          // like '14:30:00'
          hour: "2-digit",
          minute: "2-digit",
          meridiem: false,
        },
        //prevent dropping on old dates
        validRange: function (nowDate) {
          let temp = new Date();
          //add 3 months
          temp.setDate(nowDate.getDate() + 90);
          const oldDay = new Date();
          oldDay.setDate(nowDate.getDate() - 90);
          return {
            start: oldDay,
            end: temp,
          };
        },
        events: [],

        dropAccept: ".dragLesson",
        eventDrop: this.handleMoves,
        eventReceive: this.handleDrops,
      },
      addEvents: true,
    };
  },

  methods: {
    ...mapActions({
      LoadADIs: `${M_OU}${LOAD_ADI_SUMMARY}`,
      LoadADIBookings: `${M_OU}${GET_ADI_CUREENT_BOOKINGS}`,
      LoadADIOldBookings: `${M_OU}${GET_ADI_OLD_BOOKINGS}`,
      saveChanges: `${M_OU}${SET_BOOKIN_UPDATES}`,
    }),
    loadADIS() {
      //this.isADIsLoading = true;
      // eslint-disable-next-line prettier/prettier
      //this.adiList = [{"adi_id":1041,"title":"Dr","fname":"Boniface","mname":"O","lname":"Ossai"},{"adi_id":1084,"title":"Lady","fname":"Fuad","mname":"","lname":"Bar"},{"adi_id":1085,"title":"Mr","fname":"Manual","mname":"","lname":"John"},{"adi_id":1044,"title":"Mr","fname":"Syeed","mname":"M","lname":"Hussayin"},{"adi_id":1042,"title":"Mr","fname":"Wahid","mname":"A","lname":"Ali"}];
      //this.isADIsLoading = false;
    },

    loadDiaryData() {
      //this function will load adi calendar and the credit for selected learner

      this.adiCurrentBookings = []; // clear adi current bookings
      //stop selecting ADI
      let loadedBookingsData = [];
      this.isADISelectable = false;
      let sd = this.getNewDate;
      let ed = this.getThreeMonthBeforeDate;

      //lets push non edit area
      this.adiCurrentBookings.push({
        start: ed.toString(),
        end: sd.toString(),
        overlap: false,
        display: "background",
        color: "#ffebe6",
      });

      this.LoadADIBookings({ id: this.selected.adi })
        .then((rep) => {
          loadedBookingsData = rep;
          //console.log(loadedBookingsData);
        })
        .finally(() => {
          loadedBookingsData.forEach((booking) => {
            let addressStr = "Pick Up: ";
            if (booking.addr_line && booking.addr_pc) {
              addressStr += booking.addr_line + ", ";
            } else if (booking.addr_line && !booking.addr_pc) {
              addressStr += booking.addr_line;
            }
            if (booking.addr_pc) {
              addressStr += booking.addr_pc;
            }
            if (!booking.addr_line && !booking.addr_pc) {
              addressStr = "";
            }
            let dropAddStr = " Drop off: ";
            if (booking.drop_line && booking.drop_pc) {
              dropAddStr += booking.drop_line + ", ";
            } else if (booking.drop_line && !booking.drop_pc) {
              dropAddStr += booking.drop_line;
            }
            if (booking.drop_pc) {
              dropAddStr += booking.drop_pc;
            }
            if (!booking.drop_line && !booking.drop_pc) {
              dropAddStr = "";
            }
            if (addressStr && dropAddStr) {
              dropAddStr = " ," + dropAddStr;
            }
            /*
title:
                booking.stu_title +
                " " +
                booking.stu_fname +
                " " +
                booking.stu_lname +
                " (L" +
                booking.stu_id +
                ") " +
                addressStr +
                dropAddStr,
*/
            let temp = {
              id: booking.booking_id,
              title: ` ${booking.stu_title} ${booking.stu_fname} ${booking.stu_lname} (L${booking.stu_id}) ${addressStr} ${dropAddStr}`,
              start: booking.lesson_starts,
              end: booking.lesson_end,
              color: this.getEventColour(
                booking.booking_lesson_type,
                booking.booking_state
              ),
              editable: true,
              durationEditable: false,
            };
            this.adiCurrentBookings.push(temp);
          });
          this.calendarOptions.events = this.adiCurrentBookings;
          this.isCalendar = true;
          this.isBookingsLoaded = true;
        });

      //lets load old data
      this.LoadADIOldBookings({ id: this.selected.adi }).then((bookings) => {
        if (bookings) {
          bookings.forEach((booking) => {
            let addressStr = "Pick Up: ";
            if (booking.addr_line && booking.addr_pc) {
              addressStr += booking.addr_line + ", ";
            } else if (booking.addr_line && !booking.addr_pc) {
              addressStr += booking.addr_line;
            }
            if (booking.addr_pc) {
              addressStr += booking.addr_pc;
            }
            if (!booking.addr_line && !booking.addr_pc) {
              addressStr = "";
            }
            let dropAddStr = " Drop off: ";
            if (booking.drop_line && booking.drop_pc) {
              dropAddStr += booking.drop_line + ", ";
            } else if (booking.drop_line && !booking.drop_pc) {
              dropAddStr += booking.drop_line;
            }
            if (booking.drop_pc) {
              dropAddStr += booking.drop_pc;
            }
            if (!booking.drop_line && !booking.drop_pc) {
              dropAddStr = "";
            }
            if (addressStr && dropAddStr) {
              dropAddStr = " ," + dropAddStr;
            }
            let temp = {
              id: booking.booking_id,
              title: ` ${booking.stu_title} ${booking.stu_fname} ${booking.stu_lname} (L${booking.stu_id}) ${addressStr} ${dropAddStr}`,
              start: booking.lesson_starts,
              end: booking.lesson_end,
              color: this.getEventColour(
                booking.booking_lesson_type,
                booking.booking_state
              ),
              editable: false,
              durationEditable: false,
            };
            this.calendarOptions.events.push(temp);
          });
        }
      });
    },
    getJSONeventData(credID) {
      let myLearner = this.selected.learner;

      let cl = this.learnerList.filter(
        (learner) => learner.stu_id == myLearner
      );
      //array to 1 object
      cl = cl[0];
      return `{"id": ${credID}, "title": "${cl.title} ${cl.fname} ${cl.lname}  L${cl.stu_id}", "duration": "${this.lessonDuration}", "extendedProps": { "eventType": "newbooking", "learnerid" : ${cl.stu_id}}}`;
    },
    //this method will work on move events
    handleMoves(dropInfo) {
      //console.log("Evernt moved");
      //something happennign
      let removedArray = this.updatedBookings.filter((booking) => {
        return booking.id != dropInfo.event.id;
      });

      this.updatedBookings = removedArray;
      this.updatedBookings.push({
        id: dropInfo.event.id,
        start: dropInfo.event.start,
        end: dropInfo.event.end,
        durationEditable: false,
      });
    },
    getEventColour(type, state) {
      let newlearner = "#66CC00";
      let normalLesson = "#337ab7";
      let test = "#FF6600";
      let rescheduled = "#9C27B0";
      let canceled = "#FF3333";
      type = parseInt(type);
      state = parseInt(state);

      switch (state) {
        case 1:
          //scheduled
          switch (type) {
            case 1:
              return newlearner;

            case 2:
              return normalLesson;

            case 3:
              return test;

            case 4:
              return test;

            default:
              return normalLesson;
          }

        case 2:
          return rescheduled;
        //resceduled

        case 3:
          return canceled;
        //canceled

        default:
          return normalLesson;
      }
    },
    getBusinessHours() {
      return [
        {
          daysOfWeek: [1, 2, 3, 4, 5], // Monday
          startTime: "07:00", // 8am
          endTime: "20:00", // 6pm
        },
        /*{
            daysOfWeek: [2], // Tue
            startTime: "07:30", // 8am
            endTime: "21:00", // 6pm
          },
          {
            daysOfWeek: [3], // Wed
            startTime: "07:30", // 8am
            endTime: "21:00", // 6pm
          },
          {
            daysOfWeek: [4], // Thu
            startTime: "08:00", // 8am
            endTime: "22:00", // 6pm
          },
          {
            daysOfWeek: [5], // Fri
            startTime: "07:00", // 8am
            endTime: "17:00", // 6pm
          },*/
        {
          daysOfWeek: [6], // Sat
          startTime: "09:00", // 8am
          endTime: "16:00", // 6pm
        },
        {
          daysOfWeek: [0], // Sun
          startTime: "09:00", // 8am
          endTime: "12:00", // 6pm
        },
      ];
    },
    getTwoDigits(num) {
      return ("0" + num).slice(-2);
    },
    //this function convert date time to YYYY-MM-DD HH:mm:ss format
    convertDate(dtVal) {
      let d = new Date(dtVal);
      let YYYY = d.getFullYear();
      let MM = d.getMonth();

      MM++;
      MM = this.getTwoDigits(MM);
      let DD = d.getDate();
      DD = this.getTwoDigits(DD);
      let HH = d.getHours();
      HH = this.getTwoDigits(HH);
      let mm = d.getMinutes();
      mm = this.getTwoDigits(mm);

      return `${YYYY}-${MM}-${DD} ${HH}:${mm}:00`;
    },
    //this method will save all bookings
    SaveBookings() {
      this.updatedBookings.forEach((item, index) => {
        this.updatedBookings[index].start = this.convertDate(item.start);
      });
      //need to conver this to JASON
      //now do the data submit
      //TODO make sure to fire success message

      //
      let temp = JSON.stringify(this.updatedBookings);
      //console.log(temp);
      this.saveChanges({
        bookings: temp,
        adi: this.selected.adi,
      }).then((res) => {
        //response from store. All complete just need to show to popup message
        //console.log(res);
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Diary Updated Successfully .",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
            //clear all and reloadclear()
            this.resetADISelect();
            this.$router.go();
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error Updating Diary. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
    //this methos is to reset selected ADI
    resetADISelect() {
      this.updatedBookings = [];
      this.isBookingsLoaded = false;
      this.selected.adi = null;
      this.isADISelectable = true;
      this.adiCurrentBookings = []; // clear adi current bookings
      this.calendarOptions.events = [];
      //stop selecting ADI
    },
  },
  computed: {
    ...mapGetters({
      adiList: `${M_OU}${GET_ADI_SUMMARY}`,
      isADIsLoading: `${M_OU}${IS_ADI_SUMMARY_LOADED}`,
    }),
    //this function will return current data in ISO format
    getNewDate() {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      return today.toISOString().substring(0, 10);
    },
    //this funtion return 90 days before now
    getThreeMonthBeforeDate() {
      let oldDay = new Date();
      oldDay.setDate(oldDay.getMonth() - 90);
      return oldDay.toISOString().substring(0, 10);
    },
    getConstrtain() {
      return { start: this.getStartDate, end: this.getEndDate };
    },
    disableLearner() {
      if (this.selected.adi || this.isLearnersLoading) {
        return false;
      }
      return true;
    },
    isBtnState() {
      if (this.selected.adi) {
        return true;
      } else {
        return false;
      }
    },
  },

  created() {
    this.loadADIS();
  },
};
</script>
