<template>
  <v-app dark>
    <v-main class="bg">
      <v-container fill-height>
        <v-row align="center" justify="center" dense>
          <v-col cols="12" sm="8" md="4" lg="4">
            <v-card elevation="2" shaped>
              <a href="#" name="logo" title="logo">
                <!-- <v-img src="../assets/mlogo.png" contain height="200"></v-img> -->
                <!-- <v-img src="../assets/logotwo.jpg" contain height="200"></v-img> -->
                <!-- <v-img
                  src="https://via.placeholder.com/200.png?text=LOGO"
                  contain
                  height="200"
                ></v-img> -->
                <v-img
                  class="mx-10"
                  src="../assets/img/__becodrivelogo.svg"
                  contain
                  height="250"
                ></v-img>
              </a>
              <div class="text-center">
                <p style="font-size: 1.6rem">Enterprise Suite {{ ver }}</p>
                <p>
                  <i
                    >The most powerful bespoke software suite for professional
                    driving schools
                  </i>
                </p>
                <p>
                  <b>Powered by <em>Artificial Intelligence</em></b>
                </p>
                <p style="font-size: 0.8rem">
                  © Copyrights 2016 - {{ new Date().getFullYear() }} Brandiie
                  <sup>®</sup>™ and BECO Drive <sup>®</sup>™. All Rights
                  Reserved.
                </p>
              </div>
              <v-alert
                :value="alert"
                color="pink"
                dark
                colored-border
                type="error"
                elevation="2"
                border="bottom"
                icon="mdi-home"
                transition="scale-transition"
              >
                <span v-html="alertTxt"> </span>
              </v-alert>
              <v-card-text>
                <v-text-field
                  v-model="logID"
                  label="User ID"
                  name="userid"
                  prepend-inner-icon="mdi-account-circle-outline "
                  type="number"
                  placeholder="Enter Your User ID"
                  class="rounded-1"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="logPass"
                  label="Password"
                  name="pass"
                  prepend-inner-icon="mdi-lock-outline"
                  :append-icon="showIco"
                  @click:append="toggleShow"
                  :type="txtBoxType"
                  placeholder="Enter Your Password"
                  class="rounded-1"
                  outlined
                  clearable
                  v-on:keyup.enter="
                    loader = 'loading';
                    genSHA();
                  "
                >
                  <!-- suffix="| Forgot?" -->
                </v-text-field>
                <div class="text-center">
                  <v-btn
                    text
                    color="info"
                    class="mb-5"
                    @click.stop="modal = true"
                    >Forgot Password?</v-btn
                  >
                </div>
                <v-dialog v-model="modal" max-width="450">
                  <v-card>
                    <v-card-title class="text-h4">
                      Forgot Password
                    </v-card-title>

                    <v-card-text class="body-1">
                      If you are an office user please contact office manager to
                      reset the password.<br />
                      <br />If you are an office manager/office administrator
                      please contact software support.
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn color="green darken-1" text @click="modal = false">
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-btn
                  class="rounded-1"
                  color="primary"
                  x-large
                  block
                  :loading="loading"
                  :disabled="loading"
                  @click="
                    loader = 'loading';
                    genSHA();
                  "
                  >Login
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer app="app">
      <v-flex class="text-xs-center"
        >Designed, Created and Developed by
        <a href="https://www.brandiie.com"> Brandiie</a>.<v-btn
          icon
          color="black"
          to="/accountsetup"
          class="mx-10"
        >
          <v-icon>mdi-shield-key</v-icon>
        </v-btn>
        © Copyrights 2016 - {{ new Date().getFullYear() }} Brandiie
        <sup>®</sup>™ and BECO Drive <sup>®</sup>™. All Rights Reserved.</v-flex
      >
    </v-footer>
  </v-app>
</template>

<script>
import { AppSetup } from "@/store/sitedata";
import { mapActions } from "vuex";
import {
  LOGIN,
  M_AUTH,
  LOAD_DATA_AT_LOGIN,
  M_COMMON,
} from "@/store/mutation-list";
export default {
  name: "LoginComp",
  data: () => ({
    showPass: false,
    txtBoxType: "password",
    logID: null,
    logPass: null,
    loader: null,
    loading: false,
    alert: false,
    alertTxt: "",
    user: {
      //email: 'admin@example.com',
      //password: 'admin',
      //name: 'John Doe',
    },
    modal: false,
    options: {
      isLoggingIn: true,
      shouldStayLoggedIn: true,
    },
    //app verison
    ver: AppSetup.version,
  }),
  methods: {
    ...mapActions({
      userlogin: `${M_AUTH}${LOGIN}`,
      loadAtLoading: `${M_COMMON}${LOAD_DATA_AT_LOGIN}`,
    }),
    toggleShow() {
      this.showPass = !this.showPass;
      if (this.txtBoxType === "password") {
        this.txtBoxType = "text";
      } else {
        this.txtBoxType = "password";
      }
    },
    genSHA() {
      let self = this;
      self.loading = true;
      self.alert = false;

      this.digestMessage(this.logPass).then(function (outp) {
        //lets pass the info
        let fd = new FormData();
        fd.append("p", outp);
        fd.append("staffweblogin", "staffweblogin"); //form end point
        fd.append("loginid", self.logID);

        self.userlogin(fd).then((repl) => {
          if (repl) {
            //ther is a reply
            if (repl.code != 1) {
              //login error
              self.alert = true;
              self.alertTxt = repl.text + ". Please try again.";
            } else {
              //login success read the data
              //console.log(repl.data);
              let dta = repl.data;
              //lets load all the common data
              self.loadAtLoading({ isAdmin: dta.isAdmin });
              if (dta.isAdmin) {
                //forward to admin view
                self.$router.push("/dashboard/adminhome");
              } else {
                //forward to office user view
                self.$router.push("/dashboard/home");
              }
            }
          } else {
            self.alert = true;
            self.alertTxt = "Unknow error !!!. Please try again.";
          }
          self.loading = false;
        });
      });
    },
    async digestMessage(message) {
      const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
      const hashBuffer = await crypto.subtle.digest("SHA-512", msgUint8); // hash the message
      const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
      const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join(""); // convert bytes to hex string
      return hashHex;
    },
  },
  computed: {
    showIco() {
      if (this.showPass) {
        return "mdi-eye-off-outline";
      } else {
        return "mdi-eye-outline";
      }
    },
  },
};
</script>

<style scoped>
.login-form {
  max-width: 500px;
}
.bg {
  /* background: url("../assets/logbg.jpg") no-repeat center center fixed !important; */
  background: url("../assets/logbg3.webp") no-repeat center center fixed !important;
  background-size: cover;
}
</style>
