<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5">
        <h2>Active Complaints</h2>
        <v-divider class="mt-3 mb-3"></v-divider>
        <v-text-field
          class="mb-5"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="getComplaints"
          class="elevation-1"
          :search="search"
          @click:row="actionDialog"
        >
          <template v-slot:top>
            <ComplaintInfoDialog
              title="Complaint Information"
              :dialog="dialog"
              :summary-table="tableData"
              :party-list="complaintParties"
              :comp-updates-arr="updatesArray"
              type="update"
              @closedialog="closeDialog()"
            />
          </template>
          <template v-slot:[`item.complain_type_id`]="{ item }">
            <span v-if="item.complain_type_id < 5">From</span>
            {{ getComplatTypeName(item.complain_type_id) }}
          </template>
          <template v-slot:[`item.ref`]="{ item }">
            <strong> C{{ item.ref }}</strong>
          </template>
          <template v-slot:[`item.complainant`]="{ item }">
            {{
              item.complainant_type
                | formatComplanant(item.complainant_id, item.complainant_obj)
            }}
          </template>
          <template v-slot:[`item.complainee`]="{ item }">
            {{
              item.complainee_type
                | formatComplanant(item.complainee_id, item.complainee_obj)
            }}
          </template>
          <template v-slot:[`item.incident_date_time`]="{ item }">
            {{ item.created_on | noDec }}
          </template>
          <template v-slot:[`item.count`]="{ item }">
            <span style="color: rgb(255, 180, 51)">{{ item.count }} </span>
          </template>
          <template v-slot:[`item.staff`]="{ item }">
            {{
              item.staff_obj.title +
              " " +
              item.staff_obj.fname +
              " " +
              item.staff_obj.lname +
              " - " +
              item.staff_id
            }}
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import {
  GET_ACTIVE_COMPLAINTS,
  GET_COMPLAINT_PARTIES,
  M_OU,
  M_DD,
  LOAD_ACTIVE_COMPLAINTS,
  GET_COMPLAINT_UPDATES,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
import ComplaintInfoDialog from "@/components/Complaint/ComplaintInfoDialog";
export default {
  name: "ViewActiveComplaints",
  components: { ComplaintInfoDialog },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Active Complaints",
          disabled: true,
          href: "/dashboard/viewactivecomplaints",
        },
      ],
      headers: [
        { text: "Reference", value: "ref" },
        { text: "Complaint Type", value: "complain_type_id" },
        { text: "Complainant", value: "complainant" },
        { text: "Complainee", value: "complainee" },
        { text: "Date & Time", value: "incident_date_time" },
        { text: "Days", value: "count" },
        { text: "Staff - ID", value: "staff" },
      ],
      search: "",
      dialog: false,
      tableData: [],
      updatesArray: [],
    };
  },
  computed: {
    ...mapGetters({
      getComplaints: `${M_OU}${GET_ACTIVE_COMPLAINTS}`,
      complaintParties: `${M_DD}${GET_COMPLAINT_PARTIES}`,
    }),
  },
  filters: {
    formatComplanant: function (value, arg1, arg2) {
      if (!value) return "";
      if (value == 1 || value == 2) {
        return arg2.title + " " + arg2.fname + " " + arg2.lname;
      }
      if (value == 3) {
        return "Driving School";
      }
      if (value == 4) {
        return "Third Party";
      }
      if (value == 5) {
        return "Other";
      }
    },
  },
  methods: {
    ...mapActions({
      loadActiveComplaints: `${M_OU}${LOAD_ACTIVE_COMPLAINTS}`,
      getUpdates: `${M_OU}${GET_COMPLAINT_UPDATES}`,
    }),
    getComplatTypeName(id) {
      if (!id) return null;
      let temp = this.complaintParties.find((item) => item.id == id);
      if (temp) {
        return temp.txt;
      }
      return null;
    },
    actionDialog(row) {
      this.getUpdates({ id: row.ref })
        .then((rep) => {
          if (rep) {
            this.updatesArray = rep;
          } else {
            this.updatesArray = [];
          }
        })
        .finally(() => {
          this.tableData = row;
          this.dialog = true;
        });
      //console.log(row);
    },
    closeDialog() {
      this.updatesArray = null;
      this.tableData = null;
      this.dialog = false;
      this.loadActiveComplaints();
    },
  },
  created() {
    this.loadActiveComplaints();
  },
};
</script>

<style></style>
