<template>
  <v-col :md="colSize" class="mx-auto mb-5">
    <h4 class="mb-5">{{ title }}</h4>
    <v-card class="mx-auto"
      ><v-simple-table v-if="tableData">
        <template v-slot:default>
          <thead>
            <tr class="text-center">
              <th
                v-for="(th, thkey) in getHeaddings"
                :key="thkey"
                class="text-center"
              >
                {{ th }}
              </th>
              <th class="text-center" v-if="isPrecentage">Precentage</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" v-for="(td, kk) in getRecords" :key="kk">
              <td v-for="(rec, valk) in valueKeys" :key="valk">
                {{ td[rec] }}
              </td>

              <td v-if="isPrecentage">
                {{ td[totFieldKey] | precentage(total) }}
              </td>
            </tr>
            <tr v-if="tableData.length == 0">
              <th class="text-center" colspan="5">No Data to display</th>
            </tr>
            <tr v-if="total" class="tots">
              <th class="text-center" :colspan="totalCospan">Total</th>
              <th class="text-center">{{ total }}</th>
              <th class="text-center" v-if="isPrecentage">100%</th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p v-else class="warning">No Data Provided</p></v-card
    ><v-divider />
  </v-col>
</template>

<script>
export default {
  name: "DynamicGeneralDataTable",
  props: [
    "tableData",
    "title",
    "colSize",
    "headdings",
    "total",
    "isPrecentage",
    "valueKeys",
    "totFieldKey", // this field hold the key for totals to count precenrage
  ],
  computed: {
    getHeaddings() {
      if (this.headdings) {
        if (Array.isArray(this.headdings)) {
          return this.headdings;
        } else {
          //object passed

          return Object.values(this.headdings);
        }
      }
      return [];
    },
    getRecords() {
      if (this.tableData) {
        let retarr = [];
        this.tableData.forEach((el) => {
          retarr.push(Object.values(el));
        });
        return retarr;
        //Object.values(this.tableData);
      }
      return [];
    },
    totalCospan() {
      if (this.isPrecentage) {
        return 2;
      }
      return 1;
    },
  },
};
</script>

<style></style>
