<template>
  <div>
    <h2 class="mb-5">{{ this.$route.params.obj.repName.text }}</h2>
    <v-divider class="my-10" />
    <v-row v-if="subreport <= 2">
      <ComplaintsTable
        tableTitle="Complaint Types"
        :tableData="allData.report_data"
        :tots="tots"
        :tableType="tableType"
        :tblSize="5"
      /><ComplantStatusTable
        v-if="allData.status_data"
        tableTitle="Complaints Status Breakdown"
        :tableData="allData.status_data"
        :tots="tots"
        :tableType="tableType"
        :tblSize="4"
      /><ComplaintsGeoAreasTable
        v-if="allData.outerpc_data"
        tableTitle="Complaints Postcode Breakdown"
        :tableData="allData.outerpc_data"
        :tots="tots"
        :tableType="tableType"
        :tblSize="3"
      />
    </v-row>
    <v-row v-if="subreport <= 2">
      <v-col cols="md-12">
        <ComplaintsColumnChart
          :tableData="allData.report_data"
          vAxisLable="Number Of Complaints"
          hAxisLable="Complaint Types"
      /></v-col>
    </v-row>
    <v-row v-if="subreport == 3">
      <v-card class="mx-auto"
        ><v-simple-table v-if="allData.report_data">
          <template v-slot:default>
            <thead>
              <tr class="text-center">
                <th class="text-left">Complaint Type</th>
                <th class="text-center">Number of Complaints</th>
                <th class="text-center">Complaints Precentage</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rec, i) in allData.report_data"
                :key="i"
                class="text-center"
              >
                <td class="text-left">{{ rec.complaint_type }}</td>
                <td>{{ rec.total }}</td>
                <td>{{ rec.total | precentage(timeRangesTots) }}</td>
              </tr>
              <tr class="tots">
                <th class="text-center">Total</th>
                <th class="text-center">{{ timeRangesTots }}</th>
                <th class="text-center">
                  {{ timeRangesTots | precentage(timeRangesTots) }}
                </th>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p v-else class="warning">No Data Provided</p></v-card
      ></v-row
    >
    <v-row v-if="subreport == 3">
      <v-col cols="md-12">
        <ComplaintsLineChart
          vAxisLable="Number Of Complaints"
          hAxisLable="Time Period"
          :dataTitles="['Time', 'Number of complaints']"
          :dataVals="timeRnagesChardDataVals"
      /></v-col>
    </v-row>
    <v-row v-if="subreport == 4">
      <v-card class="mx-auto"
        ><v-simple-table v-if="allData.report_data">
          <template v-slot:default>
            <thead>
              <tr class="text-center">
                <th class="text-left">Complaint Type</th>
                <th class="text-center">Number of Resolved Complaints</th>
                <th class="text-center">Complaints Precentage</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rec, i) in allData.report_data"
                :key="i"
                class="text-center"
              >
                <td class="text-left">{{ rec.complaint_type }}</td>
                <td>{{ rec.total }}</td>
                <td>{{ rec.total | precentage(timeRangesTots) }}</td>
              </tr>
              <tr class="tots">
                <th class="text-center">Total</th>
                <th class="text-center">{{ timeRangesTots }}</th>
                <th class="text-center">
                  {{ timeRangesTots | precentage(timeRangesTots) }}
                </th>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p v-else class="warning">No Data Provided</p></v-card
      ></v-row
    >
    <v-row v-if="subreport == 4">
      <v-col cols="md-12">
        <ComplaintsLineChart
          vAxisLable="Number Of Resolutions"
          hAxisLable="Time Period"
          :dataTitles="['Time', 'Number of Resolved Complaints']"
          :dataVals="timeRnagesChardDataVals"
      /></v-col>
    </v-row>
    <v-row v-if="subreport == 5">
      <v-card class="mx-auto"
        ><v-simple-table v-if="allData.report_data">
          <template v-slot:default>
            <thead>
              <tr class="text-center">
                <th class="text-left">Complaint Type</th>
                <th class="text-center">Number of Resolved Complaints</th>
                <th class="text-center">Complaints Precentage</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rec, i) in allData.report_data"
                :key="i"
                class="text-center"
              >
                <td class="text-left">{{ rec.complaint_type }}</td>
                <td>{{ rec.total }}</td>
                <td>{{ rec.total | precentage(timeRangesTots) }}</td>
              </tr>
              <tr class="tots">
                <th class="text-center">Total</th>
                <th class="text-center">{{ timeRangesTots }}</th>
                <th class="text-center">
                  {{ timeRangesTots | precentage(timeRangesTots) }}
                </th>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p v-else class="warning">No Data Provided</p></v-card
      ></v-row
    >
    <v-row v-if="subreport == 5">
      <v-col cols="md-12">
        <ComplaintsLineChart
          vAxisLable="Number Of Complaints"
          hAxisLable="Time Period"
          :dataTitles="['Time', 'Number of Unresolved Complaints']"
          :dataVals="timeRnagesChardDataVals"
      /></v-col>
    </v-row>
    <v-row v-if="subreport == 6">
      <v-card class="mx-auto"
        ><v-simple-table v-if="allData.report_data">
          <template v-slot:default>
            <thead>
              <tr class="text-center">
                <th class="text-left">Complaint Type</th>
                <th class="text-center">Number of Complaints</th>
                <th class="text-center">Complaints Precentage</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rec, i) in allData.report_data"
                :key="i"
                class="text-center"
              >
                <td class="text-left">{{ rec.complaint_type }}</td>
                <td>{{ rec.total }}</td>
                <td>{{ rec.total | precentage(timeRangesTots) }}</td>
              </tr>
              <tr class="tots">
                <th class="text-center">Total</th>
                <th class="text-center">{{ timeRangesTots }}</th>
                <th class="text-center">
                  {{ timeRangesTots | precentage(timeRangesTots) }}
                </th>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p v-else class="warning">No Data Provided</p></v-card
      ></v-row
    >

    <v-row v-if="subreport == 6">
      <v-col cols="md-12">
        <ComplaintsLineChart
          vAxisLable="Number Of Complaints"
          hAxisLable="Time Period"
          :dataTitles="timeRnagesChardDataTitles"
          :dataVals="timeRnagesChardDataVals"
      /></v-col>
    </v-row>
    <v-row v-if="subreport == 7">
      <v-card class="mx-auto"
        ><v-simple-table v-if="allData.report_data">
          <template v-slot:default>
            <thead>
              <tr class="text-center">
                <th class="text-left">Complaint Type</th>
                <th class="text-center">Average Days To Resolve</th>
                <th class="text-center">Days Taken Precentage</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rec, i) in allData.report_data"
                :key="i"
                class="text-center"
              >
                <td class="text-left">{{ rec.complaint_type }}</td>
                <td>{{ rec.total }}</td>
                <td>{{ rec.total | precentage(timeRangesTots) }}</td>
              </tr>
              <tr class="tots">
                <th class="text-center">Total</th>
                <th class="text-center">{{ timeRangesTots }}</th>
                <th class="text-center">
                  {{ timeRangesTots | precentage(timeRangesTots) }}
                </th>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p v-else class="warning">No Data Provided</p></v-card
      ></v-row
    >

    <v-row v-if="subreport == 7">
      <v-col cols="md-12">
        <ComplaintsLineChart
          vAxisLable="Average Days"
          hAxisLable="Time Period"
          :dataTitles="timeRnagesChardDataTitles"
          :dataVals="timeRnagesChardDataVals"
      /></v-col>
    </v-row>
  </div>
</template>

<script>
import ComplaintsTable from "@/components/Reports/Tables/ComplaintsTable.vue";
import ComplantStatusTable from "@/components/Reports/Tables/ComplaintStatusTable.vue";
import ComplaintsGeoAreasTable from "@/components/Reports/Tables/ComplaintsGeoAreasTable.vue";
import ComplaintsColumnChart from "@/components/Reports/Charts/ComplaintsColumnChart.vue";
import ComplaintsLineChart from "@/components/Reports/Charts/ComplaintsLineChart.vue";
export default {
  name: "ComplaintsReportView",
  components: {
    ComplaintsTable,
    ComplantStatusTable,
    ComplaintsGeoAreasTable,
    ComplaintsColumnChart,
    ComplaintsLineChart,
  },
  props: ["allData", "tableData", "subreport", "statusData", "geoData"],
  computed: {
    tableType() {
      if (this.subreport == 1) {
        return "Active";
      }
      return "Resolved";
    },
    tots() {
      if (this.allData.report_data) {
        let c = 0,
          s = 0,
          a = 0;
        this.allData.report_data.forEach((rec) => {
          c += rec.complaints_count;
          s += rec.self_resolve_count;
          a += rec.average_duration * rec.complaints_count;
        });
        return { comp: c, selfres: s, duration: a };
      }
      return { comp: 0, selfres: 0, duration: 0 };
    },
    timeRangesTots() {
      if (this.allData.report_data) {
        let t = 0;
        this.allData.report_data.forEach((rec) => {
          t += parseInt(rec.total);
        });
        return t;
      }
      return 0;
    },
    timeRnagesChardDataVals() {
      if (this.allData.chart_data) {
        let retArr = [];
        //the type list can be any leght so need to get all the titles first then loop through to mach title and count
        let compTypesArr = [];

        //this loop will collect all comp types to one array
        // this.allData.chart_data.forEach((rec) => {
        //   compTypesArr.push(rec.type);
        // });
        // not using loops we are using maps now
        compTypesArr = [...new Set(this.allData.chart_data.map((a) => a.type))]; //used set to get only unique ones
        //let loop though and fill the google chat array
        this.allData.chart_data.forEach((rec) => {
          let inArry = [];
          inArry.push(rec.recorded_on);
          //loop though unique elements
          for (const i in compTypesArr) {
            if (compTypesArr[i] == rec.type) {
              //if matches add the total
              inArry.push(rec.total);
            } else {
              //add 0 if no match
              inArry.push(0);
            }
          }
          //add to returning array
          retArr.push(inArry);
        });

        return retArr;
      }

      /*let retAr = [];
      if (this.tableData) {
        this.tableData.forEach((rec) => {
          //we need to change array of object to array of array that google charts can process
          //retAr.push([)
        });
        return ["Time", "Compaint Type"];
      }*/
      return [];
    },
    timeRnagesChardDataTitles() {
      if (this.allData.chart_data) {
        //the type list can be any leght so need to get all the titles first then loop through to mach title and count
        let compTypesArr = [];

        compTypesArr = [...new Set(this.allData.chart_data.map((a) => a.type))]; //used set to get only unique ones
        compTypesArr.unshift("Time");

        return compTypesArr;
      }
      return [];
    },
  },
};
</script>
