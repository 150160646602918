<template>
  <div>
    <!-- ADI Vehicle report -->
    <div v-if="reportNo == 1">
      <h2 class="my-5">Instructor Vehicle</h2>
      <v-divider class="my-5"></v-divider>
      <v-row>
        <GeneralDataTable
          title="Vehicle Gear Types"
          :tableData="tableData.gear_type"
          :colSize="4"
          keyHedding="Gear Type"
          valueHedding="Number of Vehicles"
          :total="getTotal(tableData.gear_type)"
          :isPrecentage="true"
        />
        <GeneralDataTable
          title="Vehicle Duel Controls"
          :tableData="tableData.dual_ctrl"
          :colSize="4"
          keyHedding="Duel Control"
          valueHedding="Number of Vehicles"
          :total="getTotal(tableData.dual_ctrl)"
          :isPrecentage="true"
        />
        <GeneralDataTable
          title="Vehicle Fuel Types"
          :tableData="tableData.fuel_type"
          :colSize="4"
          keyHedding="Fuel Type"
          valueHedding="Number of Vehicles"
          :total="getTotal(tableData.fuel_type)"
          :isPrecentage="true"
        />
        <GeneralDataTable
          title="Makes"
          :tableData="tableData.makes"
          :colSize="4"
          keyHedding="Make"
          valueHedding="Number of Vehicles"
          :total="getTotal(tableData.makes)"
          :isPrecentage="true"
        />
        <GeneralDataTable
          title="Vehicle Types"
          :tableData="tableData.body_type"
          :colSize="4"
          keyHedding="Type"
          valueHedding="Number of Vehicles"
          :total="getTotal(tableData.body_type)"
          :isPrecentage="true"
        />
        <GeneralDataTable
          title="Vehicle Registration Years"
          :tableData="tableData.reg_year"
          :colSize="4"
          keyHedding="Registration Year"
          valueHedding="Number of Vehicles"
          :total="getTotal(tableData.reg_year)"
          :isPrecentage="true"
        />
      </v-row>
      <v-row>
        <!-- <v-divider /> -->
        <VehicleDataColumnChart
          :gearRatios="tableData.gear_type"
          :duelCtrlRatios="tableData.dual_ctrl"
          :fuelRatios="tableData.fuel_type"
          :makeRatios="tableData.makes"
          :shapeRatios="tableData.body_type"
          :yearRatios="tableData.reg_year"
        />
      </v-row>
    </div>
    <!-- ADI Teaching report -->
    <div v-if="reportNo == 2">
      <h2 class="my-5">Instructor Teaching Genders & Secondary Languages</h2>
      <v-divider class="my-5"></v-divider>
      <v-row>
        <GeneralDataTable
          title="Teaching Secondary Language(s)"
          :tableData="tableData.adi_per_lang"
          :colSize="4"
          keyHedding="Secondary Language"
          valueHedding="Number of ADIs"
          :total="getTotal(tableData.adi_per_lang)"
          :isPrecentage="true"
        />
        <GeneralDataTable
          title="Learners Per Secondary Language"
          :tableData="tableData.learner_per_lang"
          :colSize="4"
          keyHedding="Learner Requested Language"
          valueHedding="Number of Learners"
          :total="getTotal(tableData.learner_per_lang)"
          :isPrecentage="true"
        />

        <GeneralDataTable
          title="Teaching Genders"
          :tableData="tableData.adi_per_teaching_gender"
          :colSize="4"
          keyHedding="Teaching Genders"
          valueHedding="Number of ADIs"
          :total="getTotal(tableData.adi_per_teaching_gender)"
          :isPrecentage="true"
        />
      </v-row>
      <v-row>
        <!-- This one is a special alterd data table just use the general data component -->
        <GeneralDataTable
          title="Learner Secondary Language(s) by Postcode"
          :tableData="convertToKeyCount(tableData.learner_pc_by_lang)"
          :colSize="12"
          keyHedding="Postcode"
          valueHedding="Languages"
        />
      </v-row>
      <v-row>
        <v-divider />
        <InstructorTeachingChart
          :adiLang="tableData.adi_per_lang"
          :learnerLang="tableData.learner_per_lang"
          :teachGen="tableData.adi_per_teaching_gender"
        />
      </v-row>
    </div>
    <!-- ADI Gender and Age  report -->
    <div v-if="reportNo == 3">
      <h2 class="my-5">Instructor Genders & Age Categories</h2>
      <v-divider class="my-5"></v-divider>
      <v-row>
        <GeneralDataTable
          title="Instructor Age Categories"
          :tableData="tableData.adi_age_count"
          :colSize="6"
          keyHedding="Age Category"
          valueHedding="Number of ADIs"
          :total="getTotal(tableData.adi_age_count)"
          :isPrecentage="true"
        />
        <GeneralDataTable
          title="Instructor Gender Categories"
          :tableData="tableData.adi_gender_count"
          :colSize="6"
          keyHedding="Gender"
          valueHedding="Number of ADIs"
          :total="getTotal(tableData.adi_gender_count)"
          :isPrecentage="true"
        />
      </v-row>
      <v-row>
        <v-divider />
        <InstructorInfoChart
          :adiGender="tableData.adi_gender_count"
          :ageGroup="tableData.adi_age_count"
        />
      </v-row>
    </div>
    <!-- ADI Retention  report -->
    <div v-if="reportNo == 4">
      <h2 class="my-5">Instructor Retention</h2>
      <v-divider class="my-5"></v-divider>
      <v-row>
        <v-col md="12" class="mx-auto mb-5">
          <h4 class="mb-5">Instructor Time With Driving School</h4>
          <v-card class="mx-auto"
            ><v-simple-table v-if="tableData">
              <template v-slot:default>
                <thead>
                  <tr class="text-center">
                    <th class="text-center">Instructor Status</th>
                    <th class="text-center" colspan="2">
                      Number of Instructors
                    </th>
                    <!-- <th class="text-center">Precentage</th> -->
                    <th class="text-center" colspan="2">Minimum Time Period</th>
                    <th class="text-center" colspan="2">Maximum Time Period</th>
                    <th class="text-center" colspan="2">Average Time Period</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(rec, i) in tableData"
                    :key="i"
                    class="text-center"
                  >
                    <td>{{ rec.type }}</td>
                    <td>{{ rec.total }}</td>
                    <td>
                      {{ rec.total | precentage(getTotalPerColum.total) }}
                    </td>
                    <td>{{ rec.min }} Day{{ rec.min | plurelText }}</td>
                    <td>
                      {{ rec.min | precentage(getTotalPerColum.min) }}
                    </td>
                    <td>{{ rec.max }} Day{{ rec.max | plurelText }}</td>
                    <td>
                      {{ rec.max | precentage(getTotalPerColum.max) }}
                    </td>
                    <td>{{ rec.average }} Day{{ rec.average | plurelText }}</td>
                    <td>
                      {{ rec.average | precentage(getTotalPerColum.average) }}
                    </td>
                  </tr>
                  <tr v-if="tableData.length == 0">
                    <th class="text-center" colspan="5">No Data to display</th>
                  </tr>
                  <!-- <tr class="tots">
                    <th class="text-center">Total</th>
                    <th class="text-center">{{ total }}</th>
                  </tr> -->
                </tbody>
              </template>
            </v-simple-table>
            <p v-else class="warning">No Data Provided</p></v-card
          ><v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-divider />
        <InstructorRetentionChart :tableData="tableData" />
      </v-row>
    </div>
    <!-- ADI Postcode coverage and complaints-->
    <div v-if="reportNo == 5">
      <h2 class="my-5">
        Instructor Postcode Coverage & Complaints per Postcode
      </h2>
      <v-divider class="my-5"></v-divider>
      <v-row>
        <GeneralDataTable
          title="Instructor Postcode Coverage"
          :tableData="tableData.adi_per_pc"
          :colSize="6"
          keyHedding="Outer Postcode"
          valueHedding="Number of ADIs to Cover Postcode"
          :total="getTotal(tableData.adi_per_pc)"
          :isPrecentage="true"
        />
        <GeneralDataTable
          title="Instructor Complaints Levels per Postcode"
          :tableData="tableData.comp_per_pc"
          :colSize="6"
          keyHedding="Postcode"
          valueHedding="Number of Complaints"
          :total="getTotal(tableData.comp_per_pc)"
          :isPrecentage="true"
        />
      </v-row>
      <v-row>
        <v-divider />
        <InstructorPCandComplaintChart
          :pcData="tableData.adi_per_pc"
          :compData="tableData.comp_per_pc"
        />
      </v-row>
    </div>
    <!-- ADI Total lesson provided report -->
    <div v-if="reportNo == 6">
      <h2 class="my-5">Number for Lessons Provided</h2>
      <v-divider class="my-5"></v-divider>
      <v-row>
        <DynamicGeneralDataTable
          title="Number for Lessons Provided"
          :tableData="tableData.completed_lessons_count"
          :colSize="6"
          :headdings="['Time Range', 'Number of Lessons']"
          :valueKeys="[1, 0]"
          :total="getTotal(tableData.completed_lessons_count)"
          :totFieldKey="1"
          :isPrecentage="false"
        />
      </v-row>
      <v-row>
        <v-divider />
        <FeedbackLineChart
          chartTitle="Instructor Genders"
          chartSubTitle="Instructor Gender Totals By Time"
          :dataTitles="['Time Range', 'Number of Lessons']"
          :dataVals="convertToSingleLineData(tableData.completed_lessons_count)"
        />
      </v-row>
    </div>
    <!-- ADI Total lesson rescheduled report -->
    <div v-if="reportNo == 7">
      <h2 class="my-5">Number for Lessons Rescheduled</h2>
      <v-divider class="my-5"></v-divider>
      <v-row>
        <DynamicGeneralDataTable
          title="Number for Lessons Rescheduled"
          :tableData="tableData.rescheduled_lessons_count"
          :colSize="6"
          :headdings="['Time Range', 'Number of Lessons']"
          :valueKeys="[1, 0]"
          :total="getTotal(tableData.rescheduled_lessons_count)"
          :totFieldKey="1"
          :isPrecentage="false"
        />
      </v-row>
      <v-row>
        <v-divider />
        <FeedbackLineChart
          chartTitle="Instructor Genders"
          chartSubTitle="Instructor Gender Totals By Time"
          :dataTitles="['Time Range', 'Number of Lessons']"
          :dataVals="
            convertToSingleLineData(tableData.rescheduled_lessons_count)
          "
        />
      </v-row>
    </div>
    <!-- ADI Gender report -->
    <div v-if="reportNo == 8">
      <h2 class="my-5">Instructor Genders</h2>
      <v-divider class="my-5"></v-divider>
      <v-row>
        <DynamicGeneralDataTable
          title="Instructor Genders "
          :tableData="tableData.adi_gender_count"
          :colSize="6"
          :headdings="['Time Range', 'Gender', 'Number of ADIs']"
          :valueKeys="[2, 0, 1]"
          :total="getTotal(tableData.adi_gender_count)"
          :totFieldKey="1"
          :isPrecentage="true"
        />
      </v-row>
      <v-row>
        <v-divider />
        <FeedbackLineChart
          chartTitle="Instructor Genders"
          chartSubTitle="Instructor Gender Totals By Time"
          :dataTitles="['Time Range', 'Female', 'Male']"
          :dataVals="convertToLineChartArray(tableData.adi_gender_count)"
        />
      </v-row>
    </div>
    <!-- ADI  Age  report -->
    <div v-if="reportNo == 9">
      <h2 class="my-5">Instructor Age</h2>
      <v-divider class="my-5"></v-divider>
      <v-row>
        <DynamicGeneralDataTable
          title="Instructor Age Categories"
          :tableData="tableData.adi_age_count"
          :colSize="6"
          :headdings="['Time Range', 'Age Category', 'Number of ADIs']"
          :valueKeys="[2, 0, 1]"
          :total="getTotal(tableData.adi_age_count)"
          :totFieldKey="1"
          :isPrecentage="true"
        />
      </v-row>
      <v-row>
        <v-divider />
        <FeedbackLineChart
          chartTitle="New Instructors Age Categories"
          chartSubTitle="New Instructor Age Categories"
          :dataTitles="
            getDataTitlesForLineChartAgeCats(tableData.adi_age_count)
          "
          :dataVals="convertToLineChartArrayAgeCats(tableData.adi_age_count)"
        />
      </v-row>
    </div>
    <!-- ADI  in geo areas  report -->
    <div v-if="reportNo == 10">
      <h2 class="my-5">Instructors In Geographical Ares</h2>
      <v-divider class="my-5"></v-divider>
      <v-row>
        <DynamicGeneralDataTable
          title="Instructor Geographical Areas"
          :tableData="
            joinArraysToGraphData(
              tableData.active_adi_pcs,
              tableData.inactive_adi_pcs,
              tableData.total_adi_pcs
            )
          "
          :colSize="6"
          :headdings="[
            'Postcode',
            'Active ADIs',
            'Inactive ADIs',
            'Total ADIs',
          ]"
          :valueKeys="[0, 2, 3, 1]"
          :total="getTotal(tableData.active_adi_pcs)"
          :totFieldKey="1"
          :isPrecentage="false"
        />
      </v-row>
      <v-row>
        <v-divider />
        <FeedbackLineChart
          chartTitle="New Instructors Age Categories"
          chartSubTitle="New Instructor Age Categories"
          :dataTitles="[
            'Outer Postcode',
            'Total ADIs',
            'Active ADIs',
            'Inactive ADIs',
          ]"
          :dataVals="
            joinArraysToGraphData(
              tableData.active_adi_pcs,
              tableData.inactive_adi_pcs,
              tableData.total_adi_pcs
            )
          "
        />
      </v-row>
    </div>
    <div v-if="reportNo == 11">
      <h2 class="my-5">Instructor Departed the Company</h2>
      <v-divider class="my-5"></v-divider>
      <v-row>
        <GeneralDataTable
          title="Departed Driving Instructors"
          :tableData="convertToKeyCountDepartedADIs(tableData.departed_adis)"
          :colSize="4"
          keyHedding="Date Departed"
          valueHedding="Number Of Departed Driving Instructors"
          :total="
            getTotal(convertToKeyCountDepartedADIs(tableData.departed_adis))
          "
          :isPrecentage="true"
        />
      </v-row>
      <v-row>
        <v-divider />
        <FeedbackLineChart
          chartTitle="Departed Instructors"
          chartSubTitle="Departed Instructor Totals"
          :dataTitles="['Time', 'Number of Instructors']"
          :dataVals="getTotalsChartValuesDepartedADIs"
        />
      </v-row>
    </div>
    <!-- Total number of new instructors -->
    <div v-if="reportNo == 12">
      <GeneralDataTable
        title="New Driving Instructors"
        :tableData="convertToKeyCountNewADIs(tableData.new_adis)"
        :colSize="4"
        keyHedding="Date Joined"
        valueHedding="Number Of New Driving Instructors"
        :total="getTotal(convertToKeyCountNewADIs(tableData.new_adis))"
        :isPrecentage="true"
      />
      <FeedbackLineChart
        chartTitle="New Instructors"
        chartSubTitle="New Instructor Totals"
        :dataTitles="['Time', 'Number of New Instructors']"
        :dataVals="getTotalsChartValues"
      />
    </div>
  </div>
</template>

<script>
import GeneralDataTable from "@/components/Reports/Tables/GeneralDataTable.vue";
import VehicleDataColumnChart from "@/components/Reports/Charts/VehicleDataColumnChart.vue";
import InstructorTeachingChart from "@/components/Reports/Charts/InstructorTeachingChart.vue";
import InstructorInfoChart from "@/components/Reports/Charts/InstructorInfoChart.vue";
import InstructorRetentionChart from "@/components/Reports/Charts/InstructorRetentionChart.vue";
import InstructorPCandComplaintChart from "@/components/Reports/Charts/InstructorPCandComplaintChart.vue";
import FeedbackLineChart from "@/components/Reports/Charts/FeedbackLineChart";
import DynamicGeneralDataTable from "@/components/Reports/Tables/DynamicGeneralDataTable.vue"; //new dynamic table

export default {
  name: "InstructorReportView",
  props: ["tableData", "reportNo"],
  components: {
    GeneralDataTable,
    VehicleDataColumnChart,
    InstructorTeachingChart,
    InstructorInfoChart,
    InstructorRetentionChart,
    InstructorPCandComplaintChart,
    FeedbackLineChart,
    DynamicGeneralDataTable,
  },
  methods: {
    getTotal(arr) {
      if (arr) {
        let t = 0;
        arr.forEach((rec) => {
          t += rec.count;
        });
        return t;
      }
      return 0;
    },
    convertToKeyCount(objArr) {
      let ret = [];
      if (objArr) {
        objArr.forEach((rec) => {
          ret.push({ key: rec.postcode, count: rec.langs });
        });
      }
      return ret;
    },
    convertToKeyCountNewADIs(objArr) {
      let ret = [];
      if (objArr) {
        objArr.forEach((rec) => {
          ret.push({ key: rec.joined_on, count: rec.no_adis });
        });
      }
      return ret;
    },
    convertToKeyCountDepartedADIs(objArr) {
      let ret = [];
      if (objArr) {
        objArr.forEach((rec) => {
          ret.push({ key: rec.departed_on, count: rec.no_adis });
        });
      }
      return ret;
    },
    getTotalByColumn(col, arr) {
      if (!col) return 0;
      let t = 0;
      arr.forEach((rec) => {
        t += rec[col];
      });
      return t;
    },
    //this method will accept object array and convert all data in correct order to dispay
    convertToLineChartArray(ObjArr) {
      if (ObjArr) {
        let retAr = [];
        let convArry = [];
        //step 1 add all the dates to array
        ObjArr.forEach((element) => {
          convArry.push([element["dates"], element["key"], element["count"]]);
        });

        //new array style will be ["date", "female", "male"] ,["2020-01-04", 0,1]
        convArry.forEach((rec) => {
          const res = ObjArr.filter((aDate) => aDate.dates == rec[0]);

          if (res.length < 2) {
            //only contains that one type of Gender
            if (rec[1] == "Male") {
              retAr.push([rec[0], 0, res[0].count]); //we are adding 0 to female
            } else {
              retAr.push([rec[0], res[0].count, 0]); //we are adding 0 to male
            }
          } else {
            //both genders presents
            //need to check wich one is male and female
            //but just to prevent duplicates find date in retAr
            const found = retAr.find((elem) => elem[0] === rec[0]);
            if (!found) {
              //did not find it.
              retAr.push([rec[0], res[0].count, res[1].count]);
            }
            //else ignore ro prevent duplicates
          }
        });

        return retAr;
      }
      return [];
    },
    convertToLineChartArrayAgeCats(ObjArr) {
      if (ObjArr) {
        let retAr = [];
        let convArry = []; //tis recover dates
        let convArryUniqe = []; //tis recover uniqe dates
        let ageCats = []; //this recover uniqe agecats
        let compArr = []; //this array compressed to one date
        //step 1 add all the dates to array
        ObjArr.forEach((element) => {
          convArry.push([element["dates"], element["key"], element["count"]]);
          convArryUniqe.push(element["dates"]);
          ageCats.push(element["key"]);
        });

        ageCats = [...new Set(ageCats)];
        convArryUniqe = [...new Set(convArryUniqe)];
        convArryUniqe.forEach((rec) => {
          convArry.forEach((orRec) => {
            if (orRec[0] === rec) {
              let tempar = []; //the array keeps the one record

              ageCats.forEach((cat) => {
                if (orRec[1] === cat) {
                  tempar.push(orRec[2]);
                } else {
                  tempar.push(0);
                }
              });

              tempar.unshift(orRec[0]); //add the data as first element

              retAr.push(tempar);
            }
          });
        });

        //new array style will be ["date", "65 +", "21-23", etc...] ,["2020-01-04", 0,1, etc...]
        //need to merge same dates to make this work
        //make sure there is more than 1 line
        if (retAr.length > 1) {
          convArryUniqe.forEach((uniq) => {
            const res = retAr.filter((aDate) => aDate[0] == uniq);
            if (res.length > 1) {
              compArr.push(this.joinArrayVale(res));
            } else {
              compArr.push(res[0]);
            }
          });
        }

        //console.table(compArr);

        return compArr;
      }
      return [];
    },
    getDataTitlesForLineChartAgeCats(ObjArr) {
      if (ObjArr) {
        let ageCats = []; //this recover uniqe agecats
        ObjArr.forEach((element) => {
          ageCats.push(element["key"]);
        });
        ageCats = [...new Set(ageCats)];
        ageCats.unshift("Time Range");

        return ageCats;
      }
      return [];
    },
    //this method join two arrays
    joinArrayVale(arrOfArray) {
      if (arrOfArray) {
        //retAr.push(arrOfArray[0][0]); //first element is common so use it directly
        //number of sub arrays
        const totArrays = arrOfArray.length;
        //legth of sub arry
        const arLeng = arrOfArray[0].length;

        let retAr = Array(arLeng - 1).fill(0);
        let firstElement = arrOfArray[0][0];

        //let strippedArray = [];
        arrOfArray.forEach((ar) => {
          ar.shift();
        });

        for (let index = 0; index < totArrays; index++) {
          //arrOfArray[index].unshift();
          for (let j = 0; j < arLeng - 1; j++) {
            retAr[j] = retAr[j] + arrOfArray[index][j];
          }
        }
        retAr.unshift(firstElement); //first element is common so use it directly

        return retAr;
      }
      return [];
    },
    //this will be multicoumln array for graph data
    joinArraysToGraphData(arry1, arry2, arry3) {
      //arry4 got all the combined data
      if (arry1 && arry2 && arry3) {
        let AllOutPCs = []; //this will  contain all postcodes
        let retArray = []; //this is the returning array
        arry3.forEach((ele) => {
          AllOutPCs.push(ele.outer_pc);
        });

        AllOutPCs.forEach((pc) => {
          let tempLine = [];
          tempLine.push(pc); //lets push the postcode first

          //lets push the totals now

          //filter thought array 3 <-- totals
          const allPCs = arry3.filter((aPC) => aPC.outer_pc == pc);
          if (allPCs.length) {
            //there is a record
            //lets access 0 index
            tempLine.push(allPCs[0].no_adis); //lets push the total adis
          } else {
            //neve gonna reach this but is there if issues
          }

          //filter thought array 1 <-- active
          const actPCs = arry1.filter((aPC) => aPC.outer_pc == pc);
          if (actPCs.length) {
            //there is a record
            //llets access 0 index
            tempLine.push(actPCs[0].no_adis); //lets push the active adis
          } else {
            tempLine.push(0); //lets push the active adis 0
          }
          //filter thought array 2 <-- inactive
          const inactPCs = arry2.filter((aPC) => aPC.outer_pc == pc);
          if (inactPCs.length) {
            //there is a record
            //llets access 0 index
            tempLine.push(inactPCs[0].no_adis); //lets push the inactive adis
          } else {
            tempLine.push(0); //lets push the active adis 0
          }
          //lets put the templine to return array
          retArray.push(tempLine);
        });

        //console.table(retArray);
        //heddings will be passed with different array to the component

        return retArray;
      }

      return [];
    },
    convertToSingleLineData(ObjArr) {
      if (ObjArr) {
        let retArr = [];
        ObjArr.forEach((rec) => {
          retArr.push([rec.dates, rec.count]);
        });
        return retArr;
      }
      return [];
    },
  },
  computed: {
    getTotalPerColum() {
      if (!this.tableData) return { total: 0, min: 0, max: 0, average: 0 };
      let total = 0,
        min = 0,
        max = 0,
        average = 0;
      this.tableData.forEach((rec) => {
        total += rec.total;
        min += rec.min;
        max += rec.max;
        average += rec.average;
      });
      return { total: total, min: min, max: max, average: average };
    },
    getTotalsChartValues() {
      if (this.tableData.new_adis) {
        let ret = [];
        this.tableData.new_adis.forEach((rec) => {
          ret.push([rec.joined_on, rec.no_adis]);
        });
        return ret;
      }
      return [];
    },
    getTotalsChartValuesDepartedADIs() {
      if (this.tableData.departed_adis) {
        let ret = [];
        this.tableData.departed_adis.forEach((rec) => {
          ret.push([rec.departed_on, rec.no_adis]);
        });
        return ret;
      }
      return [];
    },
  },
};
</script>

<style></style>
