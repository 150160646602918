<template>
  <v-app id="inspire">
    <LoadingBlockSpinner />
    <Navbar :nav-colour="changeingColour" :admin="isAdmin" />
    <v-main>
      <v-container fill-height class="grey lighten-5">
        <transition>
          <keep-alive>
            <router-view />
          </keep-alive>
        </transition>
      </v-container>
    </v-main>
    <Footer :footer-colour="changeingColour" />
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import LoadingBlockSpinner from "@/components/Spinners/LoadingBlock.vue";
import { IS_ADMIN, M_AUTH } from "@/store/mutation-list";
import { mapGetters } from "vuex";

export default {
  name: "Dashbord",
  components: { Navbar, Footer, LoadingBlockSpinner },
  data: () => ({
    drawer: false,
    group: null,
    messages: 10,
    show: false,
  }),
  computed: {
    //lets check if user is admin. then we change the colour of main bar
    ...mapGetters({
      isAdmin: `${M_AUTH}${IS_ADMIN}`,
    }),
    changeingColour() {
      if (this.isAdmin) {
        return "black";
      } else {
        //return "blue darken-4";
        return "";
      }
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style></style>
