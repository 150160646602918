<template>
  <div>
    <!-- <v-divider class="my-10" /> -->
    <h2 class="mb-5">
      <span v-html="TableType.text"></span>
      <span v-if="TableType.id == 1"></span>
      <!--span v-if="TableType.id == 12"
        >: {{ getTotalOfKey }} by {{ tableData.length }}
        {{ tableData.length | sfortext }}
      </span-->
    </h2>
    <v-divider class="my-10" />
    <v-row v-if="TableType.id == 2">
      <TimeElapsedTable :tableData="tableData" :isMain="true" />
    </v-row>
    <v-row v-if="TableType.id > 5 && TableType.id < 18">
      <LearnerTestTable :tableData="tableData" :isMain="true" />
    </v-row>
    <!--v-row v-if="TableType.id == 13">
      <GenderRatioTable :tableData="tableData" :isMain="true" />
      <AgeRatioTable :tableData="tableData" />
      <GeographicalAreasTable :tableData="tableData" />
    </v-row>
    <v-row v-else-if="TableType.id == 14">
      <GenderRatioTable :tableData="tableData" />
      <AgeRatioTable :tableData="tableData" :isMain="true" />
      <GeographicalAreasTable :tableData="tableData" />
    </v-row>
    <v-row v-else-if="TableType.id == 16">
      <GenderRatioTable :tableData="tableData" />
      <AgeRatioTable :tableData="tableData" />
      <GeographicalAreasTable :tableData="tableData" :isMain="true" />
    </v-row-->
    <v-row v-if="TableType.id == 21">
      <GearRatioTable :tableData="tableData" :isMain="true" />
      <AgeRatioTable :tableData="tableData" />
      <GeographicalAreasTable :tableData="tableData" />
      <GenderRatioTable :tableData="tableData" />
    </v-row>

    <v-row v-else>
      <GenderRatioTable :tableData="tableData" />
      <AgeRatioTable :tableData="tableData" />
      <GeographicalAreasTable :tableData="tableData" />
    </v-row>
  </div>
</template>

<script>
import AgeRatioTable from "@/components/Reports/Tables/AgeRatioTable";
import GenderRatioTable from "@/components/Reports/Tables/GenderRatioTable";
import GeographicalAreasTable from "@/components/Reports/Tables/GeographicalAreasTable";

import TimeElapsedTable from "@/components/Reports/Tables/TimeElapsedTable";
import LearnerTestTable from "@/components/Reports/Tables/LearnerTestTable";
import GearRatioTable from "@/components/Reports/Tables/GearRatioTable";
export default {
  name: "FrequencyTable",
  props: ["tableData", "TableType"],
  components: {
    GeographicalAreasTable,
    GenderRatioTable,
    AgeRatioTable,
    TimeElapsedTable,
    LearnerTestTable,
    GearRatioTable,
  },
  data() {
    return { switchRD: false };
  },
  computed: {
    getTotalOfKey() {
      if (this.tableData && this.TableType.id == 12) {
        return this.tableData.reduce((tot, obj) => obj.lessons + tot, 0);
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
.tots {
  text-transform: uppercase;
  font-size: 2rem;
  background-color: #f3f3f3;
}
</style>
