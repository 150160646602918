<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col><LearnerForm FormType="new" :frm-lock="!frmLocked" /> </v-col>
  </div>
</template>

<script>
import LearnerForm from "@/components/LearnerComps/LearnerForm";

export default {
  name: "RegLearner",
  components: { LearnerForm },
  data: () => ({
    items: [
      {
        text: "Dashboard",
        disabled: false,
        href: "/dashboard/home",
      },
      {
        text: "Register Learner",
        disabled: true,
        href: "/dashboard/registerlearner",
      },
    ],
    frmLocked: false,
  }),
};
</script>

<style></style>
