import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import Vuelidate from "vuelidate";
import VueSimpleAlert from "vue-simple-alert";
import "@/helpers/filters";

Vue.use(Vuelidate);
Vue.use(VueSimpleAlert);

//axios.defaults.withCredentials = true;
//axios.defaults.headers.common["Access-Control-Allow-Origin"] =  "https://ds.test";
axios.defaults.baseURL = "https://drive.brandiie.com/ctrl/";
/*axios.interceptors.request.use(
  (config) => {
    const token = store.getters["M_AUTH/TOKEN"];
    if (token) {
      config.data = { token: token };
      console.log(config.data);
    }
    return config;
  },
  (error) => {
    console.log(error);
    //return Promise.reject(error);
  }
);
*/
axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    console.log(error);
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("LogOut");
      return router.push("/");
    }
    if (error.response.status === 405 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("LogOut");
      return router.push("/");
    }
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("LogOut");
      return router.push("/");
    }
  }
});

Vue.mixin({
  methods: {
    can: (key) => store.getters["M_AUTH/getPermissions"].includes(key),
  },
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
