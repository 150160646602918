<template>
  <v-col md="4">
    <h3 class="mb-5">Age Ratios</h3>
    <v-card class="mx-auto" :elevation="important" :outlined="isMain"
      ><v-simple-table v-if="tableData">
        <template v-slot:default>
          <thead>
            <tr class="text-center">
              <th class="text-center">Age Ratio</th>
              <th class="text-center">Frequency</th>
              <th class="text-center">Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ratio, i) in getAgeRatios" :key="i" class="text-center">
              <td>{{ ratio[0] }}</td>
              <td>{{ ratio[1] }}</td>
              <td>{{ ratio[1] | precentage(tableData.length) }}</td>
            </tr>
            <tr class="tots">
              <th class="text-center">Total</th>
              <th class="text-center">{{ tableData.length }}</th>
              <th class="text-center">100%</th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p v-else class="warning">No Data Provided</p></v-card
    ></v-col
  >
</template>

<script>
export default {
  name: "AgeRatioTable",
  props: ["tableData", "isMain"],
  computed: {
    getAgeRatios() {
      let A = 0,
        B = 0,
        C = 0,
        D = 0,
        E = 0,
        F = 0;
      if (this.tableData) {
        this.tableData.forEach((rec) => {
          switch (rec.age_ratio) {
            case "A":
              A++;
              break;
            case "B":
              B++;
              break;
            case "C":
              C++;
              break;
            case "D":
              D++;
              break;
            case "E":
              E++;
              break;
            case "F":
              F++;
              break;

            default:
              break;
          }
        });
      }
      return {
        A: [`17 - 25 (A)`, A],
        B: [`26 - 35 (B)`, B],
        C: [`36 - 45 (C)`, C],
        D: [`46 - 55 (D)`, D],
        E: [`56 - 65 (E)`, E],
        F: [`65 & over (F)`, F],
      };
    },
    important() {
      if (this.isMain) {
        return 24;
      } else {
        return 2;
      }
    },
  },
};
</script>

<style></style>
