/* eslint-disable vue/valid-v-slot */
<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5"
        ><h2>Assign Driving Instructor to Learner</h2>
        <v-data-table
          :headers="headers"
          :items="adiPendingLearners"
          :search="keyword"
          class="elevation-1"
          @click:row="openSelect"
        >
          <template v-slot:top>
            <v-text-field
              v-model="keyword"
              label="Search"
              placeholder="Enter keyword to search"
              class="ma-4"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-dialog v-model="dialog" max-width="960px" scrollable persistent>
              <v-card>
                <v-card-title class="text-h5 blue lighten-2">
                  <span>Assign Learner</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-simple-table v-if="selectedLearner">
                      <thead>
                        <tr>
                          <th class="text-left" style="width: 20%"></th>
                          <th class="text-left" style="width: 80%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Learner ID</th>
                          <th>L{{ selectedLearner.student_id }}</th>
                        </tr>
                        <tr>
                          <th>Name</th>
                          <td>
                            {{ selectedLearner.title }}
                            {{ selectedLearner.fname }}
                            {{ selectedLearner.lname }}
                          </td>
                        </tr>
                        <tr>
                          <th>Mobile Number</th>
                          <td>
                            {{ selectedLearner.mobile | mobileno }}
                          </td>
                        </tr>
                        <tr>
                          <th>E-mail</th>
                          <td>{{ selectedLearner.email }}</td>
                        </tr>
                        <tr>
                          <th>Provisional Licence No.</th>
                          <td>
                            {{ selectedLearner.driving_licence_no }}
                          </td>
                        </tr>
                        <tr>
                          <th>Lesson Type</th>
                          <td style="text-transform: capitalize">
                            {{ selectedLearner.lessons_type }}
                          </td>
                        </tr>
                        <tr>
                          <th>Address</th>
                          <td>
                            {{ selectedLearner.address.line1 | commaForAddr }}
                            {{ selectedLearner.address.line2 | commaForAddr }}
                            {{ selectedLearner.address.city | commaForAddr }}
                            {{ selectedLearner.address.postcode }}
                          </td>
                        </tr>
                        <tr>
                          <th>Pickup Postcode</th>
                          <td>{{ selectedLearner.pickup_pc }}</td>
                        </tr>
                        <tr>
                          <th>Instructor</th>
                          <td>
                            <v-select
                              v-model="selectedADI"
                              :items="getSuitableADIs"
                              class="mt-6"
                              label="Suitable Instructor"
                              item-text="txt"
                              item-value="adi_id"
                              outlined
                              return-object
                              :loading="localLoading"
                              :disabled="localLoading"
                            ></v-select>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-container>
                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Close
                  </v-btn>
                  <v-btn color="primary darken-1" @click="acctionAssign">
                    Assign Instructor Now
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:[`item.student_id`]="{ item }">
            <strong>{{ "L" + item.student_id }}</strong>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.title + " " + item.fname + " " + item.lname }}
          </template>
          <template v-slot:[`item.mobile`]="{ item }">
            {{ item.mobile | mobileno }}
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_ADI_PENDING_LEARNERS,
  GET_SUITABLE_ADIS,
  M_OU,
  LOAD_ADI_PENDING_LEARNERS,
  SET_LEARNER_ADI,
} from "@/store/mutation-list";
export default {
  name: "AssignInstructor",
  //   components: { LearnerForm },
  data: () => {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Assign Instructor",
          disabled: true,
          href: "/dashboard/assigninstructor",
        },
      ],
      dialog: false,
      //passingID: null,
      selectedLearner: null,
      //data table realated data
      keyword: "",
      headers: [
        { text: "Learner ID", value: "student_id" },
        { text: "Name", value: "name" },
        { text: "Mobile", value: "mobile" },
        { text: "E-mail", value: "email" },
        { text: "Pickup PC", value: "pickup_pc" },
        { text: "Joined", value: "created" },
      ],
      sutableADIs: null,
      localLoading: false,
      selectedADI: null,
    };
  },
  computed: {
    ...mapGetters({
      adiPendingLearners: `${M_OU}${GET_ADI_PENDING_LEARNERS}`, //make sure to filter for active learners
    }),
    getSuitableADIs() {
      if (this.selectedLearner && this.sutableADIs) {
        //lets make it readable for v-select
        let temp = [];
        this.sutableADIs.forEach((adi) => {
          temp.push({
            adi_id: adi.adi_id,
            txt: `${adi.adi_title} ${adi.adi_fname} ${adi.adi_lname} (Contracted: ${adi.adi_contract_hours}h, Given: ${adi.adi_weekly_hours}h, Pending: ${adi.adi_pending_hours}h)`,
          });
        });
        return temp;
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      loadPendingLearners: `${M_OU}${LOAD_ADI_PENDING_LEARNERS}`,
      getSuitADIs: `${M_OU}${GET_SUITABLE_ADIS}`,
      setLearnerADI: `${M_OU}${SET_LEARNER_ADI}`,
    }),
    openSelect(learner) {
      //start the spinner
      this.localLoading = true;

      // get the adi list from server
      this.getSuitADIs({
        // the data that need to sent to store/server
        form: {
          learnerID: learner.student_id,
          pc: learner.pickup_pc,
        },
      }).then((res) => {
        //response from store. All complete just need to show to popup message
        //console.log(res);
        if (res) {
          this.sutableADIs = res.adis;
        } else {
          this.sutableADIs = [];
        }
        this.localLoading = false;
      });

      this.selectedLearner = learner;
      //do all the work then
      this.dialog = true;
    },
    acctionAssign() {
      //this funciton will asssign ADI
      //just need learner ID and adi id to do this
      if (this.selectedADI) {
        this.setLearnerADI({
          // the data that need to sent to store/server
          form: {
            selectedadi: this.selectedADI.adi_id,
            studentid: this.selectedLearner.student_id,
          },
        }).then((res) => {
          //response from store. All complete just need to show to popup message
          //console.log(res);
          if (res == "SUCCESS") {
            this.$fire({
              title: "Success",
              text: "ADI assigned successfuly. Book first lesson in Instructor diary",
              type: "success",
              timer: 3000,
            }).then((r) => {
              this.close();
              console.log(r.value);
            });
            this.loadPendingLearners();
          } else {
            this.$fire({
              title: "Error",
              text: "Error occured. Please try again",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        });
      }
    },

    close() {
      //remove data object
      this.sutableADIs = [];
      this.selectedADI = null;
      this.dialog = false;
    },
  },
  created() {
    this.loadPendingLearners();
  },
};
</script>

<style></style>
