<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <ListTable :pending-list="pendingFeedbacks" type="adi" />
    </v-col>
  </div>
</template>

<script>
import { M_OU, GET_PENDING_FEEDBACK_INSTRUCTORS } from "@/store/mutation-list";
import ListTable from "@/components/Feedback/ListTable.vue";
import { mapActions } from "vuex";
export default {
  name: "RecordInstructorFeedbacks",
  components: { ListTable },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Record Instructors Feedbacks About Learners",
          disabled: true,
          href: "/dashboard/recordinstructorfeedbacks",
        },
      ],
      pendingFeedbacks: [],
    };
  },
  methods: {
    ...mapActions({
      loadTable: `${M_OU}${GET_PENDING_FEEDBACK_INSTRUCTORS}`,
    }),
    actionLoad() {
      this.dataLoading = true;
      this.loadTable()
        .then((res) => {
          if (res) {
            console.log(res);
            this.pendingFeedbacks = res;
          } else {
            this.pendingFeedbacks = [];
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
  },
  created() {
    this.actionLoad();
  },
};
</script>

<style></style>
