<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5">
        <h2>Complaint Form</h2>
        <v-divider class="mt-3 mb-3"></v-divider>
        <v-form>
          <v-row justify="center">
            <v-col md="4"
              ><v-select
                v-model="form.complaintType"
                :items="complaintParties"
                label="Complaint Type"
                item-text="txt"
                item-value="id"
                outlined
                required
                :error-messages="compTypeError"
                @input="$v.form.complaintType.$touch()"
                @blur="$v.form.complaintType.$touch()"
                @change="updateCompPartiesList()"
              ></v-select>
              <!-- Complainant will be here -->
              <v-expand-transition>
                <div v-if="complainantLearner">
                  <v-text-field
                    v-model="form.complainant"
                    label="Complainant Learner"
                    outlined
                    readonly
                    append-icon="mdi-account-plus"
                    :error-messages="complainantError"
                    @input="$v.form.complainantID.$touch()"
                    @blur="$v.form.complainantID.$touch()"
                    @click:append="selectComplainantLearner()"
                  ></v-text-field>

                  <SelectFromTableDialog
                    :dialog="complainantLearnerDialog"
                    dialog-title="Learner"
                    :headers="tableHeaders"
                    :dataarray="tableData"
                    type="learner"
                    @closed="complainantLearnerDialog = false"
                    @choosen="complainantLearnerSelected($event)"
                  />
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="complainantADI">
                  <v-text-field
                    v-model="form.complainant"
                    label="Complainant Instructor"
                    outlined
                    readonly
                    append-icon="mdi-account-tie"
                    :error-messages="complainantError"
                    @input="$v.form.complainantID.$touch()"
                    @blur="$v.form.complainantID.$touch()"
                    @click:append="selectComplainantADI()"
                  ></v-text-field>
                  <SelectFromTableDialog
                    :dialog="complainantADIDialog"
                    dialog-title="Instructor"
                    :headers="tableHeaders"
                    :dataarray="tableData"
                    type="adi"
                    @closed="complainantADIDialog = false"
                    @choosen="ComplainantADISelected($event)"
                  />
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="complainantThirdP">
                  <v-textarea
                    value="(Pleasenote down all the information about this Third Party in the description below including their Full Name, Organisation Name, E-mail, Contact Numbers, Full Address and the Complaint.)"
                    label="Third Party"
                    outlined
                    disabled
                    no-resize
                    rows="3"
                  ></v-textarea>
                  <span></span>
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="complainantOther">
                  <v-textarea
                    value="Please provide all information in description) Please add complainant information in description (E-mail, Contact Number and Address)"
                    label="Other"
                    outlined
                    disabled
                    no-resize
                    rows="3"
                  ></v-textarea>
                  <span></span>
                </div>
              </v-expand-transition>
              <!-- Complainee will be here -->
              <v-expand-transition>
                <div v-if="complaineeLearner">
                  <v-text-field
                    v-model="form.complainee"
                    label="Complainee Learner"
                    outlined
                    readonly
                    :error-messages="complaineeError"
                    @input="$v.form.complaineeID.$touch()"
                    @blur="$v.form.complaineeID.$touch()"
                    append-icon="mdi-account-plus"
                    @click:append="selectComplaineeLearner()"
                  ></v-text-field>
                  <SelectFromTableDialog
                    :dialog="complaineeLearnerDialog"
                    dialog-title="Learner"
                    :headers="tableHeaders"
                    :dataarray="tableData"
                    type="learner"
                    @closed="complaineeLearnerDialog = false"
                    @choosen="ComplaineeLearnerSelected($event)"
                  />
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="complaineeADI">
                  <v-text-field
                    v-model="form.complainee"
                    label="Complainee Instructor"
                    outlined
                    readonly
                    :error-messages="complaineeError"
                    @input="$v.form.complaineeID.$touch()"
                    @blur="$v.form.complaineeID.$touch()"
                    append-icon="mdi-account-tie"
                    @click:append="selectComplaineeADI()"
                  ></v-text-field>
                  <SelectFromTableDialog
                    :dialog="complaineeADIDialog"
                    dialog-title="Instructor"
                    :headers="tableHeaders"
                    :dataarray="tableData"
                    type="adi"
                    @closed="complaineeADIDialog = false"
                    @choosen="ComplaineeADISelected($event)"
                  />
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="complaineeThirdP">
                  <v-textarea
                    value="(Pleasenote down all the information about this Third Party in the description below including their Full Name, Organisation Name, E-mail, Contact Numbers, Full Address and the Complaint.)"
                    label="Third Party"
                    outlined
                    disabled
                    no-resize
                    rows="3"
                  ></v-textarea>
                  <span></span>
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="complaineeSchool">
                  <v-text-field
                    value="Driving School"
                    label="Complainee"
                    outlined
                    disabled
                  ></v-text-field>
                  <span></span>
                </div>
              </v-expand-transition>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col md="2"
              ><v-text-field
                v-model="form.iDate"
                label="Incident Date"
                outlined
                type="date"
                :error-messages="compDateError"
                @input="$v.form.iDate.$touch()"
                @blur="$v.form.iDate.$touch()"
              ></v-text-field>
            </v-col>

            <v-col md="2">
              <v-text-field
                v-model="form.iTime"
                label="Incident Time"
                outlined
                type="time"
                :error-messages="compTimeError"
                @input="$v.form.iTime.$touch()"
                @blur="$v.form.iTime.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col md="4"
              ><v-textarea
                v-model="form.complainDesc"
                outlined
                name="input-7-4"
                label="Complaint Description"
                placeholder="Please Describe Complaint (Max. 1600 characters)"
                counter="1600"
                clearable
                rows="4"
                auto-grow
                value=""
                :error-messages="compDescError"
                @input="$v.form.complainDesc.$touch()"
                @blur="$v.form.complainDesc.$touch()"
              ></v-textarea>
              <v-select
                v-model="form.selfResolve"
                :items="selfResolveItems"
                label="Did complainant discuss the issue with complainee?"
                outlined
              ></v-select></v-col
          ></v-row>
          <!-- following div will be displayed when self reolve  -->
          <v-expand-transition>
            <div v-if="form.selfResolve">
              <v-divider class="mb-5"></v-divider>
              <v-row justify="center">
                <v-col md="2">
                  <v-text-field
                    v-model="form.srDate"
                    label="Discussion Date With Complainee"
                    outlined
                    type="date"
                    :error-messages="srDateError"
                    @input="$v.form.srDate.$touch()"
                    @blur="$v.form.srDate.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col md="2">
                  <v-text-field
                    v-model="form.srTime"
                    label="Discussion Time With Complainee"
                    outlined
                    type="time"
                    :error-messages="srTimeError"
                    @input="$v.form.srTime.$touch()"
                    @blur="$v.form.srTime.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col md="4"
                  ><v-select
                    v-model="form.srSolution"
                    :items="selfResolveItems"
                    label="Did complainee offer any solution?"
                    outlined
                  ></v-select></v-col
              ></v-row>
              <v-row justify="center">
                <v-col md="4"
                  ><v-textarea
                    v-model="form.srReplyDesc"
                    outlined
                    name="input-7-4"
                    label="Complainee Reply Description"
                    placeholder="Please Describe Complainee Response (Max. 1600 characters)"
                    counter="1600"
                    clearable
                    rows="4"
                    auto-grow
                    value=""
                    :error-messages="srDescError"
                    @input="$v.form.srReplyDesc.$touch()"
                    @blur="$v.form.srReplyDesc.$touch()"
                  ></v-textarea></v-col
              ></v-row>
            </div>
          </v-expand-transition>

          <v-row justify="center">
            <v-btn color="primary" class="mb-5" @click="submitForm()"
              >Record Complaint</v-btn
            >
          </v-row>
        </v-form>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import SelectFromTableDialog from "@/components/CustomInputs/SelectFromTableDialog";
import {
  GET_COMPLAINT_PARTIES,
  M_DD,
  M_OU,
  GET_ALL_LEARNERS,
  GET_ALL_INSTRUCTORS,
  SAVE_NEW_COMPLAINT,
} from "@/store/mutation-list";
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  minValue,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "RecordComplaint",
  components: { SelectFromTableDialog },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "Record Complaint",
          disabled: true,
          href: "/dashboard/recordcomplaint",
        },
      ],

      selfResolveItems: [
        {
          text: "No",
          value: false,
        },
        { text: "Yes", value: true },
      ],

      //form
      form: {
        complaintType: 0,
        //just for display
        complainant: null,
        complainee: null,

        //real data we need it ID
        complainantID: null,
        complaineeID: null,

        //incident date time
        iDate: null,
        iTime: null,

        complainDesc: null,
        // did try to self reolve
        selfResolve: null,

        // self resolve disscussion date time
        srDate: null,
        srTime: null,

        //complainee offer solution
        srSolution: false,
        srReplyDesc: null,
      },
      //dialogs
      complainantLearnerDialog: false,
      complainantADIDialog: false,
      complaineeLearnerDialog: false,
      complaineeADIDialog: false,

      tableHeaders: null,
      tableData: null,

      //complaint type tracking
      //visible componenets
      complainantLearner: false,
      complainantADI: false,
      //VCcomplainantSchool : false, <- school can not complain
      complainantThirdP: false,
      complainantOther: false,

      complaineeLearner: false,
      complaineeADI: false,
      complaineeSchool: false,
      complaineeThirdP: false,
      complaineeOther: false,
    };
  },
  validations: {
    form: {
      complaintType: {
        required,
        minValue: minValue(1),
      },
      complainantID: {
        required: requiredIf(function (Model) {
          if (Model.complaintType > 0 && Model.complaintType <= 8) {
            return true;
          } else {
            return false;
          }
        }),
      },
      complaineeID: {
        required: requiredIf(function (Model) {
          let CT = Model.complaintType;
          if (CT == 1 || CT == 2 || CT == 5 || CT == 6 || CT == 9 || CT == 10) {
            return true;
          } else {
            return false;
          }
        }),
      },

      //incident date time
      iDate: {
        required,
      },
      iTime: {
        required,
      },
      complainDesc: {
        required,
        minLength: minLength(25),
        maxLength: maxLength(1600),
      },

      // self resolve disscussion date time
      srDate: {
        required: requiredIf(function (Model) {
          return Model.selfResolve;
        }),
      },
      srTime: {
        required: requiredIf(function (Model) {
          return Model.selfResolve;
        }),
      },
      //complainee offer solution
      srReplyDesc: {
        required: requiredIf(function (Model) {
          return Model.srSolution;
        }),
        maxLength: maxLength(1600),
      },
    },
  },
  methods: {
    ...mapActions({
      getLearnerData: `${M_OU}${GET_ALL_LEARNERS}`,
      getADIData: `${M_OU}${GET_ALL_INSTRUCTORS}`,
      saveComplaint: `${M_OU}${SAVE_NEW_COMPLAINT}`,
    }),
    //this function will show the to correct boxes
    updateCompPartiesList() {
      //reset data before change
      this.dataRest();
      let index = parseInt(this.form.complaintType);
      switch (index) {
        case 1: {
          //complainant is a learner
          this.complainantLearner = true;
          //complainee is a learner
          this.complaineeLearner = true;
          break;
        }
        case 2: {
          //complainant is a learner
          this.complainantLearner = true;
          //complainee is a Instructor
          this.complaineeADI = true;
          break;
        }
        case 3: {
          //complainant is a learner
          this.complainantLearner = true;
          //complainee is Driving School
          this.complaineeSchool = true;
          break;
        }
        case 4: {
          //complainant is a learner
          this.complainantLearner = true;
          //complainee is Third Party
          this.complaineeThirdP = true;
          break;
        }
        case 5: {
          //complainant is an Instructor
          this.complainantADI = true;
          //complainee is a Learner
          this.complaineeLearner = true;
          break;
        }
        case 6: {
          //complainant is an Instructor
          this.complainantADI = true;
          //complainee is a Instructor
          this.complaineeADI = true;
          break;
        }
        case 7: {
          //complainant is an Instructor
          this.complainantADI = true;
          //complainee is Driving School
          this.complaineeSchool = true;
          break;
        }
        case 8: {
          //complainant is an Instructor
          this.complainantADI = true;
          //complainee is Third Party
          this.complaineeThirdP = true;
          break;
        }
        //school can not complain
        case 9: {
          //complainant is an Third party
          this.complainantThirdP = true;
          //complainee is a Learner
          this.complaineeLearner = true;
          break;
        }
        case 10: {
          //complainant is an Third party
          this.complainantThirdP = true;
          //complainee is a Instructor
          this.complaineeADI = true;
          break;
        }
        case 11: {
          //complainant is an Third party
          this.complainantThirdP = true;
          //complainee is Driving School
          this.complaineeSchool = true;
          break;
        }

        default: {
          //complainant is an Third party
          this.complainantOther = true;
          //complainee is Driving School
          this.complaineeOther = true;
        }
      }
    },
    getLearnerHeaders() {
      return [
        { text: "Learner ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Mobile", value: "mobile" },
        { text: "E-mail", value: "email" },
        { text: "Postcode", value: "address.postcode" },
        { text: "Joined", value: "created" },
      ];
    },
    getADIHeaders() {
      return [
        { text: "Instructor ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Mobile", value: "mobile" },
        { text: "E-mail", value: "email" },
      ];
    },
    selectComplainantLearner() {
      this.getLearnerData()
        .then((rep) => {
          if (rep) {
            this.tableData = rep;
          } else {
            this.tableData = [];
          }
        })
        .finally(() => {
          this.tableHeaders = this.getLearnerHeaders();
          //this.tableData = this.getLearnerData();
          this.complainantLearnerDialog = true;
        });
    },
    complainantLearnerSelected(data) {
      this.form.complainantID = data.id;
      this.form.complainant = "L" + data.id + " - " + data.name; //this need to get from dialog
    },
    selectComplainantADI() {
      this.getADIData()
        .then((rep) => {
          if (rep) {
            this.tableData = rep;
          } else {
            this.tableData = [];
          }
        })
        .finally(() => {
          this.tableHeaders = this.getADIHeaders();
          this.complainantADIDialog = true;
        });
    },
    ComplainantADISelected(data) {
      this.form.complainantID = data.id;
      this.form.complainant = "A" + data.id + " - " + data.name; //this need to get from dialog
    },
    selectComplaineeLearner() {
      this.getLearnerData()
        .then((rep) => {
          if (rep) {
            this.tableData = rep;
          } else {
            this.tableData = [];
          }
        })
        .finally(() => {
          this.tableHeaders = this.getLearnerHeaders();
          //this.tableData = this.getLearnerData();
          this.complaineeLearnerDialog = true;
        });
    },
    ComplaineeLearnerSelected(data) {
      this.form.complaineeID = data.id;
      this.form.complainee = "L" + data.id + " - " + data.name; //this need to get from dialog
    },
    selectComplaineeADI() {
      this.getADIData()
        .then((rep) => {
          if (rep) {
            this.tableData = rep;
          } else {
            this.tableData = [];
          }
        })
        .finally(() => {
          this.tableHeaders = this.getADIHeaders();
          this.complaineeADIDialog = true;
        });
    },
    ComplaineeADISelected(data) {
      this.form.complaineeID = data.id;
      this.form.complainee = "A" + data.id + " - " + data.name; //this need to get from dialog
    },
    //this function will submit the form
    //need to do the validations and right type of form data to make sure no error when submitting this
    submitForm() {
      //lets to do the validations
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let sendObj = {
          complainantType: this.form.complaintType,
          complainant: this.form.complainantID,
          complainee: this.form.complaineeID,
          incidentdate: this.form.iDate,
          incidenttime: this.form.iTime,
          comptdesc: this.form.complainDesc,
          selfresolve: this.getBoolToInt(this.form.selfResolve),
          complaineedscdate: this.form.srDate,
          complaineedsctime: this.form.srTime,
          complaineedscsolution: this.getBoolToInt(this.form.srSolution),
          complaineedsctext: this.form.srReplyDesc,
        };
        this.saveComplaint({ form: sendObj }).then((res) => {
          if (res == "ERROR") {
            this.$fire({
              title: "Error",
              text: "Error recording complaint. Please try again. If error persists please record complaint manually.",
              type: "error",
              allowOutsideClick: false,
              //timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          } else {
            this.$fire({
              title: "Success. Ref# 'C" + res + "'",
              text:
                "Complaint Recorded. Please provide reference to complainant 'C" +
                res +
                "'",
              type: "success",
              allowOutsideClick: false,
            }).then((r) => {
              console.log(r.value);
              //clear all and reloadclear()
              this.dataRest();
            });
          }
        });
      }
    },
    //this function will rest the complant parties
    dataRest() {
      this.complainantLearner = false;
      this.complainantADI = false;
      //VCcomplainantSchool = false; <- school can not complain
      this.complainantThirdP = false;
      this.complainantRegulatory = false;
      this.complainantOther = false;

      this.complaineeLearner = false;
      this.complaineeADI = false;
      this.complaineeSchool = false;
      this.complaineeThirdP = false;
      this.complaineeRegulatory = false;
      this.complaineeOther = false;

      this.form.complainant = null;
      this.form.complainee = null;

      //real data we need it ID
      this.form.complainantID = null;
      this.form.complaineeID = null;
      this.$v.reset;
    },
    getBoolToInt(val) {
      if (val) {
        return 1;
      }
      return 0;
    },
  },
  computed: {
    ...mapGetters({
      complaintParties: `${M_DD}${GET_COMPLAINT_PARTIES}`,
    }),
    compTypeError() {
      const errors = [];
      if (!this.$v.form.complaintType.$dirty) return errors;
      !this.$v.form.complaintType.required &&
        errors.push("Complaint type is required.");
      !this.$v.form.complaintType.minValue &&
        errors.push("Complaint type is required.");
      return errors;
    },
    complainantError() {
      const errors = [];
      if (!this.$v.form.complainantID.$dirty) return errors;
      !this.$v.form.complainantID.required &&
        errors.push("Please select a complainant");
      return errors;
    },
    complaineeError() {
      const errors = [];
      if (!this.$v.form.complaineeID.$dirty) return errors;
      !this.$v.form.complaineeID.required &&
        errors.push("Please select a complainee");
      return errors;
    },
    compDateError() {
      const errors = [];
      if (!this.$v.form.iDate.$dirty) return errors;
      !this.$v.form.iDate.required &&
        errors.push("Please select incident date.");
      return errors;
    },
    compTimeError() {
      const errors = [];
      if (!this.$v.form.iTime.$dirty) return errors;
      !this.$v.form.iTime.required &&
        errors.push("Please select incident time.");
      return errors;
    },
    compDescError() {
      const errors = [];
      if (!this.$v.form.complainDesc.$dirty) return errors;
      !this.$v.form.complainDesc.required &&
        errors.push("Please enter complaint description.");
      !this.$v.form.complainDesc.minLength &&
        errors.push("Complaint description is too short. Min 25 characters");
      !this.$v.form.complainDesc.maxLength &&
        errors.push("Complaint description is too Long. Max 1600 characters");
      return errors;
    },
    srDateError() {
      const errors = [];
      if (!this.$v.form.srDate.$dirty) return errors;
      !this.$v.form.srDate.required &&
        errors.push("Please select discussion date.");
      return errors;
    },
    srTimeError() {
      const errors = [];
      if (!this.$v.form.srTime.$dirty) return errors;
      !this.$v.form.srTime.required &&
        errors.push("Please select discussion time.");
      return errors;
    },
    srDescError() {
      const errors = [];
      if (!this.$v.form.srReplyDesc.$dirty) return errors;
      !this.$v.form.srReplyDesc.required &&
        errors.push("Please enter complainee reply description.");
      !this.$v.form.srReplyDesc.maxLength &&
        errors.push(
          "Complainee reply description is too Long. Max 1600 characters"
        );
      return errors;
    },
  },
};
</script>

<style></style>
