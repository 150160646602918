<template>
  <v-col :md="tblSize">
    <h4 class="mb-5">{{ tableTitle }}</h4>
    <v-card class="mx-auto"
      ><v-simple-table v-if="tableHeadins">
        <template v-slot:default>
          <thead>
            <tr class="text-center">
              <th
                class="text-center"
                v-for="(title, x) in tableHeadins"
                :key="x"
                :colspan="1"
              >
                {{ title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rec, i) in tableData" :key="i" class="text-center">
              <td>{{ rec[tableValueName[0][0]] }}</td>
              <td>
                {{
                  getTheFormatedData(
                    rec[tableValueName[1][0]],
                    tableValueName[1][1],
                    tableValueName[1][2]
                  )
                }}
              </td>
              <td>
                {{
                  rec[tableValueName[1][0]]
                    | precentage(tots[tableValueName[1][0]])
                }}
              </td>
              <td>
                {{
                  getTheFormatedData(
                    rec[tableValueName[2][0]],
                    tableValueName[2][1],
                    tableValueName[2][2]
                  )
                }}
              </td>
              <td>
                {{
                  rec[tableValueName[2][0]]
                    | precentage(tots[tableValueName[2][0]])
                }}
              </td>
              <td>
                {{
                  getTheFormatedData(
                    rec[tableValueName[3][0]],
                    tableValueName[3][1],
                    tableValueName[3][2]
                  )
                }}
              </td>
              <td>
                {{
                  rec[tableValueName[3][0]]
                    | precentage(tots[tableValueName[3][0]])
                }}
              </td>
            </tr>
            <!-- array structure [dataname, bool:is a number, bool: is currency] -->
            <!--tr v-for="(rec, i) in tableData" :key="i" class="text-center">
              <td class="capt" v-if="tableValueName[0][0]">
                {{
                  getTheFormatedData(
                    rec[tableValueName[0][0]],
                    tableValueName[0][2],
                    tableValueName[0][2]
                  )
                }}
              </td>
              <td v-if="tableValueName[1][0]">
                {{
                  getTheFormatedData(
                    rec[tableValueName[1][0]],
                    tableValueName[1][1],
                    tableValueName[1][2]
                  )
                }}
              </td>
              <td v-if="tots">
                {{ rec[tableValueName[1][0]] }}
              </td>
              <td v-if="tableValueName[2][0]">
                {{
                  getTheFormatedData(
                    rec[tableValueName[2][0]],
                    tableValueName[2][1],
                    tableValueName[2][2]
                  )
                }}
              </td>
              <td v-if="tots">
                {{ rec[tableValueName[2][0]] }}
              </td>
              <td v-if="tableValueName[3][0]">
                {{
                  getTheFormatedData(
                    rec[tableValueName[3][0]],
                    tableValueName[3][1],
                    tableValueName[3][2]
                  )
                }}
              </td>
              <td v-if="tots">
                {{ rec[tableValueName[3][0]] }}
              </td>
              <td v-if="tableValueName[4][0] && isFiveCols">
                {{
                  getTheFormatedData(
                    rec[tableValueName[4][0]],
                    tableValueName[4][1],
                    tableValueName[4][2]
                  )
                }}
              </td>
              <td v-if="tots && isFiveCols && tableValueName[4][0]">
                £{{ rec[tableValueName[4][0]] }}
              </td>
            </tr-->
          </tbody>
        </template>
      </v-simple-table>
      <p v-else class="warning">No Data Provided</p></v-card
    ></v-col
  >
</template>
<script>
export default {
  name: "MiniTotalsTable",
  props: [
    "tableData",
    "tots",
    "tableTitle",
    "tblSize",
    "tableHeadins",
    "tableValueName",
    "isFiveCols",
  ],
  methods: {
    getTheFormatedData(text, isNum, isCurrency) {
      /*console.log(
          "Called : (" +
            text +
            ") isNum : (" +
            isNum +
            ") isCurrency : (" +
            isCurrency +
            ")"
        );*/
      if (isCurrency) {
        //add the currency styling
        return this.$options.filters.currencyStyle(text);
      } else if (isNum) {
        return this.$options.filters.numStyle(text);
      } else {
        return text;
      }
    },
    getPres(val, tot) {
      console.log("val :" + val + " Tot : " + tot);
      if (val && tot && tot > val) {
        return this.$options.filters.precentage(val, tot);
      } else return "0%";
    },
  },
  computed: {
    tblValLeng() {
      return this.tableValueName.length;
    },
  },
};
</script>
<style>
.capt {
  text-transform: capitalize;
}
</style>
